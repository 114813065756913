import React, { useState, useEffect, useRef } from 'react'
import {
  Tabs, Drawer, Card
} from 'antd'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { getUrlPushWrapper } from '../../../routes/routes'
import { DefaultTablePagination, departmentObj } from '../../../components/_utils/appUtils'
import { RowTable } from '../../../components/_utils/RowTable'
import { listAllUniversities } from '../../university/actions/university'
import { listAllCountries } from '../../countries/actions/countries'
import lodash from 'lodash'
import { getUser, singleUserProfileFxn } from '../../users/actions/user'

const { TabPane } = Tabs

const CommissionComponent = (props) => {
  let { universityCountry, user, commissionObj, commissionType } = props
  let { currency, countryName } = universityCountry
  let newField = null
  if (countryName) {
    countryName = countryName.trim()
    countryName = countryName.replace(/ /ig, '_')
    newField = countryName.toLowerCase() + 'Level'
  }
  let levelFieldValue = (user[newField] || 1)
  let packageList = {
    1: 'silver',
    2: 'gold',
    3: 'platinum'
  }
  return (
    <>
      {user && newField && levelFieldValue && !lodash.isEqual(commissionObj) ?
        <label className={'label label-success sm'}>
          {currency && commissionType == 'Amount' ? currency : ''}
          {commissionObj[packageList[levelFieldValue]]}
          {commissionType == 'Percentage' ? '%' : ''}
        </label> :
        null}
    </>
  )
}


const commissionStructureInner = (props) => {
  let { agentId = '' } = props
  let [user, setUser] = useState({})
  let [countries, setCountries] = useState([])
  const [itemNo, setItemNo] = useState(1)
  const [selectedRow, setSelectedRow] = useState({})
  const [visibleStu, setVisibleStu] = useState(false)
  const tableRef = useRef()
  const dispatch = useDispatch()


  const apiUkRequest = async (params, countryId) => {
    return new Promise(async (resolve) => {
        params.sortField = 'universityName'
        params.sortOrder = 'ascend'
        params.universityCountry = countryId
        let page = params.page
        if (!params.results) {
          params.results = 100
          params.count = 100
        }
        let respData = await dispatch(listAllUniversities({
          ...params,
          page: page,
          select: ['universityName', 'universityCity', 'universityState', 'ugCommission', 'pgCommission', 'universityCountry', 'commissionType'],
          regExFilters: ['universityName']
        }))
        resolve(respData)
      }
    )
  }

  useEffect(() => {
    loadUser()
    loadCountry()
  }, [])

  const loadCountry = async () => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

    let countryNameList = ['UK', 'Canada', ' Cyprus', 'USA', 'Germany', 'France', 'Netherlands', 'Switzerland']
    if (user.userType == 'admin') {
      countryNameList.push('Canada Onshore')
    }
    let params = {
      select: ['countryName', 'currency'],
      results: 50,
      sortField: '_id',
      sortOrder: 'ascend',
      customQuery: {
        countryName: { $in: countryNameList }
      }
    }

    if (user.department == departmentObj.marketing) {
      let { data } = await dispatch(singleUserProfileFxn({ userId: user._id }))
      if (data.marketingCountries && data.marketingCountries.length) {
        let countries = []
        lodash.each(data.marketingCountries, (item) => {
          countries.push(item.countryId)
        })
        params.customQuery._id = { $in: countries }
        loadDataFxn()
      }
    } else if (user.userType == 'marketingManager') {
      let { data } = await dispatch(singleUserProfileFxn({ userId: user._id }))
      if (data.marketingCountries && data.marketingCountries.length) {
        let countries = []
        lodash.each(data.marketingCountries, (item) => {
          countries.push(item.countryId)
        })
        params.customQuery._id = { $in: countries }
        loadDataFxn()
      }
    } else {
      if (user && user.countryId) {
        params._id = user.countryId
      }
      loadDataFxn()
    }

    async function loadDataFxn() {
      let { data } = await dispatch(listAllCountries(params))
      setCountries(data)
    }

  }

  const loadUser = async () => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    // console.log('userss', user)
    if (user && user._id) {
      let resp = await dispatch(getUser(user._id))
      setUser(resp)

    }
  }

  const openApp = (record) => {
    let studentId = record.studentId._id
    if (user && user.userType && user.userType == 'admin') {
      window.open(`/student/application?studentId=${studentId}&appId=${record.application_id}`, '_blank')
    } else {
      dispatch(
        getUrlPushWrapper('application.singleApplication', {
          appId: record.applications_id,
          studentId: studentId
        }))
    }
  }


  const callback = (key) => {
    //  console.log(key)
  }

  const events = {
    showStuDrawer: (row) => {
      if (row) {
        row.name = row.studentId.name
        row.id = row.studentId.studentId
        row.universityName = row.universityId.universityName
        row.courseName = row.courseId.courseName
      }
      setVisibleStu(true)
      setSelectedRow(row)
    },
    hideStuDrawer: (row) => {
      setVisibleStu(false)
      setSelectedRow({})
    }
  }

  const columns = [

    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (value, item, index) => (itemNo - 1) * 10 + index + 1
    },
    {
      title: 'University Name',
      dataIndex: 'universityName',
      key: 'universityName',
      searchTextName: 'universityName',
      render: (item, record) => {
        return (
          <div>
            {item}
          </div>
        )
      }
    },
    {
      title: 'City Name',
      dataIndex: 'universityCity.cityName',
      key: 'universityCity.cityName',
      render: (item, record) => {
        return (
          <div>
            {item}
          </div>
        )
      }
    },
    {
      title: 'State Name',
      dataIndex: 'universityState.stateName',
      key: 'universityState.stateName',
      render: (item, record) => {
        return (
          <div>
            {item}
          </div>
        )
      }
    },
    {
      title: 'PG commission',
      dataIndex: 'pgCommission',
      key: 'pgCommission',
      render: (item, record) => {
        let { universityCountry, commissionType } = record
        let { currency } = universityCountry
        return (
          <div>
            <CommissionComponent user={user} commissionType={commissionType}
                                 universityCountry={universityCountry}
                                 commissionObj={item} />

          </div>
        )
      }
    },
    {
      title: 'UG commission',
      dataIndex: 'ugCommission',
      key: 'ugCommission',
      render: (item, record) => {
        let { universityCountry, commissionType } = record
        let { currency } = universityCountry
        return (
          <div>

            <CommissionComponent user={user} commissionType={commissionType}
                                 universityCountry={universityCountry}
                                 commissionObj={item} />
          </div>
        )
      }
    }
    // {
    //   title: 'Credit',
    //   dataIndex: 'credit',
    //   key: 'credit',
    //   render: (item, record) => {
    //     return (
    //       <div>
    //         {record.type == 'Credit' ?
    //           <label className={'label label-success-green sm'}>
    //             {record && record.countryId && record.countryId.currency ? record.countryId.currency : ''}{record.commission}
    //           </label> : null}
    //       </div>
    //     )
    //   }
    // }
  ]

  const StudentInfoComponent = (
    <Card>
      <div className={'tableBox striped'}>
        <RowTable title={'Student Name'} value={selectedRow && selectedRow.name ? selectedRow.name : ''} />
        <RowTable title={'Student ID'} value={selectedRow && selectedRow.id ? selectedRow.id : ''} />
        <RowTable title={'App ID'} value={<a className={'hyperLink'}
                                             onClick={() => openApp(selectedRow)}>{selectedRow && selectedRow.applicationId ? selectedRow.applicationId : ''}</a>} />
        <RowTable title={'University'}
                  value={selectedRow && selectedRow.universityName ? selectedRow.universityName : ''} />
        <RowTable title={'Course'} value={selectedRow && selectedRow.courseName ? selectedRow.courseName : ''} />
      </div>
    </Card>
  )

  return (
    <div className='card-body table-responsive'>

      {countries && countries.length ?
        <Tabs defaultActiveKey={0}>
          {
            countries.map((item, i) => {
              return (
                <TabPane tab={item.countryName} key={i}>
                  <TableComp columns={columns}
                             apiRequest={(params) => apiUkRequest(params, item._id)}
                             pagination={DefaultTablePagination()}
                             ref={tableRef} />
                </TabPane>
              )
            })
          }
        </Tabs> : null}


      {/*<Tabs >
        {
          countries && countries.length ? countries.map((item, key) => {
            {
              console.log('key', key)
            }
            return (
              <TabPane tab={'item.countryName'} tab={`Tab-${key}`} key={key}>
                <TableComp columns={columns}
                           apiRequest={apiUkRequest}
                           pagination={DefaultTablePagination()}
                           ref={tableRef} />
              </TabPane>
            )
          }) : null
        }
         <TabPane tab='USA' key='4'>
          <TableComp columns={columns}
                     apiRequest={apiUSARequest}
                     pagination={DefaultTablePagination()}
                     ref={tableRef} />
        </TabPane>
        <TabPane tab='UK' key={1}>
          <TableComp columns={columns}
                     apiRequest={apiUkRequest}
                     pagination={DefaultTablePagination()}
                     ref={tableRef} />
        </TabPane>
        <TabPane tab='Canada' key='2'>
          <TableComp columns={columns}
                     apiRequest={apiCanadaRequest}
                     pagination={DefaultTablePagination()}
                     ref={tableRef} />
        </TabPane>
        <TabPane tab='Australia' key='3'>
          <TableComp columns={columns}
                     apiRequest={apiAUSRequest}
                     pagination={DefaultTablePagination()}
                     ref={tableRef} />
        </TabPane>
        <TabPane tab='USA' key='4'>
          <TableComp columns={columns}
                     apiRequest={apiUSARequest}
                     pagination={DefaultTablePagination()}
                     ref={tableRef} />
        </TabPane>
      </Tabs>*/}

      {visibleStu ?
        <Drawer
          width={'40%'}
          visible={visibleStu}
          onClose={events.hideStuDrawer}
          title={`${selectedRow.name} Info`}>
          {StudentInfoComponent}
        </Drawer> : null}
    </div>
  )
}

export default commissionStructureInner
