import { Icon, Tooltip } from 'antd'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useDispatch } from 'react-redux'
import {
  universityListForMarketing,
  departmentObj,
  currentUdaan,
  udaanQueryObj, countryIds
} from '../../../components/_utils/appUtils'
import { getUrlPushWrapper } from '../../../routes/routes'
import { push } from 'connected-react-router'
import CountryRelationshipManager from '../components/countryRelationshipManager'

let statusList = ['Waiting To Apply', 'Offer Received', 'eCOE Received', 'Visa Approved']
const ButtonLink = props => {
  let {
    searchData,
    item,
    openReport,
    applyForCourse
  } = props
  const btnA = (
    <li className={'canadaDirectUniCount canadaDirectUniBox'} key={item.universityId}>
      <div className={'innerBox'}>
        <div className={'uniCountBox'} onClick={() => searchData(item)}>
          {item && item.logo && item.logo.url ? <LazyLoadImage src={item.logo.url} /> : null}
        </div>
        <div className={'squareContainer'}>
          {statusList.map((status) => {
            return (
              <Tooltip title={status}>
                <div
                  className={'squareStyle'}
                  onClick={() => {
                    openReport({ status, universityId: item.universityId })
                  }}>
                  {item.countArr && item.countArr[status] ? item.countArr[status] : 0}
                </div>
              </Tooltip>
            )
          })}
        </div>

        {/*<div className={'squareContainer'}>
          <Tooltip title={udaanQueryObj.udaanNames[udaanQueryObj.prevUdaan]}>
            <div
              className={'squareStyle'}
              onClick={() => openReport(item, udaanQueryObj.prevUdaan)}>
              {record.prevUdaan ? record.prevUdaan : 0}
            </div>
          </Tooltip>
          <Tooltip title={udaanQueryObj.udaanNames[currentUdaan]}>
            <div
              className={'squareStyle'}
              onClick={() => openReport(item, currentUdaan)}>
              {record.udaan ? record.udaan : 0}
            </div>
          </Tooltip>
          <Tooltip title={'File pending for submission'}>
            <div
              className={'squareStyle'}
              onClick={() => openReport(item, 'File pending for submission')}>
              {record.pendingSubmission ? record.pendingSubmission : 0}
            </div>
          </Tooltip>
          <Tooltip title={'File Submitted'}>
            <div
              className={'squareStyle'}
              onClick={() => openReport(item, 'File Submitted')}>
              {record.fileSubmitted ? record.fileSubmitted : 0}
            </div>
          </Tooltip>
          <Tooltip title={'Last 8 Days'}>
            <div
              className={'squareStyle'}
              onClick={() => openReport(item, 'last8Days')}>
              {record.last8Days ? record.last8Days : 0}
            </div>
          </Tooltip>

        </div>*/}


        <div className={'btnBox'}>
          <a
            onClick={() => {
              applyForCourse(item.universityId)
            }}>
            <Icon type={'plus-circle'} size={13} /> Add Student
          </a>

        </div>
      </div>
    </li>
  )

  return btnA
}

const AustraliaUniversityRecord = props => {
  let { australiaHighlightUniversityRecord, userData, dispatch, relationshipManager } = props
  const events = {
    applyForCourse: (universityId) => {
      // country=612c8bc76efc5360647230ba&countryGroup=Australia
      dispatch(
        getUrlPushWrapper('addStudent', {
          countryGroup: 'Australia',
          country: countryIds.australia,
          university: universityId
        })
      )
    },
    openReport: (data) => {
      dispatch(
        getUrlPushWrapper('application.applicationsList', {
          countryId: countryIds.australia,
          appUniversityId: data.universityId,
          status: data.status
        })
      )
    }
  }

  return userData && userData._id ? (
    <>
      <div className='card-pane-new listing-section w-100 direct-uni-box'
           style={{ border: 'none' }}>
        <ul className={'count flex-wrap'}>
          {australiaHighlightUniversityRecord.map((item) => {
            return (
              <ButtonLink
                key={item.universityId}
                uniId={item.universityId}
                {...props}
                userData={userData}
                item={item}
                openReport={events.openReport}
                applyForCourse={events.applyForCourse}
              />
            )
          })}
          <li>
            <CountryRelationshipManager manager={relationshipManager} currentUser={userData} />
          </li>
        </ul>
      </div>

    </>
  ) : null
}

export default AustraliaUniversityRecord
