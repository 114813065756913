import React, { useRef, useState } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { useDispatch } from 'react-redux'
import { getAirLineListFxn } from '../actions/backAction'

const AirlineListComponent = () => {
  const tableRef = useRef()
  const dispatch = useDispatch()
  let [total, setTotal] = useState(0)
  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 120,
      key: 'sno',
      render: (value, item, index) => index + 1
    },
    {
      title: 'Airline',
      dataIndex: 'airLineName',
      key: 'airLineName'
    },
    {
      title: 'Code',
      dataIndex: 'airLineCode',
      key: 'airLineCode'
    }
  ]

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.sortField = '_id'
      params.sortOrder = 'descend'
      let resp = await dispatch(getAirLineListFxn({ ...params }))
      setTotal(resp.total)
      resolve(resp)
    })
  }

  return (
    <>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex justify-content-between all-student-search'>
              <h5>Airline - <small>(Total : {total})</small></h5>
              <div className='sort-box-table mark-btn text-right'>

              </div>
            </div>

            <div className='card-body table-responsive'>
              <TableComp columns={columns}
                         className={'table table-striped table-valign-middle'}
                         apiRequest={apiRequest}
                         pagination={{
                           position: 'top',
                           pageSizeOptions: ['50', '100', '200', '500'],
                           defaultPageSize: 50,
                           current: 1
                         }}
                         ref={tableRef}
              />
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default AirlineListComponent
