import React, { useState, useEffect } from 'react'
import { Form, Button, notification, Row, Col, Input, Icon, Card, Tooltip, Popconfirm, Table } from 'antd'
import { useDispatch, useSelector, connect } from 'react-redux'
import { getUniversity, updateUniversity } from '../actions/university'
import moment from 'moment'
import _ from 'lodash'
import { listAllCountries } from '../../countries/actions/countries'
import { listAllStates } from '../../state/actions/state'
import { listAllCities } from '../../city/actions/city'
import {
  ConditionOptions,
  countryIds, EligibilityList,
  Intakes,
  UniversityRefArr,
  UniversityTypes
} from '../../../components/_utils/appUtils'
import { InputBox, InterviewOptions, DownloadLink } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import AddCommission from '../drawers/addCommission'

const Edit = (props) => {
  const dispatch = useDispatch()
  let { TextArea } = Input
  let { currentUser } = props
  const [university, setUniversity] = useState({})
  const [showEligibilty, setShowEligibilty] = useState(false)
  const [showCanadaEligibility, setShowCanadaEligibility] = useState(false)
  const [notes, setNotes] = useState('')
  const [notesArr, setNotesArr] = useState([])
  const [universityId, setUniversityId] = useState(null)
  const [state, setState] = useState({
    mediaFiles: [],
    logo: {},
    smallLogo: {},
    oldSmallLogo: {},
    oldLogo: {},
    oldMediaFiles: [],
    oldCampusImages: [],
    oldBanner: {},
    oldUniversityContract: {}
  })
  const [commissions, setCommissions] = useState([])
  const [visibleCommissionDrawer, setVisibleCommissionDrawer] = useState(false)
  const [currencyArr, setCurrencyArr] = useState([])
  const [countryId, setCountryId] = useState('')
  const [allCountries, setAllCountries] = useState([])

  const { loader, allStates, allCities } = useSelector(state => ({
    loader: state.universityReducers.loader,
    allStates: state.stateReducers.allStates,
    allCities: state.cityReducers.allCities
  }))
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields } } = props
  const handleCountrySelect = (x) => {
    // console.log('inside handle counry', x)
    dispatch(listAllStates({
      results: 1000000, customQuery: { country: x },
      sortField: 'stateName',
      sortOrder: 'ascend'
    }))
  }

  const handleStateSelect = (x) => {
    // console.log('inside handle state', x)
    dispatch(listAllCities({
      results: 1000000, customQuery: { state: x },
      sortField: 'cityName',
      sortOrder: 'ascend'
    }))
  }

  useEffect(() => {
    loadAllCountry()
  }, [])
  const loadAllCountry = async () => {
    let { data } = await dispatch(listAllCountries({
      results: 1000000,
      count: 1000000,
      sortField: 'countryName',
      sortOrder: 'ascend'
    }))
    setAllCountries(data)
  }

  const setCurrency = (countryId) => {
    let currencyArr = []
    let findCountry = _.find(allCountries, (item) => {
      return item._id == countryId
    })
    if (findCountry && findCountry.currencyName) {
      currencyArr.push(findCountry.currencyName)
    }
    setCurrencyArr(currencyArr)
  }

  let inputTypes = {
    fields: [
      {
        key: 'universityCountry', label: 'Country', placeholder: 'Country', type: 'select', required: true,
        span: 8,
        options: allCountries,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.countryName}`,
        showSearch: true,
        onChange: x => {
          handleCountrySelect(x)
          setCurrency(x)
          setShowEligibilty(x && (countryIds['uk'] == x || countryIds['usa'] == x) ? true : false)
          setShowCanadaEligibility(x && ((countryIds['canada'] === x || countryIds['australia'] === x) ? true : false))
          props.form.setFieldsValue({
            universityCountry: x,
            universityState: undefined,
            universityCity: undefined
          })

        }
      },
      {
        key: 'contactAddress',
        label: 'Contact Address',
        placeholder: 'Contact Address',
        span: 16,
        style: { marginBottom: 12 }
      },
      {
        key: 'universityState', label: 'State', placeholder: 'State', type: 'select', required: true, span: 8,
        options: allStates,
        keyAccessor: x => x._id,
        showSearch: true,
        valueAccessor: x => `${x.stateName}`,
        onChange: x => {
          handleStateSelect(x)
          props.form.setFieldsValue({
            universityState: x,
            universityCity: undefined
          })
        }
      },
      { key: 'contactName', label: 'Contact Name', placeholder: 'Contact Name', span: 8 },
      {
        key: 'universityCity', label: 'City', placeholder: 'City', type: 'select', required: true, span: 8,
        options: allCities,
        keyAccessor: x => x._id,
        showSearch: true,
        valueAccessor: x => `${x.cityName}`,
        onChange: x => {
          props.form.setFieldsValue({
            universityCity: x
          })
        }
      },
      { key: 'contactPhone', label: 'Contact Phone', placeholder: 'Contact Phone', span: 8 },
      { key: 'universityName', label: 'University Name', placeholder: 'University Name', required: true, span: 8 },
      { key: 'contactEmail', label: 'Contact Email', placeholder: 'Contact Email', span: 8 },
      { key: 'universityWebsite', label: 'Website', placeholder: 'Website', span: 8 },
      {
        key: 'agreementSigningDate',
        label: 'Agreement Signing Date',
        placeholder: 'Agreement Signing Date',
        type: 'date',
        span: 8
      },
      {
        key: 'universityType', span: 8,
        label: 'Type of University',
        placeholder: 'Type of University',
        type: 'select',
        required: true,
        options: UniversityTypes,
        onChange: (universityType) => {
          props.form.setFieldsValue({ universityType })
        }
      },
      {
        key: 'interview', span: 8,
        label: 'Interview',
        placeholder: 'Interview',
        type: 'select',
        options: InterviewOptions,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            interview: x
          })
        }
      },
      { key: 'applicationFee', label: 'Application Fee', placeholder: 'Application Fee', type: 'Number', span: 8 },
      {
        key: 'universityReference', span: 8,
        label: 'Reference',
        placeholder: 'Reference',
        type: 'select',
        required: true,
        options: UniversityRefArr,
        onChange: (universityReference) => {
          props.form.setFieldsValue({ universityReference })
        }
      },
      {
        required: true, span: 8,
        type: 'select',
        options: ['Percentage', 'Amount'],
        name: 'Commission Type',
        label: 'Commission Type',
        placeholder: 'Commission Type',
        key: 'commissionType',
        hidden: !(currentUser && (currentUser.userType == 'admin' || currentUser.userType == 'branchManager')),
        onChange: (e) => {
          setFieldsValue({ commissionType: e })
        }
      },
      {
        label: 'Logo',
        span: 8,
        customField: (
          <InputBox title={'Logo'}>
            <Input type={'file'} name={'logo'} id={'logo'} key={state.mediaKey}
                   className={'form-control'}
                   onChange={(e) => {
                     chooseFiles(e.target.files && e.target.files.length ? e.target.files[0] : null, 'logo')
                   }} />
            <DownloadLink file={state.oldLogo} />
          </InputBox>
        )
      },
      /* {
           label: 'Logo',
           span: 8,
           customField: (
               <InputBox title={'Media Files'}>
                   <Input type={'file'} multiple={true} name={'mediaFile'} id={'mediaFile'} key={state.mediaKey}
                       className={'form-control'}
                       onChange={(e) => {
                           chooseFiles(e.target.files, 'mediaFiles')
                       }} />
               </InputBox>
           )
       },*/
      {
        label: 'Icon',
        span: 8,
        customField: (
          <InputBox title={'Icon Files'}>
            <Input type={'file'} multiple={true} name={'smallLogo'} id={'smallLogo'} key={state.mediaKey}
                   className={'form-control'}
                   onChange={(e) => {
                     chooseFiles(e.target.files && e.target.files.length ? e.target.files[0] : null, 'smallLogo')
                   }} />
            <DownloadLink file={state.oldSmallLogo} />
          </InputBox>
        )
      },
      {
        key: 'isFeatured',
        span: 8,
        label: 'Featured University',
        placeholder: 'Featured University',
        type: 'select',
        options: ConditionOptions,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            isFeatured: x
          })
        }
      },
      {
        key: 'starUniversity',
        span: 8,
        label: 'Star University',
        placeholder: 'Star University',
        type: 'select',
        options: ConditionOptions,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            starUniversity: x
          })
        }
      },
      {
        label: 'Banner',
        span: 8,
        customField: (
          <InputBox title={'Cover Image'}>
            <Input type={'file'} name={'banner'} id={'banner'}
                   key={state.mediaKey}
                   className={'form-control'}
                   onChange={(e) => {
                     chooseFiles(e.target.files && e.target.files.length ? e.target.files[0] : null, 'banner')
                   }} />
            <DownloadLink file={state.oldBanner} />
          </InputBox>
        )
      }

    ]
  }

  const commissionInput = {
    required: true,
    type: 'select',
    options: ['Percentage', 'Amount'],
    name: 'Commission Type',
    label: 'Commission Type',
    key: 'commissionType',
    onChange: (e) => {
      setFieldsValue({ commissionType: e })
    }
  }

  let emptyDiv = (
    <div style={{ height: 20 }}></div>
  )

  let formFields = {
    CampusImg: [
      {
        label: 'Campus Image',
        span: 12,
        customField: (
          <InputBox title={'Campus Images'}>
            <Input type={'file'} multiple={true} name={'campusImg'} id={'campusImg'}
                   key={state.mediaKey}
                   className={'form-control'}
                   onChange={(e) => {
                     chooseFiles(e.target.files && e.target.files.length ? e.target.files : null, 'campusImg')
                   }} /></InputBox>
        )
      }
    ],
    universityCommission: [
      {
        required: true,
        type: 'select',
        options: ['Percentage', 'Amount'],
        name: 'University Commission Type',
        label: 'University Commission Type',
        placeholder: 'University Commission Type',
        key: 'universityCommissionType',
        onChange: (e) => {
          setFieldsValue({ universityCommissionType: e })
        }
      },
      {
        key: 'universityCommission',
        label: (
          <>
            {getFieldValue('universityCommissionType') == 'Percentage' ? 'University Commission in Percentage' : 'University Commission in Amount'}
          </>
        ),
        placeholder: getFieldValue('universityCommissionType') == 'Percentage' ? 'University Commission in Percentage' : 'University Commission in Amount',
        type: 'number',
        required: true
      },
      {
        key: 'noOfUniversityCommission',
        label: 'Number of University Commission',
        placeholder: 'Number of University Commission',
        type: 'number',
        required: true
      },
      {
        required: true,
        type: 'select',
        options: currencyArr,
        name: 'University Commission Currency',
        label: 'University Commission Currency',
        placeholder: 'University Commission Currency',
        key: 'commissionCurrency',
        onChange: (e) => {
          setFieldsValue({ commissionCurrency: e })
        }
      },
      {
        label: 'Icon',
        span: 8,
        customField: (
          <InputBox title={'University Contract'} className={'logoCls'}>
            <Input type={'file'} multiple={true} name={'universityContract'} id={'universityContract'}
                   key={state.mediaKey}
                   className={'form-control'}
                   onChange={(e) => {
                     chooseFiles(e.target.files && e.target.files.length ? e.target.files[0] : null, 'universityContract')
                   }} />
            <DownloadLink file={state.oldUniversityContract} />
          </InputBox>
        )
      }
    ],
    ugCommission: [
      {
        key: 'ugCommission.silver',
        label: 'Silver',
        type: 'number',
        placeholder: 'UG Commission Silver',
        required: true
      },
      {
        key: 'ugCommission.gold',
        label: 'Gold',
        type: 'number',
        placeholder: 'UG Commission Gold',
        required: true
      },
      {
        key: 'ugCommission.platinum',
        label: 'Platinum',
        type: 'number',
        placeholder: 'UG Commission Platinum',
        required: true
      }
    ],
    pgCommission: [
      {
        key: 'pgCommission.silver',
        label: 'Silver',
        type: 'number',
        placeholder: 'PG Commission Silver',
        required: true
      },
      {
        key: 'pgCommission.gold',
        label: 'Gold',
        type: 'number',
        placeholder: 'PG Commission Gold',
        required: true
      },
      {
        key: 'pgCommission.platinum',
        label: 'Platinum',
        type: 'number',
        placeholder: 'PG Commission Platinum',
        required: true
      }
    ],
    eligibility: [
      {
        key: 'minimumCasDeposit',
        type: 'Number',
        label: 'Min',
        prefixCompNew: `Minimum ${getFieldValue('universityCountry') == countryIds.uk ? 'CAS' : ''} Deposit`,
        required: showEligibilty,
        hidden: false
      },
      {
        key: 'maximumCasDeposit',
        label: 'Max',
        type: 'number',
        prefixCompNew: emptyDiv,
        hidden: false
      },
      {
        key: 'ugMinimumRequired',
        label: 'Min',
        type: 'number',
        required: showEligibilty,
        prefixCompNew: 'UG Score Requirement',
        hidden: false,
        span: 8

      },
      /*      {
              key: 'ugMaximumRequired',
              label: 'Max',
              type: 'number',
              prefixCompNew: emptyDiv,
              hidden: false
            },*/
      {
        key: 'pgMinimumRequired',
        label: 'Min',
        type: 'number',
        prefixCompNew: 'PG Score Requirement',
        required: showEligibilty,
        hidden: false,
        span: 8

      },
      /*{
        key: 'pgMaximumRequired',
        label: 'Max',
        type: 'number',
        prefixCompNew: emptyDiv,
        hidden: false
      },*/
      {
        key: 'iletsMinimumForUg',
        label: 'Min',
        type: 'number',
        required: showEligibilty,
        prefixCompNew: 'IELTS for UG',
        hidden: false
      },
      {
        key: 'iletsMaximumForUg',
        label: 'Max',
        type: 'number',
        prefixCompNew: emptyDiv,
        hidden: false
      },
      {
        key: 'iletsMinimumForPg',
        label: 'Min',
        type: 'number',
        required: showEligibilty,
        prefixCompNew: 'IELTS for PG',
        hidden: false

      },
      {
        key: 'iletsMaximumForPg',
        label: 'Max',
        type: 'number',
        prefixCompNew: emptyDiv,
        hidden: false
      },
      {
        key: 'pteMinimumForUg',
        label: 'Min',
        type: 'number',
        required: showEligibilty,
        prefixCompNew: 'PTE for UG',
        hidden: false
      },
      {
        key: 'pteMaximumForUg',
        label: 'Max',
        type: 'number',
        prefixCompNew: emptyDiv,
        hidden: false
      },
      {
        key: 'pteMinimumForPg',
        label: 'Min',
        type: 'number',
        required: showEligibilty,
        prefixCompNew: 'PTE for PG',
        hidden: false
      },
      {
        key: 'pteMaximumForPg',
        label: 'Max',
        type: 'number',
        prefixCompNew: emptyDiv,
        hidden: false
      },
      {
        key: 'minWaiverForCbsc',
        label: 'Min',
        type: 'number',
        // required: showEligibilty,
        prefixCompNew: 'Eng Waiver for CBSE/ICSE',
        hidden: false,
        span: 8

      },
      /* {
         key: 'maxWaiverForCbsc',
         label: 'Max',
         type: 'number',
         prefixCompNew: emptyDiv,
         hidden: false
       },*/
      {
        key: 'timeMinimumDuration',
        label: 'Min',
        // required: showEligibilty,
        prefixCompNew: 'Offer Letter Time Duration',
        type: 'number',
        hidden: false
      },
      {
        key: 'timeMaximumDuration',
        label: 'Max',
        prefixCompNew: emptyDiv,
        type: 'number',
        hidden: false
      },
      {
        key: 'casTimeMinimum',
        label: 'Min',
        // required: showEligibilty,
        prefixCompNew: `${getFieldValue('universityCountry') == countryIds.uk ? 'CAS' : ''} Time (In weeks)`,
        type: 'number',
        hidden: false
      },
      {
        key: 'casTimeMaximum',
        label: 'Max',
        prefixCompNew: emptyDiv,
        type: 'number',
        hidden: false
      },
      {
        key: 'interviewType',
        type: 'select',
        options: ['Audio', 'Video', 'Walking'],
        label: 'Interview Type',
        span: 8,
        hidden: false,
        prefixCompNew: emptyDiv,
        onChange: (e) => {
          setFieldsValue({ interviewType: e })
        }
      },
      {
        key: 'waiverForStateBoard',
        label: 'English Waiver for State Board',
        type: 'select',
        hidden: false,
        prefixCompNew: emptyDiv,
        options: ['Yes', 'No'],
        onChange: (e) => {
          setFieldsValue({ waiverForStateBoard: e })
        },
        span: 8,
        required: showEligibilty
      },
      {
        key: 'englishWaiverForStateBoardMinimum',
        label: 'Min',
        required: showEligibilty,
        prefixCompNew: 'English Waiver for State Board',
        type: 'number',
        hidden: !(getFieldValue('waiverForStateBoard') && getFieldValue('waiverForStateBoard') == 'Yes' ? true : false),
        span: 8
      },
      /*{
        key: 'englishWaiverForStateBoardMaximum',
        label: 'Max',
        prefixCompNew: emptyDiv,
        type: 'number',
        hidden: !(getFieldValue('waiverForStateBoard') && getFieldValue('waiverForStateBoard') == 'Yes' ? true : false)
      },*/
      {
        key: 'admissionFee',
        label: 'Admission Fee',
        type: 'number',
        span: 8,
        prefixCompNew: emptyDiv,
        hidden: false
      },
      {
        key: 'intakes',
        options: Intakes,
        type: 'select',
        label: 'Intake Available',
        mode: 'multiple',
        required: showEligibilty,
        span: 8,
        prefixCompNew: emptyDiv,
        hidden: false,
        onChange: (e) => {
          setFieldsValue({ intakes: e })
        }
      },
      // {
      //   key: 'entryRequirements',
      //   label: 'Eligibility Year',
      //   required: showEligibilty,
      //   span: 8
      // },
      {
        key: 'entryRequirements',
        label: 'Eligibility For UG',
        type: 'number',
        required: showCanadaEligibility,
        prefixCompNew: 'Eligibility Year',
        hidden: false
      },
      {
        key: 'eligibilityForPg',
        label: 'Eligibility For PG',
        type: 'number',
        required: showCanadaEligibility,
        prefixCompNew: emptyDiv,
        hidden: false
      },
      {
        key: 'notes',
        // type: 'textarea',
        label: 'Notes',
        required: showEligibilty,
        hidden: false,
        customField: (
          <div style={{ marginTop: 10 }}>
            <label>Add Notes</label>
            <div className={'justifyContent'}>
              <TextArea rows={1} className={'form-control textAreaHeightAuto'} value={notes}
                        onChange={(e) => {
                          setNotes(e.target.value)
                        }} />
              <div><Icon type={'plus-circle'} style={{ fontSize: '28px', color: 'green', marginLeft: 10 }}
                         onClick={() => {
                           addNotes(notes)
                         }} /></div>
            </div>
          </div>)
      }
    ],
    canadaEligibility: [
      {
        key: 'ugMinimumRequired',
        label: 'Min',
        type: 'number',
        required: showCanadaEligibility,
        prefixCompNew: 'UG Score Requirement',
        span: 8

      },
      /*{
        key: 'ugMaximumRequired',
        label: 'Max',
        type: 'number',
        required: showCanadaEligibility,
        prefixCompNew: emptyDiv
      },*/
      {
        key: 'pgMinimumRequired',
        label: 'Min',
        type: 'number',
        prefixCompNew: 'PG Score Requirement',
        span: 8

        // required: showCanadaEligibility
      },
      {
        key: 'mathRequired',
        label: 'Math',
        type: 'number',
        prefixCompNew: 'Math Score',
        required: false,
        hidden: false,
        span: 8
      },
      {
        key: 'majorForRequirement',
        label: 'Major For Requirement',
        type: 'number',
        prefixCompNew: 'Major For Requirement',
        required: false,
        hidden: countryIds['australia'] !== countryId,
        span: 8
      },
      /*  {
          key: 'pgMaximumRequired',
          label: 'Max',
          type: 'number',
          prefixCompNew: emptyDiv
        },*/
      {
        key: 'iletsMinimumForUg',
        label: 'Min',
        type: 'number',
        required: showCanadaEligibility,
        prefixCompNew: 'IELTS for UG'
      },
      {
        key: 'iletsMaximumForUg',
        label: 'Max',
        type: 'number',
        required: showCanadaEligibility,
        prefixCompNew: emptyDiv
      },
      {
        key: 'iletsMinimumForPg',
        label: 'Min',
        type: 'number',
        prefixCompNew: 'IELTS for PG'

      },
      {
        key: 'iletsMaximumForPg',
        label: 'Max',
        type: 'number',
        prefixCompNew: emptyDiv
      },
      {
        key: 'pteMinimumForUg',
        label: 'Min',
        // type: 'number',
        required: showCanadaEligibility,
        prefixCompNew: 'PTE for UG'
      },
      {
        key: 'pteMaximumForUg',
        label: 'Max',
        type: 'number',
        required: showCanadaEligibility,
        prefixCompNew: emptyDiv
      },
      {
        key: 'pteMinimumForPg',
        label: 'Min',
        type: 'number',
        prefixCompNew: 'PTE for PG'
      },
      {
        key: 'pteMaximumForPg',
        label: 'Max',
        type: 'number',
        prefixCompNew: emptyDiv
      },
      {
        key: 'interviewType',
        type: 'select',
        options: ['Audio', 'Video', 'Walking'],
        label: 'Interview Type',
        span: 8,
        prefixCompNew: emptyDiv,
        onChange: (e) => {
          setFieldsValue({ interviewType: e })
        }
      },
      {
        key: 'admissionFee',
        label: 'Admission Fee',
        type: 'number',
        span: 8,
        prefixCompNew: emptyDiv
      },
      {
        key: 'intakes',
        options: Intakes,
        type: 'select',
        label: 'Intake Available',
        mode: 'multiple',
        prefixCompNew: emptyDiv,
        required: showCanadaEligibility,
        span: 8,
        onChange: (e) => {
          setFieldsValue({ intakes: e })
        }
      },
      // {
      //   key: 'entryRequirements',
      //   label: 'Eligibility Year',
      //   required: showCanadaEligibility,
      //   span: 8
      // },
      {
        key: 'entryRequirements',
        label: 'Eligibility For UG',
        type: 'number',
        required: showCanadaEligibility,
        prefixCompNew: 'Eligibility Year'
      },
      {
        key: 'eligibilityForPg',
        label: 'Eligibility For PG',
        type: 'number',
        required: showCanadaEligibility,
        prefixCompNew: emptyDiv
      },
      {
        key: 'notes',
        // type: 'textarea',
        label: 'Notes',
        required: showCanadaEligibility,
        customField: (
          <div style={{ marginTop: 10 }}>
            <label>Add Notes</label>
            <div className={'justifyContent'}>
              <TextArea rows={1} className={'form-control textAreaHeightAuto'} value={notes}
                        onChange={(e) => {
                          setNotes(e.target.value)
                        }} />
              <div><Icon type={'plus-circle'} style={{ fontSize: '28px', color: 'green', marginLeft: 10 }}
                         onClick={() => {
                           addNotes(notes)
                         }} /></div>
            </div>
          </div>)
      }
    ]
  }

  const addNotes = (data) => {
    if (data === '') {
      notification.warning({
        message: 'Cannot add Empty Note'
      })
      return
    }
    setNotesArr([...notesArr, data])
    setNotes('')
  }

  const removeNotes = (index) => {
    let cloneData = _.clone(notesArr)
    cloneData.splice(index, 1)
    setNotesArr(cloneData)
    // callback(cloneData)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  useEffect(() => {
    dispatch({ type: 'UNIVERSITY_HIDE_LOADER' })
    dispatch(listAllCountries({ results: 1000000, count: 1000000 }))
    dispatch(listAllStates({ results: 1000000 }))
    dispatch(listAllCities({ results: 1000000 }))
  }, [])


  const handleSubmit = async (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      valData.commissions = commissions

      if (showEligibilty) {
        valData.notes = notesArr
        let checkError = _.find(EligibilityList, (item) => {
          if (valData[item.name] !== undefined && valData[item.name] !== null && valData[item.name] < 0) {
            notification.warning({
              message: `${item.label} value cannot be Zero`
            })
            return true
          }
        })
        if (checkError) {
          return
        }
      }
      if (showCanadaEligibility) {
        valData.notes = notesArr
        let checkError = _.find(EligibilityList, (item) => {
          if (valData[item.name] !== undefined && valData[item.name] !== null && valData[item.name] < 0) {
            notification.warning({
              message: `${item.label} value cannot be NULL`
            })
            return true
          }
        })
        if (checkError) {
          return
        }
      }
      if (!err) {
        if (valData.waiverForStateBoard == 'No') {
          valData.englishWaiverForStateBoardMinimum = null
        }
        valData.mediaFiles = state.oldMediaFiles
        valData.campusImages = state.oldCampusImages
        let fd = new FormData()
        fd.append('obj', JSON.stringify(valData))
        if (state.logo && state.logo.name) {
          fd.append('logo', state.logo)
        }
        if (state.smallLogo && state.smallLogo.name) {
          fd.append('smallLogo', state.smallLogo)
        }
        if (state.universityContract && state.universityContract.name) {
          fd.append('universityContract', state.universityContract)
        }
        if (state.banner && state.banner.name) {
          fd.append('banner', state.banner)
        }
        if (state.campusImg && state.campusImg.length) {
          _.each(state.campusImg, (item, key) => {
            fd.append('campus', item)
          })
        }
        if (state.mediaFiles && state.mediaFiles.length) {
          _.each(state.mediaFiles, (item, key) => {
            fd.append('mediaFiles', item)
          })
        }
        await dispatch(updateUniversity(fd, universityId))
        getUniversityData()
        // form.resetFields()
        // dispatch(goBack())
        setState({
          ...state,
          campusImg: [],
          mediaFiles: [],
          logo: {},
          smallLogo: {},
          universityContract: {},
          mediaKey: moment()
        })
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  useEffect(() => {
    getUniversityData()
  }, [])


  const getUniversityData = async () => {
    let searchParams = new URLSearchParams(window.location.search)
    let id = searchParams.get('id')
    setUniversityId(() => id)
    if (id) {
      let data = await dispatch(getUniversity(id))
      if (data) {
        setUniversity(() => data)
        setCommissions(data.commissions)
        getStates(data)
      }
    }
  }
  const getStates = (university) => {
    if (university.universityCountry)
      dispatch(listAllStates({ results: 1000000, customQuery: { country: university.universityCountry._id } }))
    // dispatch(listAllCities({ results: 1000000, customQuery:{country:university.universityCountry._id}}));
  }

  useEffect(() => {
    let signingdate = moment(university.agreementSigningDate)
    if (university.universityCountry && university.universityCountry._id) {
      setCurrency(university.universityCountry._id)
      setCountryId(university.universityCountry._id.toString())
    }
    props.form.setFieldsValue({
      universityCountry: university.universityCountry ? university.universityCountry._id : undefined,
      universityState: university.universityState ? university.universityState._id : undefined,
      universityCity: university.universityCity ? university.universityCity._id : undefined,
      universityName: `${university.universityName || ''}`,
      universityWebsite: `${university.universityWebsite || ''}`,
      universityType: `${university.universityType || ''}`,
      universityPartnership: `${university.universityPartnership || ''}`,
      elicos: `${university.elicos || ''}`,
      contactAddress: `${university.contactAddress || ''}`,
      applicationFee: `${university.applicationFee || ''}`,
      contactName: `${university.contactName || ''}`,
      contactPhone: `${university.contactPhone || ''}`,
      contactEmail: `${university.contactEmail || ''}`,
      enrollmentDocuments: `${university.enrollmentDocuments || ''}`,
      enrollmentProcedure: `${university.enrollmentProcedure || ''}`,
      enrollmentAdmissionOfficer: `${university.enrollmentAdmissionOfficer || ''}`,
      enrollmentConfirmation: `${university.enrollmentConfirmation || ''}`,
      commission: `${university.commission || ''}`,
      paymentTerms: `${university.paymentTerms || ''}`,
      ugCommission: university.ugCommission,
      pgCommission: university.pgCommission,
      interview: university.interview,
      commissionType: university.commissionType,
      universityReference: university.universityReference,
      isFeatured: university.isFeatured,
      starUniversity: university.starUniversity,
      universityCommissionType: university.universityCommissionType,
      noOfUniversityCommission: university.noOfUniversityCommission,
      universityCommission: university.universityCommission,
      commissionCurrency: university.commissionCurrency
    })
    if (university.universityCountry && university.universityCountry._id) {
      let uniId = university.universityCountry._id.toString()
      setShowEligibilty(uniId && (countryIds['uk'] == uniId || countryIds['usa'] == uniId) ? true : false)
      setShowCanadaEligibility(uniId && ((countryIds['canada'] === uniId || countryIds['australia'] === uniId) ? true : false))

      setTimeout(() => {
        props.form.setFieldsValue({
          minimumCasDeposit: university.minimumCasDeposit || undefined,
          maximumCasDeposit: university.maximumCasDeposit || undefined,
          ugMinimumRequired: university.ugMinimumRequired || undefined,
          ugMaximumRequired: university.ugMaximumRequired || undefined,
          pgMinimumRequired: university.pgMinimumRequired || undefined,
          pgMaximumRequired: university.pgMaximumRequired || undefined,
          mathRequired: university.mathRequired || undefined,
          majorForRequirement: university.majorForRequirement || undefined,
          iletsMinimumForUg: university && (university.iletsMinimumForUg || university.iletsMinimumForUg == 0) ? university.iletsMinimumForUg : undefined,
          iletsMaximumForUg: university && (university.iletsMaximumForUg || university.iletsMaximumForUg == 0) ? university.iletsMaximumForUg : undefined,
          iletsMinimumForPg: university && (university.iletsMinimumForPg || university.iletsMinimumForPg == 0) ? university.iletsMinimumForPg : undefined,
          iletsMaximumForPg: university && (university.iletsMaximumForPg || university.iletsMaximumForPg == 0) ? university.iletsMaximumForPg : undefined,
          pteMinimumForUg: university && (university.pteMinimumForUg || university.pteMinimumForUg == 0) ? university.pteMinimumForUg : undefined,
          pteMaximumForUg: university && (university.pteMaximumForUg || university.pteMaximumForUg == 0) ? university.pteMaximumForUg : undefined,
          pteMinimumForPg: university && (university.pteMinimumForPg || university.pteMinimumForPg == 0) ? university.pteMinimumForPg : undefined,
          pteMaximumForPg: university && (university.pteMaximumForPg || university.pteMaximumForPg == 0) ? university.pteMaximumForPg : undefined,
          intakes: university.intakes ? university.intakes : [],
          waiverForStateBoard: university.waiverForStateBoard || undefined,
          minWaiverForCbsc: university.minWaiverForCbsc || undefined,
          maxWaiverForCbsc: university.maxWaiverForCbsc || undefined,
          interviewType: university.interviewType || undefined,
          timeMinimumDuration: university.timeMinimumDuration || undefined,
          timeMaximumDuration: university.timeMaximumDuration || undefined,
          admissionFee: university.admissionFee || undefined,
          casTimeMinimum: university.casTimeMinimum || undefined,
          casTimeMaximum: university.casTimeMaximum || undefined,
          entryRequirements: university.entryRequirements || undefined,
          eligibilityForUg: university.eligibilityForUg || undefined,
          eligibilityForPg: university.eligibilityForPg || undefined
        })
        if (university.notes) {
          setNotesArr(university.notes)
        }
        setTimeout(() => {
          props.form.setFieldsValue({
            englishWaiverForStateBoardMinimum: university && university.englishWaiverForStateBoardMinimum ? university.englishWaiverForStateBoardMinimum : undefined,
            englishWaiverForStateBoardMaximum: university.englishWaiverForStateBoardMaximum || undefined
          })
        }, 200)
      }, 1000)
    }

    setState({
      ...state,
      oldMediaFiles: university.mediaFiles,
      oldCampusImages: university.campusImages,
      oldLogo: university.logo,
      oldSmallLogo: university.smallLogo,
      oldBanner: university.banner,
      oldUniversityContract: university.universityContract
    })
    if (university.agreementSigningDate) {
      props.form.setFieldsValue({
        agreementSigningDate: moment(university.agreementSigningDate)
      })
    }
    if (university.enrollmentExpiry) {
      props.form.setFieldsValue({
        enrollmentExpiry: moment(university.enrollmentExpiry)
      })
    }
    if (university.enrollmentRenewal) {
      props.form.setFieldsValue({
        enrollmentRenewal: moment(university.enrollmentRenewal)
      })
    }

  }, [university])


  const chooseFiles = (files, type) => {
    setState({
      ...state,
      [type]: files
    })
  }

  const commEvents = {
    showCommissionDrawer: () => {
      setVisibleCommissionDrawer(true)
    },
    hideCommissionDrawer: () => {
      setVisibleCommissionDrawer(false)
    },
    submitCommission: (data) => {
      setCommissions((prevData) => {
        return [
          ...prevData,
          data
        ]
      })
    },
    deleteCommission: (index) => {
      let commissionArr = _.clone(commissions)
      commissionArr = _.reject(commissionArr, (item, index1) => {
        return index1 == index
      })
      setCommissions(commissionArr)
    }
  }

  const commColumn = [
    {
      title: '#',
      key: 'index',
      dataIndex: 'index',
      render: (item, record, index) => {
        return index + 1
      }
    },
    {
      title: 'University Commission',
      key: 'universityCommission',
      dataIndex: 'universityCommission'
    },
    {
      title: 'Agent Commission',
      key: 'agentCommission',
      dataIndex: 'agentCommission'
    },

    {
      key: 'actions',
      title: 'Actions',
      fixed: 'right',
      width: 80,
      render: (text, record, index) => {
        return <React.Fragment>
          <Tooltip title='Delete'>
            <Popconfirm title={'Are your sure, you want to delete commission?'}
                        onConfirm={() => commEvents.deleteCommission(index)}
                        okText='Yes' cancelText='No'>
              <a className={'btn'} style={{ marginRight: 6 }}>
                <Icon type={'delete'} />
              </a>
            </Popconfirm>
          </Tooltip>
        </React.Fragment>

      }
    }
  ]
  return <div>
    <div className='row'>
      <div className='col-lg-12'>
        <Form onSubmit={handleSubmit}>
          <div className='form-box mt-4'>

            <div className='d-flex align-items-center mb-3 heading-form'>
              <h5>Update University</h5>
            </div>

            <div className='card unizportal'>
              <Row gutter={24} className={'wrapBox'}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    item.customField && !item.hidden ?
                      <Col span={item.span} md={item.span} sm={item.span} xs={24}
                           key={item.label}>
                        {item.customField}
                      </Col> :
                      <>
                        {!item.hidden &&
                        <Col span={item.span} md={item.span} sm={item.span} xs={24} key={key}
                             className={item.style ? item.style : null}>
                          <GetEachFormFields
                            item={item}
                            getFieldDecorator={getFieldDecorator}
                            formItemLayout={formItemLayout} />
                        </Col>}
                      </>
                  )
                })}

              </Row>
              <Card className={'mt-4'}>
                <div className='inner-form'>
                  <Row gutter={24}>
                    <Col span={8}>
                      <InputBox title={'Media Files'}>
                        <Input type={'file'} multiple={true} name={'mediaFile'} id={'mediaFile'}
                               key={state.mediaKey}
                               className={'form-control'}
                               onChange={(e) => {
                                 chooseFiles(e.target.files, 'mediaFiles')
                               }} />
                      </InputBox>
                    </Col>
                  </Row>
                  <div className='imgFlex'>
                    {state.oldMediaFiles && state.oldMediaFiles.map((item, key) => {
                      return (
                        <div key={key} className='img-column'>
                          <img src={item.url} />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </Card>
              <Card className={'mt-4'}>
                <div className='inner-form'>
                  <Row gutter={24}>
                    {formFields.CampusImg.map((item, key) => {
                      return (
                        <Col span={8} key={key}>
                          {item.customField}
                        </Col>
                      )
                    })}
                  </Row>
                  <div className='imgFlex'>
                    {state.oldCampusImages && state.oldCampusImages.map((item, key) => {
                      return (
                        <div key={key} className='img-column'>
                          <img src={item.url} />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </Card>

              {/*    {currentUser && currentUser.userType == 'admin' ?
                                <Card className={'mt-4'}>
                                    <div className='inner-form'>
                                        <h4>University commission</h4>
                                        <Row gutter={24}>
                                            {formFields.universityCommission.map((item, key) => {
                                                return (
                                                    <Col span={6} key={key}>
                                                        {item.customField ? item.customField : <GetEachFormFields
                                                            item={item}
                                                            getFieldDecorator={getFieldDecorator}
                                                            formItemLayout={formItemLayout}/>}
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </div>
                                </Card> : null}
*/}


              {currentUser && currentUser.userType == 'admin' ?
                <Card
                  title={'Commission'}
                  className={'mt-4'} extra={
                  <a className='btn' onClick={commEvents.showCommissionDrawer}>
                    Add Commission
                  </a>
                }>
                  <Table dataSource={commissions}
                         className={'table table-striped table-valign-middle'}
                         pagination={false} columns={commColumn}
                         bordered={true} />
                </Card> : null}

              {currentUser && (currentUser.userType == 'admin' || currentUser.userType == 'branchManager') ?
                <Card className={'mt-4'}>
                  <div className='inner-form'>
                    <h4>UG commission</h4>
                    <Row gutter={24} className={'wrapBox'}>
                      {formFields.ugCommission.map((item, key) => {
                        return (
                          <Col span={8} key={key}>
                            <GetEachFormFields
                              item={item}
                              getFieldDecorator={getFieldDecorator}
                              formItemLayout={formItemLayout} />
                          </Col>
                        )
                      })}
                    </Row>
                  </div>
                  <div className='inner-form mt-4'>
                    <h4>PG commission</h4>
                    <Row gutter={24} className={'wrapBox'}>
                      {formFields.pgCommission.map((item, key) => {
                        return (
                          <Col span={8} key={key}>
                            <GetEachFormFields
                              item={item}
                              getFieldDecorator={getFieldDecorator}
                              formItemLayout={formItemLayout} />
                          </Col>
                        )
                      })}
                    </Row>
                  </div>
                </Card> : null}

              {showEligibilty ?
                <Card className={'mt-4'}>
                  <div className='inner-form'>
                    <h4>Eligibility Criteria </h4>
                    <Row gutter={18} className={'wrapBox'}>
                      {formFields.eligibility.map((item, key) => {
                        return (
                          item.customField ?
                            <Col span={item.span} md={item.span} sm={item.span} xs={24}
                                 key={item.label}
                                 className={'mt20'}>
                              {item.customField}
                            </Col> :
                            !item.hidden && <Col span={item && item.span ? item.span : 4}
                                                 key={key} className={'mt20'}>
                              <div
                                className={`widthLabel ${!item.span && 'w200'}`}>{item.prefixCompNew}</div>
                              <GetEachFormFields
                                item={item}
                                getFieldDecorator={getFieldDecorator}
                                formItemLayout={formItemLayout} />
                            </Col>
                        )
                      })}
                    </Row>
                  </div>
                </Card> : null}

              {showCanadaEligibility ?
                <Card className={'mt-4'}>
                  <div className='inner-form'>
                    <h4>Eligibility Criteria </h4>
                    <Row gutter={18} className={'wrapBox'}>
                      {formFields.canadaEligibility.map((item, key) => {
                        return (
                          item.customField ?
                            <Col span={item.span} md={item.span} sm={item.span} xs={24}
                                 key={item.label}
                                 className={'mt20'}>
                              {item.customField}
                            </Col> :
                            !item.hidden && <Col span={item && item.span ? item.span : 4}
                                                 key={key} className={'mt20'}>
                              <div
                                className={`widthLabel ${!item.span && 'w200'}`}>{item.prefixCompNew}</div>
                              <GetEachFormFields
                                item={item}
                                getFieldDecorator={getFieldDecorator}
                                formItemLayout={formItemLayout} />
                            </Col>
                        )
                      })}
                    </Row>
                  </div>
                </Card>
                : null}

              {notesArr && notesArr.length ?
                <Card className={'mt-4'}>
                  <div className='inner-form'>
                    <h6>All Notes</h6>
                    {notesArr.map((item, index) => {
                      return (
                        <div className={'justifyContent'} style={{
                          backgroundColor: 'white',
                          padding: 5,
                          marginBottom: 5,
                          border: 1,
                          borderColor: 'black',
                          borderRadius: 5
                        }}>
                          <div style={{
                            alignSelf: 'center',
                            marginLeft: 10
                          }}>{index + 1}.{'  '}{item}</div>
                          <div style={{
                            alignSelf: 'flex-start',
                            marginRight: 10,
                            paddingTop: 0
                          }}><Icon type={'delete'}
                                   theme='filled'
                                   style={{
                                     fontSize: '18px',
                                     color: 'red',
                                     alignSelf: 'flex-start'
                                   }}
                                   onClick={() => removeNotes(index)} />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </Card>
                : null}

              <Form.Item>
                <Button type='primary' htmlType='submit' className='btn'>
                  SAVE
                </Button>
              </Form.Item>

            </div>
          </div>
        </Form>
      </div>
    </div>
    {visibleCommissionDrawer ?
      <AddCommission
        visible={visibleCommissionDrawer}
        onClose={commEvents.hideCommissionDrawer}
        onSubmit={commEvents.submitCommission}
      /> : null}
  </div>
}

const EditUniversity = Form.create()(Edit)
const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUniversity)
