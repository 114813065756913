import React, { useState, useEffect } from 'react'
import { Form, Button, notification, Row, Col, Input } from 'antd'
import { useDispatch } from 'react-redux'
import { singleXTravelExchangeMarginFxn, updateXTravelExchangeMarginFxn } from '../actions'
import { InputBox, numberToWords } from '../../../components/_utils/appUtils'

const UpdateXTravelExchangeMargin = (props) => {
  let { user } = props
  const dispatch = useDispatch()
  const [xTravelExchangeMargin, setXTravelExchangeMargin] = useState(0.00)

  const fetchExchangeMargin = async () => {
    const data = await dispatch(singleXTravelExchangeMarginFxn({ userId: user._id }))
    if (data) {
      setXTravelExchangeMargin(data.xTravelExchangeMargin)
    }
  }

  const handleSubmit = async () => {
    if (!xTravelExchangeMargin) {
      notification.success({
        message: 'Enter exchange margin.'
      })
      return
    }

    if (xTravelExchangeMargin > 20) {
      notification.success({
        message: 'Enter Valid exchange margin.'
      })
      return
    }
    const payload = {
      userId: user._id,
      xTravelExchangeMargin: xTravelExchangeMargin
    }
    const resp = await dispatch(updateXTravelExchangeMarginFxn(payload))
    if (resp.success) {
      notification.success({
        message: 'XTravelWorld Exchange margin updated successfully!'
      })
    } else {
      notification.error({
        message: resp.message
      })
    }
  }

  useEffect(() => {
    fetchExchangeMargin()
  }, [])

  const convertToWords = (num) => {
    const [rupees, paise] = num.toFixed(2).split('.')
    return `${rupees} rupee${rupees > 1 ? 's' : ''} and ${paise} paise`
  }

  return (
    <div className='row'>
      <div className='col-lg-12'>
        <Form>
          <div className='form-box mt-4'>
            <div className='d-flex align-items-center mb-3 heading-form'>
              <h5>Update XTravelWorld Exchange Margin</h5>
            </div>

            <div className='card unizportal'>
              <Row gutter={24}>
                <Col span={6}>
                  <InputBox title={'Exchange Margin (in %)'}>
                    <Input
                      type={'number'}
                      value={xTravelExchangeMargin}
                      className={'form-control'}
                      placeholder='Enter agent margin'
                      addonAfter='%'
                      onChange={(e) => setXTravelExchangeMargin(e.target.value)}
                    />
                  </InputBox>
               {/*   <div className='margin-in-words'>
                    {xTravelExchangeMargin !== undefined ? (
                      <p><b>Amount In Words:</b> {numberToWords(parseFloat(xTravelExchangeMargin))}</p>
                    ) : null}
                  </div>*/}
                </Col>
              </Row>
              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  className='btn'
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default UpdateXTravelExchangeMargin
