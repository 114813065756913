import React, { useState, useEffect } from 'react'
import { Drawer, Form, Button, notification, Card, Row, Col, Input, Tooltip, Icon } from 'antd'
import { useDispatch } from 'react-redux'
import { getSingleContractFxn, updateContract } from '../actions/contract'
import { contractWith, countryIds, InputBox } from '../../../components/_utils/appUtils'
import { listAllCountries } from '../../countries/actions/countries'
import { listQuickSearchUniversities } from '../../university/actions/university'
import moment from 'moment'
import GetEachFormFields from '../../../components/_utils/appFormUtils'

const Edit = (props) => {
  const dispatch = useDispatch()
  let { visible, setVisible } = props
  const [contract, setContract] = useState({})
  let [allCountry, setAllCountry] = useState([])
  let [countryUniversity, setCountryUniversity] = useState([])
  let [contractFile, setContractFile] = useState({})
  let [mediaKey, setMediaKey] = useState(moment())
  let [oldContract, setOldContract] = useState({})

  const chooseFiles = (files) => {
    setContractFile(files)
  }

  const {
    form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields },
    events,
    id,
    universityTypeList
  } = props
  const appEvents = {
    loadCountry: async () => {
      let filters = {
        sortField: 'countryName', sortOrder: 'ascend',
        results: 100,
        count: 100
      }
      let { data } = await dispatch(listAllCountries(filters))
      setAllCountry(data)
    },
    loadUniversity: async (item) => {
      let obj = {
        results: 1000000,
        universityCountry: item,
        sortField: 'universityName',
        sortOrder: 'ascend'
      }
      let data = await dispatch(listQuickSearchUniversities(obj))
      setCountryUniversity(data)
    }
  }

  useEffect(() => {
    appEvents.loadCountry()
  }, [])


  let inputTypes = {
    fields: [
      {
        key: 'contractWith',
        label: 'Contract With',
        required: true,
        type: 'select',
        showSearch: true,
        options: contractWith,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            contractWith: x
          })
        }
      },
      {
        key: 'countryId',
        label: 'Country',
        required: true,
        type: 'select',
        showSearch: true,
        options: allCountry,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.countryName}`,
        onChange: x => {
          props.form.setFieldsValue({
            countryId: x,
            universityId: undefined
          })
          appEvents.loadUniversity(x)
        }
      },
      {
        key: 'universityType',
        label: 'University Type',
        required: true,
        type: 'select',
        showSearch: true,
        options: universityTypeList,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          setFieldsValue({
            universityType: x,
            universityId: undefined,
            universityName: undefined
          })
        }
      },
      {
        key: 'universityId',
        label: 'University',
        required: true,
        type: 'select',
        hidden: !(getFieldValue('universityType') && getFieldValue('universityType') == 'alreadyAdded'),
        showSearch: true,
        options: countryUniversity,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.universityName}`,
        onChange: x => {
          setFieldsValue({
            universityId: x
          })
        }
      },
      {
        key: 'universityName',
        label: 'University',
        required: true,
        placeholder: 'University',
        hidden: !(getFieldValue('universityType') && getFieldValue('universityType') == 'other')
      },
      {
        key: 'startDate',
        label: 'Choose StartDate',
        type: 'date',
        required: true
      },
      {
        key: 'endDate',
        label: 'Choose End Date',
        type: 'date',
        required: true
      },
      {
        label: 'contract',
        customField: (
          <InputBox title={'Contract'} className={'rowFlex'} customStyle={{ marginTop: 10 }}>
            <input type={'file'} name={'contract'} id={'contract'} key={mediaKey}
                   className={'form-control'}
                   onChange={(e) => {
                     chooseFiles(e.target.files && e.target.files.length ? e.target.files[0] : null)
                   }} />
            {oldContract && oldContract.path ?
              <Tooltip title={oldContract.name}> <a className={'linkBtn'} target={'_blank'}
                                                    href={oldContract.path}>
                <Icon type={'eye'}></Icon>
              </a> </Tooltip> : ''}
          </InputBox>
        )
      },
      {
        key: 'details',
        label: 'Details',
        type: 'textarea',
        span: 24
      }
    ]
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const getSingleContract = async () => {
    if (id) {
      let data = await dispatch(getSingleContractFxn({ contractId: id }))
      if (data) {
        setContract(data.data)
      }
    }
  }

  useEffect(() => {
    getSingleContract()
  }, [])

  const handleSubmit = async (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        valData.contractId = id
        let fd = new FormData()
        fd.append('obj', JSON.stringify(valData))
        if (contractFile && contractFile.name) {
          fd.append('contract', contractFile, contractFile.name)
        }
        let resp = await dispatch(updateContract(fd))
        if (resp && !resp.error) {
          events.hideEditDrawer()
          setMediaKey(moment())
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }


  useEffect(() => {
    appEvents.loadUniversity(contract.countryId)
    props.form.setFieldsValue({
      contractWith: `${contract.contractWith || ''}`,
      countryId: `${contract.countryId || ''}`,
      startDate: moment(contract.startDate),
      endDate: moment(contract.endDate),
      universityType: contract.universityType,
      details: `${contract.details || ''}`
    })
    setTimeout(() => {
      props.form.setFieldsValue({
        universityId: `${contract.universityId || ''}`,
        universityName: contract.universityName
      })
    }, 500)
    if (contract.contract && contract.contract.path) {
      setOldContract(contract.contract)
    }
  }, [contract])


  return <div>
    <Drawer
      visible={visible}
      width={'45%'}
      placement='right'
      onClose={events.hideEditDrawer}
      title={'Edit Contract'}>
      <div className='form-box'>
        <div className='card unizportal'>
          <Form onSubmit={handleSubmit}>
            <Row gutter={16} className={'wrapBox'}>
              {inputTypes.fields.map((item, key) => {
                return (
                  <React.Fragment key={key}>
                    {item.customField ?
                      <Col span={item.span ? item.span : 12}>
                        {item.customField}
                      </Col>
                      :
                      !item.hidden && <Col span={item.span ? item.span : 12} key={key}>
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout} />
                      </Col>}
                  </React.Fragment>
                )
              })}
            </Row>
            <Form.Item style={{ marginTop: 25 }}>
              <Button type='primary' htmlType='submit' className={'btn'}>
                ADD
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Drawer>
  </div>
}

const EditContract = Form.create()(Edit)
export default EditContract
