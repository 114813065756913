import React from 'react'
import { Drawer, Card } from 'antd'
import { RowTable } from '../../../components/_utils/RowTable'

const BonusDetailsDrawer = (props) => {
  let { visible, onClose, bonus } = props
  return (
    <Drawer
      title={`Bonus Details`}
      visible={visible}
      onClose={onClose}
      width={'35%'}>
      <Card>
        <div className={'tableBox striped'}>
          {bonus.universityId && bonus.universityId.universityName ?
            <RowTable title={'University'} value={bonus.universityId.universityName} /> : null}
          {bonus.intake && bonus.intake.year ?
            <RowTable title={'Intake'}
                      value={`${bonus.intake.month}, ${bonus.intake.year}`} /> : null}
          <RowTable title={'Details'} value={bonus.otherDetails} />

        </div>
      </Card>
    </Drawer>
  )

}

export default BonusDetailsDrawer
