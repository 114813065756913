import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import {
  addContractUrl,
  getAllContracts,
  getContractWithUrl,
  getsingleContractUrl,
  updateContractUrl
} from '../../contract/api/contract'
import { notification } from 'antd'

export const addContract = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addContractUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const listAllContract = (filters) => async (dispatch) => {
  let config = {
    params: { ...filters },
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(getAllContracts(), config)
  dispatch({ type: 'LIST_ALL_CONTRACT', payload: data.data })
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const getContractGroups = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(getContractWithUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const getSingleContractFxn = valData => async (dispatch) => {
  let data = await axios.post(getsingleContractUrl(), valData, getToken())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    dispatch({ type: 'LIST_CONTRACT', payload: data.data })
  }
  return data.data
}

export const updateContract = (valData, id) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateContractUrl(id), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}
