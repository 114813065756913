import React, { useState } from 'react'
import { Button, Card, Col, DatePicker, Drawer, Form, Icon, Input, notification, Row, Select } from 'antd'
import { InputBox } from '../../../components/_utils/appUtils'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { addStudentInstallmentFxn } from '../actions'

const InstallmentPaymentModal = (props) => {
  let { visible, onClose, record } = props
  let { amount, studentId } = record
  let dispatch = useDispatch()
  let [state, setState] = useState({
    documentKey: moment(),
    sendToStudent: false
  })
  let [installmentNo, setInstallmentNo] = useState()
  let [firstInstallmentDate, setFirstInstallmentDate] = useState()

  const [installments, setInstallments] = useState([])
  console.log(installments, 'installments')

  const calculateInstallments = (totalAmount) => {
    let cloneD = _.clone(installments)
    let installmentAmount = totalAmount * 0.25

    let checkAmt = _.find(cloneD, (item) => item.amount < 0)
    if (checkAmt) {
      notification.warning({ message: 'Please enter amount' })
      return false
    }

    let totalAmt = 0
    _.each(cloneD, (item) => {
      totalAmt += parseInt(item.amount)
    })

    if (totalAmt !== totalAmount) {
      notification.warning({ message: 'Please enter valid amount' })
      return false
    }

    if (cloneD[0].amount < installmentAmount) {
      notification.warning({ message: 'First installment should be greater than 25% of total amount' })
      return false
    }

    return true
  }

  const generateInstallments = (totalAmount, count, firstInstallmentDate) => {
    let installmentArr = []
    let installmentAmount = Math.floor(totalAmount / count)
    let remainingAmount = totalAmount - (installmentAmount * count)

    _.times(count, (item) => {
      let dueDate = moment(firstInstallmentDate).add(item * 4, 'months')

      installmentArr.push({
        amount: installmentAmount,
        createdAt: moment(),
        dueDate: dueDate,
        installmentNo: item + 1,
        status: 'Pending'
      })
    })

    if (remainingAmount > 0) {
      installmentArr[0].amount += remainingAmount
    }

    setInstallments([...installmentArr])
  }

  const handleSearch = (installmentNo) => {
    if (!installmentNo) {
      notification.warning({
        message: 'Please select installments'
      })
      return
    }
    if (!firstInstallmentDate) {
      notification.warning({
        message: 'Please select first installment Date'
      })
      return
    }
    generateInstallments(amount, installmentNo, firstInstallmentDate)
  }

  const handleInstallmentInputChange = (index, field, value) => {
    const updatedInstallments = [...installments]
    updatedInstallments[index][field] = value
    setInstallments(updatedInstallments)
  }


  const events = {
    setLocalState: (e) => {
      let { name, value } = e.target
      setState({
        ...state,
        [name]: value
      })
    },
    chooseDocument: (e) => {
      let { files } = e.target
      if (files && files.length) {
        setState({
          ...state,
          document: files[0]
        })
      }
    },

    handleSubmit: async () => {
      if (!amount) {
        notification.warning({
          message: 'Please Enter Amount'
        })
        return
      }
      if (!calculateInstallments(amount)) {
        return
      }

      let obj = {
        installments,
        studentId,
        paymentType: 'Installment',
        title:'invoice'
      }
      let data = await dispatch(addStudentInstallmentFxn(obj))
      if (data && !data.error) {
        onClose()
      }
    }
  }

  return (
    <Drawer width={800}
            title={`Installment Payment ${amount ? `(Invoice Amount - $ ${amount})` : null}`}
            visible={visible}
            onClose={() => {
              onClose()
            }}>
      <Card bordered={false}>
        <Form>
          <Row justify='space-between' gutter={24}>
            <Col span={10}>
              <InputBox title={'First Installment Date *'}>
                <DatePicker
                  type='date'
                  className='form-control'
                  value={firstInstallmentDate}
                  onChange={(e) =>
                    setFirstInstallmentDate(e)
                  }
                />
              </InputBox>
            </Col>
            <Col span={10}>
              <InputBox title={'Choose Installment *'}>
                <Select
                  value={installmentNo}
                  onChange={(e) => {
                    setInstallmentNo(e)
                  }}
                >
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                </Select>
              </InputBox>
            </Col>
            <Col span={2}>
              <InputBox>
                <a onClick={() => {
                  handleSearch(installmentNo)
                }}
                   className={'btn btn-default mt30'}>
                  <Icon type={'search'} />
                </a>
              </InputBox>
            </Col>
          </Row>

          <hr />
          <Row justify='space-between' gutter={24}>
            <Col span={2}>
              <label>Sr No.</label>
            </Col>
            <Col span={9}>
              <label>Amount</label>
            </Col>
            <Col span={9}>
              <label>Due Date</label>
            </Col>
          </Row>
          <hr />
          {installments && installments.length ? installments.map((installment, index) => (
            <Row key={index} className={'mt10'} justify='space-between' gutter={24}>
              <Col span={2}>
                <div>{index + 1}</div>
              </Col>
              <Col span={9}>
                <Input
                  type='Number'
                  className='form-control'
                  value={installment.amount}
                  onChange={(e) =>
                    handleInstallmentInputChange(index, 'amount', e.target.value)
                  }
                />
              </Col>
              <Col span={9}>
                <DatePicker
                  type='date'
                  className='form-control'
                  value={installment.dueDate}
                  onChange={(e) =>
                    handleInstallmentInputChange(index, 'dueDate', e)
                  }
                />
              </Col>
            </Row>
          )) : null}
          <hr />
        </Form>
        <Form.Item className={'mt20 alignRight'}>
          <Button type='primary' onClick={events.handleSubmit}>
            SUBMIT
          </Button>
        </Form.Item>
      </Card>
    </Drawer>
  )
}
export default InstallmentPaymentModal
