import React, {useState} from 'react';
import {Modal, Button, notification} from 'antd';
import {useDispatch} from 'react-redux';
import {transferAgentFxn} from "../actions";
import AgentTypeheadComponent from "../../../components/_utils/AgentTypeheadComponent";

const TransferAgentModal = (props) => {
    let {visible, onClose, transactionId} = props;
    const [selectedAgent, setSelectedAgent] = useState(null);
    const dispatch = useDispatch();

    const handleSubmit = async () => {
        if (!selectedAgent) {
            notification.warning({message: "Please choose agent."})
            return
        }
        let resp = await dispatch(transferAgentFxn(transactionId, {agentId: selectedAgent}));
        if (resp.success) {
            notification.success({message: resp.message})
            onClose();
        } else {
            notification.error({message: resp.message})
        }
    }

    return (
        <Modal
          title='Transfer Transaction to other agent'
          visible={visible}
          onCancel={onClose}
          footer={null}>
          <AgentTypeheadComponent
            key={selectedAgent}
            agentId={selectedAgent}
            customStyle={''}
            dispatch={dispatch}
            onSelect={value => {
              setSelectedAgent(value)
            }}
          />
          <br />
            <br/>
            <Button type="primary" onClick={handleSubmit}>
                Submit
            </Button>
        </Modal>
    );
};

export default TransferAgentModal;
