import {
  Button,
  Col,
  notification,
  Popconfirm, Row, Select,
  Tooltip
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { ColumnWidth } from '../../WebComponent/columnWidth'
import {
  getUnizHomePropertyListFxn,
  updatedAllPropertyStatusFxn,
  updatePropertyActiveStatusFxn
} from '../action'
import { push } from 'connected-react-router'
import {
  PropertyListStatus
} from '../../../components/_utils/appUtils'
import { getUrlPushWrapper } from '../../../routes/routes'
import { CountryCodeJson as countryCodes } from '../../../components/_utils/countryUtil'
import _ from 'lodash'

let initFilter = {
  name: '',
  id: '',
  property_group: '',
  status: '',
  city: '',
  country: '',
  showPublish: false
}

const { Option } = Select
const InventoryManagement = () => {
  const [total, setTotal] = useState(0)
  const [filterState, setFilterState] = useState(initFilter)
  const [filters, setFilters] = useState(initFilter)
  const [allCountries, setAllCountries] = useState([])
  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = params => {
    return new Promise(async resolve => {
      let resp = events.getParamsForInventoryList()
      params = { ...params, ...resp }
      if (filters.id) params.id = filters.id
      if (filters.name) params.name = filters.name.trim()
      if (filters.property_group) params.property_group = filters.property_group.trim()

      if (filterState.city) {
        params['location.locality.long_name'] = { $regex: filterState.city, $options: 'i' }
      }
      if (filterState.country) {
        params['location.country.long_name'] = { $regex: filterState.country, $options: 'i' }
      }

      let respData = await dispatch(
        getUnizHomePropertyListFxn({
          ...params,
          select: ['inventory_featured_image_path', 'id', 'children_count', 'weekly_price', 'source', 'name', 'active', 'property_group', 'location'],
          regExFilters: ['name', 'property_group', 'id', 'active']
        })
      )
      setTotal(respData.total)
      resolve(respData)
    })
  }


  const loadCountry = () => {
    let designatedArr = []
    _.each(countryCodes, (item) => {
      designatedArr.push({
        name: `${item.code} - ${item.name}`,
        value: item.name
      })
    })
    setAllCountries(designatedArr)
  }

  useEffect(() => {
    loadCountry()
  }, [])

  const events = {
    updateStatus: async (data) => {
      let resp = await dispatch(updatePropertyActiveStatusFxn({ propertyId: data._id }))
      if (resp && resp.success) {
        tableRef.current.reload()
      }
    },
    getParams: (field) => {
      const searchParams = new URLSearchParams(window.location.search)

      return searchParams.get(field) || ''
    },

    getParamsForInventoryList: () => {
      let { id, name, property_group, status } = filterState
      let obj = {}
      if (id) obj.id = id
      if (name) obj.name = name
      if (status) obj.active = status
      if (property_group) obj.property_group = property_group
      return obj
    },
    updateFilterState: (data) => {
      setFilterState({
        ...filterState,
        ...data
      })
    },
    searchFxn: async () => {
      // reload()

      let obj = {}
      if (filterState.id) obj.id = filterState.id
      if (filterState.name) obj.name = filterState.name
      if (filterState.status) obj.status = filterState.status
      if (filterState.property_group) obj.property_group = filterState.property_group
      if (filterState.city) obj.city = filterState.city
      if (filterState.country) obj.country = filterState.country
      if (filterState.id || filterState.name || filterState.status || filterState.property_group || filterState.city || filterState.country) {
        events.updateFilterState({ showPublish: true })
      } else {
        events.updateFilterState({ showPublish: false })
      }

      dispatch(
        getUrlPushWrapper('unizhomeInventoryManagement', {
          ...obj
        })
      )

      tableRef.current.reload()

    },
    clearFxn: async () => {
      let obj = {}
      setFilterState({
        id: '',
        name: '',
        property_group: '',
        status: '',
        city: '',
        country: ''
      })

      dispatch(
        getUrlPushWrapper('unizhomeInventoryManagement', {
          ...obj
        })
      )

      setTimeout(() => {
        if (tableRef && tableRef.current) {
          tableRef.current.reload()
        }
      }, 200)

    },

    setFieldByParams: () => {
      let obj = {}
      for (let key in initFilter) {

        if (initFilter.hasOwnProperty(key) && events.getParams(key)) {
          obj[key] = events.getParams(key)
        }
      }

      setFilterState((prevState) => ({
        ...prevState,
        ...obj
      }))
      setTimeout(() => {
        if (tableRef.current) {
          tableRef.current.reload()
        }
      }, 0)
    },
    updateAllStatus: async () => {
      let otherResp = events.getParamsForInventoryList()
      let params = { ...otherResp }
      if (filters.id) params.id = filters.id
      if (filters.name) params.name = filters.name.trim()
      if (filters.property_group) params.property_group = filters.property_group.trim()
      if (filterState.city) {
        params['location.locality.long_name'] = { $regex: filterState.city, $options: 'i' }
      }
      if (filterState.country) {
        params['location.country.long_name'] = { $regex: filterState.country, $options: 'i' }
      }
      if (filterState.id || filterState.name || filterState.status || filterState.property_group || filterState.city || filterState.country) {
        let resp = await dispatch(updatedAllPropertyStatusFxn({ ...params }))
        if (resp && resp.success) {
          tableRef.current.reload()
        }
      } else {
        notification.warning({
          message: 'Please apply at least one filter'
        })
      }
    }
  }

  useEffect(() => {
    events.setFieldByParams()
  }, [])
  const reload = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.reload()
    }
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (val, record, index) => {
        return index + 1
      }
    },
    /* {
       title: 'Property',
       dataIndex: 'inventory_featured_image_path',
       key: 'inventory_featured_image_path',
       searchTextName: 'propertyName',
       render: (val, record) => {
         return (
           <p>
             <img src={record.inventory_featured_image_path} height='90px' width='100px' />
           </p>
         )
       }
     },*/
    {
      title: 'Property Name',
      dataIndex: 'name',
      key: 'name',
      searchTextName: 'name'
    },
    {
      title: 'Property Id',
      dataIndex: 'id',
      key: 'id',
      searchTextName: 'id'
    },
    {
      title: 'Property Management Group',
      dataIndex: 'property_group',
      key: 'property_group',
      searchTextName: 'property_group'
    },
    {
      title: 'Country',
      dataIndex: 'location.country.long_name',
      key: 'location.country.long_name',
      width: 150,
      render: (item) => {
        return item
      }
    },
    {
      title: 'City',
      dataIndex: 'location.locality.long_name',
      key: 'location.locality.long_name',
      width: 150,
      render: (item) => {
        return item
      }
    },

    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      searchTextName: 'name',
      render: (item, record) => {
        return (
          <ColumnWidth width={120}>
            <Popconfirm title={'Are you sure, you want to update status?'} onConfirm={() => {
              events.updateStatus(record)
            }}>
              {item ? <label className={'label label-success-green'}>Published</label> :
                <label className={'label label-danger'}>Un-Published</label>}
            </Popconfirm>
          </ColumnWidth>
        )
      }
    },
    {
      title: 'Total Rooms',
      dataIndex: 'children_count',
      key: 'children_count',
      searchTextName: 'children_count'
    },
    {
      title: 'Action',
      key: '_id',
      dataIndex: '_id',
      width: 150,
      render: (val, record) => {
        return (
          <React.Fragment>
            <Tooltip title='Edit Property'>
              <a className={'btn btn-default roundNew sm'}
                 onClick={() => {
                   dispatch(push(`/edit-unizhome-property?propertyId=${record._id}`))
                 }}>
                Edit
              </a>
            </Tooltip>
          </React.Fragment>
        )
      }
    }

  ]
  const filterBlock = (
    <div className={'filter_box mt5'}>
      <Row gutter={16} className={'flexWrap'}>


        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='search-box-table round ml0'>
            <input
              className='form-control form-control'
              type='search'
              placeholder='Property Name'
              value={filterState.name}
              onChange={e => {
                events.updateFilterState({ name: e.target.value })
              }}
            />
            <img src='/dist/img/search.png' alt='' />
          </div>
        </Col>


        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='search-box-table round ml0'>
            <input
              className='form-control form-control'
              type='search'
              placeholder='Property Id'
              value={filterState.id}
              onChange={e => {
                events.updateFilterState({ id: e.target.value })
              }}

            />
            <img src='/dist/img/search.png' alt='' />
          </div>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='search-box-table round ml0'>
            <input
              className='form-control form-control'
              type='search'
              placeholder='Property Management Group'
              value={filterState.property_group}
              onChange={e => {
                events.updateFilterState({ property_group: e.target.value })
              }}
            />
            <img src='/dist/img/search.png' alt='' />
          </div>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select
              name='status'
              onChange={item => {
                events.updateFilterState({ status: item })
              }}
              value={filterState.status || 'select status'}
              placeholder='Select Status'
              allowClear={true}
              showSearch={true}
            >

              {PropertyListStatus.map((item, key) => {
                return (
                  <Option value={item.value} key={item.name}>
                    {item.name}
                  </Option>
                )
              })}
            </Select>
          </div>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select
              name='country'
              onChange={item => {
                events.updateFilterState({ country: item })
              }}
              value={filterState.country || 'select country'}
              placeholder='Select Country'
              allowClear={true}
              showSearch={true}
            >

              {allCountries.map((item, key) => {
                return (
                  <Option value={item.value} key={item.value}>
                    {item.value}
                  </Option>
                )
              })}
            </Select>
          </div>
        </Col>

        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='search-box-table round ml0'>
            <input
              className='form-control form-control'
              type='search'
              placeholder='City'
              value={filterState.city}
              onChange={e => {
                events.updateFilterState({ city: e.target.value })
              }}
            />
            <img src='/dist/img/search.png' alt='' />
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={8} sm={8} xs={12} lg={8}>
          <Button onClick={() => events.searchFxn()} className={'roundBtn'}>
            Search
          </Button>
          <Button onClick={() => events.clearFxn()} className={'roundBtn'}>
            Clear
          </Button>
          {
            (filterState.showPublish) &&

            <Popconfirm
              title={`Are you sure, you want to update status for all ${total} properties?`}
              okText={'Yes'}
              cancelText={'No'}
              onConfirm={() => {
                events.updateAllStatus()
              }}>
              <Button className={'roundBtn'}>
                Publish
              </Button>
            </Popconfirm>

          }

        </Col>
      </Row>
    </div>
  )
  return (
    <div className='row mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center all-student-search'>
            <h5>Inventory Management : ({total || 0})</h5>
            <div className='search-box-table round'>
            </div>
            <div className='sort-box-table mark-btn'>
              <a className={'btn btn-default roundNew'}
                 onClick={() => {
                   dispatch(push('/uniz-home/add-property'))
                 }}
              >Add Property</a>
            </div>

          </div>
          <div className='card-body table-responsive'>
            {filterBlock}
            <TableComp
              columns={columns}
              apiRequest={apiRequest}
              ref={tableRef}
              pagination={{
                position: 'top',
                pageSizeOptions: ['20', '50', '75', '100'],
                defaultPageSize: 20
              }}
            />
          </div>
        </div>
      </div>

    </div>
  )
}

export default InventoryManagement
