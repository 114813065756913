import React, { useRef, useState, useEffect } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { useDispatch } from 'react-redux'
import { Col, Icon, notification, Popconfirm, Row, Select, Tooltip } from 'antd'
import { getAirportListFxn, updateAirportMainStatusFxn } from '../actions/backAction'
import { getUrlPushWrapper } from '../../../routes/routes'
import { ConditionOptions, departmentList, filterOption, InputBox } from '../../../components/_utils/appUtils'
import countryJson from '../../../assets/jsonFiles/countries'
import NearestAirportList from '../components/nearestAirportList'

let initState = {
  countryName: undefined,
  countryCode: '',
  cityName: '',
  cityCode: '',
  airportName: '',
  airportCode: '',
  isMainAirport: undefined
}

const FilterComponent = (props) => {
  const { Option } = Select
  let dispatch = useDispatch()
  let { state, events } = props


  return (
    <Row gutter={12} className={'filter_box rowWrap'}>
      <Col span={4}>
        <InputBox title={'Search by name'}>
          <Select
            name='Search By Country'
            onChange={item => events._updateState({ countryName: item })}
            filterOption={filterOption}
            placeholder='Country'
            allowClear={true}
            showSearch={true}
            value={state.countryName || undefined}>
            {countryJson && countryJson.length
              ? countryJson.map((item, key) => {
                return (
                  <Option value={item.name} key={key}>
                    {item.name}
                  </Option>
                )
              })
              : null}
          </Select>
        </InputBox>
      </Col>
      <Col span={4}>
        <InputBox title={'Search by Country Code'}>
          <input
            value={state.countryCode}
            placeholder={'Country Code'}
            onChange={({ target }) => {
              events._updateState({ countryCode: target.value })
            }} />
        </InputBox>
      </Col>
      <Col span={4}>
        <InputBox title={'Search by City Name'}>
          <input
            value={state.cityName}
            placeholder={'City Code'}
            onChange={({ target }) => {
              events._updateState({ cityName: target.value })
            }} />
        </InputBox>
      </Col>
      <Col span={4}>
        <InputBox title={'Search by City Code'}>
          <input
            value={state.cityCode}
            placeholder={'City Code'}
            onChange={({ target }) => {
              events._updateState({ cityCode: target.value })
            }} />
        </InputBox>
      </Col>
      <Col span={4}>
        <InputBox title={'Search by Airport'}>
          <input
            value={state.airportName}
            placeholder={'Airport Name'}
            onChange={({ target }) => {
              events._updateState({ airportName: target.value })
            }} />
        </InputBox>
      </Col>
      <Col span={4}>
        <InputBox title={'Search by Airport Code'}>
          <input
            value={state.airportCode}
            placeholder={'Airport Code'}
            onChange={({ target }) => {
              events._updateState({ airportCode: target.value })
            }} />
        </InputBox>
      </Col>
      <Col span={4}>
        <InputBox title={'Main Airport'}>
          <Select
            value={state.isMainAirport}
            onChange={(value) => {
              events._updateState({ isMainAirport: value })
            }}
            placeholder={'Main Airport'}
            required={true}>
            {ConditionOptions.map((item) => {
              return (
                <Option value={item.value} key={item.name}>{item.name}</Option>
              )
            })}
          </Select>
        </InputBox>
      </Col>

      <Col span={20}>
        <div className={'btn_group mt40'}>
          <a className={'default_btn'} onClick={events.searchFxn}>Search</a>
          <a className={'default_btn'} onClick={events.clearFxn}>Clear</a>
        </div>
      </Col>
    </Row>
  )
}


const AirportListComponent = () => {
  const tableRef = useRef()
  const dispatch = useDispatch()
  let [state, setState] = useState(initState)
  let [total, setTotal] = useState(0)
  let [nearestState, setNearestState] = useState({
    visible: false,
    airportName: '',
    airportId: '',
    airportCode: ''
  })
  useEffect(() => {
    events.setFieldByParams()
  }, [])
  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 80,
      key: 'sno',
      render: (value, item, index) => index + 1
    },
    {
      title: 'Country Name',
      dataIndex: 'countryName',
      key: 'countryName',
      width: 220
    },
    {
      title: 'Country Code',
      dataIndex: 'countryCode',
      key: 'countryCode',
      width: 150
    },
    {
      title: 'City Name',
      dataIndex: 'cityName',
      key: 'cityName',
      width: 220
    },
    {
      title: 'City Code',
      dataIndex: 'cityCode',
      key: 'cityCode',
      width: 150
    },
    {
      title: 'Airport',
      dataIndex: 'airportName',
      key: 'airportName',
      width: 220
    },
    {
      title: 'Code',
      dataIndex: 'airportCode',
      key: 'airportCode',
      width: 150
    },
    {
      title: 'Main Airport',
      dataIndex: 'isMainAirport',
      key: 'isMainAirport',
      width: 150,
      render: (item, record) => {
        return (
          <>
            {item ?
              <div className={'mb5'}>
                <a className={'btn roundNew btn-success-green xs'}>
                  Main Airport
                </a>
              </div>
              : null
            }
            <Popconfirm title={'Are you sure, you want to update Status?'} onConfirm={() => {
              events.updateStatus(record)
            }}>
              <a className={'btn roundNew btn-default xs'}>
                Update Status
              </a>
            </Popconfirm>
          </>
        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 150,
      render: (item, record) => {
        return (
          <>
            <a className={'btn roundNew btn-success xs'} onClick={() => {
              events.showNearestAirportDrawer(record)
            }}>
              Update Nearest
            </a>
          </>
        )
      }
    }
  ]

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let filterParams = await events.getParamsForFilter()
      params = { ...params, ...filterParams }
      params.sortField = '_id'
      params.sortOrder = 'descend'
      let resp = await getAirportListFxn({
        ...params,
        regExFilters: ['countryName', 'countryCode', 'cityName', 'cityCode', 'airportName', 'airportCode']
      })
      setTotal(resp.total)
      resolve(resp)
    })
  }
  const events = {
    _updateState: (data) => {
      setState((prevState) => {
        return {
          ...prevState,
          ...data
        }
      })
    },
    setFieldByParams: async () => {
      let resp = await events.getParamsForFilter()
      let obj = {}
      if (resp.countryName) {
        obj.countryName = resp.countryName
      }
      if (resp.countryCode) {
        obj.countryCode = resp.countryCode
      }
      if (resp.cityName) {
        obj.cityName = resp.cityName
      }

      if (resp.cityCode) {
        obj.cityCode = resp.cityCode
      }
      if (resp.airportName) {
        obj.airportName = resp.airportName
      }
      if (resp.airportCode) {
        obj.airportCode = resp.airportCode
      }
      if (resp.isMainAirport) {
        obj.isMainAirport = resp.isMainAirport
      }
      events._updateState(obj)
    },
    getParamsForFilter: () => {
      return new Promise(resolve => {
        let searchParams = new URLSearchParams(window.location.search)
        let countryName = searchParams.get('countryName')
        let countryCode = searchParams.get('countryCode')
        let cityName = searchParams.get('cityName')
        let cityCode = searchParams.get('cityCode')
        let airportName = searchParams.get('airportName')
        let airportCode = searchParams.get('airportCode')
        let isMainAirport = searchParams.get('isMainAirport')
        let obj = {}

        if (countryName) {
          obj.countryName = countryName
        }
        if (countryCode) {
          obj.countryCode = countryCode
        }
        if (cityName) {
          obj.cityName = cityName
        }

        if (cityCode) {
          obj.cityCode = cityCode
        }
        if (airportName) {
          obj.airportName = airportName
        }
        if (airportCode) {
          obj.airportCode = airportCode
        }
        if (isMainAirport) {
          obj.isMainAirport = isMainAirport
        }
        resolve(obj)
      })
    },
    searchFxn: async () => {
      let obj = {}
      let { countryName, countryCode, cityName, cityCode, airportName, airportCode, isMainAirport } = state
      if (countryName) {
        obj.countryName = countryName
      }
      if (countryCode) {
        obj.countryCode = countryCode
      }
      if (cityName) {
        obj.cityName = cityName
      }

      if (cityCode) {
        obj.cityCode = cityCode
      }
      if (airportName) {
        obj.airportName = airportName
      }
      if (airportCode) {
        obj.airportCode = airportCode
      }
      if (isMainAirport) {
        obj.isMainAirport = isMainAirport
      }
      dispatch(
        getUrlPushWrapper('xTravelWorld.xTravelAirportList', {
          ...obj
        })
      )
      setTimeout(() => {
        if (tableRef && tableRef.current) {
          tableRef.current.reload()
        }
      }, 200)
    },
    clearFxn: async () => {
      events._updateState(initState)
      dispatch(
        getUrlPushWrapper('xTravelWorld.xTravelAirportList')
      )
      setTimeout(() => {
        tableRef.current.reload()
      }, 200)
    },
    updateStatus: async (data) => {
      let resp = await dispatch(updateAirportMainStatusFxn({ airportId: data._id }))
      if (resp && resp.success) {
        tableRef.current.reload()
      }
    },
    showNearestAirportDrawer: (data) => {
      setNearestState({
        visible: true,
        airportName: data.airportName,
        airportId: data._id,
        airportCode: data.airportCode
      })
    },
    hideNearestAirportDrawer: (data) => {
      setNearestState({
        visible: false,
        airportName: '',
        airportId: '',
        airportCode: ''
      })
    }
  }


  return (
    <>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex justify-content-between all-student-search'>
              <h5>Airports - <small>(Total : {total})</small></h5>
              <div className='sort-box-table mark-btn text-right'>
              </div>
            </div>

            <div className='card-body table-responsive'>
              <FilterComponent state={state} events={events} />
              <TableComp columns={columns}
                         className={'table table-striped table-valign-middle'}
                         apiRequest={apiRequest}
                         pagination={{
                           position: 'top',
                           pageSizeOptions: ['50', '100', '200', '500'],
                           defaultPageSize: 50,
                           current: 1
                         }}
                         ref={tableRef}
              />
            </div>
          </div>
        </div>
      </div>
      {nearestState.visible ?
        <NearestAirportList
          {...nearestState}
          onClose={events.hideNearestAirportDrawer}
        /> : null
      }
    </>
  )
}

export default AirportListComponent
