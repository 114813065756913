import React, { useEffect, useState } from 'react'
import { Button, Col, Drawer, Form, notification, Row, Select } from 'antd'
import { useDispatch } from 'react-redux'
import { CommissionIntakeYear, countryIds, filterOption, InputBox, Intakes } from '../../../components/_utils/appUtils'
import AgentTypeheadComponent from '../../../components/_utils/AgentTypeheadComponent'
import { listAllCountries } from '../../countries/actions/countries'
import { listQuickSearchUniversities } from '../../university/actions/university'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { addCommissionBonusFxn } from '../actions'

const AgentAddCommissionBonus = (props) => {
  let dispatch = useDispatch()
  let {
    onClose,
    visible,
    onSubmit
  } = props
  let { Option } = Select
  let [allCountry, setAllCountry] = useState([])
  let [countryUniversity, setCountryUniversity] = useState([])
  let [intakeYearList, setIntakeYearList] = useState([])
  let [intakeYear, setIntakeYear] = useState('')
  let [intakeMonth, setIntakeMonth] = useState('')
  let [agentId, setAgentId] = useState('')
  let [bonus, setBonus] = useState('')
  let [otherDetails, setOtherDetails] = useState('')
  let [countryId, setCountryId] = useState('')
  let [universityId, setUniversityId] = useState('')

  const events = {
    loadCountry: async () => {
      let filters = {
        sortField: 'countryName',
        sortOrder: 'ascend',
        allowBonus: true
      }
      let { data } = await dispatch(listAllCountries(filters))
      setAllCountry(data)
    },
    loadUniversity: async (item) => {
      let obj = {
        results: 1000000,
        universityCountry: item,
        sortField: 'universityName',
        sortOrder: 'ascend'
      }
      let data = await dispatch(listQuickSearchUniversities(obj))
      setCountryUniversity(data)
    },
    loadIntakeYears: () => {
      let intakeYearList = CommissionIntakeYear()
      setIntakeYearList(intakeYearList)
    }
  }

  useEffect(() => {
    events.loadCountry()
    events.loadIntakeYears()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    let valData = {}
    if (!agentId) {
      notification.warning({
        message: 'Please select agent'
      })
      return
    }
    if (!countryId) {
      notification.warning({
        message: 'Please select country'
      })
      return
    }
    if (!universityId) {
      notification.warning({
        message: 'Please select university'
      })
      return
    }
    if (!intakeMonth) {
      notification.warning({
        message: 'Please select intake month'
      })
      return
    }
    if (!intakeYear) {
      notification.warning({
        message: 'Please select intake year'
      })
      return
    }
    if (!bonus) {
      notification.warning({
        message: 'Please enter bonus'
      })
      return
    }

    valData.agentId = agentId
    valData.countryId = countryId
    valData.universityId = universityId
    valData.intake = {
      month: intakeMonth,
      year: intakeYear
    }
    valData.bonus = bonus
    valData.otherDetails = otherDetails
    dispatch(showPageLoad())
    let data = await dispatch(addCommissionBonusFxn({ ...valData }))
    if (data && !data.error) {
      dispatch(hidePageLoad())
      onSubmit()
    } else {
      dispatch(hidePageLoad())
    }
  }

  return (
    <>
      <Drawer
        title={'Add Commission Bonus'}
        onClose={onClose}
        visible={visible}
        width={'45%'}>
        <div className='card unizportal'>
          <Form
            className={'vertical-form'}
            autoComplete='off'
            onSubmit={handleSubmit}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item>
                  <InputBox title={'Select Agent*'}>
                    <AgentTypeheadComponent
                      key={agentId}
                      agentId={agentId}
                      dispatch={dispatch}
                      customStyle={''}
                      onSelect={item => {
                        setAgentId(item)
                      }}
                    />
                  </InputBox>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <InputBox title={'Select Country*'}>
                    <Select
                      name='Country'
                      onChange={(x) => {
                        setCountryId(x)
                        events.loadUniversity(x)
                      }}
                      filterOption={filterOption}
                      allowClear={true}
                      showSearch={true}
                      placeholder={'Select Country'}
                      value={countryId || undefined}>
                      {allCountry && allCountry.length
                        ? allCountry.map((item, key) => {
                          return (
                            <Option value={item._id} key={item._id}>
                              {item.countryName}
                            </Option>
                          )
                        })
                        : null}
                    </Select>
                  </InputBox>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <InputBox title={'Select University*'}>
                    <Select
                      name='University'
                      onChange={(x) => {
                        setUniversityId(x)
                      }}
                      filterOption={filterOption}
                      allowClear={true}
                      showSearch={true}
                      placeholder={'Select University'}
                      value={universityId || undefined}>
                      {countryUniversity && countryUniversity.length
                        ? countryUniversity.map((item, key) => {
                          return (
                            <Option value={item._id} key={item._id}>
                              {item.universityName}
                            </Option>
                          )
                        })
                        : null}
                    </Select>
                  </InputBox>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item>
                  <InputBox title={'Intake Month*'}>
                    <Select
                      name='Country'
                      onChange={(x) => {
                        setIntakeMonth(x)
                      }}
                      allowClear={true}
                      showSearch={true}
                      placeholder={'Select Intake Month'}
                      value={intakeMonth || undefined}>
                      {Intakes && Intakes.length
                        ? Intakes.map((item, key) => {
                          return (
                            <Option value={item} key={item}>
                              {item}
                            </Option>
                          )
                        })
                        : null}
                    </Select>
                  </InputBox>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <InputBox title={'Intake Year*'}>
                    <Select
                      name='Intake Year'
                      onChange={(x) => {
                        setIntakeYear(x)
                      }}
                      allowClear={true}
                      showSearch={true}
                      placeholder={'Intake Year'}
                      value={intakeYear || undefined}>
                      {intakeYearList && intakeYearList.length
                        ? intakeYearList.map((item, key) => {
                          return (
                            <Option value={item} key={item}>
                              {item}
                            </Option>
                          )
                        })
                        : null}
                    </Select>
                  </InputBox>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <InputBox title={'Bonus*'}>
                    <input
                      className='form-control form-control'
                      type='number'
                      placeholder='Enter Bonus'
                      value={bonus}
                      onChange={e => {
                        setBonus(e.target.value)
                      }}
                    />
                  </InputBox>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item>
                  <InputBox
                    title={'Other Details'}
                    customStyle={{ height: 100 }}>
                    <textarea
                      onChange={({ target }) => {
                        setOtherDetails(target.value)
                      }}
                      rows={5}
                      style={{ height: 100 }}
                      value={otherDetails}
                      placeholder='Please enter other details'
                      className={'form-control'}
                    />
                  </InputBox>
                </Form.Item>
              </Col>
            </Row>
            <Button type='primary' htmlType='submit' className={'btn'}>
              Add
            </Button>
          </Form>
        </div>
      </Drawer>
    </>
  )
}
export default AgentAddCommissionBonus
