import { customAxios as axios, getToken } from '../../request'
import { getProfitLossDataUrl } from './api'
import { hidePageLoad, showPageLoad } from '../../modules/actions'

export const getProfitLossStatementFxn = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...await getToken()
  }
  let resp = await axios.get(getProfitLossDataUrl(), config)
  dispatch(hidePageLoad())
  return resp
}
