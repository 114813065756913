import { Col, Icon, Input, Modal, notification, Popover, Row, Tooltip } from 'antd'
import React, { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import {
  AtlantisCollege,
  atlantisCollegeIcon, checkEmailIsValid,
  countryIds, currencySymbols, LarnacaCollege
} from '../../../components/_utils/appUtils'
import { getUrlPushWrapper } from '../../../routes/routes'
import renderHTML from 'react-render-html'
import { useDispatch } from 'react-redux'
import { sendEligibilityCriteriaFxn } from '../../users/actions/user'
import InputBox from '../../../components/_utils/InputBox'

let statusList = ['Waiting To Apply', 'Conditional Offer Received', 'CAS Issued', 'Visa Approved']
const EmailCriteriaModal = (props) => {
  let dispatch = useDispatch()
  let { onClose, universityId, universityName } = props
  let [visible, setVisible] = useState(false)
  let [email, setEmail] = useState('')
  let events = {
    showModal: () => {
      setVisible(true)
    },
    closeModal: () => {
      setVisible(false)
    },
    onSubmit: async () => {
      if (!email) {
        notification.warning({ message: 'Enter email address.' })
        return
      }
      if (checkEmailIsValid(email)) {
        let resp = await dispatch(sendEligibilityCriteriaFxn({ email, universityId }))
        if (resp && resp.success) {
          setEmail('')
          setVisible(false)
        }
      } else {
        notification.warning({ message: 'Enter valid email address.' })
        return
      }
    }
  }
  return (
    <>
      {visible ?
        <Modal visible={visible}
               onCancel={events.closeModal}
               onOk={events.onSubmit}
               okText={'Submit'}
               wrapClassName={'criteriaModalCls'}
               title={`Email eligibility criteria - ${universityName}`}>
          <InputBox>
            <Input placeholder={'Enter Email'} className={'form-control'} onChange={({ target }) => {
              setEmail(target.value)
            }} />
          </InputBox>
        </Modal>
        : null}
      <a className={'hyperLinkUnderLine'} onClick={() => {
        events.showModal()
        onClose()
      }}>Email eligibility criteria</a>
    </>
  )
}

const GetContentComponent = (props) => {
  let { universityName, logo, list, setVisible, universityId } = props
  return (
    <>
      <div style={{ width: 500, padding: 10 }}>
        {logo && logo.url ? <LazyLoadImage src={logo.url} style={{ width: 180, height: 'auto' }} /> : null}
        <div className={'border-box-atlantis'}>
          <ul className={'highlight-uni-ul'}>
            {list.map((item) => {
              return (
                <li>
                  {item ? renderHTML(item) : null}
                </li>
              )
            })}

          </ul>
          {/*    <div className={'align-center'}>
            <EmailCriteriaModal onClose={() => setVisible(false)} universityId={universityId}
                                universityName={universityName} />
          </div>*/}
        </div>
      </div>

    </>
  )
}


const ButtonLink = props => {
  let {
    searchData,
    item,
    openReport,
    applyForCourse
  } = props
  let [visible, setVisible] = useState(false)

  const btnA = (
    <li className={'canadaDirectUniCount canadaDirectUniBox'} key={item.universityId}>
      <div className={'innerBox'}>
        <div className={'uniCountBox'}>
          {item && item.logo && item.logo.url ?
            <LazyLoadImage src={item.logo.url}
                           style={{ width: 120, height: 'auto', maxHeight: 45 }} /> : null}
          <div className={'d-flex flex-d-columns aic'}>
            <div className={'d-flex mb10 mt10'}>
              <Popover
                visible={visible}
                content={<GetContentComponent {...item} setVisible={setVisible} />}
                trigger='hover'
                onVisibleChange={(vs) => {
                  setVisible(vs)
                }}>
                <a className={'btn btn-default roundNew xss mr5'}>Eligibility</a>
              </Popover>
            </div>
          </div>
        </div>
        <div className={'squareContainer'}>
          {statusList.map((status) => {
            return (
              <Tooltip title={status}>
                <div
                  className={'squareStyle'}
                  onClick={() => {
                    openReport({ status, universityId: item.universityId })
                  }}>
                  {item.countArr && item.countArr[status] ? item.countArr[status] : 0}
                </div>
              </Tooltip>
            )
          })}
        </div>
        <div className={'btnBox'}>
          <a
            onClick={() => {
              applyForCourse(item.universityId)
            }}>
            <Icon type={'plus-circle'} size={13} /> Add Student
          </a>

        </div>
      </div>
    </li>
  )

  return btnA
}

const UkHighlightUniversityRecords = props => {
  let { ukHighlightUniversityRecord, userData, dispatch, relationshipManager } = props
  const events = {
    applyForCourse: (universityId) => {
      dispatch(
        getUrlPushWrapper('addStudent', {
          countryGroup: 'UK',
          country: countryIds.australia,
          university: universityId
        })
      )
    },
    openReport: (data) => {
      dispatch(
        getUrlPushWrapper('application.applicationsList', {
          countryId: countryIds.uk,
          appUniversityId: data.universityId,
          status: data.status
        })
      )
    }
  }

  return userData && userData._id ? (
    <>
      <div className='card-pane-new listing-section w-100 direct-uni-box'
           style={{ border: '1px solid #ddd' }}>
        <h5 className={'highlight-uni-title'}>Conditional Offer Letter in less than 24 hours&nbsp; <Icon
          type='arrow-down' /></h5>

        <ul className={'count flex-wrap'}>
          {ukHighlightUniversityRecord.map((item) => {
            return (
              <ButtonLink
                key={item.universityId}
                uniId={item.universityId}
                {...props}
                userData={userData}
                item={item}
                openReport={events.openReport}
                applyForCourse={events.applyForCourse}
              />
            )
          })}

        </ul>
      </div>

    </>
  ) : null
}

export default UkHighlightUniversityRecords
