import React, { useState, useEffect } from 'react'
import { Form, notification, Col, Row } from 'antd'
import { connect, useDispatch } from 'react-redux'
import {
  InputBox,
  numberIntoWords
} from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import AgentTypeheadComponent from '../../../components/_utils/AgentTypeheadComponent'
import FileInput from '../../../components/_utils/fileInput'
import moment from 'moment'
import { allUsersUrlFxn } from '../../users/actions/user'
import { addXTravelTransferRequestFxn } from '../actions'

const XTravelFundTransfer = (props) => {
  const { form: { getFieldDecorator, getFieldValue } } = props
  const dispatch = useDispatch()
  let [agentId, setAgentId] = useState('')
  const [file, setFile] = useState({})
  const [refreshKey, setRefreshKey] = useState(moment())
  const [userData, setUserData] = useState([])


  const handleSubmitFxn = async (e) => {
    const { form } = props
    e.preventDefault()
    try {
      const valData = await form.validateFields()
      if (!valData.requestToUserId) {
        notification.warning({ message: 'Please Choose Request To User' })
        return
      }
      if (!agentId) {
        notification.warning({ message: 'Please Choose Agent' })
        return
      }
      if (valData.amount && valData.amount <= 0) {
        notification.warning({ message: 'Please Enter Amount Greater Then 0' })
        return
      }
      valData.agentId = agentId
      // valData.otp = otp
      valData.status = 'Pending'

      console.log(valData, 'valdata')
      const data = await dispatch(addXTravelTransferRequestFxn(valData))
      if (data.success) {
        setRefreshKey(moment())
        setAgentId('')
        form.resetFields()
      }
    } catch (err) {
      notification.warning({
        message: 'Fill All Required Fields'
      })
    }
  }


  const handleFileChange = (e) => {
    let { files } = e
    setFile(files[0])
  }

  const getUsers = async () => {
    try {
      const response = await dispatch(allUsersUrlFxn({
        customQuery: {
          $or: [{ xTravelFundTransferRight: true }, { userType: 'admin' }]
        },
        select: ['name', 'email', 'displayPassword', 'countryCode', 'mobile', 'logo']
      }))
      const data = response.data || []
      setUserData(response.data)
      return data
    } catch (error) {
      console.error('Error fetching users:', error)
      return []
    }
  }


  useEffect(() => {
    getUsers()
  }, [])

  const inputTypes = {
    fields: [
      {
        key: 'requestToUserId',
        label: 'Request To User',
        required: false,
        placeholder: 'Request To User',
        type: 'select',
        options: userData,
        showStar: true,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            requestToUserId: x
          })
        }
      },
      {
        key: 'details',
        label: 'Details',
        required: true,
        type: 'textArea',
        placeholder: 'Enter Details Here..',
        span: 16
      },
      {
        label: 'Agent',
        required: true,
        customField: (
          <>
            <InputBox title='* Agent' className='rowFlex' customStyle={{ marginTop: 10 }}>
              <div>
                <AgentTypeheadComponent
                  key={agentId}
                  agentId={agentId}
                  dispatch={dispatch}
                  customStyle=''
                  onSelect={item => setAgentId(item)}
                  blockStatus={false}
                />
              </div>
            </InputBox>
          </>
        )
      },
      {
        key: 'amount',
        label: 'Transfer Amount',
        required: true,
        type: 'number',
        placeholder: 'Amount',
        extra: `Amount in Words - ${getFieldValue('amount') ? numberIntoWords(getFieldValue('amount')) : 'Please Enter Transfer Amount'}`
      },
      {
        key: 'fundTransferDocument',
        label: 'Upload File',
        required: false,
        placeholder: 'Upload File',
        type: 'file',
        onChange: ({ target }) => {
          handleFileChange(target)
        },
        span: 8
      }
    ]
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  return (
    <div className='row'>
      <div className='col-lg-12'>
        <div className='form-box mt-4'>
          <div className='d-flex align-items-center mb-3 heading-form'>
            <h5>Add X Travel World Fund Request</h5>
          </div>
          <div className='card unizportal'>
            <Form>
              <Row gutter={24}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <>
                      {item.customField ? !item.hidden &&
                        <Col span={item.span ? item.span : 8}>
                          {item.customField}
                        </Col> :
                        item.type == 'file' ?
                          <Col span={item.span}>
                            <FileInput
                              className={'mt10'}
                              key={refreshKey}
                              name={item.name}
                              label={item.label}
                              span={item.span}
                              chooseDocument={item.onChange}
                            />
                          </Col>
                          : !item.hidden &&
                          <Col span={item.span ? item.span : 8} key={key}>
                            <GetEachFormFields
                              item={item}
                              getFieldDecorator={getFieldDecorator}
                              formItemLayout={formItemLayout} />
                          </Col>}
                    </>
                  )
                })}
              </Row>
              <button className='btn' type='submit' onClick={handleSubmitFxn}>Save</button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ global }) => ({ currentUserRights: global.currentUserRights })

const mapDispatchToProps = (dispatch) => ({ dispatch })

const XTravelTransferWrappedComponent = Form.create()(XTravelFundTransfer)

export default connect(mapStateToProps, mapDispatchToProps)(XTravelTransferWrappedComponent)
