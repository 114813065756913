import React, { useEffect, useState } from 'react'
import { Form, Steps, Row, Col, Button, Input, Card } from 'antd'
import { connect, useDispatch } from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import _ from 'lodash'
import moment from 'moment'
import { listAllCountries } from '../../countries/actions/countries'
import { countryIds, InputBox } from '../../../components/_utils/appUtils'
import { addTargetAction } from '../actions'
import { listAllUsers } from '../../users/actions/user'

const AddTarget = (props) => {
  const {
    form: { getFieldDecorator, setFieldsValue, getFieldValue }
  } = props
  const dispatch = useDispatch()
  let [allCountry, setAllCountry] = useState([])
  let [branchManager, setBranchManager] = useState([])
  let [targetFields, setTargetFields] = useState([])
  useEffect(() => {
    loadCountry()

  }, [])

  const loadBranchManager = async () => {
    let country = getFieldValue('countryId')
    if (country) {
      let { data } = await dispatch(listAllUsers({ userType: 'branchManager', countryId: country }))
      setBranchManager(data)
    }
  }

  useEffect(() => {
    setDataArr()
    loadBranchManager()

  }, [getFieldValue('countryId')])


  const setDataArr = () => {
    let country = getFieldValue('countryId')
    if (country || country === countryIds.uk) {
      let data = [
        { name: 'CAS', target: '' }
      ]
      setTargetFields(data)
    }
    if (country === countryIds.canada) {
      let data = [
        { name: 'FCMT-LOA', target: '' },
        { name: 'Cambie-LOA', target: '' },
        { name: 'LOA', target: '' }
      ]
      setTargetFields(data)
    } else if (country === countryIds.australia) {
      let data = [
        { name: 'ECOE', target: '' }
      ]
      setTargetFields(data)

    } else if (country === countryIds.germany) {
      let data = [
        { name: 'LOA', target: '' }
      ]
      setTargetFields(data)

    } else if (country === countryIds.usa) {
      let data = [
        { name: 'I20', target: '' }
      ]
      setTargetFields(data)

    }
  }

  const loadCountry = async () => {
    let filters = {
      sortField: 'countryName', sortOrder: 'ascend'
    }
    let { data } = await dispatch(listAllCountries(filters))
    setAllCountry(data)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  let formFields = {
    commonFild: [
      {
        key: 'countryId',
        label: 'Country',
        required: true,
        type: 'select',
        options: allCountry,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.countryName}`,
        onChange: x => {
          setFieldsValue({
            countryId: x
          })
          setFieldsValue({ branchManagerId: '' })
        }
      },
      {
        key: 'branchManagerId',
        label: 'Branch Manager',
        required: true,
        type: 'select',
        options: branchManager,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          setFieldsValue({
            branchManagerId: x
          })
        }
      },
      {
        key: 'fromDate',
        label: 'Date',
        required: true,
        placeholder: 'Date',
        type: 'date'

      }
    ]
  }

  const submittargetHandle = (e) => {
    let { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (valData && valData.countryId) {
        let formdata = {
          countryId: valData.countryId,
          fromDate: valData.fromDate,
          toDate: moment(valData.fromDate).add(1, 'year').subtract(1, 'day'),
          branchManagerId: valData.branchManagerId
        }
        formdata.data = targetFields


        let data = await dispatch(addTargetAction(formdata))
        if (data && !data.error) {
          setTargetFields()
          form.resetFields()
        }

      }
    })
  }

  const updateTarget = (value, key) => {
    let cloneData = _.clone(targetFields)
    cloneData[key].target = value
    setTargetFields(cloneData)
  }

  return (
    <div className={'mt-4'}>
      <div className='row'>
        <div className='col-lg-12'>
          <Form className={'vertical-form'} autoComplete='off' onSubmit={submittargetHandle}>
            <div className='form-box'>

              <div className='heading-form stuHeader  d-flex align-items-center mb-3'
                   id={'generalInformation'}>
                <h5><span className='img'><img src='/dist/img/check.svg' alt='' /></span> Add
                  Target</h5>
              </div>
              <div className='card unizportal'>
                {/*general*/}
                <Row gutter={24} className={'rowWrap'}>

                  {formFields.commonFild.map((item, key) => {
                    return (
                      <Col span={8} md={8} sm={12} xs={24} key={key} className={'mb10'}>
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout} />
                      </Col>
                    )
                  })}
                </Row>
                {targetFields && targetFields.length ?
                  <Card>
                    {targetFields && targetFields.length ? targetFields.map((item, key) => {

                      return (
                        <Row key={key} gutter={24} className={'rowWrap'}>
                          <Col span={8} md={8} sm={8} xs={12}>
                            <InputBox title='* Name'>
                              <Form.Item>
                                <Input type={'text'} value={item.name}
                                       disabled={true}
                                       return={true}
                                       className='form-control'
                                />
                              </Form.Item>
                            </InputBox>
                          </Col>
                          <Col span={8} md={8} sm={8} xs={12}>
                            <InputBox title='* Target'>
                              <Form.Item>
                                <Input type={'number'} value={item.target}
                                       return={true}
                                       className='form-control'
                                       formItemLayout={formItemLayout}
                                       required={true}
                                       onChange={(e) => {
                                         updateTarget(e.target.value, key)
                                       }} />
                              </Form.Item>
                            </InputBox>
                          </Col>
                        </Row>
                      )
                    }) : null}
                  </Card>
                  : ''}

                <button className='btn' type='submit'>save
                </button>
              </div>


            </div>
          </Form>
        </div>
      </div>

    </div>
  )
}


const WrappedAddTarget = Form.create()(AddTarget)

const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  null
)(WrappedAddTarget)


