import { apiUrl2 } from '../../../settings'

export const updateXTravelMarginUrl = () => {
  return apiUrl2 + '/x-travel/updateAgentXTravelMargin'
}

export const singleAgentXTravelMarginUrl = () => {
  return apiUrl2 + '/x-travel/getAgentXTravelMargin'
}

export const singleXTravelExchangeMarginUrl = () => {
  return apiUrl2 + '/x-travel/getExchangeMargin'
}

export const updateXTravelExchangeMarginUrl = () => {
  return apiUrl2 + '/x-travel/updateExchangeMargin'
}

export const addXTravelFundTransferRequestUrl = () => {
  return apiUrl2 + '/xTravel/addXTravelFundTransferRequest'
}

export const getAllFundTransferRequestUrl = () => {
  return apiUrl2 + '/xTravel/getAllFundTransferRequest'
}

export const updateFundTransferRequestUrl = () => {
  return apiUrl2 + '/xTravel/updateFundTransferRequest'
}