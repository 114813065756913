import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../../request'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'
import {
  addCountryUrl,
  countryListUrl,
  addUniversityUrl,
  universityListUrl,
  addIcefEventUrl,
  icefEventListUrl,
  updateIcefEventUrl,
  addIcefMeetingUrl,
  icefMeetingListUrl,
  updateIcefMeetingUrl,
  icefMeetingGroupUrl,
  updateIcefMeetingStatusUrl,
  addIcefEventNewUrl,
  icefCompanyListUrl,
  icefAddParticipantUrl,
  icefAddCommentUrl,
  icefCommentListUrl,
  icefParticipantHistoryUrl,
  icefParticipantListUrl,
  singleParticipantUrl,
  icefEditParticipantUrl
} from '../apis'


export const addIcefCountryFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addCountryUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const icefCountryListFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...await getToken()
  }
  let { data } = await axios.get(countryListUrl(), config)
  dispatch(hidePageLoad())
  return data.data
}

export const addIcefUniversityFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addUniversityUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const icefUniversityListFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...await getToken()
  }
  let { data } = await axios.get(universityListUrl(), config)
  dispatch(hidePageLoad())
  return data.data
}

export const addIcefEventFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addIcefEventUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const icefEventListFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...await getToken()
  }
  let { data } = await axios.get(icefEventListUrl(), config)
  dispatch(hidePageLoad())
  return data.data
}
export const updateIcefEventFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateIcefEventUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const addIcefMeetingFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addIcefMeetingUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const updateIcefMeetingFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateIcefMeetingUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const icefMeetingListFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...await getToken()
  }
  let { data } = await axios.get(icefMeetingListUrl(), config)
  dispatch(hidePageLoad())
  return data.data
}
export const updateIcefMeetingStatusFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateIcefMeetingStatusUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const icefMeetingGroupFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(icefMeetingGroupUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const addIcefEventNewFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addIcefEventNewUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const icefCompanyListFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: valData,
    ...await getToken()
  }
  let { data } = await axios.get(icefCompanyListUrl(), config)
  dispatch(hidePageLoad())
  return data
}

export const icefAddParticipantFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(icefAddParticipantUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const icefAddCommentFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())

  let { data } = await axios.post(icefAddCommentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const icefCommentListFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...await getToken()
  }
  let { data } = await axios.get(icefCommentListUrl(), config)
  dispatch(hidePageLoad())
  return data
}

export const icefParticipantHistoryFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(icefParticipantHistoryUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const icefParticipantListFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...await getToken()
  }
  let { data } = await axios.get(icefParticipantListUrl(), config)
  dispatch(hidePageLoad())
  return data
}

export const singleParticipantFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(singleParticipantUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const icefEditParticipantFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(icefEditParticipantUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
