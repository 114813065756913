import React, { useState, useRef } from 'react'
import { Button, Modal, notification } from 'antd'
import { useDispatch } from 'react-redux'
import {
  displayDate, displayTime, emailListObj
} from '../../../components/_utils/appUtils'
import { FundTransferStatusUpdateFxn, getAllFundTransferRequestFxn } from '../actions'
import { TableComp } from 'sz-react-utils-lite'
import lodash from 'lodash'
import { push } from 'connected-react-router'
import InputBox from '../../../components/_utils/InputBox'

const XTravelFundTransferRequestList = () => {
  const dispatch = useDispatch()
  let userData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  let initialState = {
    visible: '',
    requestId: '',
    status: ''
  }
  let [visibleApproveModal, setVisibleApproveModal] = useState(initialState)
  let [reason, setReason] = useState('')

  const tableAppRef = useRef()
  let apiRequest = params => {
    return new Promise(async resolve => {
      if (!params.results) {
        params.results = 50
        params.count = 50
      }
      try {
        let resp = await dispatch(getAllFundTransferRequestFxn({
          ...params
        }))
        resolve(resp)
      } catch (e) {
      }
    })
  }

  const handleStatusUpdate = async () => {
    if (!reason) {
      notification.warning({ message: 'Please Enter Reason.' })
      return
    }
    let obj = {
      status: visibleApproveModal.status,
      reason: reason,
      requestId: visibleApproveModal.requestId
    }
    let resp = await dispatch(FundTransferStatusUpdateFxn(obj))
    if (resp && resp.success) {
      setVisibleApproveModal(initialState)
      setReason('')
      tableAppRef.current.reload()
    }
  }

  let [pageState, setPageState] = useState({ count: 10, page: 1, groupCount: 10, groupPage: 1 })

  const columns = [
    {
      key: '_id',
      title: '#',
      dataIndex: '_id',
      width: 50,
      render: (item, record, index) => {
        return (
          <>
            {pageState.count * (pageState.page - 1) + (index + 1)}
          </>
        )
      }
    },
    {
      title: 'Agent Name',
      key: 'agentId',
      dataIndex: 'agentId',
      width: 150,
      render: (item, record) => {
        return (item.name)
      }
    },
    {
      title: 'Request To User',
      key: 'requestToUserId',
      dataIndex: 'requestToUserId',
      width: 150,
      render: (item, record) => {
        return (item.name)
      }
    },

    {
      title: 'Added On',
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 150,
      render: (item, record) => {
        return (
          <>
            {displayDate(item)}, {displayTime(item)}
          </>
        )
      }
    },
    {
      title: 'Amount',
      width: 150,
      dataIndex: 'amount',
      key: 'amount',
      render: (item, record) => {
        return (
          <>
            Rs. {item}
          </>
        )
      }
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 150,
      render: (item, record) => {
        return (
          <>
            {item}
            {item === 'Approved' && record.approveDate && <div>({displayDate(record.approveDate)})</div>}
            {item === 'Rejected' && record.rejectDate && <div>({displayDate(record.rejectDate)})</div>}
          </>
        )
      }
    },
    {
      title: 'Actions',
      width: 150,
      dataIndex: 'amount',
      key: 'amount',
      render: (item, record) => {
        let { requestToUserId: { _id } } = record
        let { userType } = userData
        let loginUserId = userData._id.toString()
        return (
          <>
            {record.status == 'Pending' && ((_id && _id.toString() == loginUserId) || userType == 'admin') ?
              <>
                <Button className={'btn btn-success-green mr10 sm'}
                        onClick={() => {
                          setVisibleApproveModal({
                            visible: true,
                            requestId: record._id,
                            status: 'Approved'
                          })
                        }}
                >Approve</Button>
                <Button className={'btn btn-danger sm'}
                        onClick={() => {
                          setVisibleApproveModal({
                            visible: true,
                            requestId: record._id,
                            status: 'Rejected'
                          })
                        }}
                >Reject</Button>
              </> : null}
          </>
        )
      }
    }
  ]

  const loadColumns = () => {
    let columnArr = []
    lodash.each(columns, item => {
      if (item.hidden == undefined) {
        columnArr.push(item)
      } else {
        if (!item.hidden) {
          columnArr.push(item)
        }
      }
    })
    return columnArr
  }

  return (
    <div className='row'>
      <div className='col-lg-12'>
        <div className='mt-4'>
          <div className='card unizportal'>
            <div className='table-head d-flex align-items-center'>
              <h5>X Travel World Fund Transfer Request List</h5>
              <div className='search-box-table'>

              </div>

              <div>
                <div className='sort-box-table mark-btn'>
                  {userData && userData.email == emailListObj.namanEmail ? <div>
                    <a className='btn' onClick={() => dispatch(push('/x-travel-add-fund-request'))}>
                      <img src={'../dist/icons/plus.png'} className={'plus'} />Add Request</a>
                  </div> : null}
                </div>
              </div>
            </div>
            <div className='card-body table-responsive'>
              <TableComp
                columns={loadColumns()}
                rowKey={(item, index) => {
                  return index
                }}
                apiRequest={apiRequest}
                pagination={{
                  position: 'top',
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '20', '30', '50'],
                  defaultPageSize: 10
                }}
                ref={tableAppRef}
              />
            </div>
          </div>
        </div>
      </div>
      {
        visibleApproveModal && visibleApproveModal.visible &&
        <Modal
          visible={visibleApproveModal.visible}
          closable={true}
          onCancel={() => {
            setVisibleApproveModal({
              visible: false,
              requestId: '',
              status: ''
            })
            setReason('')
          }}
          title={`Enter ${visibleApproveModal && visibleApproveModal.status && visibleApproveModal.status} Reason`}
          footer={false}>
          <div style={{ textAlign: 'center', marginBottom: 10 }}>
            <InputBox title={'* Enter Reason'}>
              <input
                className={'form-control mt10'}
                type={'textArea'}
                value={reason}
                onChange={({ target }) => {
                  setReason(target.value)
                }}
              />
            </InputBox>
            <Button
              className={'btn btn-success mt10'}
              onClick={handleStatusUpdate}>
              Submit
            </Button>
          </div>
        </Modal>
      }
    </div>
  )
}

export default XTravelFundTransferRequestList
