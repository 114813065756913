import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, connect } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { Col, DatePicker, Popconfirm, Row, Select, Tooltip } from 'antd'
import { getAgentCommissionBonusListFxn } from '../actions'
import {
  displayDate,
  filterOption,
  longDisplayDate,
  InputBox,
  dateFilterRange, countryIds
} from '../../../components/_utils/appUtils'
import { getUrlPushWrapper } from '../../../routes/routes'
import { listAllUsers } from '../../users/actions/user'
import _ from 'lodash'
import AgentAddCommissionBonus from './agentAddCommissionBonus'
import AgentTypeheadComponent from '../../../components/_utils/AgentTypeheadComponent'

const { Option } = Select
const { RangePicker } = DatePicker

let addCommissionBonusInit = {
  visible: false,
  record: {}
}

let initFilters = {
  agentId: '',
  invoiceNo: '',
  fromDate: '',
  toDate: ''
}

const FilterComponent = (props) => {
  let dispatch = useDispatch()
  let { filterData, events } = props
  let [agentList, setAgentList] = useState([])
  const loadMarketingUsers = async () => {
    let obj = {
      userType: 'agent',
      results: 5000,
      count: 5000,
      select: ['name', 'companyName']
    }
    let { data } = await dispatch(listAllUsers(obj))
    setAgentList(data)
  }


  return (
    <Row gutter={12} className={'filter_box'}>
      <Col span={4}>
        <InputBox title={'Search by invoice no'}>
          <input
            value={filterData.invoiceNo}
            placeholder={'Invoice No'}
            onChange={({ target }) => {
              events.updateFilter({ invoiceNo: target.value })
            }} />
        </InputBox>
      </Col>
      <Col span={4}>
        <InputBox title={'Search by date'}>
          <RangePicker
            defaultValue={[filterData.fromDate, filterData.toDate]}
            onChange={val => {
              events.updateFilter({ fromDate: val[0], toDate: val[1] })
            }}
            ranges={dateFilterRange}
          />
        </InputBox>
      </Col>
      <Col span={4}>

        <InputBox title={'Search by agent'}>
          <AgentTypeheadComponent
            key={filterData.agentId}
            agentId={filterData.agentId}
            customStyle={''}
            dispatch={dispatch}
            onSelect={item => {
              events.updateFilter({ agentId: item })
            }}
          />
        </InputBox>
      </Col>


      <Col span={24}>
        <div className={'btn_group'}>
          <a className={'default_btn'} onClick={events.searchFxn}>Search</a>
          <a className={'default_btn'} onClick={events.clearFxn}>Clear</a>
        </div>
      </Col>
    </Row>
  )
}

const AgentCommissionBonusList = (props) => {
  let { form, currentUser } = props
  let [totalRecord, setTotalRecord] = useState(0)
  let [filterData, setFilterData] = useState(initFilters)
  const [addCommissionBonusState, setAddCommissionBonusState] = useState(addCommissionBonusInit)

  const tableRef = useRef()
  const dispatch = useDispatch()
  useEffect(() => {
    filterEvents.setFieldByParams()
  }, [])
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let resp = await filterEvents.getParamsForApplicationList()
      params = { ...params, ...resp }
      let { toDate, fromDate } = params
      if (fromDate) {
        let date = {}
        date.$gte = new Date(fromDate)
        date.$lte = new Date(toDate)
        params.invoiceDate = date
        delete params['fromDate']
        delete params['toDate']
      }

      params.transactionType = 'Credit'
      params.sortField = '_id'
      params.sortOrder = 'descend'

      let respData = await dispatch(getAgentCommissionBonusListFxn({
        ...params,
        regExFilters: ['semester', 'courseName', 'name']
      }))
      setTotalRecord(respData.total)
      resolve(respData)
    })
  }
  const reload = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.reload()
    }
  }

  const events = {
    showAddCommissionBonusDrawer: (record) => {
      setAddCommissionBonusState({
        visible: true,
        record: record
      })
    },
    hideAddCommissionBonusDrawer: () => {
      setAddCommissionBonusState({
        visible: false,
        record: {}
      })
    }
  }

  const columns = [
    {
      title: 'Invoice No',
      key: 'invoiceNo',
      dataIndex: 'invoiceNo',
      width: 100,
      render: (item) => {
        return (
          <div style={{ width: 100 }}>
            {item}
          </div>
        )
      }
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      width: 120,
      render: (item, record) => {
        return (
          <div style={{ width: 150 }}>
            {item ? displayDate(item) : ''}
          </div>
        )
      }
    },
    {
      title: 'Agent',
      key: 'agentId',
      dataIndex: 'agentId',
      width: 180,
      render: (item) => {
        return (
          <div style={{ width: 150 }}>
            {item && item.name ? item.name : null}
            {item && item.companyName ? `(${item.companyName})` : null}
          </div>
        )
      }
    },
    {
      title: 'Country',
      key: 'countryId',
      dataIndex: 'countryId',
      width: 100,
      render: (item, record) => {
        return (
          <div style={{ width: 100 }}>
            {item && item.countryName ? item.countryName : null}
          </div>
        )
      }
    },
    {
      title: 'Invoice Address',
      key: 'invoiceAddress',
      dataIndex: 'invoiceAddress',
      width: 150,
      render: (item) => {
        return (
          <div style={{ width: 150 }}>
            {item}
          </div>
        )
      }
    },
    {
      title: 'University',
      dataIndex: 'universityId',
      key: 'universityId',
      width: '200',
      render: (item, record) => {
        return (
          <>
            {item && item.universityName ? item.universityName : null}
          </>
        )
      }
    },
    {
      title: 'Month/Year',
      dataIndex: 'intake',
      key: 'intake',
      width: 150,
      render: (item, record) => {
        return (
          item ? <>
            {item ? item.month : ''}, {item ? item.year : ''}
          </> : null
        )
      }
    },
    {
      title: 'Details',
      dataIndex: 'otherDetails',
      key: 'otherDetails',
      render: (item, record) => {
        return item || ''
      }
    },
    {
      title: 'Refer to Manager',
      dataIndex: 'referToManager',
      key: 'referToManager',
      width: 150,
      render: (item, record) => {
        return (
          <div className={'view-btn-box'}>
            {item == 'Pending' && <>
              <label className={'label label-warning'}>Approval Pending</label>
            </>}
            {item == 'Verified' && <>
              <label className={'label label-success'}>Verified</label>
            </>}
          </div>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'invoiceStatus',
      key: 'invoiceStatus',
      width: 100,
      render: (item, record) => {
        return (
          <div style={{ width: 100 }}>
            {item == 'Approved' ?
              <label className={'label label-success'}>Approved</label> :
              <label className={'label label-danger'}>Pending</label>}
          </div>
        )
      }
    },
    {
      title: 'Bonus',
      dataIndex: 'totalCommission',
      key: 'totalCommission',
      width: 120,
      render: (item, record) => {
        let { totalApprovedByAdminCommission, countryId, bonus, totalCommissionBonus, type } = record
        return (
          <div style={{ width: 150 }}>
            {type !== 'Bonus' && totalApprovedByAdminCommission &&
            <div>
              {countryId && countryId.currency ? countryId.currency : ''}
              {totalApprovedByAdminCommission ? totalApprovedByAdminCommission : item} &nbsp;
              {countryId && countryId.currencyName ? countryId.currencyName : ''}
            </div>
            }
            {type !== 'Bonus' && bonus && totalCommissionBonus ? <div>
              {countryId && countryId.currency ? countryId.currency : ''}
              {totalCommissionBonus ? totalCommissionBonus : ''}&nbsp;
              {countryId && countryId.currencyName ? countryId.currencyName : ''}
            </div> : null}

            {type === 'Bonus' && bonus && totalApprovedByAdminCommission ? <div>
              {countryId && countryId.currency ? countryId.currency : ''}
              {totalApprovedByAdminCommission ? totalApprovedByAdminCommission : ''}&nbsp;
              {countryId && countryId.currencyName ? countryId.currencyName : ''}
            </div> : null}
          </div>
        )
      }
    },
    {
      title: 'Added By',
      key: 'userId',
      dataIndex: 'userId',
      width: 160,
      render: (item, record) => {
        return (
          <div style={{ width: 160 }}>
            <div className={'colorPrimary font12 textCap'}>
              {item && item.name ? item.name : null}
            </div>
            <div>{longDisplayDate(record.createdAt)}</div>
          </div>
        )
      }
    }
  ]

  let filterEvents = {
    getParams: (field) => {
      let searchParams = new URLSearchParams(window.location.search)
      return searchParams.get(field) ? searchParams.get(field) : ''
    },
    updateFilter: (data) => {
      setFilterData((prevState) => {
        return {
          ...prevState,
          ...data
        }
      })
    },
    setFieldByParams: async () => {
      let { getParams } = filterEvents
      let obj = {}
      _.each(initFilters, (item, key) => {
        if (getParams(key)) {
          obj[key] = getParams(key)
        }
      })
      setFilterData((prevState) => {
        return {
          ...prevState,
          ...obj
        }
      })
      setTimeout(() => {
        if (tableRef && tableRef.current) {
          tableRef.current.reload()
        }
      }, 100)
    },
    getParamsForApplicationList: () => {
      return new Promise(resolve => {
        let obj = {}
        _.each(initFilters, (item, key) => {
          if (filterData[key]) {
            obj[key] = filterData[key]
          }
        })
        resolve(obj)
      })
    },
    searchFxn: async () => {
      let obj = {}
      _.each(initFilters, (item, key) => {
        if (filterData[key]) {
          obj[key] = filterData[key]
        }
      })
      dispatch(getUrlPushWrapper('commission.agentCommissionBonusList', { ...obj }))
      setTimeout(() => {
        if (tableRef && tableRef.current) {
          tableRef.current.reload()
        }
      }, 200)
    },
    clearFxn: () => {
      let obj = {}
      dispatch(getUrlPushWrapper('commission.agentCommissionBonusList', { ...obj }))
      setFilterData((prevState) => {
        return {
          ...prevState,
          ...initFilters
        }
      })
      setTimeout(() => {
        tableRef.current.reload()
      }, 200)
    }
  }

  return (
    <div className='row mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center justify-content-between'>
            <h5>All Agent Commission Bonus: {totalRecord || 0}</h5>
            {
              currentUser.email === 'accounts@ryanconsultants.com' &&
              <div>
                <a className='btn btn-default roundNew' onClick={() => {
                  events.showAddCommissionBonusDrawer()
                }}>Add Commission Bonus</a>
              </div>
            }
            <div className='sort-box-table mark-btn mark-btn40'>
            </div>
          </div>
          <div className='card-body table-responsive'>
            <FilterComponent filterData={filterData} events={filterEvents} />
            <TableComp columns={columns}
                       apiRequest={apiRequest}
                       pagination={{
                         position: 'top',
                         pageSizeOptions: ['20', '50', '100', '200'],
                         defaultPageSize: 20
                       }}
                       extraProps={{ scroll: { x: 1450 } }}
                       ref={tableRef}
            />
          </div>

        </div>
      </div>
      {addCommissionBonusState.visible ?
        <AgentAddCommissionBonus
          visible={addCommissionBonusState.visible}
          onClose={() => {
            events.hideAddCommissionBonusDrawer()
          }}
          onSubmit={() => {
            reload()
            events.hideAddCommissionBonusDrawer()
          }}
          form={form}
        /> : null}
    </div>
  )
}

const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgentCommissionBonusList)
