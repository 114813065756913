import { Col, Icon, Popover, Row, Input, Modal, notification, Tooltip } from 'antd'
import _ from 'lodash'
import React, { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useDispatch } from 'react-redux'
import {
  departmentObj,
  cyprusUniversityList,
  KasaHighSchool,
  CasaHCollege,
  AtlantisCollege,
  EligibilityList,
  currencySymbols,
  countryIds,
  atlantisCollegeIcon, checkEmailIsValid, adminRightUser, LarnacaCollege, larnacaCollegeIcon
} from '../../../components/_utils/appUtils'
import { getUrlPushWrapper } from '../../../routes/routes'
import DirectUniversityComponent from '../drawers/directUniversityComponent'
import InputBox from '../../../components/_utils/InputBox'
import { sendEligibilityCriteriaFxn } from '../../users/actions/user'

const EmailCriteriaModal = (props) => {
  let dispatch = useDispatch()
  let { onClose, universityId, universityName } = props
  let [visible, setVisible] = useState(false)
  let [email, setEmail] = useState('')
  let events = {
    showModal: () => {
      setVisible(true)
    },
    closeModal: () => {
      setVisible(false)
    },
    onSubmit: async () => {
      if (!email) {
        notification.warning({ message: 'Enter email address.' })
        return
      }
      if (checkEmailIsValid(email)) {
        let resp = await dispatch(sendEligibilityCriteriaFxn({ email, universityId }))
        if (resp && resp.success) {
          setEmail('')
          setVisible(false)
        }
      } else {
        notification.warning({ message: 'Enter valid email address.' })
        return
      }
    }
  }
  return (
    <>
      {visible ?
        <Modal visible={visible}
               onCancel={events.closeModal}
               onOk={events.onSubmit}
               okText={'Submit'}
               wrapClassName={'criteriaModalCls'}
               title={`Email eligibility criteria - ${universityName}`}>
          <InputBox>
            <Input placeholder={'Enter Email'} className={'form-control'} onChange={({ target }) => {
              setEmail(target.value)
            }} />
          </InputBox>
        </Modal>
        : null}
      <a className={'hyperLinkUnderLine'} onClick={() => {
        events.showModal()
        onClose()
      }}>Email eligibility criteria</a>
    </>
  )
}


const ButtonLink = props => {
  let {
    searchData,
    item,
    record,
    userCountryName,
    userData,
    applyForCourse
  } = props
  let [visible, setVisible] = useState(false)

  const atlantisContent = (
    <div style={{ width: 500, padding: 10 }}>
      <img src={atlantisCollegeIcon} style={{ width: 150, height: 'auto' }} className={'mt10 mb10'} />
      <div className={'border-box-atlantis'}>
        <Row className={'mb10'}>
          <Col span={16}><b>Total Fees : </b></Col>
          <Col span={8}>
            <a className={'label label-tag-new label-xs'}>
              <b>{currencySymbols[countryIds.cyprus]} 6800</b>
            </a>
          </Col>
        </Row>
        <Row className={'mb10'}>
          <Col span={16}><b>Agent Commission : </b></Col>
          <Col span={8}>
            <a className={'label label-tag-new label-xs'}>
              <b>{currencySymbols[countryIds.cyprus]} 1800
              </b>
            </a>
          </Col>
        </Row>
        <Row className={'mb10'}>
          <Col span={16}><b>Direct Fees Paid to University : </b></Col>
          <Col span={8}>
            <a className={'label label-tag-new label-xs'}>
              <b>{currencySymbols[countryIds.cyprus]} 5000</b>
            </a>
          </Col>
        </Row>
        <Row className={'mt20'}>
          <Col span={24}>
            Services Included -
            <ul className={'mt10 services-ul'}>
              <li>Free AirPort Pickup</li>
              <li>Free Apostille</li>
              <li>Embassy Fees will be waived off by UnizPortal</li>
            </ul>
          </Col>
          <Col span={24} className={'alignCenter'}>
            <EmailCriteriaModal onClose={() => setVisible(false)} universityId={AtlantisCollege}
                                universityName={'Atlantis College'} />
          </Col>
        </Row>
      </div>
    </div>
  )

  const larnacaContent = (
    <div style={{ width: 500, padding: 10 }}>
      <img src={larnacaCollegeIcon} style={{ width: 150, height: 'auto' }} className={'mt10 mb10'} />
      <div className={'border-box-atlantis'}>
        <Row className={'mb10'}>
          <Col span={16}><b>Total Fees : </b></Col>
          <Col span={8}>
            <a className={'label label-tag-new label-xs'}>
              <b>{currencySymbols[countryIds.cyprus]} 6800</b>
            </a>
          </Col>
        </Row>
        <Row className={'mb10'}>
          <Col span={16}><b>Agent Commission : </b></Col>
          <Col span={8}>
            <a className={'label label-tag-new label-xs'}>
              <b>{currencySymbols[countryIds.cyprus]} 1800
              </b>
            </a>
          </Col>
        </Row>
        <Row className={'mb10'}>
          <Col span={16}><b>Direct Fees Paid to University : </b></Col>
          <Col span={8}>
            <a className={'label label-tag-new label-xs'}>
              <b>{currencySymbols[countryIds.cyprus]} 5000</b>
            </a>
          </Col>
        </Row>
        <Row className={'mt20'}>
          <Col span={24}>
            Services Included -
            <ul className={'mt10 services-ul'}>
              <li>Free Apostille</li>
              <li>Embassy Fees will be waived off by UnizPortal</li>
            </ul>
          </Col>
          <Col span={24} className={'alignCenter'}>
            <EmailCriteriaModal onClose={() => setVisible(false)} universityId={LarnacaCollege}
                                universityName={'Larnaca College'} />
          </Col>
        </Row>
      </div>
    </div>
  )

  let getContent = (universityId) => {
    if (AtlantisCollege == universityId) {
      return atlantisContent
    } else if (LarnacaCollege == universityId) {
      return larnacaContent
    } else {
      return null
    }
  }

  const btnA = (
    <li className={'canadaDirectUniCount canadaDirectUniBox'} key={item.id}>
      <div className={'innerBox'}>
        <div className={'uniCountBox'} style={{ height: 70 }}>
          <LazyLoadImage src={item.image} style={{ height: '80%', maxHeight: '100%', maxWidth: '60%' }}
                         onClick={() => searchData(item)} />
          <div className={'d-flex flex-d-columns aic'}>
            {[AtlantisCollege, LarnacaCollege].includes(item.id) ?
              <div className={'d-flex mb10 mt10'}>
                <Popover
                  visible={visible}
                  content={getContent(item.id)}
                  trigger='hover'
                  onVisibleChange={(vs) => {
                    setVisible(vs)
                  }}>
                  <a className={'btn btn-default roundNew xss mr5'}>Eligibility</a>
                </Popover>
                <Tooltip title={`Upcoming Intake - ${item.id == LarnacaCollege ? 'June' : 'May'}`}>
                  <a className={'btn btn-default roundNew xss'}>Intake</a>
                </Tooltip>
              </div>
              : null}
            <h5 className={'record-count'} onClick={() => searchData(item)}>{record.total ? record.total : 0}</h5>
          </div>
        </div>
        <div className={'btnBox'}>
          <a
            onClick={() => {
              applyForCourse(item.id)
            }}>
            <Icon type={'plus-circle'} size={13} /> Add Student
          </a>
        </div>
      </div>
    </li>
  )
  let branchUserCheck = () => {
    let checkUniversityExists = _.find(userData.universities, item1 => {
      return item1.toString() == item.id
    })
    return userData.department == 'Visa Approved Department' ||
    userData.department == departmentObj.marketing || userCountryName == 'Cyprus'
      ? btnA
      : checkUniversityExists
        ? btnA
        : null
  }
  return (
    <>
      {adminRightUser.includes(userData.userType) || userData.userType == 'agent' || userData.userType == 'subAgent' ? btnA : null}
      {userData.userType == 'branchManager' && userCountryName == 'Cyprus' ? btnA : null}
      {userData.userType == 'branchUser' ? branchUserCheck() : null}
    </>
  )
}

const EuropeUniversityRecordCount = props => {
  let { directUniversityRecords, userData } = props
  let dispatch = useDispatch()

  let [directApplicationDrawer, setDirectApplicationDrawer] = useState({
    visible: false,
    universityId: ''
  })
  let searchData = (item, type = '') => {
    if (item.id == KasaHighSchool) {
      dispatch(
        getUrlPushWrapper('kasaApplicationList', {
          appUniversityId: KasaHighSchool
        })
      )
    } else if (item.id == CasaHCollege) {
      dispatch(
        getUrlPushWrapper('casaApplicationList', {
          appUniversityId: CasaHCollege
        })
      )
    } else if (item.id == AtlantisCollege) {
      dispatch(
        getUrlPushWrapper('atlantisCollegeApplicationList', {
          appUniversityId: AtlantisCollege
        })
      )
    } else if (item.id == LarnacaCollege) {
      dispatch(
        getUrlPushWrapper('larnacaCollegeApplicationList', {
          appUniversityId: LarnacaCollege
        })
      )
    }
  }

  const events = {
    showDirectApplicationDrawer: universityId => {
      setDirectApplicationDrawer({
        visible: true,
        universityId: universityId
      })
    },
    hideDirectApplicationDrawer: () => {
      setDirectApplicationDrawer({
        visible: false,
        universityId: ''
      })
    },
    applyForCourse: universityId => {
      events.showDirectApplicationDrawer(universityId)
    }
  }

  return userData && userData._id ? (
    <>


      <div className='card-pane-new listing-section w-100 direct-uni-box' style={{ border: 'none' }}>
        <ul className={'count flex-wrap'}>
          {cyprusUniversityList.map(item => {
            return (
              <ButtonLink
                key={item.id}
                uniId={item.id}
                {...props}
                item={item}
                userData={userData}
                record={directUniversityRecords[item.id] ? directUniversityRecords[item.id] : {}}
                searchData={searchData}
                applyForCourse={events.applyForCourse}
              />
            )
          })}
        </ul>
      </div>

      {directApplicationDrawer && directApplicationDrawer.visible && directApplicationDrawer.universityId ? (
        <DirectUniversityComponent
          visible={directApplicationDrawer.visible}
          selectedUniversity={directApplicationDrawer.universityId}
          onClose={events.hideDirectApplicationDrawer}
          {...props}
        />
      ) : null}
    </>
  ) : null
}

export default EuropeUniversityRecordCount
