import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, notification, Row } from 'antd'
import { useDispatch } from 'react-redux'
import {
  singleAgentXTravelMarginFxn,
  updateAgentXTravelMarginFxn
} from '../actions'
import { InputBox, numberToWords } from '../../../components/_utils/appUtils'
import AgentTypeheadComponent from '../../../components/_utils/AgentTypeheadComponent'

const UpdateXTravelWorldMargin = (props) => {
  const { user } = props
  const dispatch = useDispatch()
  const [agentXTravelMargin, setAgentXTravelMargin] = useState(0.0)
  const [agentId, setAgentId] = useState(0.0)

  const fetchAgentMargin = async () => {
    const data = await dispatch(singleAgentXTravelMarginFxn({ userId: agentId }))
    if (data) {
      setAgentXTravelMargin(data.agentXTravelMargin)
    }
  }

  const handleSubmit = async () => {
    if (!agentId) {
      notification.error({ message: 'Please choose agent.' })
      return
    }
    if (!agentXTravelMargin) {
      notification.error({ message: 'Enter XTravelWorld margin.' })
      return
    }

    if (agentXTravelMargin > 20) {
      notification.error({ message: 'Enter Valid XTravelWorld margin.' })
      return
    }
    const payload = {
      userId: agentId,
      agentXTravelMargin
    }

    const resp = await dispatch(updateAgentXTravelMarginFxn(payload))
    if (resp.success) {
      notification.success({ message: 'Agent XTravelWorld margin updated successfully!' })
    } else {
      notification.error({ message: resp.message })
    }
  }

  useEffect(() => {
    fetchAgentMargin()
  }, [agentId])

  return (
    <div className='row'>
      <div className='col-lg-12'>
        <Form>
          <div className='form-box mt-4'>
            <div className='d-flex align-items-center mb-3 heading-form'>
              <h5>Update XTravelWorld Margin</h5>
            </div>

            <div className='card unizportal'>
              <Row gutter={24}>
                <Col span={8}>
                  <InputBox title={'Choose Agent'}>
                    <div className='sort-box-table custom-sort-box-new'>
                      <AgentTypeheadComponent
                        customStyle={''}
                        key={agentId}
                        agentId={agentId}
                        dispatch={dispatch}
                        onSelect={item => {
                          setAgentId(item)
                        }}
                      />
                    </div>
                  </InputBox>
                </Col>
                <Col span={6}>
                  <InputBox title={'XTravelWorld Margin(in %)'}>
                    <Input
                      type={'number'}
                      value={agentXTravelMargin}
                      className={'form-control'}
                      placeholder='Enter forex margin'
                      addonAfter='%'
                      onChange={(e) => setAgentXTravelMargin(e.target.value)}
                    />
                  </InputBox>
                  {/*<div className='margin-in-words ml10'>
                    {agentXTravelMargin !== undefined ? (
                      <p><span>Amount In Words : </span>
                        <b>{numberToWords(parseFloat(agentXTravelMargin))}</b></p>
                    ) : null}
                  </div>*/}
                </Col>
              </Row>

              <Form.Item>
                <Button type='primary' htmlType='submit' className='btn' onClick={() => {
                  handleSubmit()
                }}>
                  Update
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default UpdateXTravelWorldMargin
