import { apiUrl, apiUrl2 } from '../../../settings'

export const getStudentAccountDetailsUrl = () => {
  return apiUrl2 + '/studentAccount/getStudentDetails'
}

export const updateTuitionFeesUrl = () => {
  return apiUrl2 + '/studentAccount/updateTuitionFees'
}

export const addInstallmentsUrl = () => {
  return apiUrl2 + '/studentAccount/updateInstallment'
}

export const getPaymentRecordUrl = () => {
  return apiUrl2 + '/studentAccount/paymentRecords'
}
export const updateFcmtInstallmentsUrl = () => {
  return apiUrl2 + '/studentAccount/updateFcmtInstallment'
}

export const payFcmtInstallmentUrl = () => {
  return apiUrl + '/pay-fcmt-installment'
}

export const directPaymentInstallmentUrl = () => {
  return apiUrl2 + '/studentAccount/fcmtDirectPaymentInstallment'
}
