import React, {useEffect, useRef, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import _ from 'lodash'
import {Button, Form, Modal, Input, Checkbox, Row, Col, Icon, notification, AutoComplete} from 'antd';
import {
    createTransactionFxn,
    singleQuotationFxn,
    singleAgentMarginFxn,
    reeudoRatesFxn, getTxnCounsellorFxn
} from "../actions";
import './styles.css';
import UnizLogo from '../../../assets/Unizportal-Logo.png';
import {displayDate, InputBox} from "../../../components/_utils/appUtils";


const TransactionDetailsModal = (props) => {
    const {
        onClose,
        visible,
        onSkip,
        form: {getFieldDecorator, setFieldsValue},
        modalSource,
        isForexOff,
        currentUserRights,
        agentMargin,
        quotation
    } = props;

    let [isCounselorBonusChecked, setIsCounselorBonusChecked] = useState(false);
    let [counselorName, setCounselorName] = useState('');
    let [counselorDesignation, setCounselorDesignation] = useState('');
    const [counselorOptions, setCounselorOptions] = useState([]);
    let [quotationData, setQuotationData] = useState({});
    let dispatch = useDispatch();

    const events = {
        apiRequest: async () => {
            const resp = await dispatch(singleQuotationFxn({quotationId: quotation._id}));
            const response = resp.quotation;
            if (response) {
                setQuotationData(response);
                setFieldsValue({
                    currency: response.fromCurrency,
                    amount: response.amount,
                    fxRate: response.fxRate,
                    name: response.name,
                    emailAddress: response.emailId,
                    phoneNumber: response.phoneNo
                });
            }
        },
        getCounselors: async () => {
            if (quotation && quotation.agentId) {
                const resp = await dispatch(getTxnCounsellorFxn({
                    agentId: quotation.agentId,
                }));
                if (resp) {
                    setCounselorOptions(
                        resp.map((c) => ({
                            value: c.counselorName,
                            label: c.counselorName,
                            designation: c.counselorDesignation
                        }))
                    );
                }
            }
        }
    };


    useEffect(() => {
        events.apiRequest();
    }, []);

    useEffect(() => {
        events.getCounselors()
    }, [quotation]);

    const forexOffModal = () => {
        Modal.warning({
            title: 'Forex is closed',
            content: (
                <div>
                    <p>Forex is Closed, Please try again between 8 a.m. and 4 p.m.</p>
                </div>
            ),
            onOk() {
            },
        });
    }

    const handleCounselorChange = (field, value) => {
        if (field === "counselorName") {
            const selectedCounselor = counselorOptions.find((c) => c.value === value);
            setCounselorName(value);
            setCounselorDesignation(selectedCounselor ? selectedCounselor.designation : "");
            setFieldsValue({
                counselorName: value,
                counselorDesignation: selectedCounselor ? selectedCounselor.designation : ""
            });
        } else if (field === "counselorDesignation") {
            setCounselorDesignation(value);
            setFieldsValue({counselorDesignation: value});
        }
    };
    const handleSubmit = async e => {
        e.preventDefault();
        if (isForexOff) {
            forexOffModal();
        } else {
            const {form} = props;
            form.validateFields(async (err, valData) => {
                if (!err) {
                    const transactionData = {
                        ...valData,
                        name: quotationData.name,
                        emailAddress: quotationData.emailId,
                        phoneNumber: quotationData.phoneNo,
                        quotationId: quotationData.quotationId,
                        fxRate: quotationData.fxRate,
                        ibrRate: quotationData.ibrRate,
                        yourRate: quotationData.yourRate,
                        amount: quotationData.amount,
                        currency: quotationData.fromCurrency,
                        sourceId: quotationData.sourceId,
                        purposeId: quotationData.purposeId,
                        agentId: quotationData.agentId._id,
                        counselorBonusApplied: isCounselorBonusChecked,
                        exchangeMargin: quotationData.exchangeMargin,
                        agentMargin: agentMargin
                    };

                    if (isCounselorBonusChecked) {
                        transactionData.counselorName = counselorName;
                        transactionData.counselorDesignation = counselorDesignation;
                    }

                    let resp = await dispatch(createTransactionFxn(transactionData));
                    if (resp.success && resp.data) {
                        notification.success({
                            message: resp.message
                        });
                        form.resetFields();
                        onClose(resp.data);
                    } else {
                        notification.error({
                            message: resp.message
                        });
                    }
                } else {
                    notification.warning({
                        message: 'Fill All Required Fields'
                    });
                }
            });
        }
    };


    const handleCheckboxChange = (e) => {
        setIsCounselorBonusChecked(e.target.checked);
    };

    return (
        <Modal
          visible={visible}
          title={'Book Transaction Details'}
          onCancel={onSkip}
          width={'45%'}
          footer={null}>
          {quotationData && quotationData._id && (
            <>
              <div className='unizportal'>
                <div className={'jcsb'}>
                  <div>
                    <img src={UnizLogo} alt={'logo'} style={{ height: 50 }} />
                  </div>
                  <div>
                    <div>Date : <b>{displayDate(quotationData.createdAt)}</b></div>
                    <div>Quotation Id : <b>#{quotationData.quotationId}</b></div>
                            </div>
                        </div>

                        <div className={'description-section mt10'}>

                            <table className="table table-bordered">
                                <tr>
                                    <th colSpan={2}>Description</th>
                                </tr>
                                <tbody>
                                <tr>
                                    <td>Currency Type</td>
                                    <td>{quotationData.fromCurrency}</td>
                                </tr>
                                <tr>
                                    <td>Foreign Currency Amount</td>
                                    <td>{quotationData.amount}</td>
                                </tr>
                                <tr>
                                    <td>Booking Rate</td>
                                    <td>{quotationData.fxRate} INR</td>
                                </tr>
                                <tr>
                                    <td>Your Rate</td>
                                    <td>{(quotationData.yourRate + quotationData.exchangeMargin).toFixed(2)} INR</td>
                                </tr>
                                <tr>
                                    <td>Gross Amount</td>
                                    <td>{quotationData.grossAmount} INR</td>
                                </tr>
                                <tr>
                                    <td>GST (Foreign Conversion)</td>
                                    <td>{quotationData.gst} INR</td>
                                </tr>
                                <tr>
                                    <td>Bank Processing Fee</td>
                                    <td>{quotationData.bankFees} INR</td>
                                </tr>
                                <tr>
                                    <td>Nostro Charges</td>
                                    <td>{quotationData.nostroCharge} INR</td>
                                </tr>
                                <tr>
                                    <td>TCS Charges</td>
                                    <td>{quotationData.tcsCharge} INR</td>
                                </tr>
                                <tr>
                                    <td>Total Amount</td>
                                    <td className="total">{quotationData.totalAmount} INR</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <hr className={'hr1'}/>
                    <div className=" row">
                        <div className={'col-md-6'}>
                            <div className={'font14'}><b>Contact Details</b></div>
                            <div>{quotationData.agentId.name}</div>
                            <div className={'aic'}>
                                <div className={'aic'}><Icon type={'mobile'}/> &nbsp; {quotationData.agentId.mobile}
                                </div>
                                {quotationData.agentId.mobile && quotationData.agentId.email ?
                                    <div>&nbsp; | &nbsp;</div> : null}
                                <div className={'aic'}><Icon type={'mail'}/> &nbsp;{quotationData.agentId.email}
                                </div>
                            </div>
                        </div>
                        <div className={'col-md-6'}>
                            <div className={'font14'}><b>Student Details</b></div>
                            <div>{quotationData.name}</div>
                            <div className={'aic'}>
                                <div className={'aic'}><Icon type={'mobile'}/> &nbsp; {quotationData.phoneNo} </div>
                                &nbsp; | &nbsp;
                                <div className={'aic'}><Icon type={'mail'}/> &nbsp;{quotationData.emailId}</div>
                            </div>
                        </div>

                    </div>
                    <hr className={'hr1'}/>

                    {modalSource !== 'isReaded' && (
                        <>
                            <Checkbox onChange={handleCheckboxChange}>
                                Do you want to add counsellor bonus?
                            </Checkbox>
                            {isCounselorBonusChecked && (
                                <Form layout="vertical" className="mt20">
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <InputBox title="Counselor Name">
                                                {getFieldDecorator("counselorName", {
                                                    rules: [
                                                        {
                                                            required: isCounselorBonusChecked,
                                                            message: "Please enter counselor name",
                                                        },
                                                    ],
                                                    initialValue: counselorName,
                                                })(
                                                    <AutoComplete
                                                        placeholder="Select or type counselor name"
                                                        className={'autoCompleted'}
                                                        filterOption={(inputValue, option) =>
                                                            option.props.children
                                                                .toUpperCase()
                                                                .indexOf(inputValue.toUpperCase()) !== -1
                                                        }
                                                        onSelect={(value) => handleCounselorChange("counselorName", value)}
                                                        onChange={(value) => handleCounselorChange("counselorName", value)}
                                                        allowClear
                                                    >
                                                        {counselorOptions.map((counselor) => (
                                                            <AutoComplete.Option key={counselor.value}
                                                                                 value={counselor.value}>
                                                                {counselor.label}
                                                            </AutoComplete.Option>
                                                        ))}
                                                    </AutoComplete>
                                                )}
                                            </InputBox>
                                        </Col>
                                        <Col span={12}>
                                            <InputBox title="Counselor Designation">
                                                {getFieldDecorator("counselorDesignation", {
                                                    rules: [
                                                        {
                                                            required: isCounselorBonusChecked,
                                                            message: "Please enter counselor designation",
                                                        },
                                                    ],
                                                    initialValue: counselorDesignation,
                                                })(
                                                    <Input
                                                        className="form-control"
                                                        placeholder="Designation"
                                                        onChange={(e) => handleCounselorChange("counselorDesignation", e.target.value)}
                                                        value={counselorDesignation}
                                                    />
                                                )}
                                            </InputBox>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                            <div>
                                <Button htmlType="submit" className={"roundBtn"} onClick={handleSubmit}>
                                    Confirm & Book Transaction
                                </Button> &nbsp;
                                <Button className={"roundBtn"} onClick={onSkip}>
                                    Skip
                                </Button>
                            </div>
                        </>
                    )}

                </>
            )}

        </Modal>
    );
};

const mapStateToProps = ({global}) => ({
    currentUserRights: global.currentUserRights
})
const WrappedTransactionDetailsModal = Form.create()(TransactionDetailsModal)
export default connect(
    mapStateToProps,
    null
)(WrappedTransactionDetailsModal)
