import { Button, Col, Drawer, Form, notification, Popconfirm } from 'antd'
import React, { useState } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { DefaultTablePagination } from '../../components/_utils/appUtils'
import { useRef } from 'react'
import { approveLeadFxn, cancelLeadFxn, eventLeadExportFxn, eventList } from '../event/action/event'
import { useDispatch } from 'react-redux'

const statusFilter = [
  { name: 'Verified', value: 'Verified' },
  { name: 'Unverified', value: 'Unverified' }
]
const cityList = [
  { name: 'Mohali', label: '5th March, Wednesday, Time - 7:30PM' },
  { name: 'Jalandhar', label: '6th March, Thursday, Time - 7:30PM' },
  { name: 'Ludhiana', label: '7th March, Friday, Time - 7:30PM' },
  { name: 'Amritsar', label: '8th March, Saturday, Time - 7:30PM' },
  { name: 'Hisar', label: '10th March, Monday, Time - 7:30PM' },
  { name: 'Karnal', label: '11th March, Tuesday, Time - 7:30PM' }
]

const ViewDrawer = (props) => {
  const { visible, events, eventId } = props
  let [filters, setFilters] = useState({})
  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.eventId = eventId
      params.sortField = '_id'
      params.sortOrder = 'descend'
      let resp = await dispatch(eventList({
        ...params,
        regExFilters: ['name', 'enrollmentBasic', 'enrollmentProcedure', 'fundSponsors']
      }))
      setFilters({ ...params, results: resp.total, count: resp.total })
      resolve(resp)
    })
  }

  const exportLeadListEvent = async () => {
    let { success, message } = await dispatch(eventLeadExportFxn({
      ...filters, eventId,
      regExFilters: ['name', 'enrollmentBasic', 'enrollmentProcedure', 'fundSponsors']
    }))
    if (success) {
      notification.success({ message: message })
    } else {
      notification.error({ message: message })
    }
  }
  let leadEvents = {
    sendData: async (record) => {
      let resp = await dispatch(approveLeadFxn({ eventId: record._id }))
      if (resp && resp.success) {
        tableRef.current.reload()
      }
    },
    cancelLead: async (record) => {
      let resp = await dispatch(cancelLeadFxn({ eventId: record._id }))
      if (resp && resp.success) {
        tableRef.current.reload()
      }
    }
  }

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 80,
      key: 'sno',
      render: (value, item, index) => index + 1
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      searchTextName: 'name'
    },
    {
      title: 'Name to Print on Certificate',
      dataIndex: 'nameToPrintOnCertificate',
      key: 'nameToPrintOnCertificate'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      searchTextName: 'email'
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName'

    },
    {
      title: 'Company Address',
      dataIndex: 'address',
      key: 'address',
      searchTextName: 'address'
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country'
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile'
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      filters: cityList.map(x => ({ value: x.name, text: x.name }))
    },
    /*{
      title: 'Reference',
      dataIndex: 'reference',
      key: 'reference',
      render: (record, item) => {
        return (
          <>
            <div>
              {item.reference && item.reference.name ? item.reference.name : null}
            </div>
          </>
        )
      }
    },*/
    {
      title: 'Reference',
      dataIndex: 'marketingUserId',
      key: 'marketingUserId',
      render: (item, record) => {
        return (
          <>
            <div>
              {item && item.name ? item.name : null}
            </div>
          </>
        )
      }
    },
    /*  {
        title: 'Members',
        dataIndex: 'persons',
        key: 'persons'
      },*/
    /*{
      title: 'Second Person',
      dataIndex: 'secondPersonName',
      key: 'secondPersonName',
      render: (item, record) => {
        return (
          <>
            {item}
            {record.designation ? <div>
              <small>(Designation : {record.designation})</small>
            </div> : null}
          </>
        )
      }
    }*/

    /*{
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: statusFilter.map(x => ({ value: x.value, text: x.name }))
    },*/
    {
      title: 'Approved',
      dataIndex: 'approved',
      key: 'approved',
      width: 120,
      render: (item, record) => {
        let { cancelled } = record
        return (
          <>
            {cancelled ?
              <>
                <a>Cancelled</a>
              </> :
              <>
                {item ? 'Approved' : <>
                  <Popconfirm title={'Are you sure, you want to approved this lead?'}
                              onConfirm={() => {
                                leadEvents.sendData(record)
                              }}>
                    <a className={'btn btn-success btn-xs'}>Approved Now</a>
                  </Popconfirm>

                </>}

              </>}


          </>
        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 150,
      render: (item, record) => {
        let { approved, cancelled } = record
        return (
          <>
            {approved && !cancelled ? <>
              <Popconfirm
                title={'Are you sure, you want to Cancel this lead?'}
                onConfirm={() => {
                  leadEvents.cancelLead(record)
                }}>
                <a className={'btn btn-danger btn-xs'}>Cancel Now</a>
              </Popconfirm>
            </> : null}
          </>
        )
      }
    }
  ]
  return (
    <>
      <Drawer
        visible={visible}
        width={'90%'}
        placement='right'
        onClose={events.hideEventLeadList}
        title={(
          <>
            Events Lead
            <a className={'ml-4 btn btn-default roundNew md'} onClick={exportLeadListEvent}>Export Records</a>
          </>
        )}>
        <div className={'card'}>
          <div className='card-body table-responsive'>
            <TableComp
              columns={columns}
              apiRequest={apiRequest}
              ref={tableRef}
              pagination={DefaultTablePagination()}
            />
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default Form.create()(ViewDrawer)
