import {
  Button,
  Card,
  Col,
  Drawer,
  Icon,
  List,
  Popconfirm,
  Row,
  Select,
  Tooltip,
  notification
} from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  BooleanOptions,
  ConditionOptions,
  departmentObj,
  filterOption,
  getDirectUniversityName,
  longDisplayDate
} from '../../../components/_utils/appUtils'
import { ColumnWidth } from '../../WebComponent/columnWidth'
import ViewApplication from '../../applications/views/viewApplication'
import { CheckUserRight } from '../../dashboard/views/DashboardUserWise'
import {
  agentCountFxn,
  autoAssignMarketingUserFxn,
  blockAgent,
  getExportAgent,
  listAllUsers, reGenerateCertificateAndUpdateFxn,
  updateInsuranceRight, verifyAgentFxn
} from '../actions/user'
import LogsComponents from '../drawer/logsComponents'
import UpdatePassword from '../views/changePassword'
import EditUser from '../views/edit'
import EditSubAgent from '../views/editSubAgent'
import CheckActiveUser from './activeCheck'
import UserRightList from './rightsList'
import DirectUniversityRights from '../components/directUniversityRights'
import _ from 'lodash'
import moment from 'moment'
import {
  LoadAllCountry,
  LoadCity,
  LoadState
} from '../../../components/_utils/countryUtil'
import MobileEmailInfo from '../../../components/commonComponents/mobileEmailInfo'
import { getUrlPushWrapper } from '../../../routes/routes'
import { listAllCountries } from '../../countries/actions/countries'
import MarketingDrawer from '../drawer/marketingDrawer'
import ButtonGrid from '../../../components/_utils/buttonGrid'
import AgentVerifiedModal from '../drawer/verifiedModal'
import { AllowComponentRightsWise } from '../../WebComponent/allowComponentRightsWise'
import AssignUserToAgent from '../views/assignUsersToAgent'

const { Option } = Select

const sortingOrderList = [
  { name: 'A-Z', sortField: 'name', sortOrder: 'ascend' },
  { name: 'Z-A', sortField: 'name', sortOrder: 'descend' },
  { name: 'Newest', sortField: '_id', sortOrder: 'descend' },
  { name: 'Oldest', sortField: '_id', sortOrder: 'ascend' }
]
const blockStatusList = [
  { name: 'Active', value: 'active' }, // block false means active
  { name: 'Block', value: 'block' }
]

const AgentMarketingUsers = (props) => {
  let { agentMarketing, visible, onClose, companyName, agentName } = props
  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      title={`${agentName} (${companyName})`}
      width={'35%'}>
      <>
        <Card>
          {agentMarketing && agentMarketing.length ?
            <List
              size='small'
              bordered
              dataSource={agentMarketing}
              renderItem={(item, index) => {
                let { marketingUserId } = item
                return (
                  <List.Item>
                    <span>{index + 1}. </span>
                    {marketingUserId && marketingUserId.name ?
                      <span>
                        {marketingUserId.name}
                      </span> : null}&nbsp;
                    <strong
                      className={'colorPrimary'}>({getDirectUniversityName(item.university)})</strong>
                  </List.Item>
                )
              }}
            /> : null
          }

        </Card>
      </>
    </Drawer>
  )
}

class AllUsers extends Component {
  events = {
    hideApplicationDrawer: () => {
      this.setState({
        visibleApplicationDrawer: false
      })
    },
    showApplicationDrawer: data => {
      this.setState({
        visibleApplicationDrawer: true,
        queryData: {
          agentId: data._id
        }
      })
    },
    showUserRightDrawer: data => {
      this.setState({
        visibleUserRightDrawer: true,
        selectedUser: data
      })
    },
    showMarketingDrawer: data => {
      this.setState({
        marketingState: {
          agentMarketing: data.agentMarketing,
          armUserData: data.armUsers,
          visible: true,
          agentId: data._id,
          countryName: data.countryName,
          companyName: data.companyName
        }
      })
    },

    hideMarketingDrawer: () => {
      this.setState({
        marketingState: {
          agentMarketing: {},
          armUserData: [],
          visible: false,
          agentId: '',
          companyName: '',
          countryName: ''
        }
      })
    },
    hideUserRightDrawer: () => {
      this.setState({
        visibleUserRightDrawer: false,
        selectedUser: {}
      })
    },
    closeAndUpdateUserRightDrawer: () => {
      this.setState(
        {
          visibleUserRightDrawer: false,
          selectedUser: {}
        },
        () => {
          this.tableRef.current.reload()
        }
      )
    },
    chooseCountry: name => {
      let countryName = _.find(this.state.allCountries, item => {
        return item.name == name
      })
      if (countryName) {
        this.setState({
          countryName: countryName.name ? countryName.name : '',
          stateName: undefined,
          cityName: undefined,
          pincode: undefined,
          allStates: countryName.id ? LoadState(countryName.id) : []
        })
      }
    },
    chooseState: name => {
      let stateName = _.find(this.state.allStates, item => {
        return item.name == name
      })
      if (stateName) {
        this.setState({
          stateName: stateName.name ? stateName.name : '',
          cityName: undefined,
          pincode: undefined,
          allCities: stateName.id ? LoadCity(stateName.id) : []
        })
      }
    },
    chooseCity: name => {
      this.setState({
        cityName: name ? name : ''
      })
    },
    showRightDrawer: (data) => {
      this.setState({
        rightState: {
          visible: true,
          agentData: data
        }
      })
    },
    hideRightDrawer: () => {
      this.setState({
        rightState: {
          visible: false,
          agentData: {}
        }
      })
    },
    showMarketingListDrawer: (data) => {
      this.setState({
        marketingObjState: {
          visible: true,
          agentMarketing: data.agentMarketing,
          companyName: data.companyName,
          agentName: data.name
        }
      })
    },
    hideMarketingListDrawer: () => {
      this.setState({
        marketingObjState: {
          visible: false,
          agentMarketing: [],
          companyName: '',
          agentName: ''
        }
      })
    },
    showVerifyModal: (record) => {
      this.setState({
        verifyState: {
          visible: true,
          agentId: record._id,
          agentVerified: record.agentVerified || undefined
        }
      })
    },
    hideVerifyModal: () => {
      this.setState({
        verifyState: {
          visible: false,
          agentId: '',
          agentVerified: undefined
        }
      })
    },
    submitAutoAssignMarketingUser: async (agent) => {
      let { dispatch } = this.props
      let resp = await dispatch(autoAssignMarketingUserFxn({ agentId: agent._id }))
      if (resp && resp.success) {
        if (this.tableRef && this.tableRef.current) {
          this.tableRef.current.reload()
        }
      }
    },
    copyWebsiteFxn: (webSite) => {
      navigator.clipboard.writeText(webSite)
      notification.success({ message: 'Website share link copied successfully.' })
    },
    generateCertificateFxn: async (agentId) => {
      let { dispatch } = this.props
      let resp = await dispatch(reGenerateCertificateAndUpdateFxn({ agentId }))
      if (resp && resp.success) {
        this.tableRef.current.reload()
      }
    },
    showAssignUserDrawer: (data) => {
      this.setState({
        assignUserState: {
          visible: true,
          agentId: data._id,
          reeudoManagerId: data.reeudoManagerId,
          giftByMarketingUserId: data.giftByMarketingUserId,
          xTravelWorldMarketingId: data.xTravelWorldMarketingId
        }
      })
    },
    hideAssignUserDrawer: () => {
      this.setState({
        assignUserState: {
          visible: false,
          agentId: '',
          reeudoManagerId: '',
          giftByMarketingUserId: '',
          xTravelWorldMarketingId: ''
        }
      })
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      viewInfoDrawer: false,
      selectedRow: {},
      allAgents: [],
      branchManager: [],
      selectedUser: {},
      visibleUpdatePassword: false,
      visibleUpdateUser: false,
      visibleUpdateSubAgent: false,
      visibleApplicationDrawer: false,
      queryData: {},
      visibleUserRightDrawer: false,
      approvedAgentRightsUser: [],
      zeroRecordAgent: false,
      filters: {},
      total: 0,
      countryName: '',
      stateName: '',
      cityName: '',
      pincode: '',
      allCountries: LoadAllCountry(),
      allStates: [],
      allCities: [],
      allMarketingUser: [],
      allMarketingManagerAndUser: [],
      keyMoment: moment(),
      countryList: [],
      armUserData: [],
      marketingState: {
        visible: false,
        agentId: '',
        countryName: '',
        agentMarketing: {}
      },
      agentFilterType: 'All',
      rightState: {
        visible: false,
        agentData: {}
      },
      sortingOrder: 'A-Z',
      marketingObjState: {
        visible: false,
        agentMarketing: [],
        agentName: '',
        companyName: ''
      },
      verifyState: {
        visible: false,
        agentVerified: '',
        agentId: ''
      },
      assignUserState: {
        visible: false,
        reeudoManagerId: '',
        giftByMarketingUserId: '',
        xTravelWorldMarketingId: '',
        agentId: ''
      },
      reeudoManagerList: [],
      unizHomeUsers: [],
      agentCount: {},
      reeudoManagerId: '',
      xTravelMarketingUserList: []
    }
    this.tableRef = React.createRef()
  }

  componentDidMount() {
    // this.loadAllAgent()
    this.loadRightUser()
    this.loadBranchManager()
    this.loadMarketingUser()
    this.loadMarketingManagerOrUser()
    this.setFieldByParams()
    this.loadCountry()
    this.loadReeudoManagerList()
    this.loadXTravelMarketingUserList()
    this.getCountOfAgent()
    this.loadUnizHomeUserList()
  }

  loadCountry = async () => {
    let { dispatch } = this.props
    let countryFilter = {
      results: 10,
      sortField: 'countryName',
      sortOrder: 'ascend'
    }
    let { data } = await dispatch(listAllCountries(countryFilter))
    this.setState({
      countryList: data
    })
  }

  async loadBranchManager() {
    let { dispatch } = this.props
    let obj = {
      userType: 'branchManager',
      customQuery: {
        'agents.0': { $exists: true }
      },
      results: 100,
      select: ['name', 'email', 'mobile']
    }
    let { data } = await dispatch(listAllUsers(obj))
    this.setState({ branchManager: data })
  }

  async loadAllAgent() {
    let { dispatch } = this.props
    let { data } = await dispatch(listAllUsers({ userType: 'agent' }))
    this.setState({ allAgents: data })
  }

  async loadMarketingUser() {
    let { dispatch } = this.props
    let obj = {
      userType: 'branchUser',
      select: ['name', 'mobile'],
      customQuery: {
        department: departmentObj.marketing
      },
      results: 100
    }
    let { data } = await dispatch(listAllUsers(obj))
    this.setState({ allMarketingUser: data })
  }

  async loadMarketingManagerOrUser() {
    let { dispatch } = this.props
    let obj = {
      select: ['name', 'mobile'],
      block: false,
      customQuery: {
        $or: [
          { department: departmentObj.marketing, userType: 'branchUser' },
          { userType: 'marketingManager' }
        ]
      },
      results: 100
    }
    let { data } = await dispatch(listAllUsers(obj))
    this.setState({ allMarketingManagerAndUser: data })
  }

  loadRightUser = async () => {
    let params = {
      results: 200,
      approvedAgent: true,
      select: ['name', 'userType']
    }
    let { data } = await this.props.dispatch(listAllUsers(params))
    this.setState({ approvedAgentRightsUser: data })
  }

  viewInfo(record) {
    this.setState({
      selectedRow: record,
      viewInfoDrawer: true
    })
  }

  onClose = () => {
    this.setState({
      selectedRow: {},
      viewInfoDrawer: false
    })
  }

  closeUpdatePassword = () => {
    this.setState(
      {
        selectedUser: {},
        visibleUpdatePassword: false
      },
      () => {
        this.tableRef.current.reload()
      }
    )
  }

  closeEditUser = () => {
    this.setState(
      {
        selectedUser: {},
        visibleUpdateUser: false
      },
      () => {
        this.tableRef.current.reload()
      }
    )
  }

  closeEditSubAgent = () => {
    this.setState(
      {
        selectedUser: {},
        visibleUpdateSubAgent: false
      },
      () => {
        this.tableRef.current.reload()
      }
    )
  }
  updateAgentBlockStatus = async id => {
    let { dispatch } = this.props
    let resp = await dispatch(blockAgent({ agentId: id }))
    if (resp && resp.success) {
      this.tableRef.current.reload()
    }
  }

  getSortingValue = (sortingOrder) => {
    let findV = _.find(sortingOrderList, (item) => {
      return item.name == sortingOrder
    })
    if (findV) {
      return { sortField: findV.sortField, sortOrder: findV.sortOrder }
    } else {
      return null
    }
  }

  apiRequest = params => {
    let { dispatch } = this.props
    return new Promise(async resolve => {
      if (
        !params.userType ||
        (params.userType && params.userType.length == 0)
      ) {
        params.userType = ['agent']
      }

      let resp1 = await this.getParamsForApplicationList()
      let data = this.getSortingValue(resp1.sortingOrder)
      params.sortField = 'name'
      params.sortOrder = 'ascend'
      if (data) {
        params = { ...params, ...data }
      }

      if (resp1.countryName) {
        params.countryName = resp1.countryName
      }
      if (resp1.stateName) {
        params.stateName = resp1.stateName
      }
      if (resp1.cityName) {
        params.cityName = resp1.cityName
      }
      if (resp1.pincode) {
        params.pincode = resp1.pincode
      }

      if (resp1.agentWiseManagerId) {
        params.agentWiseManagerId = resp1.agentWiseManagerId
      }
      if (resp1.marketingManagerId) {
        params.marketingManagerId = resp1.marketingManagerId
      }
      if (resp1.email) {
        params.email = resp1.email
      }
      if (resp1.mobile) {
        params.mobile = resp1.mobile
      }
      if (resp1.companyName) {
        params.companyName = resp1.companyName
      }
      if (resp1.name) {
        params.name = resp1.name
      }
      if (resp1.reeudoManagerId) {
        params.reeudoManagerId = resp1.reeudoManagerId
      }
      if (resp1.xTravelWorldMarketingId) {
        params.xTravelWorldMarketingId = resp1.xTravelWorldMarketingId
      }

      if (resp1.profileStatus) {
        if (resp1.profileStatus == 'block') {
          params.block = true
        } else {
          params.block = false
        }
      } else {
        params.block = [true, false]
      }

      let finalFilter = {
        ...params,
        showAll: true,
        zeroRecordAgent: this.state.zeroRecordAgent,
        agentFilterType: this.state.agentFilterType,
        select: [
          'name',
          'companyName', 'email', 'mobile', 'address', 'countryCode',
          'showCommission',
          'allowAddingUser',
          'cityName',
          'stateName',
          'countryName',
          'pincode',
          'logo',
          'alternateEmails',
          'createdByUser',
          'block',
          'managers',
          'marketingUser',
          'certificate',
          'showContract',
          'agentVerified',
          'userType',
          'approvedAgent',
          'allowLeads',
          'newsRight',
          'feedback',
          'allowAddingUser',
          'webinarRight',
          'showCommission',
          'showOnShoreAustralia',
          'showCanadaLoa',
          'reeudoManagerId',
          'giftByMarketingUserId',
          'xTravelWorldMarketingId'
        ],
        regExFilters: [
          'name',
          'address',
          'mobile',
          'email',
          'cityName',
          'companyName'
        ]
      }
      this.setState({ filters: finalFilter })
      let resp = await dispatch(listAllUsers({ ...finalFilter }))
      this.setState({ total: resp.total })
      resolve(resp)
    })
  }

  exportAgentsFxn = async () => {
    let { filters, total } = this.state
    let { dispatch } = this.props
    let { success, message } = await dispatch(
      getExportAgent({ ...filters, results: total })
    )
    if (success) {
      notification.success({ message: message })
    } else {
      notification.warning({ message: 'Error, please try again' })
      return
    }
  }

  getCountOfAgent = async () => {
    let data = await agentCountFxn()
    this.setState({
      agentCount: data
    })
  }

  getParamsForApplicationList = () => {
    return new Promise(resolve => {
      let searchParams = new URLSearchParams(window.location.search)
      let countryName = searchParams.get('countryName')
      let stateName = searchParams.get('stateName')
      let cityName = searchParams.get('cityName')
      let pincode = searchParams.get('pincode')
      let agentWiseManagerId = searchParams.get('agentWiseManagerId')
      let name = searchParams.get('name')
      let email = searchParams.get('email')
      let companyName = searchParams.get('companyName')
      let mobile = searchParams.get('mobile')
      let marketingManagerId = searchParams.get('marketingManagerId')
      let sortingOrder = searchParams.get('sortingOrder')
      let profileStatus = searchParams.get('profileStatus')
      let reeudoManagerId = searchParams.get('reeudoManagerId')
      let xTravelWorldMarketingId = searchParams.get('xTravelWorldMarketingId')

      let obj = {}
      if (countryName) {
        obj.countryName = countryName
      }
      if (stateName) {
        obj.stateName = stateName
      }
      if (cityName) {
        obj.cityName = cityName
      }
      if (pincode) {
        obj.pincode = pincode
      }
      if (agentWiseManagerId) {
        obj.agentWiseManagerId = agentWiseManagerId
      }
      if (marketingManagerId) {
        obj.marketingManagerId = marketingManagerId
      }
      if (name) {
        obj.name = name
      }
      if (companyName) {
        obj.companyName = companyName
      }
      if (mobile) {
        obj.mobile = mobile
      }
      if (email) {
        obj.email = email
      }
      if (sortingOrder) {
        obj.sortingOrder = sortingOrder
      }
      if (profileStatus) {
        obj.profileStatus = profileStatus
      }
      if (reeudoManagerId) {
        obj.reeudoManagerId = reeudoManagerId
      }
      if (xTravelWorldMarketingId) {
        obj.xTravelWorldMarketingId = xTravelWorldMarketingId
      }
      resolve(obj)
    })
  }

  setFieldByParams = async () => {
    let resp = await this.getParamsForApplicationList()
    let obj = {}
    if (resp.countryName) {
      obj.countryName = resp.countryName
    }
    if (resp.stateName) {
      obj.stateName = resp.stateName
    }
    if (resp.cityName) {
      obj.cityName = resp.cityName
    }
    if (resp.pincode) {
      obj.pincode = resp.pincode
    }
    if (resp.agentWiseManagerId) {
      obj.agentWiseManagerId = resp.agentWiseManagerId
    }
    if (resp.marketingManagerId) {
      obj.marketingManagerId = resp.marketingManagerId
    }
    if (resp.name) {
      obj.name = resp.name
    }
    if (resp.email) {
      obj.email = resp.email
    }
    if (resp.companyName) {
      obj.companyName = resp.companyName
    }
    if (resp.mobile) {
      obj.mobile = resp.mobile
    }
    if (resp.sortingOrder) {
      obj.sortingOrder = resp.sortingOrder
    }
    if (resp.profileStatus) {
      obj.profileStatus = resp.profileStatus
    }
    if (resp.reeudoManagerId) {
      obj.reeudoManagerId = resp.reeudoManagerId
    }
    if (resp.xTravelWorldMarketingId) {
      obj.xTravelWorldMarketingId = resp.xTravelWorldMarketingId
    }
    this.setState({ ...obj })
  }

  searchFxn = async () => {
    let { dispatch } = this.props
    // let resp = await this.getParamsForApplicationList()
    let obj = {}
    if (this.state.countryName) {
      obj.countryName = this.state.countryName
    }
    if (this.state.stateName) {
      obj.stateName = this.state.stateName
    }
    if (this.state.cityName) {
      obj.cityName = this.state.cityName
    }
    if (this.state.pincode) {
      obj.pincode = this.state.pincode
    }
    if (this.state.agentWiseManagerId) {
      obj.agentWiseManagerId = this.state.agentWiseManagerId
    }
    if (this.state.marketingManagerId) {
      obj.marketingManagerId = this.state.marketingManagerId
    }
    if (this.state.name) {
      obj.name = this.state.name
    }
    if (this.state.email) {
      obj.email = this.state.email
    }
    if (this.state.companyName) {
      obj.companyName = this.state.companyName
    }
    if (this.state.mobile) {
      obj.mobile = this.state.mobile
    }
    if (this.state.sortingOrder) {
      obj.sortingOrder = this.state.sortingOrder
    }
    if (this.state.profileStatus) {
      obj.profileStatus = this.state.profileStatus
    }
    if (this.state.reeudoManagerId) {
      obj.reeudoManagerId = this.state.reeudoManagerId
    }
    if (this.state.xTravelWorldMarketingId) {
      obj.xTravelWorldMarketingId = this.state.xTravelWorldMarketingId
    }
    dispatch(
      getUrlPushWrapper('user.allAgents', {
        ...obj
      })
    )
    // setTableSearch(true)
    setTimeout(() => {
      if (this.tableRef && this.tableRef.current) {
        this.tableRef.current.reload()
      }
    }, 200)
  }
  clearFxn = async () => {
    let { dispatch } = this.props
    // let resp = await getParamsForApplicationList()
    let obj = {}
    this.setState({
      countryName: '',
      stateName: '',
      cityName: '',
      pincode: '',
      agentWiseManagerId: '',
      marketingManagerId: '',
      name: '',
      email: '',
      companyName: '',
      mobile: '',
      profileStatus: '',
      reeudoManagerId: '',
      xTravelWorldMarketingId: '',
      sortingOrder: 'A-Z'
    })
    dispatch(
      getUrlPushWrapper('user.allAgents', {
        ...obj
      })
    )
    setTimeout(() => {
      this.tableRef.current.reload()
    }, 200)
  }

  UpdateInsuranceRight = async id => {
    let { dispatch } = this.props
    let resp = await dispatch(updateInsuranceRight({ userId: id }))
    if (resp && resp.success) {
      this.tableRef.current.reload()
    }
  }

  verifyAgentSubmit = async (agent) => {
    let { dispatch } = this.props
    let resp = await dispatch(verifyAgentFxn({ agentId: agent._id }))
    if (resp && resp.success) {
      if (this.tableRef && this.tableRef.current) {
        this.tableRef.current.reload()
      }
    }
  }

  async loadReeudoManagerList() {
    let { dispatch } = this.props
    let obj = {
      userType: 'reeudoManager',
      select: ['name', 'mobile'],
      results: 100
    }
    let { data } = await dispatch(listAllUsers(obj))
    this.setState({ reeudoManagerList: data })
  }

  loadXTravelMarketingUserList = async () => {
    let { dispatch } = this.props
    let obj = {
      userType: 'branchUser',
      department: departmentObj.marketing,
      marketingFor: 'xTravelWorld',
      select: ['name', 'mobile'],
      results: 100
    }
    let { data } = await dispatch(listAllUsers(obj))
    this.setState({ xTravelMarketingUserList: data })
  }

  async loadUnizHomeUserList() {
    let { dispatch } = this.props
    let obj = {
      userType: 'unizHomeUser',
      select: ['name', 'mobile'],
      results: 100
    }
    let { data } = await dispatch(listAllUsers(obj))
    this.setState({ unizHomeUsers: data })
  }

  render() {
    let {
      selectedRow,
      countryList,
      visibleUpdatePassword,
      selectedUser,
      visibleUpdateUser,
      visibleUpdateSubAgent,
      visibleApplicationDrawer,
      queryData,
      visibleUserRightDrawer,
      marketingState,
      approvedAgentRightsUser,
      countryName,
      agentFilterType,
      rightState,
      marketingObjState,
      verifyState,
      assignUserState,
      agentCount,
      total,
      reeudoManagerList,
      xTravelMarketingUserList,
      unizHomeUsers
    } = this.state
    let { events } = this
    const columns = [
      {
        title: '#',
        key: '_id',
        dataIndex: '_id',
        width: 50,
        render: (ite, record, index) => {
          return <React.Fragment>{index + 1}</React.Fragment>
        }
      },
      {
        title: 'Name',
        key: 'name',
        sorter: true,
        dataIndex: 'name',
        width: 100,
        render: (item, record) => {
          return (
            <ColumnWidth width={100}>
              {item}
              <CheckActiveUser userId={record._id} {...this.props} />
            </ColumnWidth>
          )
        }
      },
      {
        title: 'Company Name',
        key: 'companyName',
        sorter: true,
        dataIndex: 'companyName',
        // searchTextName: 'companyName',
        width: 150,
        render: (item, record) => {
          let { website } = record
          return (
            <ColumnWidth width={150}>
              {item}
              <br />
              {website ?
                <div className={'d-flex mt5'}>
                  <Tooltip title={website}>
                    <a href={website} target={'_blank'}
                       className={'btn btn-link roundNew xs p0 aic mr5'}>
                      <Icon type='link' />Website
                    </a>
                  </Tooltip>
                  <Tooltip title={'Copy'}>
                    <a onClick={() => {
                      events.copyWebsiteFxn(website)
                    }} className={'btn btn-default roundNew2 xs aic ml10 alignCenter'}>
                      <Icon type='copy' className={'mr0'} />
                    </a>
                  </Tooltip>
                </div> : null}
            </ColumnWidth>
          )
        }
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        // searchTextName: 'email',
        width: 120,
        render: (item, record) => {
          return <MobileEmailInfo data={record} type={'email'} value={item} />
        }
      },
      {
        title: 'Mobile No',
        dataIndex: 'mobile',
        key: 'mobile',
        // searchTextName: 'mobile',
        width: 80,
        render: (item, record) => {
          return <MobileEmailInfo data={record} type={'mobile'} value={item} />
        }
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        searchTextName: 'address',
        width: 150,
        render: item => {
          return <ColumnWidth width={150}>{item}</ColumnWidth>
        }
      },
      {
        title: 'Show Comm.',
        dataIndex: 'showCommission',
        key: 'showCommission',
        filters: ConditionOptions.map(x => ({ value: x.value, text: x.name })),
        width: 90,
        render: item => {
          return (
            <>
              {item != false ? (
                <div className={'btn btn-success-green roundNew xs'}>Yes</div>
              ) : (
                <div className={'btn btn-danger roundNew xs'}>No</div>
              )}
            </>
          )
        }
      },
      {
        title: 'Add Team',
        dataIndex: 'allowAddingUser',
        key: 'allowAddingUser',
        filters: BooleanOptions.map(x => ({ value: x.value, text: x.name })),
        width: 100,
        render: item => {
          return (
            <ColumnWidth width={100}>
              {item != false ? (
                <div className={'btn btn-success-green roundNew xs'}>Enable</div>
              ) : (
                <div className={'btn btn-danger roundNew xs'}>Disable</div>
              )}
            </ColumnWidth>
          )
        }
      },

      {
        title: 'City Name',
        dataIndex: 'cityName',
        key: 'cityName',
        // searchTextName: 'address',
        // filters: CityJson.map(x => ({ value: x.name, text: x.name })),
        width: 100,
        render: item => {
          return <ColumnWidth width={80}>{item}</ColumnWidth>
        }
      },

      {
        title: 'State Name',
        dataIndex: 'stateName',
        key: 'stateName',
        width: 100,
        // filters: StateJson.map(x => ({ value: x.name, text: x.name })),
        render: item => {
          return <ColumnWidth width={80}>{item}</ColumnWidth>
        }
      },
      {
        title: 'Country Name',
        dataIndex: 'countryName',
        key: 'countryName',
        width: 100,
        // filters: CountryJson.map(x => ({ value: x.name, text: x.name })),
        render: item => {
          return <ColumnWidth width={80}>{item}</ColumnWidth>
        }
      },
      {
        title: 'Pincode',
        dataIndex: 'pincode',
        key: 'pincode',
        // searchTextName: 'address',
        width: 100,
        render: item => {
          return <ColumnWidth width={80}>{item}</ColumnWidth>
        }
      },

      {
        title: 'Logo',
        dataIndex: 'logo',
        key: 'logo',
        width: 100,
        render: val => {
          return (
            <ColumnWidth width={100}>
              {' '}
              {val && val.url ? (
                <img src={val.url} className={'imgSm'} />
              ) : null}
            </ColumnWidth>
          )
        }
      },
      {
        title: 'Alternate Email',
        dataIndex: 'alternateEmails',
        key: 'alternateEmails',
        width: 200,
        render: val => {
          let emails = ''
          if (val) {
            emails = val.split(',')
          }
          return (
            <div>
              {emails && emails.length ? (
                <ul className={'countList'}>
                  {emails.map((ite, key) => {
                    return <li key={key}>{ite}</li>
                  })}
                </ul>
              ) : null}
            </div>
          )
        }
      },
      {
        title: 'Approved By',
        dataIndex: 'createdByUser.userId',
        key: 'createdByUser.userId',
        width: 150,
        filters: approvedAgentRightsUser.map(x => ({
          value: x._id,
          text: x.name
        })),
        render: (item, record) => {
          let val = record.createdByUser
          return (
            <ColumnWidth width={150}>
              {val && val.userId ? (
                <div>
                  User : {val.name}
                  {val.time ? (
                    <div>Date : {longDisplayDate(val.time)}</div>
                  ) : null}
                </div>
              ) : null}
            </ColumnWidth>
          )
        }
      },
      {
        title: 'Block',
        dataIndex: 'block',
        width: 80,
        key: 'block',
        // filters: ConditionOptions.map(x => ({value: x.value, text: x.name})),
        render: (item, record) => {
          return (
            <React.Fragment>
              {record && record.userType && record.userType !== 'admin' ? (
                <div>
                  <Popconfirm
                    title={`Are your sure, you want to ${
                      item ? 'Un-Block' : 'Block'
                    } this User?`}
                    onConfirm={() => {
                      this.updateAgentBlockStatus(record._id)
                    }}
                    okText='Yes'
                    cancelText='No'>
                    {item ? (
                      <div className={'btn btn-danger roundNew xs'}>Yes</div>
                    ) : (
                      <div className={'btn btn-success roundNew xs'}>No</div>
                    )}
                  </Popconfirm>
                </div>
              ) : null}
            </React.Fragment>
          )
        }
      },
      {
        title: 'Managers',
        dataIndex: 'managers',
        width: 120,
        key: 'managers',
        render: (item, record) => {
          let { armUsers } = record
          return (
            <div key={this.state.keyMoment}>
              {armUsers && armUsers.length ? (
                <ul>
                  {armUsers.map((item, index) => {
                    return (
                      <li key={item._id}>
                        {index + 1}. {item && item.name ? item.name : ''}
                      </li>
                    )
                  })}
                </ul>
              ) : null}
            </div>
          )
        }
      },
      {
        title: 'Marketing User',
        dataIndex: 'marketingUser',
        width: 150,
        key: 'marketingUser',
        render: (item, record) => {
          return (
            <a className={'btn btn-default xs roundNew'}
               onClick={() => {
                 this.events.showMarketingListDrawer(record)
               }}>
              Marketing Users
            </a>
          )
        }
      },
      {
        title: 'Reeudo Manager',
        dataIndex: 'reeudoManagerId',
        width: 120,
        key: 'reeudoManagerId',
        render: (item) => {
          return (
            <div>
              {item && item.name ? item.name : ''}
            </div>
          )
        }
      },
      {
        title: 'X Travel Marking User',
        dataIndex: 'xTravelWorldMarketingId',
        width: 120,
        key: 'xTravelWorldMarketingId',
        render: (item) => {
          return (
            <div>
              {item && item.name ? item.name : ''}
            </div>
          )
        }
      },

      {
        title: 'Certificate',
        dataIndex: 'certificate',
        width: 120,
        key: 'certificate',
        render: (item) => {
          return (
            <div>
              {item && item.url ? (
                <a
                  className={'btn btn-link roundNew xs'}
                  href={item.url}
                  target={'_blank'}>
                  View Certificate
                </a>
              ) : null}
            </div>
          )
        }
      },
      {
        title: 'Show Contract',
        dataIndex: 'showContract',
        key: 'showContract',
        filters: BooleanOptions.map(x => ({ value: x.value, text: x.name })),
        width: 120,
        render: item => {
          return (
            <ColumnWidth width={120}>
              {item != false ? (
                <div className={'btn btn-success-green roundNew xs'}>Yes</div>
              ) : (
                <div className={'btn btn-danger roundNew xs'}>No</div>
              )}
            </ColumnWidth>
          )
        }
      },
      {
        title: 'Verified',
        key: 'agentVerified',
        width: 130,
        render: (item) => {
          return (
            item !== undefined ? <>
              {!item ? <label className='label label-danger round sm'>Not Verified</label> : null}
              {item ? <label className='label label-success-green round sm'>Verified</label> : null}
            </> : null
          )
        }
      },
      {
        title: 'Action',
        key: '_id',
        dataIndex: '_id',
        fixed: 'right',
        width: 150,
        render: (val, record) => {
          let { currentUser } = this.props
          let { agentVerified } = record
          return (
            <div>
              <ButtonGrid width={100} gap={0}>
                {record.userType !== 'master' ? (
                  <Tooltip title='Edit'>
                    <a className={'btn sm'}
                       onClick={() => {
                         {
                           record.userType == 'subAgent'
                             ? this.setState({
                               visibleUpdateSubAgent: true,
                               selectedUser: record
                             })
                             : this.setState({
                               visibleUpdateUser: true,
                               selectedUser: record
                             })
                         }
                       }}>
                      <Icon type={'edit'} />
                    </a>
                  </Tooltip>
                ) : null}
                <Tooltip title='Change Password'>
                  <a
                    className={'btn sm'}
                    onClick={() => {
                      this.setState({
                        selectedUser: record,
                        visibleUpdatePassword: true
                      })
                    }}>
                    <Icon type={'key'} />
                  </a>
                </Tooltip>
                <Tooltip title='Applications'>
                  <a
                    className={'btn sm'}
                    onClick={() => {
                      this.events.showApplicationDrawer(record)
                    }}>
                    <Icon type={'eye'} />
                  </a>
                </Tooltip>

                <CheckUserRight
                  user={currentUser}
                  rightUserType={['admin', 'branchManager']}>
                  <Tooltip title='Rights'>
                    <a
                      className={'btn sm'}
                      onClick={() => {
                        this.events.showUserRightDrawer(record)
                      }}>
                      <Icon type='trophy' />
                    </a>
                  </Tooltip>
                </CheckUserRight>

                <LogsComponents
                  userData={record}
                  {...this.props}
                  currentUser={currentUser}
                />
                <Tooltip title='Assign Marketing User'>
                  <a
                    className={'btn sm'}
                    onClick={() => {
                      this.events.showMarketingDrawer(record)
                    }}>
                    <Icon type='solution' />
                  </a>
                </Tooltip>


                {currentUser && currentUser.userType == 'admin' ?
                  <>
                    <Tooltip title='Agent Rights'>
                      <a
                        className={'btn sm'}
                        onClick={() => {
                          this.events.showRightDrawer(record)
                        }}>
                        <img src={'../../dist/img/secure-access.png'} />
                      </a>
                    </Tooltip>
                  </> : null}

                <AllowComponentRightsWise rightUserType={['userManager', 'admin']}>
                  <Tooltip title='Assign User'>
                    <a
                      className={'btn sm'}
                      onClick={() => {
                        this.events.showAssignUserDrawer(record)
                      }}>
                      <Icon type='usergroup-add' />
                    </a>
                  </Tooltip>
                </AllowComponentRightsWise>

              </ButtonGrid>

              <AllowComponentRightsWise rightUserType={['userManager', 'itTeam']}>
                <>
                  {!agentVerified ?
                    <a className={'btn btn-default roundNew xs mt5 mr5'} onClick={() => {
                      events.showVerifyModal(record)
                    }}>
                      Verify Now
                    </a> : null}
                  <br />
                  <Popconfirm title={'Are you sure, you want to Re-Generate Certificates?'}
                              onConfirm={() => {
                                events.generateCertificateFxn(record._id)
                              }}>
                    <a className={'btn btn-default roundNew xs mt5 mr5'}>
                      Re-generate Certificates
                    </a>
                  </Popconfirm>


                </>
              </AllowComponentRightsWise>
              <AllowComponentRightsWise rightUserType={['itTeam']}>
                <Popconfirm
                  title={'Are your sure, you want to Auto Assign Marketing Users?'}
                  onConfirm={() => {
                    events.submitAutoAssignMarketingUser(record)
                  }}>
                  <a className={'btn btn-default roundNew xs mt5'}>
                    Assign
                  </a>
                </Popconfirm>
              </AllowComponentRightsWise>


            </div>
          )
        }
      }
    ]

    const filterBlock = (
      <div className={'filter_box mt5'}>
        <Row gutter={16} className={'flexWrap'}>

          <Col md={4} sm={4} xs={8} lg={4}>
            <div className='sort-box-table custom-sort-box-new'>
              <Select
                name='Search By Country'
                onChange={item => this.events.chooseCountry(item)}
                filterOption={filterOption}
                placeholder='Country'
                allowClear={true}
                showSearch={true}
                value={this.state.countryName || undefined}>
                {this.state.allCountries && this.state.allCountries.length
                  ? this.state.allCountries.map((item, key) => {
                    return (
                      <Option value={item.name} key={key}>
                        {item.name}
                      </Option>
                    )
                  })
                  : null}
              </Select>
            </div>
          </Col>
          {/*</AllowComponentRightsWise>*/}
          <Col md={4} sm={4} xs={8} lg={4}>
            <div className='sort-box-table custom-sort-box-new'>
              <Select
                name='Search By State'
                onChange={item => this.events.chooseState(item)}
                filterOption={filterOption}
                placeholder='State'
                allowClear={true}
                showSearch={true}
                value={this.state.stateName || undefined}>
                {this.state.allStates && this.state.allStates.length
                  ? this.state.allStates.map((item, key) => {
                    return (
                      <Option value={item.name} key={key}>
                        {item.name}
                      </Option>
                    )
                  })
                  : null}
              </Select>
            </div>
          </Col>
          <Col md={4} sm={4} xs={8} lg={4}>
            {!countryName || countryName == 'India' ? (
              <div className='sort-box-table custom-sort-box-new'>
                <Select
                  name='Search By City'
                  onChange={item => this.events.chooseCity(item)}
                  filterOption={filterOption}
                  placeholder='City'
                  allowClear={true}
                  showSearch={true}
                  value={this.state.cityName || undefined}>
                  {this.state.allCities && this.state.allCities.length
                    ? this.state.allCities.map((item, key) => {
                      return (
                        <Option value={item.name} key={key}>
                          {item.name}
                        </Option>
                      )
                    })
                    : null}
                </Select>
              </div>
            ) : (
              <div className='search-box-table round ml0'>
                <input
                  name='Search By City'
                  className='form-control'
                  onChange={e => {
                    this.setState({ cityName: e.target.value })
                  }}
                  placeholder='City'
                  value={this.state.cityName}
                />
                <img src='/dist/img/search.png' alt='' />
              </div>
            )}
          </Col>
          <Col md={4} sm={4} xs={8} lg={4}>
            <div className='sort-box-table custom-sort-box-new'>
              <Select
                name='Search By Branch Manager'
                onChange={item => {
                  this.setState({ agentWiseManagerId: item })
                }}
                filterOption={filterOption}
                placeholder='Branch Manager'
                allowClear={true}
                showSearch={true}
                value={this.state.agentWiseManagerId || undefined}>
                {this.state.branchManager && this.state.branchManager.length
                  ? this.state.branchManager.map((item, key) => {
                    return (
                      <Option value={item._id} key={key}>
                        {item.name}
                      </Option>
                    )
                  })
                  : null}
              </Select>
            </div>
          </Col>
          <Col md={4} sm={4} xs={8} lg={4}>
            <div className='sort-box-table custom-sort-box-new'>
              <Select
                name='Search By Marketing User'
                onChange={item => {
                  this.setState({ marketingManagerId: item })
                }}
                filterOption={filterOption}
                placeholder='Marketing Manager'
                allowClear={true}
                showSearch={true}
                value={this.state.marketingManagerId || undefined}>
                {this.state.allMarketingUser && this.state.allMarketingUser.length
                  ? this.state.allMarketingUser.map((item, key) => {
                    return (
                      <Option value={item._id} key={key}>
                        {item.name}
                      </Option>
                    )
                  })
                  : null}
              </Select>
            </div>
          </Col>

          <Col md={4} sm={4} xs={8} lg={4}>
            <div className='search-box-table round ml0'>
              <input
                className='form-control form-control'
                type='search'
                placeholder='Pincode'
                value={this.state.pincode}
                onChange={e => {
                  this.setState({ pincode: e.target.value })
                }}
              />
              <img src='/dist/img/search.png' alt='' />
            </div>
          </Col>

          <Col md={4} sm={4} xs={8} lg={4}>
            <div className='search-box-table round ml0'>
              <input
                className='form-control form-control'
                type='search'
                placeholder='Name'
                value={this.state.name}
                onChange={e => {
                  this.setState({ name: e.target.value })
                }}
              />
              <img src='/dist/img/search.png' alt='' />
            </div>
          </Col>

          <Col md={4} sm={4} xs={8} lg={4}>
            <div className='search-box-table round ml0'>
              <input
                className='form-control form-control'
                type='search'
                placeholder='Company Name'
                value={this.state.companyName}
                onChange={e => {
                  this.setState({ companyName: e.target.value })
                }}
              />
              <img src='/dist/img/search.png' alt='' />
            </div>
          </Col>

          <Col md={4} sm={4} xs={8} lg={4}>
            <div className='search-box-table round ml0'>
              <input
                className='form-control form-control'
                type='search'
                placeholder='Email'
                value={this.state.email}
                onChange={e => {
                  this.setState({ email: e.target.value })
                }}
              />
              <img src='/dist/img/search.png' alt='' />
            </div>
          </Col>
          <Col md={4} sm={4} xs={8} lg={4}>
            <div className='search-box-table round ml0'>
              <input
                className='form-control form-control'
                type='search'
                placeholder='Mobile No'
                value={this.state.mobile}
                onChange={e => {
                  this.setState({ mobile: e.target.value })
                }}
              />
              <img src='/dist/img/search.png' alt='' />
            </div>
          </Col>
          <Col md={4} sm={4} xs={8} lg={4}>
            <div className='sort-box-table custom-sort-box-new'>
              <Select
                name='Sorting'
                onChange={item => {
                  this.setState({ sortingOrder: item })
                }}
                placeholder='State'
                allowClear={true}
                value={this.state.sortingOrder || undefined}>
                {sortingOrderList.map((item, key) => {
                  return (
                    <Option value={item.name} key={item.name}>
                      {item.name}
                    </Option>
                  )
                })}
              </Select>
            </div>
          </Col>
          <Col md={4} sm={4} xs={8} lg={4}>
            <div className='sort-box-table custom-sort-box-new'>
              <Select
                name='Agent Status'
                onChange={item => {
                  this.setState({ profileStatus: item })
                }}
                placeholder='Profile Status'
                allowClear={true}
                value={this.state.profileStatus || undefined}>
                {blockStatusList.map((item, key) => {
                  return (
                    <Option value={item.value} key={item.name}>
                      {item.name}
                    </Option>
                  )
                })}
              </Select>
            </div>
          </Col>
          <Col md={4} sm={4} xs={8} lg={4}>
            <div className='sort-box-table custom-sort-box-new'>
              <Select
                name='Reeudo Manager'
                onChange={item => {
                  this.setState({ reeudoManagerId: item })
                }}
                placeholder='Reeudo Manager'
                allowClear={true}
                value={this.state.reeudoManagerId || undefined}>
                {reeudoManagerList.map((item, key) => {
                  return (
                    <Option value={item._id} key={item._id}>
                      {item.name}
                    </Option>
                  )
                })}
              </Select>
            </div>
          </Col>
          <Col md={4} sm={4} xs={8} lg={4}>
            <div className='sort-box-table custom-sort-box-new'>
              <Select
                name='X Travel Marketing User'
                onChange={item => {
                  this.setState({ xTravelWorldMarketingId: item })
                }}
                placeholder='X Travel Marketing User'
                allowClear={true}
                value={this.state.xTravelWorldMarketingId || undefined}>
                {xTravelMarketingUserList.map((item, key) => {
                  return (
                    <Option value={item._id} key={item._id}>
                      {item.name}
                    </Option>
                  )
                })}
              </Select>
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={8} sm={8} xs={12} lg={4}>
            <Button onClick={() => this.searchFxn()} className={'roundBtn'}>
              Search
            </Button>
            <Button onClick={() => this.clearFxn()} className={'roundBtn'}>
              Clear
            </Button>
          </Col>
        </Row>
      </div>
    )
    return (
      <div>
        <div className='row  mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='table-head d-flex align-items-center'>
                <div className={'w-50'}>
                  <h5>All Agents (Total : {total} )</h5>
                  <div className={'mt10'}>
                    <div className={'btn roundNew btn-default xs mr10'}>Active
                      : {agentCount.active} </div>
                    &nbsp;
                    <div className={'btn roundNew btn-default xs'}>Un-Active
                      : {agentCount.block} </div>
                  </div>
                </div>
                <div className='search-box-table'>

                </div>

                <div className='sort-box-table mark-btn'>
                  <Select
                    value={agentFilterType}
                    style={{ width: 250 }}
                    className={'antSelect'}
                    onChange={(value) => {
                      this.setState({ agentFilterType: value })
                    }}>
                    <Option value={'All'}>All Agents</Option>
                    <Option value={'showZeroRecord'}>Show Zero Record Agents</Option>
                    <Option value={'showWorkingRecord'}>Show All Working Agents</Option>

                  </Select>

                  {/*<Checkbox
                                        value={zeroRecordAgent}
                                        checked={zeroRecordAgent}
                                        onChange={e =>
                                            this.setState(
                                                {zeroRecordAgent: !zeroRecordAgent},
                                                () => {
                                                    this.tableRef.current.reload()
                                                }
                                            )
                                        }
                                        style={{float: 'right', marginBottom: 10}}>
                                        Show Zero Record Agents
                                    </Checkbox>*/}
                </div>
                <CheckUserRight
                  user={this.props.currentUser}
                  rightUserType={['admin']}>
                  <span className={'sort-box-table-right'}>
                    <Tooltip title={'Export Excel'}>
                      {' '}
                      <Button
                        onClick={() => this.exportAgentsFxn()}
                        className={'roundBtn'}>
                        Export Excel
                      </Button>
                    </Tooltip>
                  </span>
                </CheckUserRight>
              </div>

              <div className='card-body table-responsive'>
                {filterBlock}
                <TableComp
                  columns={columns}
                  ref={this.tableRef}
                  pagination={{
                    position: 'top',
                    pageSizeOptions: ['10', '20', '50', '100'],
                    defaultPageSize: 20
                  }}
                  apiRequest={this.apiRequest}
                  extraProps={{ scroll: { x: 2800 } }}
                />
              </div>
            </div>
          </div>
        </div>

        <Drawer
          visible={this.state.viewInfoDrawer}
          width={520}
          onClose={this.onClose}>
          <Card title={'Universities'}>
            {selectedRow &&
            selectedRow.universities &&
            selectedRow.universities.length ? (
              <List
                size='small'
                bordered
                dataSource={selectedRow.universities}
                renderItem={item => (
                  <List.Item>
                    {item && item.universityName ? item.universityName : null}
                  </List.Item>
                )}
              />
            ) : null}
          </Card>
        </Drawer>
        {visibleUpdatePassword ? (
          <UpdatePassword
            visible={visibleUpdatePassword}
            onClose={() => this.closeUpdatePassword()}
            userData={selectedUser}
          />
        ) : null}

        {visibleUpdateUser ? (
          <EditUser
            visible={visibleUpdateUser}
            reloadTable={() => {
              this.loadBranchManager()
              this.loadMarketingUser()
              setTimeout(() => {
                this.tableRef.current.reload()
                this.setState({ keyMoment: moment() })
              }, 2000)
            }}
            onClose={() => this.closeEditUser()}
            userData={selectedUser}
          />
        ) : null}
        {visibleUpdateSubAgent ? (
          <EditSubAgent
            visible={visibleUpdateSubAgent}
            onClose={() => this.closeEditSubAgent()}
            userData={selectedUser}
          />
        ) : null}

        {visibleApplicationDrawer ? (
          <ViewApplication
            visible={visibleApplicationDrawer}
            pageType={'allApplication'}
            onClose={() => this.events.hideApplicationDrawer()}
            queryData={queryData}
          />
        ) : null}

        {visibleUserRightDrawer ? (
          <UserRightList
            user={selectedUser}
            visible={visibleUserRightDrawer}
            onClose={() => this.events.hideUserRightDrawer()}
            closeAndUpdate={() => this.events.closeAndUpdateUserRightDrawer()}
          />
        ) : null}

        {marketingState.visible ? (
          <MarketingDrawer
            {...marketingState}
            countryList={countryList}
            onclose={() => this.events.hideMarketingDrawer()}
            onSubmit={() => {
              this.events.hideMarketingDrawer()
              this.tableRef.current.reload()
            }}
          />
        ) : null}
        {rightState.visible ? (
          <DirectUniversityRights
            {...rightState}
            onClose={() => this.events.hideRightDrawer()}
            onSubmit={() => {
              this.events.hideRightDrawer()
              this.tableRef.current.reload()
            }}
          />
        ) : null}

        {marketingObjState.visible ?
          <AgentMarketingUsers
            {...marketingObjState}
            onClose={this.events.hideMarketingListDrawer}
          /> : null}


        {verifyState.visible ?
          <AgentVerifiedModal
            {...verifyState}
            onClose={events.hideVerifyModal}
            onSubmit={() => {
              events.hideVerifyModal()
              this.tableRef.current.reload()
            }}
          /> : null}

        {assignUserState.visible ?
          <AssignUserToAgent
            {...assignUserState}
            onClose={() => {
              events.hideAssignUserDrawer()
            }}
            onSubmit={() => {
              events.hideAssignUserDrawer()
              this.tableRef.current.reload()
            }}
            marketingUserList={this.state.allMarketingManagerAndUser}
            reeudoManagerList={this.state.reeudoManagerList}
            xTravelMarketingUserList={xTravelMarketingUserList}
            unizHomeUsers={unizHomeUsers}
          /> : null}

      </div>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllUsers)
