import React, { useEffect, useState } from 'react'
import { getProfitLossStatementFxn } from './action'
import {
  countryIds, currentUdaan,
  getCurrentUdaan,
  numberToWords
} from '../../components/_utils/appUtils'
import S from 'string'
import { getUdaanFilterFxn } from '../dashboard/actions/dashboardAction'
import { Select } from 'antd'
import { useDispatch } from 'react-redux'

let { Option } = Select
const ProfitLossPage = () => {
  let dispatch = useDispatch()
  const [profitLossData, setProfitLossData] = useState(null)
  const [profitLossDataCanadaPrivate, setProfitLossDataCanadaPrivate] = useState(null)
  const [profitLossDataGic, setProfitLossDataGic] = useState(null)
  const [profitLossDataForex, setProfitLossDataForex] = useState(null)
  const [profitLossDataInternational, setProfitLossDataInternation] = useState(null)
  const [profitLossDataOther, setProfitLossDataOther] = useState(null)
  const [profitLossDataEurope, setProfitLossDataEurope] = useState(null)
  const [countriesInrRates, setCountriesInrRate] = useState([])
  const [revenueDetails, setRevenueDetails] = useState({ totalNetRevenueInINR: '', totalExpense: '', grossProfit: '' })
  const [processedData, setProcessedData] = useState({
    allCountryState: {},
    canadaPrivate: {},
    gic: {},
    forex: {},
    international: {},
    other: {},
    europe: {}
  })
  let [udaanFilter, setUdaanFilter] = useState([])
  let [selectedUdaan, setselectedUdaan] = useState(currentUdaan)

  const loadUdaanFilter = async () => {
    let udaanFilter = await getUdaanFilterFxn()
    setUdaanFilter((prevData) => {
      return [
        ...prevData,
        ...udaanFilter
      ]
    })
  }

  const getProfitLossStatement = async () => {
    const { data } = await dispatch(getProfitLossStatementFxn({ udaan: selectedUdaan }))
    setProfitLossData(data.data.allCountryData)
    setProfitLossDataCanadaPrivate(data.data.canadaPrivateData)
    setProfitLossDataGic(data.data.gicData)
    setProfitLossDataForex(data.data.forexData)
    setProfitLossDataInternation(data.data.internationalData)
    setProfitLossDataOther(data.data.otherData)
    setProfitLossDataEurope(data.data.europeData)
    if (data.data.countriesData.length > 0) {
      setCountriesInrRate(data.data.countriesData)
    }
  }

  useEffect(() => {
    getProfitLossStatement()
  }, [selectedUdaan])
  useEffect(() => {
    loadUdaanFilter()
  }, [])


  const getCurrencyRateByCountryId = (countryId) => {
    const country = countriesInrRates.find(item => item._id === countryId)
    if (country && country.currencyInrRate !== undefined) {
      return country.currencyInrRate
    }
    return 1
  }

  useEffect(() => {
    if (processedData && Object.keys(processedData).length > 0) {
      function calculateTotals(data) {
        let totalNetRevenueInINR = 0
        let totalExpense = 0

        function traverse(obj) {
          for (const key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
              traverse(obj[key])
            } else {
              if (key === 'netRevenueInINR') {
                totalNetRevenueInINR += obj[key]
              } else if (key === 'totalExpense') {
                totalExpense += obj[key]
              }
            }
          }
        }

        traverse(data)
        const grossProfit = totalNetRevenueInINR - totalExpense
        return { totalNetRevenueInINR, totalExpense, grossProfit }
      }

      const totals = calculateTotals(processedData)
      if (totals) {
        setRevenueDetails(totals)
      }
    }
  }, [processedData])

  useEffect(() => {
    if (profitLossData) {
      const currencyRateUK = countriesInrRates.length > 0 && getCurrencyRateByCountryId(countryIds.uk)
      const currencyRateCanada = countriesInrRates.length > 0 && getCurrencyRateByCountryId(countryIds.canada)

      const calculateSectionData = (countryId, currencyRate) => {
        const findData = (list, key, defaultValue = 0) =>
          list && list.find((item) => item._id === countryId) ? list.find((item) => item._id === countryId)[key] : defaultValue

        const commission = findData(profitLossData.commission, 'totalCommission')
        const agentShare = findData(profitLossData.agentShare, 'totalCommission')
        const visaCount = findData(profitLossData.visaCount, 'visaCount')
        const staffSalaryItem = profitLossData.departmentExpense.find(
          (item) => item && item._id && item._id.countryId === countryId && item._id.expenseType === 'staffSalary'
        )
        const staffSalary = staffSalaryItem ? staffSalaryItem.totalExpense : 0

        const otherExpenseItem = profitLossData.departmentExpense.find(
          (item) => item && item._id && item._id.countryId === countryId && item._id.expenseType === 'otherExpense'
        )
        const otherExpense = otherExpenseItem ? otherExpenseItem.totalExpense : 0

        const otherBonusItem = profitLossData.departmentExpense.find(
          (item) => item && item._id && item._id.countryId === countryId && item._id.expenseType === 'otherProfit'
        )
        const otherBonus = otherBonusItem ? otherBonusItem.totalExpense : 0

        const counsellorBonus = countryId === countryIds.canada ? 0 : findData(profitLossData.counsellorBonus, 'totalCommission')


        const staffTaItem = profitLossData.staffTa.find(
          (item) => item && item.departmentCountryId && item.departmentCountryId === countryId
        )
        const staffTA = staffTaItem ? staffTaItem.totalExpense : 0


        const netRevenue = commission - agentShare - counsellorBonus + otherBonus
        const netRevenueInINR = netRevenue * currencyRate
        const totalExpense = staffSalary + staffTA + otherExpense
        const netProfit = netRevenueInINR - totalExpense
        const perVisaProfit = visaCount === 0 ? 0 : netProfit / visaCount

        return {
          revenueDetails: {
            commission,
            agentShare,
            counsellorBonus,
            otherBonus,
            netRevenue,
            currencyRate,
            netRevenueInINR
          },
          expenseDetails: {
            staffSalary,
            staffTA,
            otherExpense,
            totalExpense,
            netProfit,
            visaCount,
            perVisaProfit
          }
        }
      }

      setProcessedData((prev) => ({
        ...prev,
        allCountryState: {
          uk: calculateSectionData(countryIds.uk, currencyRateUK),
          canadaPublic: calculateSectionData(countryIds.canada, currencyRateCanada)
        }
      }))
    }

    if (profitLossDataCanadaPrivate) {
      const currencyRateCanada = getCurrencyRateByCountryId(countryIds.canada)

      const calculateSectionData = (countryId, currencyRate) => {
        const findData = (list, key, defaultValue = 0) =>
          list && list.find((item) => item._id === countryId) ? list.find((item) => item._id === countryId)[key] : defaultValue

        const commission = profitLossDataCanadaPrivate && profitLossDataCanadaPrivate.commission.length ? profitLossDataCanadaPrivate.commission[0].totalVisaApproved * 3520 : null
        const visaCount = profitLossDataCanadaPrivate && profitLossDataCanadaPrivate.commission.length ? profitLossDataCanadaPrivate.commission[0].totalVisaApproved : null
        const staffSalaryItem = profitLossDataCanadaPrivate.departmentExpense.find(
          (item) => item && item._id && item._id.expenseType === 'staffSalary'
        )
        const staffSalary = staffSalaryItem ? staffSalaryItem.totalExpense : 0

        const otherExpenseItem = profitLossDataCanadaPrivate.departmentExpense.find(
          (item) => item && item._id && item._id.expenseType === 'otherExpense'
        )
        const otherExpense = otherExpenseItem ? otherExpenseItem.totalExpense : 0

        const otherBonusItem = profitLossDataCanadaPrivate.departmentExpense.find(
          (item) => item && item._id && item._id.expenseType === 'otherProfit'
        )
        const otherBonus = otherBonusItem ? otherBonusItem.totalExpense : 0

        const staffTaItem = profitLossDataCanadaPrivate.staffTa.find(
          (item) => item && item.departmentName && item.departmentName == 'Canada Private'
        )
        const staffTA = staffTaItem ? staffTaItem.totalExpense : 0

        const counsellorBonus = findData(profitLossDataCanadaPrivate.counsellorBonus, 'totalCommission')

        const netRevenue = commission - counsellorBonus + otherBonus
        const netRevenueInINR = netRevenue * currencyRate
        const totalExpense = staffSalary + staffTA + otherExpense
        const netProfit = netRevenueInINR - totalExpense
        const perVisaProfit = visaCount === 0 ? 0 : netProfit / visaCount

        return {
          revenueDetails: {
            commission,
            counsellorBonus,
            otherBonus,
            netRevenue,
            currencyRate,
            netRevenueInINR
          },
          expenseDetails: {
            staffSalary,
            staffTA,
            otherExpense,
            totalExpense,
            netProfit,
            visaCount,
            perVisaProfit
          }
        }
      }

      setProcessedData((prev) => ({
        ...prev,
        canadaPrivate: {
          canadaPrivate: calculateSectionData(countryIds.canada, currencyRateCanada)
        }
      }))
    }

    if (profitLossDataGic) {
      const currencyRateCanada = getCurrencyRateByCountryId(countryIds.canada)
      const calculateSectionData = (currencyRate) => {
        const commission = profitLossDataGic && profitLossDataGic.commission.length ? profitLossDataGic.commission[0].count * 250 : 0
        const agentShare = profitLossDataGic && profitLossDataGic.commission.length ? profitLossDataGic.commission[0].count * 175 : 0
        const staffSalaryItem = profitLossDataGic.departmentExpense.find(
          (item) => item && item._id && item._id.expenseType === 'staffSalary'
        )
        const staffSalary = staffSalaryItem ? staffSalaryItem.totalExpense : 0

        const otherExpenseItem = profitLossDataGic.departmentExpense.find(
          (item) => item && item._id && item._id.expenseType === 'otherExpense'
        )
        const otherExpense = otherExpenseItem ? otherExpenseItem.totalExpense : 0

        const otherBonusItem = profitLossDataGic.departmentExpense.find(
          (item) => item && item._id && item._id.expenseType === 'otherProfit'
        )
        const otherBonus = otherBonusItem ? otherBonusItem.totalExpense : 0

        const staffTaItem = profitLossDataGic.staffTa.find(
          (item) => item && item.departmentName && item.departmentName == 'Gic'
        )
        const staffTA = staffTaItem ? staffTaItem.totalExpense : 0


        const netRevenue = commission - agentShare + otherBonus
        const netRevenueInINR = netRevenue * currencyRate
        const totalExpense = staffSalary + staffTA + otherExpense
        const netProfit = netRevenueInINR - totalExpense

        return {
          revenueDetails: {
            commission,
            agentShare,
            otherBonus,
            netRevenue,
            currencyRate,
            netRevenueInINR
          },
          expenseDetails: {
            staffSalary,
            staffTA,
            otherExpense,
            totalExpense,
            netProfit
          }
        }
      }

      setProcessedData((prev) => ({
        ...prev,
        gic: {
          gicData: calculateSectionData(currencyRateCanada)
        }
      }))
    }

    if (profitLossDataForex) {
      const currencyRateCanada = 1

      const calculateSectionData = (currencyRate) => {
        const commission = profitLossDataForex && profitLossDataForex.commission.length ? profitLossDataForex.commission[0].totalProfit : 0
        const agentShare = profitLossDataForex && profitLossDataForex.agentShare.length ? profitLossDataForex.agentShare[0].agentProfit : 0
        const counsellorBonus = profitLossDataForex && profitLossDataForex.counsellorBonus.length ? profitLossDataForex.counsellorBonus[0].count * 100 : 0
        const referredBonus = profitLossDataForex && profitLossDataForex.referredBonus.length ? profitLossDataForex.referredBonus[0].count * 100 : 0
        const staffSalaryItem = profitLossDataForex.departmentExpense.find(
          (item) => item && item._id && item._id.expenseType === 'staffSalary'
        )
        const staffSalary = staffSalaryItem ? staffSalaryItem.totalExpense : 0
        const otherExpenseItem = profitLossDataForex.departmentExpense.find(
          (item) => item && item._id && item._id.expenseType === 'otherExpense'
        )
        const otherExpense = otherExpenseItem ? otherExpenseItem.totalExpense : 0

        const otherBonusItem = profitLossDataForex.departmentExpense.find(
          (item) => item && item._id && item._id.expenseType === 'otherProfit'
        )
        const otherBonus = otherBonusItem ? otherBonusItem.totalExpense : 0

        const staffTaItem = profitLossDataGic.staffTa.find(
          (item) => item && item.departmentName && item.departmentName == 'Forex'
        )
        const staffTA = staffTaItem ? staffTaItem.totalExpense : 0

        const netRevenue = commission - agentShare - counsellorBonus - referredBonus + otherBonus
        const netRevenueInINR = netRevenue * currencyRate
        const totalExpense = staffSalary + staffTA + otherExpense
        const netProfit = netRevenueInINR - totalExpense

        return {
          revenueDetails: {
            commission,
            agentShare,
            netRevenue,
            counsellorBonus,
            otherBonus,
            referredBonus,
            currencyRate,
            netRevenueInINR
          },
          expenseDetails: {
            staffSalary,
            staffTA,
            otherExpense,
            totalExpense,
            netProfit
          }
        }
      }

      setProcessedData((prev) => ({
        ...prev,
        forex: {
          forexData: calculateSectionData(currencyRateCanada)
        }
      }))
    }

    if (profitLossDataInternational) {
      const calculateSectionData = () => {
        const staffSalaryItem = profitLossDataInternational.departmentExpense.find(
          (item) => item && item._id && item._id.expenseType == 'staffSalary'
        )
        const staffSalary = staffSalaryItem ? staffSalaryItem.totalExpense : 0
        const otherExpenseItem = profitLossDataInternational.departmentExpense.find(
          (item) => item && item._id && item._id.expenseType == 'otherExpense'
        )
        const otherExpense = otherExpenseItem ? otherExpenseItem.totalExpense : 0

        const otherBonusItem = profitLossDataInternational.departmentExpense.find(
          (item) => item && item._id && item._id.expenseType == 'otherProfit'
        )
        const otherBonus = otherBonusItem ? otherBonusItem.totalExpense : 0

        const staffTaItem = profitLossDataInternational.staffTa.find(
          (item) => item && item.departmentName && item.departmentName == 'International'
        )
        const staffTA = staffTaItem ? staffTaItem.totalExpense : 0

        const totalExpense = staffSalary + staffTA + otherExpense

        return {
          expenseDetails: {
            staffSalary,
            staffTA,
            otherExpense,
            otherBonus,
            totalExpense
          }
        }
      }

      setProcessedData((prev) => ({
        ...prev,
        international: {
          internationalData: calculateSectionData()
        }
      }))
    }

    if (profitLossDataOther) {
      const calculateSectionData = () => {
        const staffSalaryItem = profitLossDataInternational.departmentExpense.find(
          (item) => item && item._id && item._id.expenseType == 'staffSalary'
        )
        const staffSalary = staffSalaryItem ? staffSalaryItem.totalExpense : 0
        const otherExpenseItem = profitLossDataInternational.departmentExpense.find(
          (item) => item && item._id && item._id.expenseType == 'otherExpense'
        )
        const otherExpense = otherExpenseItem ? otherExpenseItem.totalExpense : 0

        const otherBonusItem = profitLossDataInternational.departmentExpense.find(
          (item) => item && item._id && item._id.expenseType == 'otherProfit'
        )
        const otherBonus = otherBonusItem ? otherBonusItem.totalExpense : 0

        const staffTaItem = profitLossDataInternational.staffTa.find(
          (item) => item && item.departmentName && item.departmentName == 'Other'
        )
        const staffTA = staffTaItem ? staffTaItem.totalExpense : 0

        const totalExpense = staffSalary + staffTA + otherExpense

        return {
          expenseDetails: {
            staffSalary,
            staffTA,
            otherExpense,
            otherBonus,
            totalExpense
          }
        }
      }

      setProcessedData((prev) => ({
        ...prev,
        other: {
          otherData: calculateSectionData()
        }
      }))
    }

    if (profitLossDataEurope) {
      const currencyRateGermany = getCurrencyRateByCountryId(countryIds.germany)

      const calculateSectionData = (currencyRate) => {
        const commission = profitLossDataEurope && profitLossDataEurope.commission.length ? profitLossDataEurope.commission[0].totalCommission : null
        const agentShare = profitLossDataEurope && profitLossDataEurope.agentShare.length ? profitLossDataEurope.agentShare[0].totalCommission : null
        const visaCount = profitLossDataEurope && profitLossDataEurope.visaCount.length ? profitLossDataEurope.visaCount[0].visaCount : null

        const staffSalaryItem = profitLossDataEurope.departmentExpense.find(
          (item) => item && item._id && item._id == 'staffSalary'
        )
        const staffSalary = staffSalaryItem ? staffSalaryItem.totalExpense : 0
        const otherExpenseItem = profitLossDataEurope.departmentExpense.find(
          (item) => item && item._id && item._id == 'otherExpense'
        )
        const otherExpense = otherExpenseItem ? otherExpenseItem.totalExpense : 0

        const otherBonusItem = profitLossDataEurope.departmentExpense.find(
          (item) => item && item._id && item._id == 'otherProfit'
        )
        const otherBonus = otherBonusItem ? otherBonusItem.totalExpense : 0

        const staffTaItem = profitLossDataEurope.staffTa.find(
          (item) => item && item.departmentName && item.departmentName == 'Other'
        )
        const staffTA = staffTaItem ? staffTaItem.totalExpense : 0

        const netRevenue = commission - agentShare
        const netRevenueInINR = netRevenue * currencyRate
        const totalExpense = staffSalary + staffTA + otherExpense
        const netProfit = netRevenueInINR - totalExpense
        const perVisaProfit = visaCount === 0 ? 0 : netProfit / visaCount

        return {
          revenueDetails: {
            commission,
            agentShare,
            otherBonus,
            netRevenue,
            currencyRate,
            netRevenueInINR
          },
          expenseDetails: {
            staffSalary,
            staffTA,
            otherExpense,
            totalExpense,
            netProfit,
            visaCount,
            perVisaProfit
          }
        }
      }

      setProcessedData((prev) => ({
        ...prev,
        europe: {
          europeData: calculateSectionData(currencyRateGermany)
        }
      }))
    }

  }, [countriesInrRates, profitLossData, profitLossDataEurope, profitLossDataCanadaPrivate, profitLossDataGic, profitLossDataForex, profitLossDataInternational, profitLossDataOther])

  const renderSection = (title, data, currency) => (
    <div className='profit-loss-section'>
      <h3 className='profit-loss-section-title'>{title}</h3>
      <div className='profit-loss-section-content d-flex'>
        {data && data.revenueDetails != undefined &&
        <div className='profit-loss-details-box'>
          <h4>Revenue Details</h4>
          {data && data.revenueDetails && data.revenueDetails.commission !== undefined && (
            <div className='profit-loss-details-row'>
              <span className='profit-loss-detail-name'>Gross Revenue</span>
              {data.revenueDetails && data.revenueDetails.commission ? <span className='profit-loss-detail-amount'>
              {currency}{data.revenueDetails.commission.toLocaleString()}
            </span> : null}
            </div>
          )}
          {data && data.revenueDetails && data.revenueDetails.agentShare !== undefined && (
            <div className='profit-loss-details-row'>
              <span className='profit-loss-detail-name'>(-) Agent Share</span>
              {data.revenueDetails && data.revenueDetails.agentShare ? <span className='profit-loss-detail-amount'>
              (-{currency}{data.revenueDetails.agentShare.toLocaleString()})
            </span> : null}
            </div>
          )}
          {data && data.revenueDetails && data.revenueDetails.otherBonus !== undefined && (
            <div className='profit-loss-details-row'>
              <span className='profit-loss-detail-name'>(+) Other Bonus</span>
              <span className='profit-loss-detail-amount'>
              (+{currency}{data.revenueDetails.otherBonus.toLocaleString()})
            </span>
            </div>
          )}
          {data && data.revenueDetails && data.revenueDetails.counsellorBonus !== undefined && (
            <div className='profit-loss-details-row'>
              <span className='profit-loss-detail-name'>(-) Counsellor Bonus</span>
              <span className='profit-loss-detail-amount'>
              (-{currency}{data.revenueDetails.counsellorBonus.toLocaleString()})
            </span>
            </div>
          )}
          {data && data.revenueDetails && data.revenueDetails.referredBonus !== undefined && (
            <div className='profit-loss-details-row'>
              <span className='profit-loss-detail-name'>(-) Refer Bonus</span>
              <span className='profit-loss-detail-amount'>
              (-{currency}{data.revenueDetails.referredBonus.toLocaleString()})
            </span>
            </div>
          )}
          {data && data.revenueDetails && data.revenueDetails.netRevenue !== undefined && (
            <div className='profit-loss-details-row'>
              <span className='profit-loss-detail-name profit-loss-boldValue'>Company Revenue</span>
              <span className='profit-loss-detail-amount profit-loss-border-top-bottom'>
              {currency}{data.revenueDetails.netRevenue.toLocaleString()}
            </span>
            </div>
          )}
          {data && data.revenueDetails && data.revenueDetails.currencyRate !== undefined && (
            <div className='profit-loss-details-row'>
              <span className='profit-loss-detail-name'>(*) Currency Rate</span>
              <span className='profit-loss-detail-amount'>
              *{data.revenueDetails.currencyRate}
            </span>
            </div>
          )}
          {data && data.revenueDetails && data.revenueDetails.netRevenueInINR !== undefined && (
            <>
              <div className='profit-loss-details-row'>
                <span className='profit-loss-detail-name profit-loss-boldValue'>Company Revenue in INR</span>
                <span className='profit-loss-detail-amount profit-loss-border-top-bottom'>
              ₹{data.revenueDetails.netRevenueInINR.toLocaleString()}
            </span>
              </div>
              <span className='profit-loss-detail-words align-right'>
            ({numberToWords(data.revenueDetails.netRevenueInINR)} rupees)
            </span>
            </>
          )}
        </div>
        }
        <div className='profit-loss-details-box'>
          <h4>Expense Details</h4>
          {data && data.expenseDetails && data.expenseDetails.staffSalary !== undefined && (
            <div className='profit-loss-details-row'>
              <span className='profit-loss-detail-name'>(+) Staff Salary</span>
              <span className='profit-loss-detail-amount'>
              ₹{data.expenseDetails.staffSalary.toLocaleString()}
            </span>
            </div>
          )}
          {data && data.expenseDetails && data.expenseDetails.staffTA !== undefined && (
            <div className='profit-loss-details-row'>
              <span className='profit-loss-detail-name'>(+) Staff TA</span>
              <span className='profit-loss-detail-amount'>
              ₹{data.expenseDetails.staffTA.toLocaleString()}
            </span>
            </div>
          )}
          {data && data.expenseDetails && data.expenseDetails.otherExpense !== undefined && (
            <div className='profit-loss-details-row'>
              <span className='profit-loss-detail-name'>(+) Other Expense</span>
              <span className='profit-loss-detail-amount'>
              ₹{data.expenseDetails.otherExpense.toLocaleString()}
            </span>
            </div>
          )}
          {data && data.expenseDetails && data.expenseDetails.totalExpense !== undefined && (
            <div className='profit-loss-details-row'>
              <span className='profit-loss-detail-name profit-loss-boldValue'>Total Expense</span>
              <span className='profit-loss-detail-amount profit-loss-border-top-bottom'>
              ₹{data.expenseDetails.totalExpense.toLocaleString()}
            </span>
            </div>
          )}
          {data && data.expenseDetails && data.expenseDetails.netProfit !== undefined && (
            <div className='profit-loss-details-row'>
              <span className='profit-loss-detail-name profit-loss-boldValue'>Net Profit</span>
              <span className='profit-loss-detail-amount'>
              ₹{data.expenseDetails.netProfit.toLocaleString()}
            </span>
            </div>
          )}
          {data &&
          data.expenseDetails &&
          data.expenseDetails.visaCount !== undefined &&
          data.expenseDetails.perVisaProfit !== undefined && (
            <>
              <div className='profit-loss-details-row'>
              <span className='profit-loss-detail-name'>
                Per Visa Profit <b>@ {data.expenseDetails.visaCount}</b>
              </span>
                <span className='profit-loss-detail-amount profit-loss-border-top-bottom'>
                ₹{data.expenseDetails.perVisaProfit.toLocaleString()}
              </span>
              </div>
              <span className='profit-loss-detail-words align-right'>
            ({numberToWords(data.expenseDetails.perVisaProfit)} rupees)
            </span>
            </>
          )}
        </div>
      </div>
    </div>
  )


  return (
    <div className='profit-loss-page'>
      <div className='profit-loss-header-section'>
        <h2>Profit & Loss Statement</h2>
      </div>
      <div className='profit-loss-header-section-udaan'>
        <div className={'alignRight'}>
          <Select className={'w-25'} value={selectedUdaan} onChange={(e) => {
            setselectedUdaan(e)
          }}>
            {udaanFilter && udaanFilter.length ? udaanFilter.map((item) => {
              return (
                <Option value={item.value} key={item.value}><b>{S(item.value).humanize().s}</b> {item.message ?
                  <span>({item.message})</span> : ''}</Option>
              )
            }) : null}
          </Select>
        </div>
      </div>
      <div className='profit-loss-summary-section'>
        <div className='profit-loss-summary-box revenue'>
          <h4>Total Revenue</h4>
          <p>₹{revenueDetails.totalNetRevenueInINR.toLocaleString()}</p>
          <span className='profit-loss-detail-words-top align-right'>
            ({numberToWords(revenueDetails.totalNetRevenueInINR)} rupees)
            </span>
        </div>
        <div className='profit-loss-summary-box expense'>
          <h4>Total Expense</h4>
          <p>₹{revenueDetails.totalExpense.toLocaleString()}</p>
          <span className='profit-loss-detail-words-top align-right'>
            ({numberToWords(revenueDetails.totalExpense)} rupees)
            </span>
        </div>
        <div className='profit-loss-summary-box profit'>
          <h4>Gross Profit</h4>
          <p>₹{revenueDetails.grossProfit.toLocaleString()}</p>
          <span className='profit-loss-detail-words-top align-right'>
            ({numberToWords(revenueDetails.grossProfit)} rupees)
            </span>
        </div>
      </div>
      {processedData.allCountryState && (
        <>
          {renderSection('UK', processedData.allCountryState.uk, '£')}
          {renderSection('Canada Public', processedData.allCountryState.canadaPublic, '$')}
        </>
      )}
      {processedData.gic && (
        <>
          {renderSection('Gic', processedData.gic.gicData, '$')}
        </>
      )}
      {processedData.forex && (
        <>
          {renderSection('Forex', processedData.forex.forexData, '₹')}
        </>
      )}
      {processedData.canadaPrivate && (
        <>
          {renderSection('Canada Private', processedData.canadaPrivate.canadaPrivate, '$')}
        </>
      )}
      {processedData.europe && (
        <>
          {renderSection('Europe', processedData.europe.europeData, '€')}
        </>
      )}
      {processedData.international && (
        <>
          {renderSection('International', processedData.international.internationalData, '$')}
        </>
      )}
      {processedData.other && (
        <>
          {renderSection('Other', processedData.other.otherData, '$')}
        </>
      )}
    </div>
  )
}

export default ProfitLossPage
