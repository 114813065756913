import React, { PureComponent, useState } from 'react'
import {
  Form,
  Input,
  Select,
  Button, Col, Row, notification, Icon, Card, Table, Tag, DatePicker, Avatar
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { hideLoader, hidePageLoad } from '../../modules/actions'
import { connect, useDispatch } from 'react-redux'
import { listAllUniversities } from '../university/actions/university'
import { listAllCountries } from '../countries/actions/countries'
import {
  InputBox,
  departmentObj,
  PreferredLanguage,
  AustraliaState,
  newFormatDisplayDate
} from '../../components/_utils/appUtils'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import { listAllUsers } from '../users/actions/user'
import { addStudentInsurance, getNibPriceFxn } from './actions/actions'
import styles from '../../components/Qualification/style.less'
import nibLogoSvg from '../../assets/nib/nib-logo.svg'
import { push } from 'connected-react-router'

const insuranceTypeList = [
  { name: 'Single', value: 'Single' },
  { name: 'Couple', value: 'Couple' },
  { name: 'Family', value: 'Family' }
]

let productList = [
  {
    name: 'nib OSHC',
    value: 'nib OSHC'
  }
]

let declaration = [
  'I declare the details in this Application Form to be true and complete, and I agree to be bound by the rules and by-laws of nib as amended from time to time. I have read and understood nib’s rules for transferring from another health fund',
  'I declare that dependants on this policy are under the age of 18 years and are listed as a dependant on the student visa.',
  'I authorise my previous health fund, hospital, medical or other authorities to release to nib all information required to confirm my benefit entitlements.',
  'I acknowledge I have been given access to the nib Privacy Policy, and the opportunity to clarify any issues or concerns. I consent to nib collecting, using or disclosing my personal information for the purposes set out in the nib Privacy Policy. I acknowledge nib is not responsible for the security of my personal information contained in this Application Form until it is received by nib.',
  'I realise that by submitting this Application Form I am authorised by each person listed herein to consent to nib making disclosures about their health information to other people listed in the application.',
  'I acknowledge that upon requesting cancellation or refund of my OSHC Policy with nib, that my details will be forwarded to the Department of Immigration and Citizenship (DIAC)'
]

const initState = {
  startDate: '',
  endDate: '',
  type: 'Single'
}

const FormItem = Form.Item
const { Option } = Select
const { TextArea } = Input

class AddStudentInsurance extends PureComponent {


  agentEvents = {
    fetchAgent: async (name) => {
      let { dispatch } = this.props
      let obj = {
        results: 100,
        userType: 'agent',
        sortField: 'name',
        sortOrder: 'ascend',
        select: ['name', 'email', 'companyName'],
        name: name,
        block: false
      }
      let { data } = await dispatch(listAllUsers(obj))
      this.setState({
        agentList: data
      })
    },
    removeDependent: (index) => {
      let cloneAgent = _.clone(this.state.agents)
      cloneAgent.splice(index, 1)
      this.setState({
        agents: cloneAgent
      })
    },
    chooseAgent: (data) => {
      let { agents, agentList } = this.state
      let findUser = _.find(agents, (item) => {
        return item._id && item._id.toString() == data
      })
      if (findUser) {
        this.setState({
          agentName: '',
          agentList: []
        })
        return notification.warning({
          message: 'Agent already exists.'
        })
      } else {
        let findAgent = _.find(agentList, (item) => {
          return item._id && item._id.toString() == data
        })
        if (findAgent) {

          let cloneAgents = _.clone(agents)
          cloneAgents.push(findAgent)

          this.setState({
            agentName: '',
            agentList: [],
            agents: cloneAgents
          })
        }
      }
    }
  }
  events = {
    selectAllUni: () => {
      const { form: { setFieldsValue } } = this.props
      let { universityList } = this.state
      let universities = []
      _.each(universityList, (item) => {
        if (item && item._id) {
          universities.push(item._id)
        }
      })
      setFieldsValue({
        universities: universities
      })
    },
    deSelectAllUni: () => {
      const { form: { setFieldsValue } } = this.props
      let universities = []
      setFieldsValue({
        universities: universities
      })
    }
  }
  conditionalFxn = {}

  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
    // this.tableRef = useRef();
    this.state = {
      countryOfOrigin: '',
      product: '',
      insuranceCoverFor: '',
      primaryStudent: '',
      title: '',
      name: '',
      middleName: '',
      lastName: '',
      dateOfBirth: '',
      details: '',
      preferredLanguage: '',
      studentName: '',
      mobile: '',
      currentlyInAustralia: '',
      addressLine1: '',
      addressLine2: '',
      suburb: '',
      postCode: '',
      state: '',
      visaStartDate: '',
      visaEndDate: '',
      passportNumber: '',
      visaType: '',
      dependentList: [],
      acceptCondition: true,
      uploadKey: moment(),
      uploadOfferKey: moment() + 5,
      passport: {},
      offerLetter: {}
    }
  }

  componentDidMount() {
    this.loadCountry()
    let { dispatch, form: { setFieldsValue } } = this.props
    dispatch({ type: 'USER_HIDELOADER' })
    dispatch(hideLoader())
    dispatch(hidePageLoad())

    setFieldsValue({
      countryOfOrigin: 'India',
      product: 'nib OSHC',
      insuranceCoverFor: 'Single',
      title: 'Mr',
      gender: 'Male',
      preferredLanguage: 'English',
      gst: 'No'
    })
  }

  async loadCountry() {
    let { dispatch } = this.props
    let countryFilter = {
      results: 10,
      sortField: 'countryName',
      sortOrder: 'ascend'
    }
    let { data } = await listAllCountries(countryFilter)(dispatch)
    this.setState({
      countryList: data
    })
  }

  async loadCountryUniversity(countryId) {
    let { dispatch } = this.props
    let filters = {
      sortField: 'universityName',
      sortOrder: 'ascend',
      results: 1000000, universityCountry: countryId
    }
    let { data } = await dispatch(listAllUniversities(filters))
    this.setState({
      universityList: data
    })
  }

  addDependent = () => {
    const {
      form: { getFieldValue, setFieldsValue }
    } = this.props
    let { dependentList } = this.state
    let obj = {
      title: getFieldValue('dependentTitle'),
      gender: getFieldValue('dependentGender'),
      name: getFieldValue('dependentName'),
      middleName: getFieldValue('dependentMiddleName'),
      lastName: getFieldValue('dependentLastName'),
      dateOfBirth: getFieldValue('dependentDateOfBirth')
    }

    let list = _.clone(dependentList)
    list.push(obj)
    this.setState({ dependentList: list })

    setFieldsValue({
      'dependentTitle': 'Mr',
      'dependentGender': 'Male',
      'dependentName': '',
      'dependentLastName': '',
      'dependentMiddleName': '',
      'dependentDateOfBirth': ''
    })

  }

  removeDependent = (index) => {
    let { dependentList } = this.state
    let cloneList = _.clone(dependentList)
    cloneList.splice(index, 1)
    this.setState({ dependentList: cloneList })
  }


  handleSubmit = e => {
    const { dispatch, form, state, priceState, resetForm } = this.props
    e.preventDefault()
    let fd = new FormData()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let { address, dependentList, passport, offerLetter } = this.state

        valData.dependentList = dependentList
        if (state.startDate) {
          valData.startDate = state.startDate
        }
        if (state.endDate) {
          valData.endDate = state.endDate
        }
        if (state.type) {
          valData.type = state.type
          valData.insuranceCoverFor = state.type
        } else {
          valData.insuranceCoverFor = 'Single'
        }
        if (priceState.paymentByFrequency) {
          valData.paymentByFrequency = priceState.paymentByFrequency
        }
        if (priceState.paymentTotal) {
          valData.paymentTotal = priceState.paymentTotal
        }
        if (priceState.tenureRating) {
          valData.tenureRating = priceState.tenureRating
        }
        if (!this.state.acceptCondition) {
          notification.warning({ message: 'Please Accept the terms and conditions.' })
          return
        }

        fd.append('obj', JSON.stringify(valData))

        if (passport && passport.name) {
          fd.append('passport', passport)
        } else {
          notification.warning({ message: 'Please Upload Passport' })
        }

        if (offerLetter && offerLetter.name) {
          fd.append('offerLetter', offerLetter)
        } else {
          notification.warning({ message: 'Please Upload Offer Letter' })
        }

        let data = await dispatch(addStudentInsurance(fd))
        if (data && !data.error) {
          resetForm()
          form.resetFields()
          this.setState({
            uploadKey: moment(),
            uploadOfferKey: moment() + 5,
            acceptCondition: undefined
          })
        }
      }
    })
  }

  render() {
    const {
      form: { getFieldDecorator, getFieldValue, setFieldsValue }
    } = this.props
    let { acceptCondition } = this.state


    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
        md: { span: 12, offset: 8 }
      }
    }


    const chooseCountryCode = (val) => {
      this.setState({
        'countryCode': val
      })
    }

    let columns = [
      {
        title: 'First name',
        // width: 400,
        key: 'name',
        dataIndex: 'name'
      },
      {
        title: 'Middle name',
        // width: 400,
        key: 'middleName',
        dataIndex: 'middleName'
      },
      {
        title: 'Last name',
        // width: 400,
        key: 'lastName',
        dataIndex: 'lastName'
      },
      {
        title: 'Date Of Birth',
        // width: 400,
        key: 'dateOfBirth',
        render: (val) => {
          return (
            <span>
            {newFormatDisplayDate(val)}
          </span>
          )
        },
        dataIndex: 'dateOfBirth'
      },
      {
        title: 'Actions',
        // width: 400,
        render: (val, result, index) => {
          return (

            <a onClick={() => {
              this.removeDependent(index)
            }}>
              <Icon type={'close'} style={{ color: 'red' }} />
            </a>
          )
        },
        dataIndex: 'dateOfBirth'
      }
    ]


    let inputTypes = {

      fields: [
        {
          label: 'Country Of Origin',
          key: 'countryOfOrigin',
          required: true,
          type: 'select',
          options: ['India', 'Others'],
          valueAccessor: x => x,
          keyAccessor: x => x,
          onChange: async (v) => {
            setFieldsValue({
              countryOfOrigin: v
            })
          }
        },
        {
          label: 'Product',
          key: 'product',
          required: true,
          type: 'select',
          disabled: true,
          options: productList,
          valueAccessor: x => x.name,
          keyAccessor: x => x.value,
          onChange: async (v) => {
            setFieldsValue({
              product: v
            })
          }
        }, {
          key: '1',
          span: 8,
          customField: (
            <div></div>
          )
        }
        /*  {
            label: 'Who is this cover for',
            key: 'insuranceCoverFor',
            required: true,
            type: 'select',
            disabled: true,
            options: ['Single', 'Couple', 'Family'],
            valueAccessor: x => x,
            keyAccessor: x => x,
            onChange: async (v) => {
              setFieldsValue({
                insuranceCoverFor: v
              })
            }
          }*/
      ],

      CustomerDetails: [
        {
          label: 'Title',
          key: 'title',
          required: true,
          type: 'select',
          span: 3,
          options: ['Mr', 'Mrs', 'Ms', 'Miss'],
          valueAccessor: x => x,
          keyAccessor: x => x,
          onChange: async (v) => {
            setFieldsValue({
              title: v,
              gender: v == 'Mr' ? 'Male' : 'Female'
            })
          }
        },
        {
          label: 'Gender',
          key: 'gender',
          disabled: true,
          span: 3
        },
        {
          label: 'First Name',
          placeholder: 'First Name',
          key: 'name',
          required: true,
          span: 6
        },
        {
          label: 'Middle Name',
          placeholder: 'Middle Name',
          key: 'middleName',
          span: 6
          // required: true
        },
        {
          label: 'Last Name',
          placeholder: 'Last Name',
          key: 'lastName',
          required: true,
          span: 6
        },

        {
          label: 'Student Email',
          placeholder: 'Student Email',
          key: 'studentEmail',
          type: 'email',
          required: true
        },
        {
          label: 'Student Mobile No',
          placeholder: 'Student Mobile No',
          key: 'mobile',
          type: 'phoneNumber',
          required: false
        },
        {
          label: 'Date Of Birth',
          placeholder: 'Date Of Birth',
          key: 'dateOfBirth',
          type: 'date',
          required: false
        },
        {
          label: 'Counsellor Name',
          placeholder: 'Counsellor Name',
          key: 'counsellorName',
          required: true
        },
        {
          label: 'Counsellor Contact No',
          placeholder: 'Counsellor Contact No',
          key: 'counsellorContactNo',
          type: 'phoneNumber',
          required: true
        }
      ],

      VisaDetails: [
        {
          label: 'College Name',
          key: 'collegeName',
          required: true
        },
        {
          key: 'passport',
          customField: (
            <InputBox title={'* Upload Passport'} className={'logoCls'} required={true}>
              <Input type={'file'} className={'form-control'} required={true} key={this.state.uploadKey}
                     name={'passport'}
                     id={'passport'}
                     onChange={(e) => {
                       this.setState({
                         passport: e.target.files && e.target.files.length ? e.target.files[0] : null
                       })
                     }} />
            </InputBox>
          )
        },
        {
          key: 'offerLetter',
          customField: (
            <InputBox title={'* Upload Offer Letter'} className={'logoCls'} required={true}>
              <Input type={'file'} className={'form-control'} required={true} key={this.state.uploadOfferKey}
                     name={'offerLetter'}
                     id={'offerLetter'}
                     onChange={(e) => {
                       this.setState({
                         offerLetter: e.target.files && e.target.files.length ? e.target.files[0] : null
                       })
                     }} />
            </InputBox>
          )
        }
      ]
    }


    return (
      <>
        <div className={'nib-oshc-block-new sm-box mt-2'}>
          <div className='header-nib align-center'>
            Student Insurance
          </div>

          <Form onSubmit={this.handleSubmit}>
            <div className='form-box'>
              <div className='card unizportal'>

                <Row gutter={16} className={'rowWrap'}>
                  {inputTypes.fields.map((item, key) => {
                    return (
                      <React.Fragment key={key}>
                        {item.customField ? !item.hidden && <Col span={item.span ? item.span : 8}>
                          {item.customField}
                        </Col> : !item.hidden &&
                          <Col span={item.span ? item.span : 8} key={key}
                               style={item.extraBox ? { paddingBottom: 1 } : {}}>
                            <InputBox title={item.label1 ? item.label1 : ''} extra={item.extraBox}>
                              <GetEachFormFields
                                item={item}
                                getFieldDecorator={getFieldDecorator}
                                formItemLayout={formItemLayout} />
                            </InputBox>
                          </Col>}
                      </React.Fragment>
                    )
                  })}

                  <div className='inner-form mt-4'>
                    <h4>Customer Details</h4>
                    <Row gutter={24} className={'rowWrap'}>
                      {inputTypes.CustomerDetails.map((item, key) => {
                        return (
                          <React.Fragment key={key}>
                            {item.customField ?
                              <Col span={item.span ? item.span : 8}>
                                {item.customField}
                              </Col> : !item.hidden &&
                              <Col span={item.span ? item.span : 8} key={key}>
                                <GetEachFormFields
                                  item={item}
                                  getFieldDecorator={getFieldDecorator}
                                  formItemLayout={formItemLayout} />
                              </Col>}
                          </React.Fragment>
                        )
                      })}
                    </Row>
                  </div>


                  <div className='inner-form mt-4'>
                    <h4>Details</h4>
                    <Row gutter={24}>
                      {inputTypes.VisaDetails.map((item, key) => {
                        return (
                          <React.Fragment key={key}>
                            {item.customField ?
                              <Col span={item.span ? item.span : 8}>
                                <div className={'mt10'}>
                                  {item.customField}
                                </div>
                              </Col> : !item.hidden &&
                              <Col span={item.span ? item.span : 8} key={key}>
                                <GetEachFormFields
                                  item={item}
                                  getFieldDecorator={getFieldDecorator}
                                  formItemLayout={formItemLayout} />
                              </Col>}
                          </React.Fragment>
                        )
                      })}
                    </Row>
                  </div>
                </Row>


                <h5 style={{ marginTop: 20 }}>Declaration:</h5>
                <ul style={{ listStyleType: 'disc', marginVertical: 10, paddingLeft: 20 }}>
                  {declaration.map((item) => {
                    return (
                      <li>{item}</li>
                    )
                  })}
                </ul>

                <div className={'col-4'}>
                  <label className={styles.test}>I have read and accept the terms and conditions above</label>
                  <Select required={true}
                          value={acceptCondition == true ? 'Yes' : 'No'}
                          onChange={(val) => {
                            if (val == 'Yes') {
                              this.setState({ acceptCondition: true })
                            } else {
                              this.setState({ acceptCondition: false })
                            }
                          }}>
                    <Option value={'Yes'}>Yes</Option>
                    <Option value={'No'} selected={true}>No</Option>
                  </Select>
                </div>

                <Form.Item>
                  <Button type='primary' htmlType='submit'
                    // disabled={getFieldValue('disableSubmit')}
                    //       disabled={!this.state.acceptCondition}
                    // loading={this.props.loading}
                          className={'btn'}>
                    SAVE
                  </Button>
                </Form.Item>


              </div>
            </div>

          </Form>

        </div>
      </>

    )
  }
}

const WrappedAddStudentInsurance = Form.create()(AddStudentInsurance)

const StudentInsurance = () => {
  let dispatch = useDispatch()
  let [state, setState] = useState(initState)
  let [visibleForm, setVisibleForm] = useState(false)
  let [priceState, setPriceState] = useState({})
  const events = {
    _updateState: (data) => {
      setState((prevData) => {
        return {
          ...prevData,
          ...data
        }
      })
      setVisibleForm(false)
      setPriceState({})
    },
    buyNowEvent: () => {
      setVisibleForm(true)
    },
    getPriceEvent: async () => {
      if (!state.startDate) {
        notification.warning({ message: 'Choose start date.' })
        return
      }
      if (!state.endDate) {
        notification.warning({ message: 'Choose end date.' })
        return
      }
      if (!state.type) {
        notification.warning({ message: 'Choose type.' })
        return
      }
      let { success, data } = await dispatch(getNibPriceFxn({ ...state, showLoader: true }))
      if (success) {
        setPriceState(data)
      }
    },
    resetForm: () => {
      setState(initState)
      setVisibleForm(false)
      setPriceState({})
    },
    disabledDate: (current, fromDate) => {
      return current && current.isBefore(fromDate, 'day')
    }
  }
  return (
    <>
      <div className={'nib-oshc-block-new sm-box mt-4'}>
        <div className='header-nib align-center'>
          Get Your Overseas Student Health Cover Price
        </div>
        <div className='table-responsive'>
          <div className={'filter_box'}>
            <Row gutter={20}>
              <Col span={6}>
                <InputBox title={'Start Date'}>
                  <DatePicker
                    placeholder={'Start Date'}
                    disabledDate={(current) => events.disabledDate(current, moment())}
                    value={state.startDate} onChange={(date) => {
                    events._updateState({ startDate: date })
                  }} />
                </InputBox>
              </Col>
              <Col span={6}>
                <InputBox title={'End Date'}>
                  <DatePicker
                    placeholder={'End Date'}
                    disabledDate={(current) => events.disabledDate(current, (state.startDate || moment()))}
                    value={state.endDate} onChange={(date) => {
                    events._updateState({ endDate: date })
                  }} />
                </InputBox>
              </Col>
              <Col span={6}>
                <InputBox title={'Who is this cover for?'}>
                  <Select
                    disabled={true}
                    value={state.type}
                    onChange={(value) => {
                      events._updateState({ type: value })
                    }}>
                    {insuranceTypeList.map((item) => {
                      return (
                        <Option value={item.value}>
                          {item.name}
                        </Option>
                      )
                    })}
                  </Select>
                </InputBox>
              </Col>
              <Col span={6}>

                <a className={'nib-btn mt30'} onClick={events.getPriceEvent}>
                  <span className={'w-100'}>Get Your Price</span>
                  <Icon type='right-circle' />
                </a>
              </Col>
            </Row>
          </div>
          {priceState && priceState.paymentTotal ?
            <div className={'nib-inner-block'}>
              <div className={'nib-price-card'}>
                <h4 className={'nib-title'}>Overseas Student Health Cover</h4>
                <div className={'info-aa'}>
                  <span>Starting From </span>
                  <div className={'price'}>
                    {priceState && priceState.paymentTotal ? `$${priceState.paymentTotal}` : ''}
                  </div>
                  <div>
                    AUD Per year for singles
                  </div>
                </div>
                <button className={'nib-btn'} onClick={events.buyNowEvent} disabled={visibleForm}>
                  <span className={'w-100'}>Buy Now!</span>
                  <Icon type='right-circle' />
                </button>
              </div>
              <div className={'nib-price-card'}>
                <ul>
                  <li className={'jcsb aic'}>
                    <span>Doctors and Specialists</span>
                    <Icon type={'info-circle'} />
                  </li>
                  <li className={'jcsb aic'}>
                    <span>Hospital</span>
                    <Icon type={'info-circle'} />
                  </li>
                  <li className={'jcsb aic'}>
                    <span>Emergency Ambulance</span>
                    <Icon type={'info-circle'} />
                  </li>
                  <li className={'jcsb aic'}>
                    <span>Prescription Medication</span>
                    <Icon type={'info-circle'} />
                  </li>
                </ul>
              </div>
            </div> :
            <div>
              <br />
            </div>
          }
        </div>
      </div>


      {/*<div className='form-box mt-4'>
        <div className='d-flex align-items-center mb-3 heading-form'>
          <h5>Get Your OSHC Australia Price</h5>
        </div>
        <div className='card unizportal'>
          <Row gutter={16}>
            <Col span={6}>
              <InputBox title={'Start Date'}>
                <DatePicker
                  placeholder={'Start Date'}
                  className={'form-control'}
                  disabledDate={(current) => events.disabledDate(current, moment())}
                  value={state.startDate} onChange={(date) => {
                  events._updateState({ startDate: date })
                }} />
              </InputBox>
            </Col>
            <Col span={6}>
              <InputBox title={'End Date'}>
                <DatePicker
                  placeholder={'End Date'}
                  className={'form-control'}
                  disabledDate={(current) => events.disabledDate(current, (state.startDate || moment()))}
                  value={state.endDate} onChange={(date) => {
                  events._updateState({ endDate: date })
                }} />
              </InputBox>
            </Col>
            <Col span={6}>
              <InputBox title={'Who is this cover for?'}>
                <Select
                  disabled={true}
                  value={state.type}
                  onChange={(value) => {
                    events._updateState({ type: value })
                  }}>
                  {insuranceTypeList.map((item) => {
                    return (
                      <Option value={item.value}>
                        {item.name}
                      </Option>
                    )
                  })}
                </Select>
              </InputBox>
            </Col>
            <Col span={6}>
              <a className={'btn btn-default roundNew mt30 lg'} onClick={events.getPriceEvent}>Get Your Price</a>
            </Col>
          </Row>

          {priceState && priceState.paymentTotal ? <div className={'mt20'}>
            <Row gutter={20}>
              <Col span={14}>
                <div className={'nib-oshc-block'}>
                  <div className={'nib-box w-100'}>
                    <div className={'info-aa'}>
                      <span>Your Price</span>
                      <div className={'price'}>
                        ${priceState && priceState.paymentTotal ? priceState.paymentTotal : ''}
                        <small className={'font12 ml5'}>AUD</small>
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <button disabled={visibleForm} className={'get-your-price-btn w-50 mt10'}
                              onClick={events.buyNowEvent}>
                        Buy Now
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={10}>
                <div className={'nib-oshc-block'}>
                  <div className={'nib-box w-100'}>
                    <ul>
                      <li className={'jcsb'}>
                        <span>Doctors and Specialists</span>
                        <Icon type={'info-circle'} />
                      </li>
                      <li className={'jcsb'}>
                        <span>Hospital</span>
                        <Icon type={'info-circle'} />
                      </li>
                      <li className={'jcsb'}>
                        <span>Emergency Ambulance</span>
                        <Icon type={'info-circle'} />
                      </li>
                      <li className={'jcsb'}>
                        <span>Prescription Medication</span>
                        <Icon type={'info-circle'} />
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div> : null}
        </div>
      </div>*/}

      {visibleForm ?
        <WrappedAddStudentInsurance
          dispatch={dispatch} state={state} priceState={priceState}
          resetForm={events.resetForm}
        /> : null}
    </>
  )
}

export default StudentInsurance
