import React from 'react'
import '../../FrontendComponents/registrationPage.css'
import { Form, Select, Button, Card, notification, Spin, Row, Col, Drawer, Input, Modal } from 'antd'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { useState } from 'react'
import {
  SingleEventsFxn,
  referenceMarketingFxn,
  verifyLeadFxn,
  addLeadForUkRoadshowFxn, ukRoadShowEvent
} from '../Webinar/action'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ConditionOptions, countryIds, displayDate, displayTime } from '../../../components/_utils/appUtils'
import { getUkArmManagerListFxn } from '../../users/actions/user'
import _ from 'lodash'
// const cityList = [
//   { name: 'Mohali', label: '5th March, Wednesday, Time - 7:30PM' },
//   { name: 'Jalandhar', label: '6th March, Thursday, Time - 7:30PM' },
//   { name: 'Ludhiana', label: '7th March, Friday, Time - 7:30PM' },
//   { name: 'Amritsar', label: '8th March, Saturday, Time - 7:30PM' },
//   { name: 'Hisar', label: '10th March, Monday, Time - 7:30PM' },
//   { name: 'Karnal', label: '11th March, Tuesday, Time - 7:30PM' }
// ]

const singleWebinar = (props) => {
  const { form: { getFieldDecorator, getFieldValue, setFieldsValue } } = props
  let [cityList, setCityList] = useState([])
  const eventId = '67c54a4a2f9e7017d1885da6'
  const dispatch = useDispatch()
  const [singleEvent, setSingleEvent] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [referenceList, setReferenceList] = useState([])
  const [open, setOpen] = useState(false)
  const [otp, setOtp] = useState('')
  let [respData, setRespData] = useState({})
  const [visible, setVisible] = useState(false)
  const [marketingUserList, setMarketingUserList] = useState([])

  const handleClose = () => {
    setVisible(false)
  }

  const handleOk = () => {
    setVisible(false)
  }

  const handleCancel = () => {
    setOpen(false)
  }


  const handleSubmit = (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFields(async (err, valData) => {
      if (!err) {
        valData.eventId = eventId
        if (valData.additionalMember) {
          valData.persons = 2
        }
        let resp = await dispatch(addLeadForUkRoadshowFxn(valData))
        if (resp && resp.success) {
          setVisible(true)
          props.form.setFieldsValue({
            name: '',
            email: '',
            companyName: '',
            city: undefined,
            address: '',
            country: undefined,
            mobile: '',
            reference: undefined,
            persons: '',
            secondPersonName: '',
            secondPersonMobile: '',
            designation: '',
            alreadyRegistered: undefined,
            additionalMember: undefined
          })
        }
      }
    })
  }

  const verifyOtp = async (e) => {
    const { form } = props
    e.preventDefault()
    if (!otp) {
      notification.warning({ message: 'Enter OTP' })
      return
    }

    let obj = {
      otp,
      agentId: respData.token,
      mobile: respData.mobile
    }
    let resp = await dispatch(verifyLeadFxn(obj))
    if (resp && resp.success) {
      setOpen(false)
      setVisible(true)
      let { data } = resp
      props.form.setFieldsValue({
        name: '',
        email: '',
        companyName: '',
        city: undefined,
        address: '',
        country: undefined,
        mobile: '',
        reference: undefined,
        persons: '',
        secondPersonName: '',
        secondPersonMobile: '',
        alreadyRegistered: undefined,
        designation: '',
        additionalMember: undefined
      })
    } else {
      notification.error({ message: resp.message })
      return
    }

  }


  const singleEventDataEvent = async (id) => {
    let { data, cityList } = await dispatch(ukRoadShowEvent(id))
    let findList = _.filter(cityList, 'active')
    setCityList(findList || [])
    if (data && data._id) {
      setSingleEvent(data)
      setIsLoaded(true)
    }
  }


  const handleState = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    let data = await dispatch(referenceMarketingFxn())
    setReferenceList(data)
  }

  useEffect(() => {
    handleState()
    singleEventDataEvent(eventId)
    loadMarketingUserData()
  }, [])

  let loadMarketingUserData = async () => {
    let data = {
      countryId: countryIds.uk,
      university: 'Public'
    }
    let { data: respData } = await getUkArmManagerListFxn(data)
    let obj = { name: 'Not Assigned', _id: 'Not Assigned' }
    let newList = [obj, ...respData]
    setMarketingUserList(newList)
  }


  let inputTypes = {
    fields: [
      {
        key: 'companyName',
        label: 'Company Name',
        type: 'text',
        required: true,
        placeholder: 'Enter Company Name'
      },
      {
        key: 'city', label: 'Which city event do you want to attend?', type: 'select', required: true,
        options: cityList,
        keyAccessor: x => x.name,
        showSearch: true,
        valueAccessor: x => `${x.name} - ${x.label}`,
        onChange: x => {
          setFieldsValue({
            city: x
          })
        }
      },
      { key: 'name', label: 'Full Name', type: 'text', required: true, placeholder: 'Enter Full Name' },
      { key: 'email', label: 'Your Email', type: 'text', required: true, placeholder: 'Enter Email' },

      /* {
         key: 'address',
         label: 'Company Address',
         type: 'text',
         required: true,
         placeholder: 'Enter Company Address'
       },*/

      /* {
         key: 'country', label: 'Country', type: 'select', required: true,
         options: CountryJson,
         keyAccessor: x => x.name,
         showSearch: true,
         valueAccessor: x => `${x.name}`,
         onChange: x => {
           setFieldsValue({
             country: x
           })
         }
       },*/
      { key: 'mobile', label: 'Phone No', type: 'text', required: true, placeholder: 'Enter Mobile no' },
      {
        key: 'alreadyRegistered',
        label: 'Already Registered with UnizPortal',
        type: 'select',
        required: true,
        options: ConditionOptions,
        keyAccessor: (x) => x.value,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            alreadyRegistered: x
          })
        }
      },
      /*
        {
          key: 'referralCode',
          label: 'Marketing Referral Code',
          placeholder: 'Marketing Referral Code',
          required: getFieldValue('alreadyRegistered') == false,
          hidden: !(getFieldValue('alreadyRegistered') == false),
          onChange: ({ target }) => {
            props.form.setFieldsValue({
              referralCode: target.value
            })
          }
        },*/
      {
        key: 'reference',
        label: 'Reference',
        type: 'select',
        required: true,
        showSearch: true,
        hidden: !(singleEvent.showReference) && (singleEvent.showReference) !== true,
        options: marketingUserList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            reference: x
          })
        }
      },
      {
        key: 'additionalMember',
        label: 'Add Additional Members',
        type: 'select',
        required: true,
        options: ConditionOptions,
        keyAccessor: (x) => x.value,
        valueAccessor: (x) => `${x.name}`,
        onChange: e => {
          setFieldsValue({
            additionalMember: e
          })
        }
      },

      {
        key: 'secondPersonName',
        label: 'Second Person Name',
        type: 'text',
        placeholder: 'Enter Second Person Name',
        required: true,
        hidden: !(getFieldValue('additionalMember'))
      },
      {
        key: 'designation',
        label: 'Second Person Designation',
        type: 'text',
        required: true,
        hidden: !(getFieldValue('additionalMember')),
        placeholder: 'Enter Designation'
      },
      {
        key: 'secondPersonMobile',
        label: 'Second Person Mobile No',
        type: 'text',
        required: true,
        hidden: !(getFieldValue('additionalMember')),
        placeholder: 'Enter Mobile No'
      }

    ]
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }
  return (
    <>
      <section className='banner blog-banner'>
        <div className='row align-items-center'>
          <div className='col-lg-12'>
          </div>
          {singleEvent && singleEvent.topBanner && singleEvent && singleEvent.topBanner.path ?
            <div className='col-lg-12'>
              <div className='img text-center'>
                <img src={singleEvent && singleEvent.topBanner &&
                singleEvent.topBanner.path ? singleEvent.topBanner.path : null} alt='no image '
                     width='100%' />
              </div>
            </div> : null}
        </div>
      </section>

      <section className='innovation gap-62 blog-innovation webinar-info'>
        <div className='container'>
          <div className='agent_form'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-container'>
                  <div className='align-center'>
                    <div className='agent-name'>Hey Agent’s
                    </div>
                    <p>Please fill this form to get yourself Registered!</p>
                  </div>
                  {isLoaded ? <>
                    <Form onSubmit={handleSubmit} noValidate={true}>
                      <div className=''>
                        <div className=''>
                          <Row gutter={24}>
                            {inputTypes.fields.map((item, key) => {
                              return (
                                <Col span={item.span ? item.span : 24} key={key}>
                                  <GetEachFormFields
                                    item={item}
                                    formItemLayout={formItemLayout}
                                    getFieldDecorator={getFieldDecorator}
                                  />

                                </Col>
                              )
                            })}


                          </Row>
                          <Form.Item>
                            <Button type='primary' htmlType='submit' className='btn_sub'>
                              SUBMIT
                            </Button>
                          </Form.Item>

                        </div>
                      </div>
                    </Form>
                  </> : null}

                  <Modal
                    title='Lead Verification'
                    visible={open}
                    footer={false}>
                    <div className={'text-center'}>
                      <i className={'fa fa-check-circle fa-2x'}
                         style={{ color: '#feb900' }}></i>
                      <h6> A text message with a-4 digit verification
                        code has been sent to your phone
                        number </h6>
                    </div>
                    <br />
                    <div className='mb-4 mt-2'>
                      <input type='text' className='form-control'
                             placeholder='Enter OTP'
                             value={otp}
                             minLength={4}
                             maxLength={4}
                             onChange={({ target }) => {
                               setOtp(target.value)
                             }}
                             name='name' />
                    </div>
                    <div className={'text-right'}>
                      <button
                        className='btn btn-success'
                        onClick={verifyOtp}>Submit
                      </button>
                      <button
                        className='btn btn-info m-lg-2'
                        onClick={handleCancel}>Cancel
                      </button>
                    </div>
                  </Modal>

                  <Modal
                    title='Thank You'
                    visible={visible}
                    onOk={handleOk}
                    onCancel={handleClose}>
                    <p>
                      Your registration request has been placed successfully. Our team representative will send you
                      invitation shortly.
                    </p>
                  </Modal>
                </div>
              </div>


              <div className='col-md-6'>
                {singleEvent && singleEvent.registrationBanner &&
                singleEvent.registrationBanner.path ?
                  <>
                    <div className='right_bg'
                         style={{
                           backgroundImage: `url(${singleEvent.registrationBanner.path})`,
                           backgroundSize: 'contain',
                           height: '100%'
                         }}>
                    </div>
                  </> :
                  <div className='right_bg'
                       style={{ backgroundImage: `url(../assets/image/working_bg.png)` }}>
                  </div>}
              </div>


            </div>
          </div>
        </div>
      </section>

      <section className='section-card'>
        <div className='container'>
          <div className='row info_box ' style={{ backgroundColor: 'white' }}>
            <div className='col-lg-5'>
              <h3> Date:{displayDate(singleEvent.date)}
                <div>
                  Time:{displayTime(singleEvent.time)} Onwards
                </div>
                {singleEvent.location ? <div>Location:{singleEvent.location}</div> : null}
                <br />
              </h3>

            </div>
            <div className='col-lg-7'>
              <p>
                {singleEvent.details}
              </p>
            </div>
          </div>
        </div>
      </section>

    </>


  )
}

export default Form.create()(singleWebinar)
