import React, { useState } from 'react'
import { Button, Card, Drawer, Form, Input, notification, Radio, Upload } from 'antd'
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { StripePromiseFcmtOnshore } from '../../../unizHome/homeUtils'
import { directPaymentInstallmentfxn, payFcmtInstallmentFxn, updateFcmtInstallmentsFxn } from '../actions'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'

const Payment = (props) => {
  let { data } = props
  let { visible, onClose, record } = data
  let { invoiceReference, amount, studentIdNo, name, studentId } = record
  let dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const initState = {
    studentIdNo: studentIdNo || '',
    amount: amount || '',
    name: name || '',
    cardHolderName: name || '',
    passportNumber: '',
    cardNumber: '',
    expiryYear: moment().format('YY'),
    expiryMonth: 1,
    cvv: '',
    invoiceReference: invoiceReference || '',
    details: '',
    receiverName: '',
    senderEmail: ''
  }
  let [state, setState] = useState(initState)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [file, setFile] = useState(null)


  const events = {
    setLocalState: (e) => {
      let { name, value } = e.target
      setState({
        ...state,
        [name]: value
      })
    },
    chooseDocument: (e) => {
      const { files } = e.target
      if (files && files.length > 0) {
        setFile(files[0])
      }
    },
    handleSubmit: async () => {
      if (paymentMethod === 'online') {
        dispatch(showPageLoad())
        const card = elements.getElement(CardElement)
        if (!card) {
          return
        }
        const result = await stripe.createToken(card)
        if (result && result.error) {
          notification.warning({ message: result.error.message })
          dispatch(hidePageLoad())
          return
        } else {
          let data = await payFcmtInstallmentFxn(state)
          try {
            const cardElement = elements.getElement(CardElement)
            const confirmPayment = await stripe.confirmCardPayment(data.clientSecret, {
              payment_method: { card: cardElement }
            })
            const { paymentIntent } = confirmPayment
            let obj = {
              fcmtPaymentId: data.fcmtPaymentId,
              status: 'Failed',
              response: ''
            }
            if (paymentIntent) {
              obj.status = paymentIntent.status
              obj.response = paymentIntent
            } else {
              obj.response = confirmPayment
            }
            obj.studentId = studentId
            obj.invoiceReference = invoiceReference
            obj.paymentMethod = paymentMethod

            let resp = await dispatch(updateFcmtInstallmentsFxn(obj))
            setState({
              ...state,
              ...initState
            })
            setTimeout(() => {
              dispatch(hidePageLoad())
              onClose()
            }, 500)

          } catch (e) {
            setTimeout(() => {
              dispatch(hidePageLoad())
            }, 500)
          }
        }
      } else {
        if (paymentMethod == 'email') {
          if (!state.senderEmail) {
            notification.warning({
              message: 'Please Enter Sender Email'
            })
            return
          }
        } else {
          if (!state.receiverName) {
            notification.warning({
              message: 'Please Enter Receive by Name'
            })
            return
          }
        }
        if (!file) {
          notification.warning({
            message: 'Please choose file'
          })
          return
        }
        if (!state.details) {
          notification.warning({
            message: 'Please enter details'
          })
          return
        }

        let obj = {
          installmentDetails: state.details,
          status: 'succeeded',
          invoiceReference: state.invoiceReference,
          studentId: studentId,
          paymentMethod: paymentMethod,
          ...state
        }

        let fd = new FormData()
        fd.append('obj', JSON.stringify(obj))
        if (file && file.name) {
          fd.append('paymentSlip', file)
        }
        let resp = await dispatch(directPaymentInstallmentfxn(fd))
        if (resp.success) {
          onClose()
        }
      }
    }
  }

  const CARD_ELEMENT_OPTIONS = {
    iconStyle: 'solid',
    hidePostalCode: true,
    style: {
      base: {},
      invalid: {
        color: '#e5424d',
        ':focus': {
          color: '#303238'
        }
      }
    }
  }


  return (
    <Drawer width={'45%'}
            title={`Payment Installment (Amount - $ ${amount && amount})`}
            visible={visible}
            onClose={() => {
              onClose()
            }}>
      <Card bordered={false}>
        <Form>
          <Form.Item label='Select Payment Method'>
            <Radio.Group className={'form-control'} onChange={(e) => setPaymentMethod(e.target.value)}>
              <Radio value='direct' className={'mt5px'}>Direct Payment</Radio>
              <Radio value='email' className={'mt5px'}>Email Payment</Radio>
              <Radio value='online' className={'mt5px'}>Online Payment</Radio>
            </Radio.Group>
          </Form.Item>

          {paymentMethod === 'direct' && (
            <>
              <Form.Item label='Receiver Name'>
                <input type={'text'} className={'form-control'} name={'receiverName'}
                       placeholder={'Receive by Name'}
                       value={state.receiverName}
                       onChange={(e) => {
                         events.setLocalState(e)
                       }}
                />
              </Form.Item>
              <Form.Item label='Upload Payment Slip'>
                <input type={'file'} className={'form-control'}
                       onChange={events.chooseDocument}
                />
              </Form.Item>
              <Form.Item label='Enter Details'>
                <Input.TextArea
                  onChange={(e) => {
                    events.setLocalState(e)
                  }}
                  className={'form-control'}
                  placeholder='Enter payment details'
                  name={'details'}
                />
              </Form.Item>
            </>
          )}
          {paymentMethod === 'email' && (
            <>
              <Form.Item label='Sender Email'>
                <input type={'text'} className={'form-control'} name={'senderEmail'}
                       placeholder={'Sender Email'}
                       value={state.senderEmail}
                       onChange={(e) => {
                         events.setLocalState(e)
                       }}
                />
              </Form.Item>
              <Form.Item label='Upload Payment Slip'>
                <input type={'file'} className={'form-control'}
                       onChange={events.chooseDocument}
                />
              </Form.Item>
              <Form.Item label='Enter Details'>
                <Input.TextArea
                  onChange={(e) => {
                    events.setLocalState(e)
                  }}
                  className={'form-control'}
                  placeholder='Enter payment details'
                  name={'details'}
                />
              </Form.Item>
            </>
          )}

          {paymentMethod === 'online' && (
            <>
              <Form.Item label='Enter Card Holder Name'>
                <Input onChange={(e) => {
                  events.setLocalState(e)
                }} className={'form-control'} placeholder='Card Holder Name' name={'cardHolderName'} />
              </Form.Item>
              <Form.Item label='Card'>
                <div className={'form-control'}>
                  <CardElement className={'mt5px'} options={CARD_ELEMENT_OPTIONS} />
                </div>
              </Form.Item>
            </>
          )}

          <Form.Item className='mt20 alignRight'>
            <Button type='primary' onClick={() => events.handleSubmit()}>
              Pay
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Drawer>
  )
}

const PaymentDrawer = (props) => {
  return (
    <>
      <Elements stripe={StripePromiseFcmtOnshore}>
        <Payment
          data={props}
        />
      </Elements>

    </>
  )
}
export default PaymentDrawer
