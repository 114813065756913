import React, { useEffect, useRef, useState } from 'react'
import { Tabs, Form, Row, Col, Button, Popconfirm, Tooltip, notification, Card, Select } from 'antd'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {
  addMapDistanceByCategoryFxn,

  deleteMapDistanceByNameFxn,

  mapDistanceFxn
} from '../action'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import EditPaymentPolicyModal from '../modal/editPaymentPolicy'
import EditMapDistanceModal from '../modal/editMapDistance'

const { TabPane } = Tabs

const CommonForm = (props) => {
  let dispatch = useDispatch()
  const tableRef = useRef()
  const {
    form: { getFieldDecorator, resetFields },
    tabName, propertyId
  } = props
  const [openMapDistance, setOpenMapDistance] = useState({
    visible: false,
    distanceData: {}
  })

  const formItemLayout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 24 }
  }
  const events = {
    showEditDistanceModal: async (data) => {
      setOpenMapDistance({
        visible: true,
        distanceData: data,
        distanceId: data._id,
        category: tabName

      })
    },

    hideEditDistanceModal: async () => {
      setOpenMapDistance({
        visible: false,
        distanceData: {}

      })

    }

  }
  const deleteMapDistanceByCategory = async (record) => {
    let finalObj = {
      distanceId: record._id,
      propertyId: propertyId,
      category: tabName
    }

    try {
      let resp = await dispatch(deleteMapDistanceByNameFxn(finalObj))
      if (resp) {
        setTimeout(() => {
          if (tableRef && tableRef.current) {
            tableRef.current.reload()
          }
        }, 100)
      }
    } catch (err) {
      console.log(err)
    }

  }
  const baseFormFields = [
    { key: 'name', label: 'Name', type: 'text', required: true, placeholder: 'Name' },
    { key: 'lat', label: 'Lat', type: 'text', required: true, placeholder: 'Lat' },
    { key: 'lng', label: 'Lng', type: 'text', required: true, placeholder: 'lng' },
    { key: 'distance', label: 'Distance', type: 'text', required: true, placeholder: 'Distance' },
    { key: 'duration', label: 'Duration', type: 'text', required: true, placeholder: 'Duration' }
  ]

  const apiRequest = () => {
    let params = {
      propertyId: propertyId,
      name: tabName

    }
    return new Promise(async (resolve) => {
      let { data } = await mapDistanceFxn({
        ...params
      })
      let items = []
      if (Array.isArray(data)) {
        data.forEach((element) => {
          if (Array.isArray(element.items)) {
            element.items.forEach((item) => {
              items.push(item)
            })
          }
        })
        resolve({ data: items })
      } else {
        resolve({ data: [] })
      }
    })
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'

    },
    {
      title: 'Lat',
      dataIndex: 'lat',
      key: 'lat'

    },
    {
      title: 'Lng',
      dataIndex: 'lng',
      key: 'lng'

    },
    {
      title: 'Distance',
      dataIndex: 'distance',
      key: 'distance'

    },
    {
      title: 'Action',
      key: '_id',
      dataIndex: '_id',
      width: 200,
      render: (val, record) => {

        return (
          <React.Fragment>
            <Tooltip title='Edit Image'>
              <a className={'btn btn-default roundNew sm mr5'}
                 onClick={() => {
                   events.showEditDistanceModal(record)
                 }}
              >
                Edit
              </a>
            </Tooltip>
            <Tooltip title='Delete'>
              <Popconfirm
                title='Are you sure, you want to delete this record?'
                onConfirm={() => deleteMapDistanceByCategory(record)}
              >
                <a className={'btn btn-danger roundNew sm'}>
                  Delete
                </a>
              </Popconfirm>
            </Tooltip>
          </React.Fragment>
        )
      }
    }
  ]
  const handleFormSubmit = async (e) => {
    e.preventDefault()

    const { form } = props
    form.validateFieldsAndScroll(async (err, valData) => {

      if (!err) {
        const updateData = {
          propertyId: propertyId,
          category: tabName,
          newDistance: {
            ...valData
          }
        }
        const resp = await dispatch(addMapDistanceByCategoryFxn(updateData))
        if (resp && resp.success) {
          notification.success({ message: resp.message })
          resetFields()
          setTimeout(() => {
            if (tableRef && tableRef.current) {
              tableRef.current.reload()
            }
          }, 100)
        }
      } else {
        notification.warning({
          message: 'Please fill in all required fields'
        })
      }
    })

  }

  return (
    <>
      <Card className={'mapDistanceCard mb20'}>
        <Form onSubmit={handleFormSubmit}>


          <Row gutter={16} className='wrapBox'>
            {baseFormFields.map((item, key) => {
              return (
                <React.Fragment key={key}>
                  {item.customField ? (
                    <Col span={4}>
                      {item.customField}
                    </Col>
                  ) : ((item.hidden == undefined || (!item.hidden)) && (
                    <Col span={4}>
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout}
                      />
                    </Col>
                  ))}
                </React.Fragment>
              )
            })}
            <Col span={4}>
              <Form.Item>
                <Button type='primary btn-sm' htmlType='submit' className={'btn mt40'}>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row>

          </Row>
        </Form>
      </Card>
      <Card className={'mapDistanceCard mb20'}>
        <div className='card-body table-responsive'>
          <TableComp
            apiRequest={apiRequest}
            columns={columns}
            ref={tableRef}
          />
        </div>
      </Card>

      {openMapDistance.visible && (
        <EditMapDistanceModal
          propertyId={propertyId}
          {...openMapDistance}
          onClose={() => events.hideEditDistanceModal()}
          tableRef={tableRef}
          onSubmit={() => {
            events.hideEditDistanceModal()

          }}
        />
      )}

    </>
  )
}

const DistanceComponent = (props) => {
  let { propertyId } = props
  let dispatch = useDispatch()

  const tabData = [
    { name: 'Universities', tabKey: '1' },
    { name: 'Bus Stations', tabKey: '2' },
    { name: 'Train Stations', tabKey: '3' },
    { name: 'Supermarkets', tabKey: '4' },
    { name: 'Pharmacies', tabKey: '5' },
    { name: 'Restaurants', tabKey: '6' }
  ]

  return (
    <Tabs defaultActiveKey={tabData[0].tabKey} type='line'>
      {tabData.map((data) => (
        <TabPane tab={data.name.toUpperCase()} key={data.tabKey}>
          <CommonForm {...props} tabName={data.name} propertyId={propertyId} dispatch={dispatch} />

        </TabPane>
      ))}
    </Tabs>
  )
}

export default Form.create()(DistanceComponent)
