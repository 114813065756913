import { Api_Url, Api_Url2, Reeudo_Url, Unizhome_Url, XTravel_Url } from './api'

export const reeudoForexApi = Reeudo_Url

export default {}
export const pageTitle = 'Uniz Portal'
export const subTitle = ''
export const apiUrl = Api_Url
export const apiUrl2 = Api_Url2
export const unizHomeUrl = Unizhome_Url
export const xTravelWorldUrl = XTravel_Url
export const casitaUrl = 'https://www.casita.com'
// export const reeudoForexApi = Api_Url2
// export const casitaUrl = ''
export const scholarUrl = 'https://api.scholarscrm.com'
export const dataApi = Api_Url
export const unizHomeApi = 'https://unizhome.com'
export const partnerCode = '35538435345345345345345'
// export const housematesUrl = 'https://api.housemates.io'
export const housematesUrl = 'https://api.dev-housemates.io1'
export const getTokenn = () => {
  return {
    headers: {
      'X-API-PARTNER-ID': '01HQ8CN3MS35FZ9M7NJP35VF3X',
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer 3|yqdvMsvy1KCFV0kidZONbtWhOQcLRJnDGpq2qvxU55ef4d08'
    }
  }
}

/*
export const getTokenn = () => {
  return {
    headers: {
      'X-API-PARTNER-ID': '01HE81H5RSKN24B61RYE4HW4BA',
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer 6|Xhg7fR4gAsoEiaZ33I8S3EsTKQoqN0jfV5LwCbLD13f120f0'
    }
  }
}
*/
