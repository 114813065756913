import React, { Component } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { Row, Col, Select, notification, Button, Card, Icon } from 'antd'
import { connect } from 'react-redux'
import {
  adminRightUser,
  displayDate,
  FcmtCollegeOnshore,
  fcmtUniversity,
  filterOption
} from '../../../components/_utils/appUtils'
import { ColumnWidth } from '../../WebComponent/columnWidth'
import { getUrlPushWrapper } from '../../../routes/routes'
import { LoadAllCountry, LoadCity, LoadState } from '../../../components/_utils/countryUtil'
import _ from 'lodash'
import moment from 'moment'
import { getUniversityFoDealCloseFxn } from '../../closeDeal/actions/closeDeal'
import { getStudentDetailsFxn, paymentRecordFxn } from '../actions'
import OneTimePayment from '../view/oneTimePaymentModal'
import InstallmentPaymentModal from '../view/installmentPaymentModal'
import UpdateTuitionFeesModal from './updateTuitionFeesModal'
import PaymentModal from './paymentModal'
import FlairUniversity from '../../evaluateProfile/fcmt/flairUniversity'
import { getStudent, searchedStudent, searchedStudentFcmtFxn } from '../../student/actions/student'
import debounce from 'lodash/debounce'

const { Option } = Select

class StudentAccount extends Component {

  events = {
    tableReload: () => {
      if (this.tableRef && this.tableRef.current) {
        this.tableRef.current.reload()
      }
    },
    tableReload2: () => {
      if (this.tableRef2 && this.tableRef2.current) {
        this.tableRef2.current.reload()
      }
    },
    chooseCountry: (name) => {
      let countryName = _.find(this.state.allCountries, (item) => {
        return item.name == name
      })
      if (countryName) {
        this.setState({
          countryName: countryName.name ? countryName.name : '',
          stateName: undefined,
          cityName: undefined,
          pincode: undefined,
          allStates: countryName.id ? LoadState(countryName.id) : []
        })
      }
    },
    chooseState: (name) => {
      let stateName = _.find(this.state.allStates, (item) => {
        return item.name == name
      })
      if (stateName) {
        this.setState({
          stateName: stateName.name ? stateName.name : '',
          cityName: undefined,
          pincode: undefined,
          allCities: stateName.id ? LoadCity(stateName.id) : []
        })
      }
    },
    chooseCity: (name) => {
      this.setState({
        cityName: name ? name : ''
      })
    },
    showDealClose: data => {
      let { universityId } = this.state
      let findDoc = _.find(data.dealClose, (item) => {
        return item.universityId && item.universityId._id == universityId
      })
      if (findDoc) {
        notification.warning({ message: 'Deal already closed.' })
      } else {
        this.setState({
          dealCloseState: {
            visible: true,
            agentId: data._id,
            dealClose: data.dealClose
          }
        })
      }
    },
    showRequestConsentDeal: data => {
      this.setState({
        requestState: {
          visible: true,
          agentId: data._id,
          dealClose: data.dealClose
        }
      })
    },
    showDeal: (uniId) => {
      let { universityId } = this.state
      return uniId == universityId
    },
    hideCloseDealUpdate: () => {
      this.setState({
        dealUpdateState: {
          visible: false,
          agentId: '',
          dealClose: []
        }
      })
    },
    showAddFcmtDrawer: () => {
      this.setState({
        visibleAddFcmtStudent: true
      })
    },
    hideAddFcmtDrawer: () => {
      this.setState({
        visibleAddFcmtStudent: false
      })
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      viewInfoDrawer: false,
      selectedRow: {},
      queryData: {},
      visibleUserRightDrawer: false,
      approvedAgentRightsUser: [],
      zeroRecordAgent: false,
      filters: {},
      total: 0,
      countryName: '',
      stateName: '',
      cityName: '',
      pincode: '',
      allCountries: LoadAllCountry(),
      allStates: [],
      allCities: [],
      keyMoment: moment(),
      countryList: [],
      armUserData: [],
      marketingState: {
        visible: false,
        agentId: '',
        agentMarketing: {},
        email: {}
      },
      universityId: FcmtCollegeOnshore,
      dealCloseState: {
        visible: false,
        agentId: '',
        dealClose: []
      },
      dealUpdateState: {
        visible: false,
        agentId: '',
        dealClose: []
      },
      requestState: {
        visible: false,
        agentId: '',
        dealClose: []
      },
      universityFoDealClose: [],
      assignUniversity: [{
        universityName: 'FCMT University',
        university: FcmtCollegeOnshore
      }],
      visibleFeesDrawer: false,
      studentDetails: null,
      directUniversityPayment: null,
      oneTimePaymentModal: {
        visible: false,
        studentId: '',
        amount: 0
      },
      installmentPaymentModal: {
        visible: false,
        studentId: '',
        amount: 0
      },
      tuitionFeesModal: {
        visible: false,
        studentId: '',
        amount: 0
      },
      paymentModal: {
        visible: false,
        studentId: '',
        studentIdNo: '',
        invoiceReference: '',
        amount: 0
      },
      installments: [],
      activity: [],
      studentId: undefined,
      studentIdNo: '',
      visibleAddFcmtStudent: false,
      studentList: []
    }
    this.tableRef = React.createRef()
    this.tableRef2 = React.createRef()
  }

  componentDidMount() {
    this.getParamsForApplicationList()
    this.setFieldByParams()
    // this.apiRequest()
    this.getUniversityFoDealClose()
    this.getPaymentRecordAndActivity()
  }

  getUniversityFoDealClose = async () => {
    let { data } = await this.props.dispatch(getUniversityFoDealCloseFxn({}))
    this.setState({ universityFoDealClose: data })
  }

  showDeals = (universityId) => {
    let findDoc = _.find(this.state.universityFoDealClose, (item) => {
      return item.universityId && item.universityId._id == universityId
    })
    if (findDoc) {
      return true
    } else {
      return false
    }
  }

  ShowOneTimePaymentModal = (studentId, amount) => {
    this.setState({
      oneTimePaymentModal: {
        visible: true,
        studentId: studentId,
        amount: amount
      }
    })
  }
  hideOneTimePaymentModal = () => {
    this.setState({
      oneTimePaymentModal: {
        visible: false,
        studentId: '',
        amount: 0
      }
    })
    this.apiRequest()
  }

  ShowInstallmentPaymentModal = (studentId, amount) => {
    this.setState({
      installmentPaymentModal: {
        visible: true,
        studentId: studentId,
        amount: amount
      }
    })
  }

  hideInstallmentPaymentModal = (studentId) => {
    this.setState({
      installmentPaymentModal: {
        visible: false,
        studentId: studentId,
        amount: 0
      }
    })
    this.apiRequest()
  }

  showTuitionFeesModal = (studentId, amount) => {
    this.setState({
      tuitionFeesModal: {
        visible: true,
        studentId: studentId,
        amount: amount
      }
    })
  }

  hideTuitionFeesModal = () => {
    this.setState({
      tuitionFeesModal: {
        visible: false,
        studentId: '',
        amount: 0
      }
    })
    this.apiRequest()
  }

  showPaymentModal = (studentId, amount, invoiceReference, studentIdNo) => {
    this.setState({
      paymentModal: {
        visible: true,
        studentId: studentId,
        invoiceReference: invoiceReference,
        studentIdNo: studentIdNo,
        amount: amount
      }
    })
  }

  hidePaymentModal = () => {
    this.setState({
      paymentModal: {
        visible: false,
        studentId: '',
        invoiceReference: '',
        amount: 0
      }
    })
    this.apiRequest()
  }

  apiRequest = (params = {}) => {
    return new Promise(async (resolve) => {
      let { dispatch } = this.props
      params.sortField = '_id'
      params.sortOrder = 'descend'
      let resp1 = await this.getParamsForApplicationList()
      if (resp1.studentIdNo) {
        params.studentIdNo = resp1.studentIdNo
      }
      /* else {
         notification.warning({
           message: 'Please Enter Student Id No'
         })
         return
       }*/
      if (resp1.universityId) {
        params.universityId = resp1.universityId ? resp1.universityId : FcmtCollegeOnshore
      }

      if (resp1.studentId) {
        params.studentId = resp1.studentId
      }


      let finalFilter = {
        ...params,
        regExFilters: ['studentIdNo']
      }
      this.setState({ filters: finalFilter })
      if (finalFilter.studentIdNo || finalFilter.studentId) {
        let data = await dispatch(getStudentDetailsFxn(finalFilter))
        if (data.success) {
          this.setState({
            studentDetails: data.data,
            directUniversityPayment: data.directUniversityPayment,
            studentId: data.data._id ? data.data._id : ''
          })
          setTimeout(() => {
            this.getPaymentRecordAndActivity(data.data._id)
          }, 100)
        } else {
          this.setState({ studentDetails: null })
        }
      }
    })
  }

  getParamsForApplicationList = () => {
    return new Promise((resolve) => {
      let searchParams = new URLSearchParams(window.location.search)
      let studentIdNo = searchParams.get('studentIdNo')
      let universityId = searchParams.get('universityId') ? searchParams.get('universityId') : FcmtCollegeOnshore
      let studentId = searchParams.get('studentId')

      let obj = {}
      if (studentIdNo) {
        obj.studentIdNo = studentIdNo
      }
      if (universityId) {
        obj.universityId = universityId
      }
      if (studentId) {
        obj.studentId = studentId
      }
      resolve(obj)
    })
  }

  setFieldByParams = async () => {
    let resp = await this.getParamsForApplicationList()
    let obj = {}
    if (resp.studentIdNo) {
      obj.studentIdNo = resp.studentIdNo
    }
    if (resp.universityId) {
      obj.universityId = resp.universityId
    } else {
      obj.universityId = FcmtCollegeOnshore
    }
    this.setState({ ...obj })
  }

  searchFxn = async () => {
    let { dispatch } = this.props
    let obj = {}
    if (this.state.studentIdNo) {
      obj.studentIdNo = this.state.studentIdNo
    }
    if (this.state.universityId) {
      obj.universityId = this.state.universityId
    }
    if (this.state.studentId) {
      obj.studentId = this.state.studentId
    }
    dispatch(
      getUrlPushWrapper('studentAccount', {
        ...obj
      }))
    this.setFieldByParams()
    setTimeout(() => {
      this.apiRequest()
      if (this.tableRef && this.tableRef.current) {
        this.tableRef.current.reload()
      }
    }, 200)
  }

  clearFxn = async () => {
    let { dispatch } = this.props
    let obj = {}
    this.setState({
      universityId: '',
      studentIdNo: '',
      studentId: undefined
    })
    dispatch(
      getUrlPushWrapper('studentAccount', {
        ...obj
      }))
    this.setFieldByParams()
    setTimeout(() => {
      this.setState({ studentDetails: null })
      this.tableRef.current.reload()
    }, 200)
    this.setState({
      installments: [],
      activity: []
    })
  }

  getPaymentRecordAndActivity = async (studentId) => {
    if (studentId) {
      let obj = {
        studentId
      }
      let data = await this.props.dispatch(paymentRecordFxn(obj))
      if (data.success) {
        this.setState({
          installments: data.data.installments,
          paymentRecord: data.data,
          activity: data.data.activity
        })
        this.events.tableReload()
      }
    } else {
      this.setState({ installments: [] })
    }
  }

  render() {
    let { visibleAddFcmtStudent } = this.state
    let { currentUser } = this.props
    const activityColumns = [
      {
        title: 'Date',
        key: 'date',
        dataIndex: 'date',
        render: (item, record) => {
          return (
            <>
              {item && moment(item).format('MMM. DD, YYYY').toLowerCase()}
            </>
          )
        }
      },
      {
        title: 'Type',
        key: 'title',
        dataIndex: 'title'
      },
      {
        title: 'Reference',
        key: 'reference',
        dataIndex: 'reference'
      },
      {
        title: 'Description',
        key: 'details',
        dataIndex: 'details'
      },
      {
        title: 'Amount',
        key: 'amount',
        dataIndex: 'amount',
        render: (item, record) => {
          return (
            <>
              <ColumnWidth width={150}>
                <span
                  style={{
                    color: record.status && record.status === 'succeeded' ?
                      'green' : record.status && record.status === 'Failed' ?
                        'red' : 'grey',
                    border: '1px solid black',
                    padding: '4px 8px',
                    display: 'inline-block',
                    borderRadius: '4px'
                  }}
                >
                  ${item}
                </span>
              </ColumnWidth>
            </>
          )
        }
      }
    ]

    const columns = [
      {
        title: 'Installment No.',
        key: 'installmentNo',
        dataIndex: 'installmentNo',
        width: 150,
        render: (item, record) => {
          return (
            <>
              {item}
            </>
          )
        }
      },
      {
        title: 'Payment Method',
        key: 'paymentMethod',
        dataIndex: 'paymentMethod',
        width: 100,
        render: (item) => {
          return (
            <div className={'textCap'}>
              {item}
            </div>
          )
        }
      },
      {
        title: 'Due Date',
        key: 'dueDate',
        dataIndex: 'dueDate',
        render: (item, record) => {
          return (
            <>
              {item && moment(item).format('DD/MM/YYYY')}
            </>
          )
        }
      },
      {
        title: 'Amount',
        key: 'amount',
        dataIndex: 'amount',
        render: (item, record) => {
          return (
            <ColumnWidth width={150}>
              ${item}
            </ColumnWidth>
          )
        }
      },
      {
        title: 'Description',
        key: 'invoiceReference',
        dataIndex: 'invoiceReference'
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        render: (item, record) => {
          return (
            <ColumnWidth width={150}>
                <span
                  style={{
                    color: item === 'succeeded' ? 'green' : item === 'Failed' ? 'red' : 'grey',
                    border: '1px solid black',
                    padding: '4px 8px',
                    display: 'inline-block',
                    borderRadius: '4px'
                  }}
                >
                  {item && item == 'succeeded' ? 'Succeeded' : item}
                </span>
            </ColumnWidth>
          )
        }
      },
      {
        title: 'Details',
        key: 'receiptNo',
        dataIndex: 'receiptNo',
        render: (item, record) => {
          return (
            <ColumnWidth width={150}>
              {(record.status == 'succeeded') &&
              <>
                Payment Method
                - {record.paymentMethod === 'online' ? 'Stripe' : `Direct (${record.details && record.details})`} <br />
                Receipt No - {' '}
                {record.paymentMethod === 'direct' ? (
                  <a
                    className='btn btn-link btn-sm'
                    href={record.receipt && record.receipt.path && record.receipt.path}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {record.receiptReference && record.receiptReference}
                  </a>
                ) : (
                  record.receiptReference && record.receiptReference
                )}
              </>
              }
            </ColumnWidth>
          )
        }
      },
      {
        title: 'Actions',
        render: (item, record) => {
          return (
            <>
              {(record.status == 'Pending' || record.status == 'Failed') &&
              <Button
                onClick={() => {
                  this.showPaymentModal(this.state.studentDetails._id, record.amount, record.invoiceReference, this.state.studentIdNo)
                }}
                className={'btn btn-success-green sm'}>
                Pay
              </Button>
              }
            </>
          )
        }
      }
    ]

    const fetchStudent = async (value) => {
      let studentList = await searchedStudentFcmtFxn({ name: value })
      this.setState({
        studentList
      })
    }

    const chooseStudentFxn = async (id) => {
      if (id) {
        let { dispatch } = this.props
        let data = await dispatch(getStudent(id))
        if (data) {
          setTimeout(() => {
            this.setState({
              studentId: data._id
            })
          }, 500)
        }
      } else {
        this.setState({
          studentId: ''
        })
      }
    }

    const filterBlock = (
      <Row gutter={16} className={'mt10 mb10'}>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select name='Search By University'
                    onChange={(e) => {
                      this.setState({ universityId: e })
                    }}
                    filterOption={filterOption}
                    className={'antSelect'}
                    placeholder='University'
                    allowClear={true}
                    showSearch={true}
                    value={this.state.universityId || undefined}>
              {this.state.assignUniversity ? this.state.assignUniversity.map((item, key) => {
                return (
                  <Option value={item.university} key={key}>{item.universityName}</Option>
                )
              }) : null}
            </Select>
          </div>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='search-box-table round ml0'>
            <input className='form-control form-control' type='search'
                   placeholder='Student Id No' value={this.state.studentIdNo} onChange={(e) => {
              this.setState({ studentIdNo: e.target.value })
            }} />
            <img src='/dist/img/search.png' alt='' />
          </div>
        </Col>
        {/*<Col md={6} sm={6} xs={6} lg={6}>
          <Select
            showSearch={true}
            onSearch={debounce(fetchStudent, 500)}
            filterOption={false}
            autoClearSearchValue={true}
            allowClear={true}
            className={'antSelect'}
            style={{ width: '100%' }}
            showArrow={true}
            value={this.state.studentId}
            onChange={chooseStudentFxn}
            optionLabelProp='label'
            placeholder='Search Student'>
            {this.state.studentList && this.state.studentList.length && this.state.studentList.map((item, index) => {
              return (
                <Option key={item._id} value={item._id} label={item.name}>
                  <div>
                    <div>
                      {item.studentId} : {item.name} {item.dateOfBirth ? ` (${displayDate(item.dateOfBirth)})` : ''}
                    </div>
                    <div>
                      {item.email}
                    </div>
                  </div>
                </Option>
              )
            })}
          </Select>
        </Col>*/}
        <Col md={8} sm={8} xs={12} lg={4}>
          <Button onClick={() => this.searchFxn()} className={'roundBtn'}>Search</Button>
          <Button onClick={() => this.clearFxn()} className={'roundBtn'}>
            Clear
          </Button>
        </Col>
      </Row>
    )
    return (
      <div className='row  mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>Student Account</h5>
              <div className='search-box-table'>
              </div>
              <div className='sort-box-table mark-btn'>
                <button className='btn' onClick={this.events.showAddFcmtDrawer}>
                  <img src={'../dist/icons/plus.png'} className={'plus'} />
                  Add Student
                </button>
              </div>
            </div>
            <div className='card-body table-responsive'>
              {filterBlock}
              {
                this.state.studentDetails &&
                <>
                  <Row>
                    <Col span={24}>
                      <Card className='card-slot'>
                        <div className='border-bottom row'>
                          <h6
                            className={'col-6'}>{this.state.studentDetails && this.state.studentDetails.name && this.state.studentDetails.name}</h6>
                          <h6 className={'col-6'}>{
                            this.state.studentDetails &&
                            this.state.studentDetails.applications &&
                            this.state.studentDetails.applications &&
                            this.state.studentDetails.applications.courseName &&
                            this.state.studentDetails.applications.courseName
                          }</h6>
                        </div>
                        <Row gutter={16}>
                          <Col span={12}>

                            <div className={'mt10'}>
                              <b>App-ID: </b>{this.state.studentDetails && this.state.studentDetails.applications && this.state.studentDetails.applications.id}
                            </div>
                            <div>
                              <b>Mobile: </b>{this.state.studentDetails && this.state.studentDetails.mobile && this.state.studentDetails.mobile}
                            </div>
                            <div>
                              <b>University: </b> FCMT
                            </div>
                          </Col>
                          <Col span={12}>

                            <div className={'mt10'}>
                              <b>Student-Id: </b>
                              {
                                this.state.studentDetails &&
                                this.state.studentDetails.applications &&
                                this.state.studentDetails.applications &&
                                this.state.studentDetails.applications.studentIdNo &&
                                this.state.studentDetails.applications.studentIdNo
                              }
                            </div>
                            <div>
                              <b>Intake: </b>{
                              this.state.studentDetails &&
                              this.state.studentDetails.applications &&
                              this.state.studentDetails.applications.intake &&
                              this.state.studentDetails.applications.intake.month &&
                              this.state.studentDetails.applications.intake.month
                            }/{
                              this.state.studentDetails &&
                              this.state.studentDetails.applications &&
                              this.state.studentDetails.applications.intake &&
                              this.state.studentDetails.applications.intake.year &&
                              this.state.studentDetails.applications.intake.year
                            }
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col span={24}>
                      <Card className='card-slot'>
                        <div className='border-bottom row'>
                          <h6 className={'col-6'}>Account</h6>
                          <h6 className={'col-6'}>Payment Plan</h6>
                        </div>

                        <div className='row mt10'>
                          <div className='col-2 d-flex'>
                            Account Balance:
                          </div>
                          <div className='col-4 d-flex'>
                            <button className={'btn btn-xs btn-success'}> ${
                              this.state.directUniversityPayment &&
                              this.state.directUniversityPayment.tuitionFees &&
                              this.state.directUniversityPayment.tuitionFees
                            }
                            </button>
                            {[...adminRightUser, 'canadaOnshoreAdmin'].includes(currentUser.userType) ? <>
                              {this.state.installments && this.state.installments.length === 0 &&
                              <Icon
                                onClick={() => {
                                  this.showTuitionFeesModal(
                                    this.state.studentDetails._id,
                                    this.state.directUniversityPayment.tuitionFees)
                                }}
                                className={'ml10'}
                                type={'edit'}
                              />
                              }
                            </> : null}

                          </div>
                          <div className='col-2 d-flex'>
                            PP Balance:
                          </div>
                          <div className={'col-2'}>
                            <button className={'btn btn-xs btn-success'}>
                              ${
                              this.state.directUniversityPayment &&
                              this.state.directUniversityPayment.balance &&
                              this.state.directUniversityPayment.balance
                            }
                            </button>
                          </div>
                        </div>
                        <div className='row mt5'>
                          <div className='col-2 d-flex'>
                            Total Due:
                          </div>
                          <div className={'col-4'}>
                            <button className={'btn btn-xs btn-success-green'}>
                              ${
                              this.state.directUniversityPayment &&
                              this.state.directUniversityPayment.balance &&
                              this.state.directUniversityPayment.balance}
                            </button>
                          </div>
                          {this.state.installments && this.state.installments.length === 0 ?
                            <div className='col-2 d-flex'>
                              Choose Payment Type:
                            </div> : <div className='col-2 d-flex'>
                              Payment Type:
                            </div>
                          }

                          {
                            this.state.installments && this.state.installments.length === 0 ?
                              <div className={'col-3 d-flex flex-wrap'}>
                                <Button
                                  className={'btn btn-default xs round '}
                                  onClick={() => {
                                    this.ShowOneTimePaymentModal(this.state.studentDetails._id, this.state.directUniversityPayment.tuitionFees)
                                  }}
                                >
                                  One Time
                                </Button>
                                <Button
                                  className={'btn btn-default xs round '}
                                  onClick={() =>
                                    this.ShowInstallmentPaymentModal(this.state.studentDetails._id, this.state.directUniversityPayment.tuitionFees)
                                  }
                                >
                                  Pay In Installments
                                </Button>
                              </div> :
                              <div
                                className='d-inline-flex flex-wrap align-items-center text-success bg-light px-2 rounded border border-success w-auto'>
                                <i className='bi bi-check-circle-fill me-1' />
                                {this.state.paymentRecord.paymentType && this.state.paymentRecord.paymentType === 'Installment' && `${this.state.installments.length} `}{this.state.paymentRecord.paymentType && this.state.paymentRecord.paymentType}
                              </div>
                          }
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </>
              }
            </div>
          </div>
          <div className='card'>
            <h5>Payments</h5>
            <TableComp
              columns={columns}
              ref={this.tableRef}
              dataSource={this.state.installments}
            />
          </div>
          <div className='card'>
            <h5>Activity</h5>
            <TableComp columns={activityColumns}
                       ref={this.tableRef2}
                       dataSource={this.state.activity}
            />
          </div>
        </div>
        {this.state.oneTimePaymentModal.visible ?
          <OneTimePayment
            visible={this.state.oneTimePaymentModal.visible}
            record={this.state.oneTimePaymentModal}
            onClose={() => {
              this.hideOneTimePaymentModal()
            }}
          />
          : null
        }
        {this.state.installmentPaymentModal.visible ?
          <InstallmentPaymentModal
            visible={this.state.installmentPaymentModal.visible}
            record={this.state.installmentPaymentModal}
            onClose={() => {
              this.hideInstallmentPaymentModal()
            }}
          />
          : null
        }

        {this.state.tuitionFeesModal.visible ?
          <UpdateTuitionFeesModal
            visible={this.state.tuitionFeesModal.visible}
            record={this.state.tuitionFeesModal}
            onClose={() => {
              this.hideTuitionFeesModal()
            }}
          />
          : null
        }

        {this.state.paymentModal.visible ?
          <PaymentModal
            visible={this.state.paymentModal.visible}
            record={{ ...this.state.paymentModal, name: this.state.studentDetails.name }}
            onClose={() => {
              this.hidePaymentModal()
            }}
          />
          : null
        }

        {visibleAddFcmtStudent ? (
          <FlairUniversity
            visible={visibleAddFcmtStudent}
            selectedUniversity={FcmtCollegeOnshore}
            onClose={this.events.hideAddFcmtDrawer}
            {...this.props}
          />
        ) : null}

      </div>
    )
  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentAccount)
