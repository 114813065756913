import { customAxios as axios, getToken } from '../../../request'
import {
  addInstallmentsUrl, directPaymentInstallmentUrl,
  getPaymentRecordUrl,
  getStudentAccountDetailsUrl, payFcmtInstallmentUrl, updateFcmtInstallmentsUrl,
  updateTuitionFeesUrl
} from '../apis/index'
import { notification } from 'antd'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'

export const getStudentDetailsFxn = (filters) => async dispatch => {
  let config = {
    params: { ...filters },
    ...getToken()
  }
  dispatch(showPageLoad())
  let { data } = await axios.get(getStudentAccountDetailsUrl(), config)
  dispatch(hidePageLoad())
  if (data && data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const updateTuitionFeesFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateTuitionFeesUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const addStudentInstallmentFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addInstallmentsUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const updateFcmtInstallmentsFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateFcmtInstallmentsUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const paymentRecordFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...await getToken()
  }
  let { data } = await axios.get(getPaymentRecordUrl(), config)
  dispatch(hidePageLoad())
  return data
}

export const payFcmtInstallmentFxn = async (valData) => {
  let { data } = await axios.post(payFcmtInstallmentUrl(), valData, getToken())
  return data
}

export const directPaymentInstallmentfxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(directPaymentInstallmentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
