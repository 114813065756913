import React, { useState, useEffect } from 'react'
import { Form, Button, Card, notification, Spin, Row, Col, Drawer } from 'antd'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { listAllCountries } from '../../countries/actions/countries'
import { listQuickSearchUniversities } from '../../university/actions/university'
import { listAllCourse, loadCourseIntakesFxn } from '../../course/actions/courseAction'
import { addSelectedCourse } from '../../applications/actions/chooseStudent'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'
import { listCourseCampus } from '../../campus/actions/campus'
import { fcmtUniversity, DirectUniversityCheck } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { EligibilityIcon } from '../../university/drawers/universityEligibility'
import { addPropertyManagementGrpFxn } from '../../../unizHome/actions'

const AddPropertyManagementGroupDrawer = (props) => {
  const dispatch = useDispatch()
  const {
    form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields },
    onClose,
    onRefresh,
    visible

  } = props


  let inputTypes = {
    fields: [
      {
        key: 'property_group_name',
        label: 'Property management group',
        required: true,
        placeholder: 'Enter Property management group'
      }
    ]
  }


  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const handleSubmit = (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        dispatch(showPageLoad())
        let data = await dispatch(addPropertyManagementGrpFxn(valData))
        if (data.success) {
          onClose()
          onRefresh()
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  return (
    <React.Fragment>
      <Drawer visible={visible}
              title={`Property Management Groups`}
              width={'50%'}
              closable={true}
              maskClosable={true}
              onClose={onClose}>
        <div className='card unizportal'>
          <div className='inner-form'>
            <Form onSubmit={handleSubmit} style={{ marginTop: 8, textAlign: 'left' }}>
              <Row gutter={24}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <Col span={item.span ? item.span : 24} md={item.span ? item.span : 24} sm={24} xs={24} key={key}>
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout} />
                    </Col>
                  )
                })}
              </Row>

              <Form.Item>
                <button className='btn btn-default' type='submit'>Submit
                </button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  )
}


export default Form.create()(AddPropertyManagementGroupDrawer)
