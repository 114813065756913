import UpdateXTravelWorldMargin from '../containers/xTravelWorld/view/updateXTravelWorldMargin'
import UpdateXTravelExchangeMargin from '../containers/xTravelWorld/view/updateXTravelExchangeMargin'
import { adminRightUser } from '../components/_utils/appUtils'
import XTravelTransfer from '../containers/xTravelTransfer/view'
import XTravelTransactionList from '../containers/xTravelTransfer/view/xTravelTransferList'
import XTravelFundTransfer from '../containers/xTravelWorld/view/xTravelFundTransfer'
import XTravelFundTransferList from '../containers/xTravelWorld/view/xTravelFundTransferRequestList'
import AirlineList from '../containers/xTravelWorld/list/airlineList'
import AirportList from '../containers/xTravelWorld/list/airportList'

let menu = [
  {
    path: '/update-xtravelworld-margin',
    title: 'Update XTravelWorld Margin',
    name: 'Update XTravelWorld Margin',
    key: 'updateXTravelWorldMargin',
    dontShowOnMenu: true,
    component: UpdateXTravelWorldMargin, // for agent by admin
    authority: [...adminRightUser]
  },
  {
    path: '/update-xtravelworld-exchange-margin',
    title: 'Update XTravelWorld Exchange Margin',
    name: 'Update XTravelWorld Exchange Margin',
    key: 'updateXTravelWorldExchangeMargin',
    dontShowOnMenu: true,
    component: UpdateXTravelExchangeMargin, // for admin
    authority: [...adminRightUser]
  },
  {
    path: '/add-transfer-x-travel',
    name: 'Add X Travel Transfer',
    title: 'Add X Travel Transfer',
    icon: 'home',
    key: 'add-x-travel-transfer',
    newLink: true,
    component: XTravelTransfer,
    authority: ['admin']
  },
  {
    path: '/x-travel-transfer-list',
    name: 'X Travel Transfer List',
    title: 'X Travel Transfer List',
    icon: 'home',
    key: 'x-travel-transfer-list',
    newLink: true,
    component: XTravelTransactionList,
    authority: ['admin']
  },
  {
    path: '/x-travel-add-fund-request',
    name: 'Add X Travel Fund Request',
    title: 'Add X Travel Fund Request',
    key: 'xTravelAddFundTransfer',
    newLink: true,
    restrict: true,
    component: XTravelFundTransfer,
    dontShowOnMenu: true,
    authority: ['admin', 'assistantMarketingManager']
  },
  {
    path: '/x-travel-fund-request-list',
    name: 'X Travel Fund Request List',
    title: 'X Travel Fund Request List',
    key: 'xTravelFundRequestList',
    newLink: true,
    restrict: true,
    component: XTravelFundTransferList,
    authority: ['admin', 'branchManager', 'branchUser', 'assistantMarketingManager']
  },

  {
    path: '/x-travel-world',
    name: 'X Travel World',
    icon: 'user',
    title: 'X Travel World',
    key: 'xTravelWorld',
    newLink: true,
    authority: [...adminRightUser, 'userManager'],
    children: [
      {
        path: '/x-travel-world/airline-list',
        name: 'X Travel Airline List',
        title: 'X Travel Airline List',
        key: 'xTravelAirlineList',
        component: AirlineList,
        authority: [...adminRightUser, 'userManager']
      },
      {
        path: '/x-travel-world/airport-list',
        name: 'X Travel Airport List',
        title: 'X Travel Airport List',
        key: 'xTravelAirportList',
        component: AirportList,
        authority: [...adminRightUser, 'userManager']
      }
    ]
  }


]

export default menu
