import React, { useEffect, useState, useRef } from 'react'
import {
  Col,
  Form,
  notification,
  Row,
  Tabs,
  Icon,
  Table,
  Tooltip,
  Popconfirm, Select,
  Button, Input
} from 'antd'
import { DownloadLink, InputBox } from '../../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import {
  updateUnizhomePropertyFxn,
  deleteUnizhomeFaqFxn,
  deleteUnizhomePolicyFxn,
  deleteUnizhomeRoomFxn,
  deleteUnizhomeActiveChildRoomFxn,
  updateRoomActiveStatusFxn,
  getAmenitiesFxn,
  getMediaFxn,
  getRoomsFxn,
  updateFeaturedImageFxn, deleteMediaFxn, distinctUniversityListFxn, deleteUniversityFromPropertyFxn, getPropertyGrpFxn
} from '../action'
import { FileInput } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { CountryCodeJson as countryCodes } from '../../../components/_utils/countryUtil'
import EditPropertyFaqModal from '../modal/editPropertyFaq'
import AddPropertyAmenities from '../../unizHomeEnquiry/view/addPropertyAmenities'
import EditPropertyImage from '../modal/editPropertyImage'
import AddPropertyImage from '../modal/addPropertyImage'
import AddPropertyFaqModal from '../modal/addPropertyFaq'
import EditPaymentPolicyModal from '../modal/editPaymentPolicy'
import '../utils/style.css'
import EditPropertyRoom from '../components/editRoom'
import AddPropertyRoom from '../modal/addPropertyRoom'
import { unizhomeSinglePropertyFxn } from '../action'
import renderHTML from 'react-render-html'
import S from 'string'
import _ from 'lodash'
import { DurationTypes, currencyList } from '../utils/propertyUtils'
import AddPaymentPolicyModal from '../modal/addPaymentPolicy'
import EditRoomDuration from '../modal/editRoomDuration'
import AddPropertySubRoom from '../modal/addPropertySubRoom'
import AmenitiesComponent from '../components/amenitiesComponent'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { requestForCallFxn } from '../../gicWithdrawal/actions'
import DistanceComponent from '../components/distanceComponent'
import { addPropertyManagementGrpFxn } from '../../../unizHome/actions'
import ApplyCourse from '../../student/views/applyForApplicationDrawer'
import AddPropertyManagementGroupDrawer from '../drawer/addPropertyManagementGroupDrawer'

const { TabPane } = Tabs
const CustomCard = (props) => {
  let { title, children, extra } = props
  return (
    <>
      <div className='card p0'>
        <div className='table-head d-flex align-items-center all-student-search p0'>
          <h5 className={'mb10 ml-2'}>{title}</h5>
          <div className='search-box-table round'>
          </div>
          <div className='sort-box-table mark-btn'>
            <div className={'aic'}>
              {extra ? extra : null}
            </div>
          </div>
        </div>
        <div className='card-body table-responsive p0'>
          {children}
        </div>
      </div>
    </>
  )
}
const {Option} = Select
const RoomComponent = (props) => {
  let { meta, roomTypes, propertyId, dispatch, refreshData } = props
  const tableRef = useRef()
  let [groupedData, setGroupedData] = useState([])
  const [editRoomVisible, setEditRoomVisible] = useState({
    visible: false,
    room: {}
  })
  const [addRoomSubVisible, setAddSubRoomVisible] = useState({
    visible: false,
    roomId: ''
  })
  const [addRoomVisible, setAddRoomVisible] = useState({
    visible: false
  })
  const [durationState, setDurationState] = useState({
    visible: false,
    roomId: '',
    durationObj: {}
  })

  const [currentPage, setCurrentPage] = useState(1)
  const events = {
    handlePageChange: (page) => {
      setCurrentPage(page)
    },
    updateStatus: async (data) => {

      let resp = await dispatch(updateRoomActiveStatusFxn({ propertyId: data }))
      if (resp && resp.success) {
        refreshData()
      }
    },

    groupedDataFxn: () => {
      let groupedData = _.groupBy(roomTypes, 'meta.unit_type')
      let data = Object.entries(groupedData).map(([groupKey, groupedItems]) => ({
        roomList: groupedItems,
        roomName: groupKey
      }))
      setGroupedData(data)
    },

    deleteRoom: async (roomId) => {
      let resp = await dispatch(deleteUnizhomeRoomFxn({ propertyId: propertyId, roomId: roomId }))
      if (resp.success) {
        notification.success({ message: resp.message })
        refreshData()
      } else {
        notification.error({ message: resp.message })
      }
    },
    deleteActiveChildRoom: async (id, roomId) => {

      let resp = await dispatch(deleteUnizhomeActiveChildRoomFxn({ propertyId: propertyId, id: id, roomId: roomId }))

      if (resp.success) {
        notification.success({ message: resp.message })
        refreshData()
      } else {
        notification.error({ message: resp.message })
      }
    },
    showRoomModal: async (room) => {
      setEditRoomVisible({
        visible: true,
        room: room
      })
    },
    hideRoomModal: async () => {
      setEditRoomVisible({
        visible: false,
        room: {}
      })
      refreshData()
    },
    showAddSubRoomModal: async (room) => {
      setAddSubRoomVisible({
        visible: true,
        roomId: room._id
      })

    },
    hideAddSubRoomModal: async () => {
      setAddSubRoomVisible({
        visible: false,
        roomId: ''
      })
    },
    showAddRoomModal: async () => {
      setAddRoomVisible({
        visible: true
      })
    },
    hideAddRoomModal: async () => {
      setAddRoomVisible({
        visible: false
      })
    },
    showEditDuration: async (data, roomId) => {

      setDurationState({
        visible: true,
        roomId: roomId,
        durationObj: data
      })
    },
    hideEditDuration: async () => {
      setDurationState({
        visible: false,
        durationObj: {},
        roomId: ''
      })
    }
  }

  const formatDate = (date) => {
    if (!date) return ''
    const options = { month: 'short', day: '2-digit', year: 'numeric' }
    return new Intl.DateTimeFormat('en-US', options).format(new Date(date))
  }
  useEffect(() => {
    events.groupedDataFxn()
  }, [roomTypes])
  const activeRoomColumns = (roomId) => [
    {
      title: 'Duration',
      key: 'meta',
      dataIndex: 'meta',
      render: (item) => {
        return (
          <div>
            {item && item.lease_duration ? <div>{item.lease_duration}</div> : ''}
          </div>
        )
      }
    },
    {
      title: 'Move In',
      key: 'meta',
      dataIndex: 'meta',
      render: (item) => {
        return (
          <div>
            {item && item.available_from_formatted ? (
              <div>{formatDate(item.available_from_formatted)}</div>
            ) : (
              'Flexible'
            )}
          </div>
        )
      }
    },
    {
      title: 'Move Out',
      key: 'meta',
      dataIndex: 'meta',
      render: (item) => {
        return (
          <div>
            {item && item.available_to_formatted ? (
              <div>{formatDate(item.available_to_formatted)}</div>
            ) : (
              ''
            )}
          </div>
        )
      }
    },
    {
      title: 'Rent',
      dataIndex: 'weekly_price',
      key: 'weekly_price'
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <>
          <Tooltip title='Edit Duration'>
            <a className={'btn btn-default roundNew sm'}
               onClick={() => events.showEditDuration(record, roomId)}>
              Edit
            </a>
          </Tooltip>
          <Tooltip title='Delete Duration'>
            <Popconfirm
              title='Are you sure, you want to delete this data?'
              onConfirm={() => events.deleteActiveChildRoom(record._id, roomId)}>
              <Icon type={'close'}
                    style={{ color: 'red' }} />
            </Popconfirm>
          </Tooltip>
        </>
      )
    }
  ]

  return (
    <>
      <CustomCard title={'Choose Your Room'} extra={(
        <a className={'btn btn-default roundNew sm aic'}
           onClick={() => events.showAddRoomModal()}>+ Add Room</a>
      )}>
        {groupedData && groupedData.length ? <Tabs>
          {_.map(groupedData, (data) => {
            const unitType = data.roomName == 'undefined' ? 'unnamed' : `${data.roomName}`
            let tabName = S(unitType).humanize().s
            return (
              <TabPane tab={tabName} key={unitType}>
                <ul>
                  {data.roomList.map((room) => {
                    return (
                      <li key={room._id} className='property-item mt-3'>
                        <Row span={24}>
                          <Col span={4}>
                            <div className='property-info2'>
                              {room && room.inventory_featured_image_path ?
                                <div className='image-box-update' onClick={() => {
                                  window.open(room.inventory_featured_image_path, '_blank')
                                }}>
                                  <LazyLoadImage src={room.inventory_featured_image_path}
                                                 alt={room.name}
                                                 className='suggest-pro-img'
                                  />
                                </div> : null}
                            </div>
                          </Col>
                          <Col span={14}>
                            <div className='property-location ml-3'>
                              <h6>{room.name}</h6>
                              <Popconfirm title={'Are you sure, you want to update status?'} onConfirm={() => {
                                events.updateStatus(room._id)
                              }}>
                                <label
                                  className={`label  sm ${room.active ? 'label label-success-green' : 'label-danger'}`}>
                                  {room.active ? 'Active' : 'Inactive'}
                                </label>
                              </Popconfirm>

                              <p className='mt-2'>
                                {room && room.meta && room.meta.available_from_formatted ?
                                  <label className={'label label-success sm'}>
                                    Available From: {room.meta.available_from_formatted}
                                  </label> : null}
                                <div className={'mt10'}>
                                  Starting From:
                                  {room.pricing.deposit} / {room.pricing.duration}
                                </div>
                              </p>
                            </div>
                          </Col>
                          <Col span={6}>
                            <div className={'d-flex flex-column align-items-end'}>
                              <a className={'btn btn-default roundNew2 sm aic'}
                                 onClick={() => events.showAddSubRoomModal(room)}> <Icon type={'plus'} />
                                Add Room Duration </a>
                              <a className={'btn btn-default roundNew2 sm aic'}
                                 onClick={() => events.showRoomModal(room)}>
                                <Icon type={'edit'} />
                                Edit Room</a>
                              <Popconfirm title={'Are you sure, you want to delete this room?'}
                                          onConfirm={() => events.deleteRoom(room._id)}>
                                <a className={'btn btn-danger sm roundNew2 aic'}>
                                  <Icon type={'delete'} />
                                  Delete Room</a>
                              </Popconfirm>
                            </div>
                          </Col>
                          <Col span={24}>
                            <Table
                              dataSource={room.active_children}
                              columns={activeRoomColumns(room._id)}
                              roomId={room._id}
                              rowKey='_id'
                              bordered={true}
                              ref={tableRef}
                            />
                          </Col>
                        </Row>

                      </li>
                    )
                  })}
                </ul>
              </TabPane>
            )
          })}
        </Tabs> : null}
      </CustomCard>

      {editRoomVisible.visible ?
        <EditPropertyRoom
          {...editRoomVisible}
          propertyId={propertyId}
          onClose={() => events.hideRoomModal()}
          onSubmit={() => {
            events.hideRoomModal()
            refreshData()
          }}
        /> : null}
      {addRoomVisible.visible ? <AddPropertyRoom
        propertyId={propertyId}
        {...addRoomVisible}
        onClose={() => events.hideAddRoomModal()}
        onSubmit={() => {
          events.hideAddRoomModal()
          refreshData()
        }}
      /> : null}
      {addRoomSubVisible.visible ? <AddPropertySubRoom
        propertyId={propertyId}
        {...addRoomSubVisible}
        onClose={() => events.hideAddSubRoomModal()}
        onSubmit={() => {
          events.hideAddSubRoomModal()
          refreshData()
        }}
      /> : null}

      {durationState.visible && (
        <EditRoomDuration
          propertyId={propertyId}
          {...durationState}
          onClose={() => events.hideEditDuration()}
          onSubmit={() => {
            events.hideEditDuration()
            refreshData()
          }}
        />
      )}


    </>
  )
}

const PaymentPolicyComponent = (props) => {
  let { paymentPolicies, propertyId, refreshData, dispatch } = props
  const [openPolicy, setOpenPolicy] = useState({
    visible: false,
    policy: {}
  })

  const [openAddPolicy, setOpenAddPolicy] = useState({
    visible: false,
    policy: {}
  })
  const events = {
    deletePolicy: async (policyId) => {
      let resp = await dispatch(deleteUnizhomePolicyFxn({ propertyId: propertyId, policyId: policyId }))
      if (resp.success) {
        notification.success({ message: resp.message })
        refreshData()
      } else {
        notification.error({ message: resp.message })
      }
    },

    showPolicyModal: async (policy) => {
      setOpenPolicy({
        visible: true,
        policy: policy,
        policyType: policy.policy_applicable ? 'Policy' : 'Description'
      })
    },

    hidePolicyModal: async () => {
      setOpenPolicy({
        visible: false,
        policy: {},
        policyType: ''
      })
    },
    showAddPolicyModal: async (policy) => {
      setOpenAddPolicy({
        visible: true,
        policy: policy
        // policyType: policy.policy_applicable ? 'Policy' : 'Description'
      })
    },
    hideAddPolicyModal: async () => {
      setOpenAddPolicy({
        visible: false,
        policy: {},
        policyType: ''
      })
    }
  }
  const policyColumns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (val, record, index) => {
        return index + 1
      }
    },
    {
      title: 'Display Name',
      dataIndex: 'display_name',
      key: 'display_name'

    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'

    },
    {
      title: 'Description',
      dataIndex: 'value',
      key: 'value',
      render: (text) => {
        return (
          <>
            {renderHTML(text)}
          </>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (text, record) => (
        <>
          <Tooltip title='Edit Duration'>
            <a className={'btn btn-default roundNew sm'}
               onClick={() => events.showPolicyModal(record)}>
              Edit
            </a>
          </Tooltip>
         <Tooltip title='Delete Duration'>
            <Popconfirm
              title='Are you sure, you want to delete this record?'
              onConfirm={() => events.deletePolicy(record._id)}>
              <a className={'btn btn-danger roundNew sm ml5'}>
                Delete
              </a>

            </Popconfirm>
          </Tooltip>

        </>
      )
    }
  ]

  return (
    <>
      <CustomCard title={'Payment Policies'} extra={(
        <a className={'btn btn-default roundNew sm aic'}
           onClick={() => events.showAddPolicyModal()}>+ Add Payment Policy</a>
      )}>


        <Table
          dataSource={paymentPolicies.applicable}
          columns={policyColumns}
          rowKey='_id'
          bordered={true}
        />
      </CustomCard>
      {openAddPolicy.visible && (
        <AddPaymentPolicyModal
          propertyId={propertyId}
          {...openAddPolicy}
          onClose={() => events.hideAddPolicyModal()}
          onSubmit={() => {
            events.hideAddPolicyModal()
            refreshData()
          }}
        />
      )}
      <CustomCard title={'Descriptions'} className={'mt-2'}>
        <Table
          dataSource={paymentPolicies.notApplicable}
          columns={policyColumns}
          rowKey='_id'
          bordered={true}
        />
      </CustomCard>


      {openPolicy.visible && (
        <EditPaymentPolicyModal
          propertyId={propertyId}
          {...openPolicy}
          onClose={() => events.hidePolicyModal()}
          onSubmit={() => {
            events.hidePolicyModal()
            refreshData()
          }}
        />
      )}

    </>
  )
}
const FaqComponent = (props) => {
  let { faqs, propertyId, refreshData, dispatch } = props
  let [faqVisible, setFaqVisible] = useState(false)
  let [openFaq, setOpenFaq] = useState({ visible: false, faq: {} })
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (val, record, index) => {
        return index + 1
      }
    },
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
      width: 330
    },
    {
      title: 'Answer',
      dataIndex: 'answer',
      key: 'answer',
      render: (text) => <div dangerouslySetInnerHTML={{ __html: text }} />
    },
    {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (text, record) => (
        <>

          <Tooltip title='Edit Duration'>
            <a className={'btn btn-default roundNew sm'}
               onClick={() => events.showFaqModal(record)}>
              Edit
            </a>
          </Tooltip>
          <Tooltip title='Delete Duration'>
            <Popconfirm
              title={'Are you sure, you want to delete this query?'}
              onConfirm={() => events.deleteFaq(record._id)}>
              <a className={'btn btn-danger roundNew sm ml5'}>
                Delete
              </a>
            </Popconfirm>
          </Tooltip>

        </>

      )
    }
  ]

  const events = {
    showFaqModal: async (faq) => {
      setOpenFaq({
        visible: true,
        faq: faq
      })
    },
    hideFaqModal: async () => {
      setOpenFaq({
        visible: false,
        faq: {}
      })
    },
    showAddFaqModal: async () => {
      setFaqVisible(true)
    },
    hideAddFaqModal: async () => {
      setFaqVisible(false)
    },
    deleteFaq: async (faqId) => {
      let resp = await dispatch(deleteUnizhomeFaqFxn({ propertyId: propertyId, faqId: faqId }))
      if (resp.success) {
        notification.success({ message: resp.message })
        refreshData()
      } else {
        notification.error({ message: resp.message })
      }
    }
  }
  return (
    <>
      <CustomCard title={'FAQ'} extra={(
        <a className={'btn btn-default roundNew sm aic'}
           onClick={() => events.showAddFaqModal()}>+ Add Faq</a>
      )}>
        <>
          <Table
            dataSource={faqs}
            columns={columns}
            rowKey='_id'
            bordered={true}
          />
        </>
      </CustomCard>

      {faqVisible ?
        <AddPropertyFaqModal
          visible={faqVisible}
          propertyId={propertyId}
          onClose={() => events.hideAddFaqModal()}
          onSubmit={() => {
            events.hideAddFaqModal()
            refreshData()
          }}
        /> : null}
      {openFaq.visible ?
        <EditPropertyFaqModal
          {...openFaq}
          propertyId={propertyId}
          onClose={() => events.hideFaqModal()}
          onSubmit={() => {
            events.hideFaqModal()
            refreshData()
          }}
        /> : null
      }
    </>
  )
}
const MediaComponent = (props) => {
  let { images, videos, propertyId, onSubmit, dispatch, refreshData } = props

  let [imagesVisible, setImagesVisible] = useState({
    visible: false, imageData: {}
  })
  let [imagesAddVisible, setImagesAddVisible] = useState({
    visible: false, imageData: {}
  })
  let [loading, setLoading] = useState(false)
  const tableRef = useRef()
  const events = {
    showAddImagesModal: async (imageData) => {
      setImagesAddVisible({
        visible: true,
        imageData: imageData
      })
    },
    hideAddImagesModal: async () => {
      setImagesAddVisible({
        visible: false,
        imageData: {}
      })
      onSubmit()
    },
    showImagesModal: async (imageData) => {
      setImagesVisible({
        visible: true,
        imageData: imageData
      })
    },
    hideImagesModal: async () => {
      setImagesVisible({
        visible: false,
        imageData: {}
      })
      onSubmit()
    },
    deleteMedia: async (record) => {

      let resp = await dispatch(deleteMediaFxn({propertyId: propertyId, imageId: record}))
      if (resp && resp.success) {
        refreshData()
      }
    },
    updateFeaturedImage: async (_id, propertyId) => {
      let finalObj = {
        _id: _id,
        propertyId: propertyId
      }
      try {
        let data = await dispatch(updateFeaturedImageFxn(finalObj))

        if (data.success) {
          refreshData()
        }
      } catch (err) {
        console.log(err)
      }

    }
  }
  const imagesColumns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (val, record, index) => {
        return index + 1
      }
    },
    {
      title: 'Image',
      dataIndex: 'path',
      key: 'path',
      width: 150,
      render: (val, record) => {
        return (
          record.path ? <p onClick={() => {
            window.open(record.path, '_blank')
          }}>
            <LazyLoadImage src={record.path} height='35px' width='auto' />
          </p> : null
        )
      }
    },
    {
      title: 'Caption',
      dataIndex: 'caption',
      key: 'caption'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Featured',
      dataIndex: 'featured',
      key: 'featured',
      render: (val, record) => {
        return (
          <div>
            {
              record.featured ?
                <label className={'label label-success sm pointerCls'}>Featured</label> :
                <Popconfirm
                  title={`Are you sure, you want make this feature Image?`}
                  onConfirm={() => events.updateFeaturedImage(record._id, propertyId)}
                  okText='Yes'
                  cancelText='No'>
                  <label className={'label label-info sm pointerCls'}>Non Featured</label>
                </Popconfirm>
            }
          </div>
        )
      }
    },
      {
          title: 'Action',
          key: '_id',
          dataIndex: '_id',
          width: 150,
          render: (val, record) => {
            return (
              <React.Fragment>

                <Tooltip title='Edit Image'>
                  <a className={'btn btn-default roundNew sm'}
                     onClick={() => events.showImagesModal(record)}>
                    Edit
                  </a>
                </Tooltip>
                <Tooltip title='Delete Image'>
                  <Popconfirm
                    title={'Are you sure, you want to delete this Image?'}
                    onConfirm={() => events.deleteMedia(record._id)}>
                    <a className={'btn btn-danger roundNew sm ml5'}>
                      Delete
                    </a>
                  </Popconfirm>
                </Tooltip>
              </React.Fragment>


            )
          }
        }

  ]
  // const videosColumns = [
  //   {
  //     title: '#',
  //     dataIndex: 'index',
  //     key: 'index',
  //     render: (val, record, index) => {
  //       return index + 1
  //     }
  //   },
  //   {
  //     title: 'Video',
  //     dataIndex: 'thumbnail_url',
  //     key: 'thumbnail_url',
  //     width: 150,
  //     render: (val, record) => {
  //       return (
  //         <a>
  //           <video poster={record.thumbnail_url} height='80px' width='auto'>
  //             <source src={record.path} />
  //           </video>
  //         </a>
  //       )
  //     }
  //   },
  //   {
  //     title: 'Caption',
  //     dataIndex: 'caption',
  //     key: 'caption'
  //   },
  //   {
  //     title: 'Type',
  //     dataIndex: 'type',
  //     key: 'type'
  //   },
  //   {
  //     title: 'Upload Date',
  //     dataIndex: 'upload_date',
  //     key: 'upload_date'
  //   }
  //   /*   {
  //        title: 'Action',
  //        key: '_id',
  //        dataIndex: '_id',
  //        width: 150,
  //        render: (val, record) => {
  //          return (
  //            <React.Fragment>
  //              {/!*  <Tooltip title='Edit Image'>
  //                <a
  //                  className={'linkAA'}
  //                >
  //                  <Icon type={'edit'} />
  //                </a>
  //              </Tooltip>*!/}
  //            </React.Fragment>
  //          )
  //        }
  //      }*/
  //
  // ]
  return (
    <>
      <CustomCard title={'Images'} extra={(
        <a className={'btn btn-default roundNew sm aic'}
           onClick={() => events.showAddImagesModal()}>+ Add Image</a>
      )}>
        <Table
          dataSource={images}
          columns={imagesColumns}
          rowKey='_id'
          bordered={true}
          loading={loading}

        />
        {/*<TableComp columns={imagesColumns}*/}
        {/*           apiRequest={images}*/}
        {/*           ref={tableRef}*/}


        {/*/>*/}
      </CustomCard>
      <br />
      {/*<CustomCard title={'Videos'}>*/}
      {/*  <Table*/}
      {/*    dataSource={videos}*/}
      {/*    columns={videosColumns}*/}
      {/*    rowKey='_id'*/}
      {/*    bordered={true}*/}
      {/*  />*/}
      {/*</CustomCard>*/}
      {imagesVisible.visible && (
        <EditPropertyImage
          {...imagesVisible}
          propertyId={propertyId}
          refreshData={refreshData}
          onClose={() => events.hideImagesModal()}
        />
      )}

      {imagesAddVisible.visible && (
        <AddPropertyImage
          {...imagesAddVisible}
          propertyId={propertyId}
          refreshData={refreshData}
          onClose={() => events.hideAddImagesModal()}
        />
      )}
    </>
  )
}

const EditPropertyModal = (props) => {
  let dispatch = useDispatch()
  let tableRef = useRef()
  let history = useRef()
  let { form: { getFieldDecorator, setFieldsValue } } = props
  const [activeTab, setActiveTab] = useState('1')
  const [faqs, setFaqs] = useState([])
  const [features, setFeatures] = useState([])
  const [amenities, setAmenities] = useState([])
  let [universityData, setUniversityData] = useState([]);
  const [dynamicFields, setDynamicFields] = useState([{ place: '', distance: '' }]);
  console.log(setFieldsValue, "setFieldsValue")

  const [designatedCountry, setDesignatedCountry] = useState([])
  const [propertyGroupData, setPropertyGroupData] = useState('')
  const [paymentPolicies, setPaymentPolicies] = useState({ applicable: [], notApplicable: [] })
  const [roomTypes, setRoomTypes] = useState([])
  const [images, setImages] = useState([])
  const [propertyGrpList, setPropertyGrpList] = useState([])
  const [cityForUniversity, setCityForUniversity] = useState('')
  const [videos, setVideos] = useState([])
  const [meta, setMeta] = useState({})
  const [metaDistances, setMetaDistances] = useState({})
  const [openAmenities, setOpenAmenities] = useState({
    visible: false,
    amenities: {},
    propertyId: ''
  })
  const [visibleAddPropertyGrp, setVisibleAddPropertyGrp] = useState({
    visible: false,

  })
  const [activeRoomTab, setActiveRoomTab] = useState('')
  const searchParams = new URLSearchParams(window.location.search)
  const propertyId = searchParams.get('propertyId')
  const apiRequest = async () => {
    let resp = await dispatch(unizhomeSinglePropertyFxn({ propertyId: propertyId }))
    console.log(resp.property.property_group, "respresprespresp")
    if (resp) {
      const {
        faqs,
        descriptions: description,
        property
      } = resp
      let { location, pricing } = property
      setCityForUniversity(location && location.locality && location.locality.long_name)
      setFaqs(faqs)
      setMeta(resp.property.meta)
      setMetaDistances({

        distances: resp.property.meta.distances.filter(distance => distance.place != 'city center')
      });
      setPropertyGroupData(resp.property.property_group)
      if (description) {
        const applicablePolicies = description.filter(
          (policy) => policy.policy_applicable
        )
        const notApplicablePolicies = description.filter(
          (policy) => !policy.policy_applicable
        )

        setPaymentPolicies({
          applicable: applicablePolicies,
          notApplicable: notApplicablePolicies
        })
      }
      let currencyObj = events.getCurrency(location.country.long_name)

      setFieldsValue({
        name: property.name,
        country: location && location.country && location.country.long_name,
        countryShort: location && location.country && location.country.short_name,
        currencyName: currencyObj.code || '',
        currency: currencyObj.symbol || '',

        state: location && location.state && location.state.long_name,
        route: location && location.route && location.route.long_name,
        district: location && location.district && location.district.long_name,
        locality: location && location.locality && location.locality.long_name,
        postalCode: location && location.postal_code && location.postal_code.long_name,
        streetNumber: location && location.street_number && location.street_number.long_name,
        // ownerEmails: owner && owner.emails,
        // ownerPhones: owner && owner.phones,
        duration: pricing && pricing.duration,
        min_price: pricing && pricing.min_price,
        max_price: pricing && pricing.max_price,
        weekly_price: property.weekly_price,
        // property_group: property.property_group,
        refundable_token: pricing && pricing.refundable_token,
        max_available_price: pricing && pricing.max_available_price,
        min_available_price: pricing && location.min_available_price

      })
    }
  }

  const universityFxn = async () => {

    let params = {
      cityName: cityForUniversity
    }
    let resp2 = await distinctUniversityListFxn({
      ...params
    });
    setUniversityData(resp2)

  }

  useEffect(() => {
    universityFxn()
  },[cityForUniversity])
  const loadCountry = () => {
    const designatedArr = countryCodes.map((item) => ({
      label: item.name,
      value: item.name,
      short_name: item.code
    }))
    setDesignatedCountry(designatedArr)
  }
  useEffect(() => {
    apiRequest()
    loadCountry()
    events.getAmenities()
    events.getMedia()
    events.getRooms()
  }, [])


  const events = {


    deleteUniversityFromProperty: async (universityId) => {
      let resp = await dispatch(deleteUniversityFromPropertyFxn({ propertyId: propertyId, universityId: universityId }))
      if (resp.success) {
        notification.success({ message: resp.message })
       await apiRequest()
      } else {
        notification.error({ message: resp.message })
      }
    },
    addPropertyManagementGrp : async  () => {
        const {
          form: { getFieldValue, setFieldsValue }
        } = props
        let property_groups = getFieldValue('property_group_name') ? getFieldValue('property_group_name') : undefined;
        let data = await dispatch(addPropertyManagementGrpFxn({property_group_name:property_groups}))
        setFieldsValue({
          property_group_name: '',
      })
      await events.getDistinctPropertyGrp()
   console.log(data, "getFieldValue")
    },
    showAmenitiesModal: async (amenities) => {
      setOpenAmenities({
        visible: true,
        amenities: amenities,
        propertyId: propertyId
      })

    },
    hideAmenitiesModal: async () => {
      setOpenAmenities({
        visible: false,
        amenities: {},
        propertyId: ''
      })
      apiRequest()
    },
    openPropertyManagementGrp: async (amenities) => {
      setVisibleAddPropertyGrp({
        visible: true,

      })

    },
    hidePropertyManagementGrp: async () => {
      setVisibleAddPropertyGrp({
        visible: false,


      })

    },
    getCurrency: (country) => {
      let findCurrency = _.find(currencyList, (item) => {
        return item.name == country
      })
      if (findCurrency && findCurrency.currency) {
        return findCurrency.currency
      }
    },
    getAmenities: async () => {
      let { data } = await dispatch(getAmenitiesFxn({ propertyId }))
      if (data && data.amenities) {
        setAmenities(data.amenities)
      }
    },
    getMedia: async () => {
      let { image, video } = await dispatch(getMediaFxn({ propertyId }))

      // setImages(image || [])
      setImages(image.filter((item) => item.active == true));
      setVideos(video || [])
    },
    getRooms: async () => {
      let { data } = await dispatch(getRoomsFxn({ propertyId }))
      if (data && data.length) {
        setRoomTypes(data)
      }
    },
    getDistinctPropertyGrp: async () => {
      let { data } = await dispatch(getPropertyGrpFxn())
      setPropertyGrpList(data)
    }
  }

  useEffect(() => {
    events.getDistinctPropertyGrp()
  },[])
  const baseFormFields = [
    { key: 'name', label: 'Name', type: 'text' },
    {
      key: 'country',
      label: 'Country',
      placeholder: 'Select Country',
      required: true,
      showSearch: true,
      type: 'select',
      options: designatedCountry,
      keyAccessor: (x) => `${x.value}`,
      valueAccessor: (x) => `${x.value}`,
      onChange: (value, option) => {
        let currencyObj = events.getCurrency(value)
        setFieldsValue({
          country: option.props.children,
          countryShort: option.props.value,
          currencyName: currencyObj.code || '',
          currency: currencyObj.symbol || ''
        })
      }
    },
    { key: 'state', label: 'State', type: 'text' },
    { key: 'route', label: 'Route', type: 'text' },
    { key: 'district', label: 'District', type: 'text' },
    { key: 'locality', label: 'Locality', type: 'text' },
    { key: 'streetNumber', label: 'Street Number', type: 'text' },
    { key: 'postalCode', label: 'Postal Code', type: 'text' },
    { key: 'currencyName', label: 'Currency', type: 'text', disabled: true, span: 4 },
    { key: 'currency', label: 'Currency Symbol', type: 'text', disabled: true, span: 4 },
    {
      key: 'duration',
      label: 'Duration',
      type: 'select',
      options: DurationTypes,
      keyAccessor: (x) => `${x.value}`,
      valueAccessor: (x) => `${x.name}`,
      onChange: (value, option) => {
        setFieldsValue({
          duration: value
        })
      }
    },
    // { key: 'min_price', label: 'Minimum Price', type: 'number' },
    // { key: 'max_price', label: 'Maximum Price', type: 'number' },
    { key: 'weekly_price', label: 'Weekly  Price', type: 'number' },
    { key: 'refundable_token', label: 'Refundable Token', type: 'number' },
    // {
    //   span: 8,
    //   key: 'property_group',
    //   label: 'Select Property Group',
    //   type: 'select',
    //   options: propertyGrpList,
    //
    //   onChange: (value, option) => {
    //     setFieldsValue({
    //       property_group: value
    //     })
    //   }
    // },

    // { key: 'property_group',  type: 'select', label: 'Select Property management group', placeholder: 'Property management group' },
    {
      key: 'property_group',
      label: 'Select Property Group',
      span: 8,
      customField: (
        <InputBox title={'Select Property management group'}>
          <select
            name="smallLogo"
            id="smallLogo"
            value={propertyGroupData}
            className="form-control"
            onChange={(e) => {
              setPropertyGroupData(e.target.value)
            }}
          >
            {propertyGrpList.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
          <Tooltip>
            <a
              className={'linkBtn'}
              target={'_blank'}
              onClick={() => events.openPropertyManagementGrp()}
            >
              <Icon type={'plus'} />
            </a>{' '}
          </Tooltip>
        </InputBox>
      )
    },
    // {
    //   key: 'add',
    //   span: 4,
    //   customField: (
    //     <InputBox title={'Add'} >
    //       <button className='btn mt10' type='primary'
    //          onClick={() => events.openPropertyManagementGrp()}
    //
    //       style={{ backgroundColor: 'white', border:'1px solid black', textColor: 'black', color: 'black' ,marginTop:40}}>
    //         <Icon type={'plus'} />
    //       </button>
    //     </InputBox>
    //   )
    // },

    // {
    //   title: 'add',
    //   dataIndex: 'add',
    //   key: 'add',
    //
    //   customField: (
    //     <InputBox title={'Add'}>
    //       <Tooltip >
    //         <a className={'btn'}   onClick={() => events.openPropertyManagementGrp()} target={'_blank'} >
    //           <Icon type={'plus'} />
    //         </a>{' '}
    //       </Tooltip>
    //     </InputBox>
    //     )
    //
    // },
    // { key: 'max_available_price', label: 'Maximum Available Price', type: 'number' },
    // { key: 'min_available_price', label: 'Minimum Available Price', type: 'number' }
    // {
    //   key: 'place',
    //   label: 'University',
    //   type: 'select',
    //   showSearch: true,
    //   options: universityData,
    //
    //   allowClear: true,
    //   keyAccessor: x => x.name,
    //   valueAccessor: x => `${x.name}`,
    //   onChange: x => {
    //     setFieldsValue({
    //       place: x
    //     })
    //   }
    // },
    // { key: 'distance', label: 'Distance', type: 'text' },
  ]
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const { form } = props

    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        console.log(propertyGroupData, "oppppppppppppppp")
        const location = {
          country: {
            long_name: valData.country,
            short_name: valData.countryShort
          },

          state: { long_name: valData.state },
          route: { long_name: valData.route },
          district: { long_name: valData.district },
          locality: { long_name: valData.locality },
          postal_code: { long_name: valData.postalCode },
          street_number: { long_name: valData.streetNumber }
        }
        const pricing = {
          currency: valData.currency,
          duration: valData.duration,
          min_price: valData.min_price,
          max_price: valData.max_price,
          refundable_token: valData.refundable_token,
          max_available_price: valData.max_available_price,
          min_available_price: valData.min_available_price
        }

        const {
          country,
          state,
          route,
          district,
          locality,
          postalCode,
          streetNumber,
          currencyName,
          currency,
          ...otherFields
        } = valData
        const updateFields = {
          ...otherFields,
          currencyName, currency,
          location,
          pricing,
          property_group :  propertyGroupData,
          // multipleUniversities: addedFields || []
        }
    console.log(updateFields, "property_group")
        const resp = await dispatch(updateUnizhomePropertyFxn(propertyId, updateFields))
        if (resp && resp.success) {

          if (!resp.error) {
            notification.success({
              message: resp.message || 'Success'
            })
          } else {
            notification.error({
              message: resp.message || 'Error'
            })
          }
        }

      } else {
        notification.warning({ message: 'Validation Error' })
      }
    })
  }

  const handleUniversitySubmit = (e) => {
    e.preventDefault()
    const { form } = props
    const hasEmptyFields = dynamicFields.some(field => !field.place || !field.distance);

    if (hasEmptyFields) {
      notification.warning({ message: 'Both university and distance are required' });
      return;
    }
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {

        const updateFields = {
          multipleUniversities: dynamicFields || []
        }
        const resp = await dispatch(updateUnizhomePropertyFxn(propertyId, updateFields))
        if (resp && resp.success) {
          setDynamicFields([{ place: '', distance: '' }])
          if (!resp.error) {
            notification.success({
              message: "University added successfully"
            })
            await apiRequest()
          } else {
            notification.error({
              message: resp.message || 'Error'
            })
          }
        }

      } else {
        notification.warning({ message: 'Validation Error' })
      }
    })
  }
  useEffect(() => {
    /* if (activeTab === 'roomTypes' && meta && meta.unit_types && meta.unit_types.length > 0) {
       const availableTabs = meta.unit_types.filter((unitType) => unitType !== 'student_accommodation')

       if (activeRoomTab === '') {
         setActiveRoomTab(availableTabs[0])
       }
     }*/
  }, [activeTab, meta, activeRoomTab])




  const handleDynamicFieldChange = (index, field, value) => {
    const updatedFields = [...dynamicFields];
    updatedFields[index][field] = value;
    setDynamicFields(updatedFields);
  };


  const columns = [
    {
      title: 'University',
      dataIndex: 'place',
      key: 'place',
    },
    {
      title: 'Distance',
      dataIndex: 'distance',
      key: 'distance',
    },
    {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (text, record) => (
        <>

          <Tooltip title='Delete University'>
            <Popconfirm
              title='Are you sure, you want to delete this record?'
              onConfirm={() => events.deleteUniversityFromProperty(record._id)}>
              <a className={'btn btn-danger roundNew sm ml5'}>
                Delete
              </a>
            </Popconfirm>
          </Tooltip>

        </>
      )
    }

  ];
  const tabsConfig = [
    {
      name: 'Basic Details',
      tabKey: 1,
      component: (
        <>
        <Form >
          <Row gutter={16}>
            {baseFormFields.map((item, key) => {
              return (
                <>
                  <Col span={item.type === 'checkboxGroup' ? 24 : (item.span || 8)} key={key}>
                    {item.type === 'file' ? (
                      <FileInput
                        name={item.fileName}
                        label={item.label}
                        className='mt20'
                        file={{ url: '' }}
                        chooseDocument={item.onChange}
                      />
                    ) : item.type === 'checkboxGroup' ? (
                      <div className={'card-body table-responsive'}>
                        {key === 0 && (
                          <a className={'edit-amenities'}
                             onClick={() => events.showAmenitiesModal(features)}
                          >+ Edit Amenities</a>
                        )}

                        <div>
                          <h6 className={'amenities-label'}>{item.label}{item.name}</h6>
                          <hr />

                        </div>
                        <div>
                          <Row>
                            {item.options.map((option, index) => (
                              <Col span={6} key={index}>
                                <div>
                                  <Icon type={'check-circle'}
                                        className={'mr-3'}
                                  />{option.label}</div>
                              </Col>
                            ))}
                          </Row>
                        </div>

                      </div>
                    ) : item.customField ? (
                      <>{item.customField}</>
                    ) : (
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout}
                      />
                    )}

                  </Col>

                </>
              )
            })}

          </Row>

          <Row justify='end'>
            <Col>
              <button className='btn' type='submit' onClick={(e) => {handleSubmit(e)}} >Submit</button>
            </Col>
          </Row>

        </Form>



        </>
      )
    },
    {
      name: 'Media',
      tabKey: 2,
      component: (
        <MediaComponent
          dispatch={dispatch}
          propertyId={propertyId}
          videos={videos}
          images={images}
          refreshData={events.getMedia}
          onSubmit={() => {
          }} />
      )
    },
    {
      name: 'Faqs',
      tabKey: 3,
      component: (
        <FaqComponent
          dispatch={dispatch}
          propertyId={propertyId} faqs={faqs}
          refreshData={apiRequest}
        />
      )
    },
    {
      name: 'Payment Policy',
      tabKey: 4,
      component: (
        <PaymentPolicyComponent
          dispatch={dispatch}
          paymentPolicies={paymentPolicies} propertyId={propertyId}
          refreshData={apiRequest}
          refreshData={apiRequest}
        />
      )
    },
    {
      name: 'Room',
      tabKey: 5,
      component: (
        <RoomComponent meta={meta} dispatch={dispatch} roomTypes={roomTypes} refreshData={events.getRooms}
                       propertyId={propertyId} />
      )
    },
    {
      name: 'Amenities',
      tabKey: 6,
      component: (
        // <AmenitiesComponent amenities={amenities} propertyId={propertyId} roomTypes={roomTypes} getAmenities={events.getAmenities} onClick={(data) => {
        <AmenitiesComponent
          amenities={amenities}
          propertyId={propertyId}
          refreshData={events.getAmenities} />

      )
    },
    {
      name: 'Distance',
      tabKey: 7,
      component: (
        <DistanceComponent   propertyId={propertyId}
        />

      )
    },
    {
      name: 'University',
      tabKey: 8,
      component: (
        <>
          <Form >

            {dynamicFields.map((field, index) => (
              <Row key={index} style={{marginTop:'13px'}} gutter={16}>
                <Col span={8}>
                  <InputBox title={'Select University'}>
                    <Select
                      value={field.place || "Select University"}
                      onChange={(value) => handleDynamicFieldChange(index, 'place', value)}
                      allowClear
                      required
                      placeholder="Select University"
                      showSearch
                    >
                      {universityData.map((university) => (
                        <Select.Option key={university.name} value={university.name}>
                          {university.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </InputBox>
                </Col>
                <Col span={8}>
                  <InputBox title={'Distance'}>
                    <input
                      required
                      className="form-control"
                      value={field.distance}
                      onChange={(e) => handleDynamicFieldChange(index, 'distance', e.target.value)}
                      placeholder="Distance"
                    />
                  </InputBox>
                </Col>
                <Col span={8} style={{marginTop:'34px'}}>
                  <Button className="btn btn-danger roundNew sm" onClick={(e) => {handleUniversitySubmit(e)}} >Add</Button>
                </Col>

              </Row>

            ))}


          </Form>

            <div style={{ marginTop: '30px' }}>

              <Table
                columns={columns}
                dataSource={metaDistances.distances}
                rowKey={(record, index) => index}

              />
            </div>



        </>
      )
    },
  ]

  return (
    <>
      <div className='form-box commissionBox mt-4'>
        <div className='card unizportal'>
          <Tabs activeKey={activeTab} onChange={setActiveTab}>
            {tabsConfig.map((data) => {
              return (
                <TabPane tab={data.name.toUpperCase()} key={data.tabKey}>
                  {data.component}
                </TabPane>
              )
            })}
          </Tabs>
        </div>
      </div>

      {openAmenities.visible && (
        <AddPropertyAmenities
          {...openAmenities}
          onClose={() => events.hideAmenitiesModal()}
          onSubmit={() => events.hideAmenitiesModal()}
        />
      )}
      {visibleAddPropertyGrp.visible && (
        <AddPropertyManagementGroupDrawer
          {...visibleAddPropertyGrp}
          onClose={() => events.hidePropertyManagementGrp()}
          onRefresh={() => events.getDistinctPropertyGrp()}

        />
      )}


    </>
  )
}

export default Form.create()(EditPropertyModal)
