import React, { useEffect } from 'react'
import { Button, Col, Modal, Form, notification, Row } from 'antd'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import { updateMapDistanceDataFxn } from '../action'
import GetEachFormFields from '../../../components/_utils/appFormUtils'

const EditMapDistance = (props) => {
  let dispatch = useDispatch()
  let {
    visible,
    onClose,
    form: { getFieldDecorator, setFieldsValue ,resetFields},
    propertyId,
    distanceData,

    tableRef,
    category,
    onSubmit,
    distanceId
  } = props
console.log(distanceId, "distanceData")
  const setDefaultValues = () => {
    setTimeout(() => {
      setFieldsValue({
        name: distanceData.name,
        lat: distanceData.lat,
        lng: distanceData.lng || '',
        distance: distanceData.distance || '',
        duration: distanceData.duration
      })
    }, 100)
  }
  let inputTypes = {
    fields: [
      { key: "name", label: "Name", type: "text" , required:true},
      { key: "lat", label: "Lat", type: "text",required:true },
      { key: "lng", label: "Lng", type: "text",required:true  },
      { key: "distance", label: "Distance", type: "text",required:true  },
      { key: "duration", label: "Duration", type: "text",required:true  },
    ]
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const { form } = props
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        const updatedFaq = {
          updatedData:valData,
          category:category,
          propertyId,
          distanceId: distanceId
        }
        const resp = await dispatch(updateMapDistanceDataFxn(updatedFaq))
        if (resp && resp.success) {
          setTimeout(() => {
            if (tableRef && tableRef.current) {
              tableRef.current.reload()
            }
          }, 100)
          onSubmit()
          resetFields()
        }
      } else {
        notification.warning({
          message: 'Please fill in all required fields'
        })
      }
    })
  }


  useEffect(() => {
    setDefaultValues()
  }, [distanceData])

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onClose}
        title={`Edit Distance`}
        width={'85%'}
        footer={null}>
        <div className='form-box commissionBox'>
          <div className='card'>
            <Form onSubmit={handleSubmit}>
              <Row gutter={12} className={'wrapBox'}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <React.Fragment key={key}>
                      <Col span={item.span ? item.span : 12} key={key}>
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={{
                            labelCol: { xs: { span: 0 }, sm: { span: 0 } },
                            wrapperCol: { xs: { span: 24 } }
                          }}
                        />
                      </Col>
                    </React.Fragment>
                  )
                })}

              </Row>
              <Col span={4}>
                <Form.Item>
                  <Button
                    type='primary' htmlType='submit' className={'btn mt40'}>
                    Update
                  </Button>
                </Form.Item>
              </Col>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  )
}

const EditMapDistanceModal = Form.create()(EditMapDistance)

const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})

const mapDispatchToProps = dispatch => ({
  dispatch
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditMapDistanceModal)
