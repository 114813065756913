import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import { notification } from 'antd'
import {
  getAirportListUrl,
  getAirLineListUrl,
  updateAirportMainStatusUrl,
  getNearestAirportUrl,
  addNearestAirportUrl,
  deleteNearestAirportUrl
} from '../api/backApi'

export const getAirportListFxn = async (filters) => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getAirportListUrl(), config)
  return data.data
}

export const getAirLineListFxn = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getAirLineListUrl(), config)
  dispatch(hidePageLoad())
  return data.data
}

export const updateAirportMainStatusFxn = (valData) => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateAirportMainStatusUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getNearestAirportFxn = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getNearestAirportUrl(), config)
  dispatch(hidePageLoad())
  return data.data
}

export const addNearestAirportFxn = (valData) => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addNearestAirportUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const deleteNearestAirportFxn = (valData) => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(deleteNearestAirportUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
