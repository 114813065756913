import { Button } from 'antd'
import moment from 'moment'
import React, { Suspense, useEffect, useState, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import {
  departmentObj,
  canadaOnShoreApplicationStatusSteps,
  singaporeApplicationSteps,
  cambieUniversity,
  fcmtUniversity,
  universityListForMarketing
} from '../../../components/_utils/appUtils'
import { getUserWithoutLoader } from '../../users/actions/user'
import { countryWiseDashboard } from '../actions/dashboardAction'

import {
  GetCountryCountRight
} from './DashboardUserWise'
import {
  CanadaInnerBlockNew,
  InnerBlockNew,
  UkInnerBlockNew,
  CanadaOnShoreInnerBlockNew
} from './countryCount.js'
import {
  canadaApplicationStatusSteps,
  ukApplicationStatusSteps,
  usaApplicationStatusSteps
} from '../../../components/_utils/ApplicationStatusSteps.js'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { getStudentFcmtCourseFxn } from '../../applications/actions/chooseStudent.js'
import { getUrlPushWrapper } from '../../../routes/routes.js'
import {
  canadaUniversityRecordsCountFxn
} from '../../reporting/actions/index.js'
import { EuropeAllCountryBlocks } from '../countryApplications/europeAllCountryBlocks.js'
import { Helmet } from 'react-helmet'


const initialState = {
  ukData: {},
  canadaData: {},
  australiaData: {},
  usaData: {},
  germanyData: {},
  singaporeData: {},
  canadaOnshoreData: {},
  australiaOnshoreData: {}
}
const AgentDashboard = (props) => {
  let {
    userData,
    userCountryName,
    userCountryId,
    state,
    allCountryRecords,
    currentUserRights
  } = props
  const dispatch = useDispatch()
  let [countRecords, setCountRecords] = useState({})
  let [country, setCountry] = useState('Europe')
  let [originalCountry, setOriginalCountry] = useState('')
  useEffect(() => {
    loadDirectCount()
  }, [])


  let loadDirectCount = async () => {
    let { data } = await dispatch(
      canadaUniversityRecordsCountFxn()
    )
    setCountRecords(data)
  }

  return (
    <>
      <div className='card card-pdf'>
        <div className='card-body p-0'>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <LazyLoadImage
              src={`./../dist/img/canadaFlag.png`}
              alt=''
              style={{ height: 23, width: 25, marginRight: 15 }}
              className={'download-pdf-flag'}
            />
            <h5 className={'download-pdf-text'}>Canada</h5>
          </div>

          <Suspense fallback={<></>}>
            <GetCountryCountRight
              user={userData}
              country={'canada'}
              userCountryName={userCountryName}
              countryName={'canada'}>
              <CanadaInnerBlockNew
                data={state.canadaData}
                statusList={canadaApplicationStatusSteps}
                userData={userData}
                currentUserRights={currentUserRights}
                userCountryName={'canada'}

              />
            </GetCountryCountRight>
          </Suspense>
        </div>
        <div
          className='card-pane-right card-pane-new listing-section w-100'
          style={{ border: 'none' }}>
          <ul className={'count'}>
            {universityListForMarketing.map(item => {
              const countRecordss = countRecords[item.id] || { total: 0 }

              return (
                <li className='canadaDirectUniCount canadaDirectUniCount2' key={item.id} style={{ width: '25%' }}
                >
                  <div className='innerBox'>
                    <div className='uniCountBox'>
                      <LazyLoadImage src={item.image} style={{ height: '20px', width: '100px' }} />
                      <h5 style={{
                        fontSize: '12px',
                        height: '26px',
                        minWidth: '10px',
                        width: '26px',
                        marginLeft: '5px'
                      }}>{countRecordss.total ? countRecordss.total : 0}</h5>
                    </div>
                  </div>
                </li>

              )
            })}
          </ul>
        </div>
      </div>

      <div className='card card-pdf'>
        <div className='card-body p-0'>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <LazyLoadImage
              src={`./../dist/img/united-kingdomFlag.png`}
              alt=''
              style={{ height: 20, width: 25, marginRight: 15 }}
              className={'download-pdf-flag'}
            />
            <h5>UK</h5>
          </div>
          <GetCountryCountRight
            user={userData}
            userCountryName={userCountryName}
            countryName={'uk'}>
            <UkInnerBlockNew
              data={state.ukData}
              statusList={ukApplicationStatusSteps}
              userData={userData}

            />
          </GetCountryCountRight>
        </div>
      </div>
      <div className='card card-pdf'>
        <div className='card-body p-0'>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <LazyLoadImage
              src={`./../dist/img/usa.jpg`}
              alt=''
              style={{ height: 20, width: 25, marginRight: 15 }}
              className={'download-pdf-flag'}
            />
            <h5>USA</h5>
          </div>
          <GetCountryCountRight
            user={userData}
            userCountryName={userCountryName}
            countryName={'usa'}>
            <InnerBlockNew
              data={state.usaData}
              statusList={usaApplicationStatusSteps}
              userData={userData}

            />
          </GetCountryCountRight>
        </div>
      </div>
      <div className='card card-pdf'>
        <div className='card-body p-0'>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <LazyLoadImage
              src={`./../dist/img/canadaFlag.png`}
              alt=''
              style={{ height: 20, width: 25, marginRight: 15 }}
              className={'download-pdf-flag'}
            />
            <h5>Canada Onshore</h5>
          </div>
          <GetCountryCountRight
            user={userData}
            userCountryName={userCountryName}>
            <CanadaOnShoreInnerBlockNew
              data={state.canadaOnshoreData}
              statusList={canadaOnShoreApplicationStatusSteps}
              userData={userData}

            />
          </GetCountryCountRight>
        </div>
      </div>
      <div className='card card-pdf'>
        <div className='card-body p-0'>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <LazyLoadImage
              src={`./../dist/img/singapore.png`}
              alt=''
              style={{ height: 20, width: 25, marginRight: 15 }}
            />
            <h5>Singapore</h5>
          </div>
          <GetCountryCountRight
            user={userData}
            country={'singapore'}
            userCountryName={userCountryName}
            countryName={'singapore'}>
            <InnerBlockNew
              data={state.singaporeData}
              statusList={singaporeApplicationSteps}
            />
          </GetCountryCountRight>
        </div>
      </div>
      <div style={{ pageBreakAfter: 'always' }}></div>
      <div className='card card-pdf'>
        <div className='card-body p-0'>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <LazyLoadImage
              src={`./../dist/img/europe.png`}
              alt=''
              style={{ height: 20, width: 25, marginRight: 15 }}
            />
            <h5>{country}</h5>
          </div>
          {country == 'Europe' ? (
            <Suspense fallback={<></>}>
              <GetCountryCountRight
                user={userData}
                country={country}
                userCountryName={userCountryName}
                countryName={originalCountry}>
                <EuropeAllCountryBlocks
                  dispatch={dispatch}
                  userCountryId={userCountryId}
                  data={allCountryRecords}
                  countRecords={countRecords} // Pass countRecords to EuropeAllCountryBlocks
                />
              </GetCountryCountRight>
            </Suspense>
          ) : null}
        </div>
      </div>
    </>
  )
}

const FcmtUniversity = props => {
  let { userCountryName, userData } = props
  let [totalApplication, setTotalApplication] = useState(0)
  let dispatch = useDispatch()
  useEffect(() => {
    loadCount()
  }, [])
  let loadCount = async () => {
    let resp = await dispatch(
      getStudentFcmtCourseFxn({
        appUniversityId: fcmtUniversity,
        count: 0,
        results: 0
      })
    )
    setTotalApplication(resp.total)
  }

  let searchData = () => {
    if (userData && userData.showAllFcmtList) {
      dispatch(
        getUrlPushWrapper('allFcmtApplicationsList', {
          appUniversityId: fcmtUniversity
        })
      )
    } else {
      dispatch(
        getUrlPushWrapper('application.fcmtApplicationsList', {
          appUniversityId: fcmtUniversity
        })
      )
    }
  }

  let btnA = (
    <>
      <li onClick={() => searchData()} className={'pointerCls pointerCls1'}>
        <LazyLoadImage
          src={'/assets/flairCollage.png'}
          style={{ height: 30 }}
        />
        <h5>{totalApplication}</h5>
      </li>
    </>
  )

  let branchUserCheck = () => {
    let checkUniversityExists = _.find(userData.universities, item => {
      return item.toString() == fcmtUniversity
    })
    return userData.department == 'Visa Approved Department'
      ? btnA
      : checkUniversityExists
        ? btnA
        : null
  }
  return (
    <>
      {userCountryName == 'Canada' ? (
        <>
          {userData.userType == 'admin' ||
          userData.userType == 'agent' ||
          userData.userType == 'subAgent'
            ? btnA
            : null}
          {userData.userType == 'branchManager' && userCountryName == 'Canada'
            ? btnA
            : null}
          {userData.userType == 'branchUser' ? branchUserCheck() : null}
        </>
      ) : null}
    </>
  )
}

const CambieUniversity = props => {
  let { userCountryName, userData } = props
  let [totalApplication, setTotalApplication] = useState(0)
  let dispatch = useDispatch()
  useEffect(() => {
    loadCount()
  }, [])
  let loadCount = async () => {
    let resp = await dispatch(
      getStudentFcmtCourseFxn({
        appUniversityId: cambieUniversity,
        count: 0,
        results: 0
      })
    )
    setTotalApplication(resp.total)
  }

  let searchData = () => {
    if (userData && userData.showAllFcmtList) {
      dispatch(
        getUrlPushWrapper('allFcmtApplicationsList', {
          appUniversityId: cambieUniversity
        })
      )
    } else {
      dispatch(
        getUrlPushWrapper('application.fcmtApplicationsList', {
          appUniversityId: cambieUniversity
        })
      )
    }
  }

  let btnA = (
    <>
      <li onClick={() => searchData()} className={'pointerCls pointerCls1'}>
        <LazyLoadImage
          src={'/assets/flairCollage.png'}
          style={{ height: 30 }}
        />
        <h5>{totalApplication}</h5>
      </li>
    </>
  )

  let branchUserCheck = () => {
    let checkUniversityExists = _.find(userData.universities, item => {
      return item.toString() == cambieUniversity
    })
    return userData.department == 'Visa Approved Department'
      ? btnA
      : checkUniversityExists
        ? btnA
        : null
  }
  return (
    <>
      {userCountryName == 'Canada' ? (
        <>
          {userData.userType == 'admin' ||
          userData.userType == 'agent' ||
          userData.userType == 'subAgent'
            ? btnA
            : null}
          {userData.userType == 'branchManager' && userCountryName == 'Canada'
            ? btnA
            : null}
          {userData.userType == 'branchUser' ? branchUserCheck() : null}
        </>
      ) : null}
    </>
  )
}
const DashboardProfile = props => {
  let { dispatch, currentUserRights } = props
  let [state, setState] = useState(initialState)
  let [userCountryName, setUserCountryName] = useState(null)
  let [userCountryId, setUserCountryId] = useState(null)
  let [userData, setUserData] = useState({})
  let [countryManager, setCountryManager] = useState({})
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
  let [allCountryRecords, setAllCountryRecords] = useState([])
  useEffect(() => {
    loadCountryWiseData()
    loadUserCountry()
  }, [])

  const componentRef = useRef(null)

  const downloadPDF = () => {
    const input = componentRef.current
    let opt = {
      margin: [0.1, 0.1],
      html2canvas: {
        scale: 2,
        padding: 20
      },
      pagebreak: { mode: ['css'] },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    }
    window
      .html2pdf()
      .from(input)
      .set(opt)
      .save('dashboardProfile.pdf')
  }


  const loadCountryWiseData = async () => {
    let resp = await dispatch(countryWiseDashboard())
    let {
      UK,
      Canada,
      Australia,
      USA,
      Germany,
      Australia_L1,
      Singapore,
      CA
    } = resp
    setAllCountryRecords(resp)
    setState({
      ...state,
      ukData: UK ? UK : {},
      canadaData: Canada ? Canada : {},
      australiaData: Australia ? Australia : {},
      usaData: USA ? USA : {},
      germanyData: Germany ? Germany : {},
      singaporeData: Singapore ? Singapore : {},
      caData: CA ? CA : {},
      canadaOnshoreData: resp['Canada Onshore'] ? resp['Canada Onshore'] : {},
      australiaOnshoreData: resp['Australia Onshore']
        ? resp['Australia Onshore']
        : {}
    })
  }

  const loadUserCountry = async () => {
    let resp = props.currentUserRights

    if (user && user.userType !== 'admin') {
      if (user.department == departmentObj.marketing) {
        setUserData(user)
      } else {
        setUserData(resp)
        if (resp && resp.countryId) {
          setUserCountryName(resp.countryId.countryName)
          setUserCountryId(resp.countryId._id)
        }
      }
    } else {
      setUserData(user)
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <script src='https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.9.2/html2pdf.bundle.js'
                as={'script'}></script>
      </Helmet>

      <div className='form-box mt-3'>
        <div className='card unizportal'>
          <div className={'mb20 alignRight'}>
            <Button onClick={downloadPDF} type={'primary'}>Download Your Profile</Button>
          </div>

          <div
            className='row'
            ref={componentRef}
            style={{ fontFamily: 'Arial', fontSize: '12px' }}>
            <div className='col-lg-12'>
              <div style={{ padding: 20 }}>
                {userData && userData._id ? (
                  <Suspense fallback={<></>}>
                    <AgentDashboard
                      userData={userData}
                      userCountryName={userCountryName}
                      userCountryId={userCountryId}
                      countryManager={countryManager}
                      currentUserRights={currentUserRights}

                      // events={events}
                      state={state}
                      allCountryRecords={allCountryRecords}
                    />
                  </Suspense>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ counter, global }) => ({
  count: counter.count,
  loading: global.buttonLoading,
  currentUserRights: global.currentUserRights
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardProfile, FcmtUniversity, CambieUniversity)
