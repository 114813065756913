import React from 'react'
import { getAirportListFxn } from '../actions/backAction'
import { Select, Spin } from 'antd'
import debounce from 'lodash/debounce'
import _ from 'lodash'

const { Option } = Select


class AirportTypeheadComponent extends React.Component {
  state = {
    arrList: [],
    value: [],
    fetching: false,
    airportName: '',
    airportId: ''
  }

  constructor(props) {
    super(props)
  }

  _getAirportId = () => {
    let { airportId } = this.props
    if (airportId) {
      this.setState({
        airportId
      }, () => {
        this.fetchAirport()
      })
    }
  }

  componentDidMount() {
    this._getAirportId()
  }

  fetchAirport = async (airportName) => {
    let { airportId } = this.state
    this.setState({ arrList: [], fetching: true })
    let { mainAirportId } = this.props
    let obj = {
      results: 30,
      count: 30,
      name: airportName,
      mainAirportId,
      regExFilters: ['airportCode', 'airportName', 'countryCode', 'countryName', 'cityCode', 'cityCode']
    }

    if (airportId && !airportName) {
      obj.airportId = airportId
    }

    let { data } = await getAirportListFxn(obj)
    let dataNew = []
    if (data && data.length) {
      _.each(data, (airport) => {
        dataNew.push({
          text: `${airport.airportName} (${airport.airportCode}) - ${airport.cityName}  | ${airport.countryName}`,
          value: airport._id
        })
      })
    }
    let newObj = {
      arrList: dataNew
    }
    if (airportId && !airportName && dataNew && dataNew.length) {
      let currentAgent = dataNew[0]
      newObj.airportName = {
        key: currentAgent.value,
        label: currentAgent.text
      }
    }
    this.setState(newObj)
  }

  handleChange = value => {
    let { onSelect } = this.props
    this.setState({
      airportName: value,
      airportId: value && value.key ? value.key : '',
      data: [],
      fetching: false
    })
    onSelect((value && value.key) || '')
  }

  render() {
    const { fetching, arrList, airportName, required = false } = this.state
    let { customStyle = 'antSelect' } = this.props
    return (
      <Select
        labelInValue
        value={airportName || undefined}
        className={customStyle}
        placeholder='Select Airport'
        allowClear={true}
        notFoundContent={fetching ? <Spin size='small' /> : null}
        filterOption={false}
        showSearch={true}
        onSearch={debounce(this.fetchAirport, 500)}
        onChange={this.handleChange}>
        {arrList.map(d => (
          <Option key={d.value} value={d.value}>{d.text}</Option>
        ))}
      </Select>
    )
  }
}

export default AirportTypeheadComponent
