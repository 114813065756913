import React, { PureComponent } from 'react'
import {
  Form,
  Input,
  Select,
  Button,
  Icon, Col, Row, Tooltip, Drawer, notification, Card
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { hideLoader, hidePageLoad } from '../../../modules/actions'
import { connect } from 'react-redux'
import { listAllUniversities } from '../../university/actions/university'
import { listAllCountries } from '../../countries/actions/countries'
import {
  InputBox,
  departmentList,
  BooleanOptions,
  assignAgentTypeOptions,
  UniversityOptions,
  departmentObj, managerType, countryIds, UserTypeHumanizeName, ConditionOptions,
  canadaOnshoreStatesList,
  assignMarketingTypeOptions, UniversityOptionsForOnshoreCanada
} from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { updateUser, listAllUsers, getUser } from '../actions/user'
import debounce from 'lodash/debounce'
import { CountryCodeWithFlag, LoadAllCountry, LoadCity, LoadState } from '../../../components/_utils/countryUtil'

let users = [
  {
    name: 'Admin',
    value: 'admin'
  },
  {
    name: 'Branch Manager',
    value: 'branchManager'
  },
  {
    name: 'Branch User',
    value: 'branchUser'
  },
  {
    name: 'Agent',
    value: 'agent'
  }
]
const { Option } = Select
const { TextArea } = Input

@Form.create()
class EditUser extends PureComponent {

  events = {
    selectAllUni: () => {
      const { form: { setFieldsValue } } = this.props
      let { universityList } = this.state
      let universities = []
      _.each(universityList, (item) => {
        if (item && item._id) {
          universities.push(item._id)
        }
      })
      setFieldsValue({
        universities: universities
      })
    },
    deSelectAllUni: () => {
      const { form: { setFieldsValue } } = this.props
      let universities = []
      setFieldsValue({
        universities: universities
      })
    },
    chooseCountry: (name) => {
      const { setFieldsValue } = this.props.form
      let countryName = _.find(this.state.allCountries, (item) => {
        return item.name == name
      })
      if (countryName) {
        setFieldsValue({
          stateName: undefined,
          cityName: undefined
        })
        this.setState({
          countryName: countryName.name ? countryName.name : '',
          stateName: '',
          cityName: '',
          allStates: countryName.id ? LoadState(countryName.id) : []
        })
      }
    },
    chooseState: (name) => {
      const { setFieldsValue } = this.props.form
      let stateName = _.find(this.state.allStates, (item) => {
        return item.name == name
      })
      setFieldsValue({
        cityName: undefined
      })
      if (stateName) {
        this.setState({
          stateName: stateName.name ? stateName.name : '',
          cityName: '',
          allCities: stateName.id ? LoadCity(stateName.id) : []
        })
      }
    },
    chooseCity: (name) => {
      this.setState({
        cityName: name ? name : ''
      })
    },
    assignUserFnx: (id) => {
      let user = _.find(this.state.usersForAgent, (item) => {
        return item._id === id
      })
      this.setState({
        createdByUserIdName: user.name,
        createdByUser: {
          name: user.name,
          userId: user._id,
          time: new Date()
        }
      })
    },
    assignAgentUser: (id) => {
      let user = _.find(this.state.usersForAgent, (item) => {
        return item._id === id
      })
      this.setState({
        createdByUserIdName: user.name,
        createdByUser: {
          name: user.name,
          userId: user._id,
          time: new Date()
        }
      })
    },
    assignManagerAgent: (id) => {
      let user = _.find(this.state.marketingManagerForAgent, (item) => {
        return item._id === id
      })
      this.setState({
        displayAgentManagers: user.name,
        selectedMarketingManager: id
      })
    },
    showAssignProvisions: () => {
      const { form: { getFieldValue } } = this.props
      if (getFieldValue('countryId') === countryIds.canadaOnshore) {
        if (getFieldValue('userType') === 'branchManager' || getFieldValue('department') === departmentObj.assDepartment) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    loadMarketingUser: async () => {
      const { form: { getFieldValue }, dispatch } = this.props
      let countryId = getFieldValue('countryId')
      let params = {
        results: 100,
        count: 100,
        department: departmentObj.marketing,
        customQuery: {
          'marketingCountries.countryId': countryId
        },
        select: 'name email',
        block: false
      }
      let { data } = await dispatch(listAllUsers({ ...params }))
      this.setState({
        marketingUserList: data
      })
    }
  }

  agentEvents = {
    fetchAgent: async (name) => {
      let { dispatch } = this.props
      let obj = {
        results: 100,
        userType: 'agent',
        sortField: 'name',
        sortOrder: 'ascend',
        select: ['name', 'companyName'],
        name: name,
        block: false
      }
      let { data } = await dispatch(listAllUsers(obj))
      this.setState({
        agentList: data
      })
    },
    removeAgent: (index) => {
      let cloneAgent = _.clone(this.state.agents)
      cloneAgent.splice(index, 1)
      this.setState({
        agents: cloneAgent
      })
    },
    chooseAgent: (data) => {
      let { agents, agentList } = this.state
      let findUser = _.find(agents, (item) => {
        return item._id && item._id.toString() == data
      })
      if (findUser) {
        this.setState({
          agentName: '',
          agentList: []
        })
        return notification.warning({
          message: 'Agent already exists.'
        })
      } else {
        let findAgent = _.find(agentList, (item) => {
          return item._id && item._id.toString() == data
        })
        if (findAgent) {
          let cloneAgents = _.clone(agents)
          cloneAgents.push(findAgent)
          this.setState({
            agentName: '',
            agentList: [],
            agents: cloneAgents
          })
        }
      }
    }
  }
  conditionalFxn = {
    countryFieldFxn: () => {
      const {
        form: { getFieldValue }
      } = this.props
      return (getFieldValue('userType') && ((getFieldValue('userType') == 'branchUser' && (getFieldValue('department') !== departmentObj.visaApproved && getFieldValue('department') !== departmentObj.warningDepartment) && getFieldValue('department')) || getFieldValue('userType') == 'branchManager' || getFieldValue('userType') == 'marketingManager'))
    },
    branchManagerFieldFxn: () => {
      const {
        form: { getFieldValue }
      } = this.props
      return ((getFieldValue('userType') && getFieldValue('department')) && (getFieldValue('userType') == 'branchUser' && (getFieldValue('department') !== departmentObj.visaApproved && getFieldValue('department') !== departmentObj.warningDepartment)))
    },
    approvedAgentFieldFxn: () => {
      const {
        form: { getFieldValue }
      } = this.props
      return (getFieldValue('userType') && (getFieldValue('userType') == 'admin' || getFieldValue('userType') == 'branchManager' || getFieldValue('userType') == 'branchUser'))
    },
    universitySelectFieldFxn: () => {
      const {
        form: { getFieldValue }
      } = this.props
      return (getFieldValue('userType') &&
        ((getFieldValue('userType') == 'branchUser' && getFieldValue('department') && (getFieldValue('department').indexOf('Visa') < 0 && getFieldValue('department') !== departmentObj.warningDepartment)) || (getFieldValue('userType') == 'branchManager' && getFieldValue('branchManagerType') == 'universityWise') || getFieldValue('userType') == 'marketingManager')
      )
    },
    universitySelectFieldFxnRequired: () => {
      const {
        form: { getFieldValue }
      } = this.props
      return (getFieldValue('userType') &&
        ((getFieldValue('userType') == 'branchUser' && getFieldValue('department') && (getFieldValue('department').indexOf('Visa') < 0 && getFieldValue('department') !== departmentObj.warningDepartment)) || (getFieldValue('userType') == 'branchManager' && getFieldValue('branchManagerType') == 'universityWise') || getFieldValue('userType') == 'marketingManager')
      )
    },
    assignMarketingUserRequirement: () => {
      const { form: { getFieldValue } } = this.props
      if (getFieldValue('userType') && (getFieldValue('userType') == 'marketingManager' ||
        getFieldValue('department') == departmentObj.marketing ||
        (getFieldValue('department') == departmentObj.assDepartment && getFieldValue('countryId') == countryIds.canadaOnshore))) {
        return true
      }
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      allBranch: [],
      universityList: [],
      countryList: [],
      mainBranchArr: [],
      logo: {},
      uploadKey: moment(),
      oldLogo: {},
      certificate: {},
      agentList: [],
      agents: [],
      userData: {},
      usersForAgent: [],
      marketingUsersForAgent: [],
      marketingManagerForAgent: [],
      displayAgentUsers: [],
      displayAgentManagers: [],
      createdByUserIdName: '',
      createdByUser: {},
      allCountries: LoadAllCountry(),
      allStates: [],
      allCities: [],
      selectedMarketingManager: [],
      selectedMarketingUser: [],
      countryCode: '',
      marketingUserList: []
    }
  }

  componentDidMount() {

    this.loadCountry()
    this.getUserData()
    this.loadUserData()
    this.loadUserForAgent()
    this.loadMarketingUserForAgent()
    this.loadMarketingManagerForAgent()
    this.displayUserForAgent()
    this.displayManagerForAgent()
    this.loadMarketingUsers()
    let { dispatch } = this.props
    dispatch({ type: 'USER_HIDELOADER' })
    dispatch(hideLoader())
    dispatch(hidePageLoad())
  }

  async loadUserData() {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    this.setState({ userData: user })
  }

  async loadUserForAgent() {
    let { dispatch } = this.props
    let obj = {
      userType: ['admin', 'branchManager', 'branchUser'],
      /* customQuery: {
         'approvedAgent': true
       },*/
      results: 100,
      select: ['name', 'email', 'mobile', 'approvedAgent']
    }
    let { data } = await dispatch(listAllUsers(obj))
    this.setState({ usersForAgent: data })
  }

  async loadMarketingUserForAgent() {
    let { dispatch } = this.props
    let obj = {
      userType: ['branchUser'],
      department: departmentObj.marketing,
      results: 100,
      select: ['name', 'email']
    }
    let { data } = await dispatch(listAllUsers(obj))
    this.setState({ marketingUsersForAgent: data })
  }

  async loadMarketingManagerForAgent() {
    let { dispatch } = this.props
    let obj = {
      userType: ['branchManager'],
      branchManagerType: 'agentWise',
      results: 100,
      select: ['name', 'email']
    }
    let { data } = await dispatch(listAllUsers(obj))
    this.setState({ marketingManagerForAgent: data })
  }

  async displayUserForAgent() {
    let { dispatch, userData } = this.props
    let obj = {
      userType: ['branchUser'],
      customQuery: {
        'department': departmentObj.marketing,
        'agents': userData._id
      },
      results: 100,
      select: ['name', 'email', 'mobile']
    }
    let { data } = await dispatch(listAllUsers(obj))
    let selectedMarketingUser = []
    if (data && data.length) {
      _.each(data, (item) => {
        selectedMarketingUser.push(item._id)
      })
    }
    this.setState({ selectedMarketingUser })
  }

  async displayManagerForAgent() {
    let { dispatch, userData } = this.props
    let obj = {
      userType: ['branchManager'],
      customQuery: {
        'branchManagerType': 'agentWise',
        'agents': userData._id
      },
      results: 100,
      select: ['name', 'email', 'mobile']
    }
    let { data } = await dispatch(listAllUsers(obj))
    let selectedMarketingManager = []
    if (data && data.length) {
      _.each(data, (item) => {
        selectedMarketingManager.push(item._id)
      })
    }
    this.setState({ selectedMarketingManager })
  }

  handleSubmit = e => {
    const { dispatch, form, onClose, reloadTable } = this.props
    let { getFieldValue } = form
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let {
          address,
          agents,
          logo,
          certificate,
          createdByUser,
          selectedMarketingManager,
          selectedMarketingUser,
          countryCode
        } = this.state
        if (!countryCode) {
          notification.error({
            message: 'Please choose County Code'
          })
          return
        }
        if (valData.assignAgentType == 'selected' || valData.assignAgentType == 'exceptSelected') {
          if ((agents && agents.length == 0) || !agents) {
            notification.error({
              message: 'Please choose Agents'
            })
            return
          }
        }
        if (createdByUser && createdByUser.userId) {
          valData.createdByUser = createdByUser
        }
        valData.address = address
        valData.countryISOCode = countryCode
        let fd = new FormData()
        let selectedAgents = []
        if (getFieldValue('userType') == 'branchManager') {
          if (getFieldValue('branchManagerType') == 'agentWise') {
            valData.universities = []
            if (!agents || (agents && !agents.length)) {
              notification.warning({
                message: 'Choose Agents'
              })
              return
            }
          }
        }
        _.each(agents, (item) => {
          if (item && item._id) {
            selectedAgents.push(item._id)
          }
        })
        if (selectedAgents && selectedAgents.length) {
          valData.agents = selectedAgents
        } else {
          valData.agents = []
        }
        if (getFieldValue('branchManagerType') == 'universityWise') {
          valData.agents = []
        }
        if (selectedMarketingManager || selectedMarketingUser) {
          valData.selectedMarketingUser = selectedMarketingUser
          valData.selectedMarketingManager = selectedMarketingManager
        }
        if (valData.GSTINAvailable == 'No') {
          valData.GSTIN = null
        }
        fd.append('obj', JSON.stringify(valData))
        if (logo && logo.name) {
          fd.append('logo', logo)
        }
        if (certificate && certificate.name) {
          fd.append('certificate', certificate)
        }

        let data = await dispatch(updateUser(fd, this.state.userId))
        if (data && !data.error) {
          this.setState({
            address: '',
            uploadKey: moment(),
            logo: {}
          })
          onClose()
          if (reloadTable) {
            reloadTable()
          }
        }
      }
    })
  }

  async loadCountry() {
    let { dispatch } = this.props
    let countryFilter = {
      results: 100,
      sortField: 'countryName',
      sortOrder: 'ascend',
      active: [true, false]
    }
    let { data } = await dispatch(listAllCountries(countryFilter))
    this.setState({
      countryList: data
    })
  }

  async loadCountryUniversity(countryId) {
    let { dispatch } = this.props
    let { data } = await dispatch(listAllUniversities({
      results: 1000000,
      universityCountry: countryId,
      sortField: 'universityName',
      sortOrder: 'ascend'
    }))
    this.setState({
      universityList: data
    })
  }

  async getUserData() {
    let { pathname, dispatch, form: { setFieldsValue }, userData } = this.props
    if (userData && userData._id) {
      let resp = await dispatch(getUser(userData._id))
      if (resp) {
        this.setState({
          name: resp.name,
          userType: resp.userType,
          userId: resp._id,
          oldLogo: resp.logo,
          certificate: resp.certificate,
          address: resp.address,
          agents: resp.agents,
          email: resp.email,
          assignProvisions: resp.assignProvisions,
          countryCode: resp.countryISOCode ? resp.countryISOCode : '',
          createdByUserIdName: resp.createdByUser ? resp.createdByUser.name : ''
        })
        if (resp.userType == 'branchUser' && resp.countryId) {
          let { data } = await dispatch(listAllUsers({ userType: 'branchManager', countryId: resp.countryId }))
          this.setState({
            mainBranchArr: data
          })
        }
        if ((resp.userType == 'branchUser' || resp.userType == 'marketingManager' || (resp.userType == 'branchManager' && resp.branchManagerType == 'universityWise')) && resp.countryId && resp.countryId._id) {
          this.loadCountryUniversity(resp.countryId._id)
        }
        setFieldsValue({
          name: resp.name,
          mobile: resp.mobile,
          email: resp.email,
          userType: resp.userType,
          address: resp.address,
          assignProvisions: resp.assignProvisions ? resp.assignProvisions : []

        })
        setTimeout(() => {
          setFieldsValue({
            companyName: resp.companyName,
            commissionType: resp.commissionType,
            department: resp.department,
            postName: resp.postName,
            allowAddingUser: resp.allowAddingUser,
            enableChanelPartner: resp.enableChanelPartner ? resp.enableChanelPartner : false,
            showAustraliaUniversity: resp.showAustraliaUniversity ? resp.showAustraliaUniversity : false,
            approvedAgent: resp.approvedAgent ? resp.approvedAgent : false,
            showCommission: resp.showCommission ? resp.showCommission : false,
            managerViewOnly: resp.managerViewOnly ? resp.managerViewOnly : false,
            enabledStudentLogin: resp.enabledStudentLogin ? resp.enabledStudentLogin : false,
            showAgent: resp.showAgent ? resp.showAgent : false,
            alternateEmails: resp.alternateEmails ? resp.alternateEmails : null,
            alternateMobileNo: resp.alternateMobileNo ? resp.alternateMobileNo : null,
            branchManagerType: resp.branchManagerType ? resp.branchManagerType : null,
            cityName: resp.cityName ? resp.cityName : undefined,
            pincode: resp.pincode ? resp.pincode : undefined,
            stateName: resp.stateName ? resp.stateName : undefined,
            countryName: resp.countryName ? resp.countryName : undefined,
            assignProvisions: resp.assignProvisions ? resp.assignProvisions : [],
            // accountName: resp.accountName || undefined,
            // accountNumber: resp.accountNumber || undefined,
            // bankName: resp.bankName || undefined,
            // IFSC: resp.IFSC || undefined,
            GSTINAvailable: resp.GSTINAvailable || undefined,
            panCardNumber: resp.panCardNumber || undefined,
            joiningDate: resp.joiningDate ? moment(resp.joiningDate) : '',
            xTravelTransferFundLimit: resp.xTravelTransferFundLimit ? resp.xTravelTransferFundLimit : ''
          })
          setTimeout(() => {
            setFieldsValue({
              showAllUniversity: resp.showAllUniversity,
              countryId: resp.countryId ? resp.countryId._id : undefined,
              // countryId: resp.countryId && resp.countryId._id,
              branchMangerId: resp.branchMangerId,
              showVisaFileStages: resp.showVisaFileStages !== undefined ? resp.showVisaFileStages : true
            })
            setTimeout(() => {
              setFieldsValue({
                GSTIN: resp.GSTIN || undefined,
                universities: resp.universities,
                assignAgentType: resp.assignAgentType ? resp.assignAgentType : 'all',
                assignProvisions: resp.assignProvisions ? resp.assignProvisions : [],
                assignMarketingType: resp.assignMarketingType ? resp.assignMarketingType : '',
                assignedMarketingUsers: resp.assignedMarketingUsers ? resp.assignedMarketingUsers : []

              })
              this.events.loadMarketingUser()

            }, 200)
          }, 500)
        }, 500)
      }
    }

  }

  showAssignMarketingField = () => {
    const { form: { getFieldValue } } = this.props
    if (getFieldValue('userType') == 'branchUser') {
      if (getFieldValue('department') == departmentObj.assDepartment && getFieldValue('countryId') == countryIds.canadaOnshore) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  loadMarketingUsers = async () => {
    let { dispatch } = this.props
    let obj = {
      userType: 'branchUser',
      department: departmentObj.marketing,
      results: 100,
      select: ['name', 'email', 'mobile']
    }
    let { data } = await dispatch(listAllUsers(obj))
    this.setState({
      marketingUserList: data
    })
  }


  render() {
    let { events, props, state } = this
    const { dispatch, visible, onClose } = props
    const {
      form: { getFieldDecorator, getFieldValue, setFieldsValue }
    } = this.props

    const { oldLogo, certificate, agentName, agentList, agents, userData, marketingUserList } = state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    let inputTypes = {
      fields: [
        {
          label: 'Contact Person Name',
          key: 'name',
          required: true
        },
        {
          key: 'countryCode',
          span: 3,
          customField: (
            <div style={{ marginTop: 5 }}>
              <CountryCodeWithFlag countryCode={this.state.countryCode} chooseCode={(val) => {
                this.setState({
                  countryCode: val
                })
              }} />
            </div>
          )
        },
        {
          span: 5,
          label: 'Mobile no',
          key: 'mobile',
          type: 'number',
          required: true
        },
        {
          label: 'Email',
          key: 'email',
          required: true,
          hidden: userData && (userData.userType === 'admin' || userData.userType === 'userManager' || userData.userType === 'branchUser') ? false : true
        },
        {
          label: 'User Type',
          key: 'userType',
          required: true,
          type: 'select',
          disabled: true,
          options: users,
          valueAccessor: x => x.name,
          keyAccessor: x => x.value,
          onChange: async (v) => {
            /* setFieldsValue({
               userType: v
             })*/
          }
        },
        {
          label: 'Manager Type',
          key: 'branchManagerType',
          type: 'select',
          allowClear: true,
          hidden: !(getFieldValue('userType') && (getFieldValue('userType') == 'branchManager')),
          options: managerType,
          valueAccessor: x => x.name,
          keyAccessor: x => x.value,
          onChange: async (v) => {
            setFieldsValue({
              branchManagerType: v,
              universities: [],
              showAllUniversity: ''
            })
            if (v == 'universityWise') {
              setFieldsValue({
                managerViewOnly: true
              })
            }
            this.setState({
              agentName: '',
              agents: []
            })
          }
        },
        {
          label: 'Show Visa File Stages',
          key: 'showVisaFileStages',
          type: 'select',
          hidden: !(getFieldValue('userType') && (getFieldValue('userType') == 'branchManager')),
          options: ConditionOptions,
          valueAccessor: x => x.name,
          keyAccessor: x => x.value,
          onChange: async (v) => {
            setFieldsValue({
              showVisaFileStages: v
            })
          }
        },

        {
          label: 'Post Name',
          key: 'postName',
          hidden: !(getFieldValue('userType') && (getFieldValue('userType') == 'branchUser' || getFieldValue('userType') == 'branchManager' || getFieldValue('userType') == 'marketingManager' || getFieldValue('userType') == 'admin'))
        },

        {
          label: 'Department',
          key: 'department',
          required: true,
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'branchUser'),
          type: 'select',
          options: departmentList,
          onChange: v => {
            setFieldsValue({
              department: v
            })
          }
        },
        {
          label: 'Country',
          key: 'countryId',
          required: true,
          type: 'select',
          showSearch: true,
          options: this.state.countryList,
          hidden: !this.conditionalFxn.countryFieldFxn(),
          valueAccessor: x => x.countryName,
          keyAccessor: x => x._id,
          onChange: async (v) => {
            setFieldsValue({
              countryId: v,
              universities: [],
              branchMangerId: '',
              showAllUniversity: undefined
            })
            if (getFieldValue('userType') == 'branchUser') {
              let countryId = getFieldValue('countryId')

              if (countryId) {
                let { data } = await listAllUsers({ userType: 'branchManager', countryId })(dispatch)
                this.setState({
                  mainBranchArr: data
                })
              } else {
                this.setState({
                  mainBranchArr: []
                })
              }

            }
            this.loadCountryUniversity(v)
            this.events.loadMarketingUser()
          }
        },
        {
          key: 'assignProvisions',
          label: 'Assign Provisions',
          type: 'select',
          mode: 'multiple',
          options: canadaOnshoreStatesList,
          valueAccessor: x => x.name,
          keyAccessor: x => x.name,
          onChange: v => {
            setFieldsValue({
              assignProvisions: v
            })
          },
          hidden: !(events.showAssignProvisions()),
          initialValue: (events.showAssignProvisions()) ? this.state.assignProvisions : undefined
        },


        {
          label: 'Branch Manger',
          key: 'branchMangerId',
          required: true,
          hidden: !this.conditionalFxn.branchManagerFieldFxn(),
          type: 'select',
          valueAccessor: x => x.name,
          keyAccessor: x => x._id,
          options: this.state.mainBranchArr,
          onChange: v => {
            setFieldsValue({
              branchMangerId: v
            })
          }
        },
        {
          key: 'showAllUniversity',
          label: 'University',
          hidden: !this.conditionalFxn.universitySelectFieldFxn(),
          type: 'select',
          required: this.conditionalFxn.universitySelectFieldFxnRequired(),
          keyAccessor: x => x.value,
          valueAccessor: x => `${x.name}`,
          options: (getFieldValue('countryId') == countryIds.canadaOnshore && getFieldValue('department') == departmentObj.offerDepartment) ? UniversityOptionsForOnshoreCanada : UniversityOptions,
          onChange: x => {
            setFieldsValue({
              showAllUniversity: x
            })
          }
        },
        {
          key: 'universities',
          label: '',
          label1: '* Select University',
          type: 'select',
          extraBox1: (
            <React.Fragment>
              <a onClick={() => this.events.selectAllUni()}>Select All</a>{' '}
              <a onClick={() => this.events.deSelectAllUni()}>De-select All</a>
              {getFieldValue('universities') && getFieldValue('universities').length ?
                <a className={'selectedUni'}>{getFieldValue('universities').length}</a>
                : null}
            </React.Fragment>
          ),
          required: true,
          showSearch: true,
          options: this.state.universityList,
          // hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'branchUser' && getFieldValue('department') && getFieldValue('department').indexOf('Visa') < 0),
          hidden: !(getFieldValue('showAllUniversity') == 'Selected'),
          mode: 'multiple',
          keyAccessor: x => x._id,
          valueAccessor: x => `${x.universityName}`,
          onChange: x => {
            setFieldsValue({
              universities: x
            })
          }
        },
        {
          key: 'companyName',
          label: 'Company Name',
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
          required: true
        },

        {
          key: 'allowAddingUser',
          label: 'Add Team',
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
          type: 'select',
          required: true,
          keyAccessor: x => x.value,
          valueAccessor: x => `${x.name}`,
          options: BooleanOptions,
          onChange: x => {
            setFieldsValue({
              allowAddingUser: x
            })
          }

        },
        /* {
           key: 'enableChanelPartner',
           label: 'Show Channel Partner',
           hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
           type: 'select',
           required: true,
           keyAccessor: x => x.value,
           valueAccessor: x => `${x.name}`,
           options: BooleanOptions,
           onChange: x => {
             setFieldsValue({
               enableChanelPartner: x
             })
           }
         },*/
        /*  {
            key: 'showAustraliaUniversity',
            label: 'Show Australia University',
            hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
            type: 'select',
            required: true,
            keyAccessor: x => x.value,
            valueAccessor: x => `${x.name}`,
            options: BooleanOptions,
            onChange: x => {
              setFieldsValue({
                showAustraliaUniversity: x
              })
            }
          },*/
        {
          label: 'Approved Agent',
          key: 'approvedAgent',
          required: true,
          hidden: !(getFieldValue('userType') && (getFieldValue('userType') == 'admin' || getFieldValue('userType') == 'branchManager' || getFieldValue('userType') == 'branchUser')),
          type: 'select',
          keyAccessor: x => x.value,
          valueAccessor: x => `${x.name}`,
          options: BooleanOptions,
          onChange: v => {
            setFieldsValue({
              approvedAgent: v
            })
          }
        },
        {
          key: 'showCommission',
          label: 'Show Commission',
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
          type: 'select',
          required: true,
          keyAccessor: x => x.value,
          valueAccessor: x => `${x.name}`,
          options: BooleanOptions,
          onChange: x => {
            setFieldsValue({
              showCommission: x
            })
          }
        },
        {
          key: 'enabledStudentLogin',
          label: (
            <>Student Login <small>(Note : Ryan Branch)</small></>
          ),
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
          type: 'select',
          required: true,
          placeholder: 'Student Login',
          keyAccessor: x => x.value,
          valueAccessor: x => `${x.name}`,
          options: BooleanOptions,
          onChange: x => {
            setFieldsValue({
              enabledStudentLogin: x
            })
          }
        },
        {
          key: 'managerViewOnly',
          label: 'Manager View Only',
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'branchManager'),
          options: BooleanOptions,
          type: 'select',
          keyAccessor: x => x.value,
          valueAccessor: x => `${x.name}`,
          onChange: x => {
            setFieldsValue({
              managerViewOnly: x
            })
          }
        },

        {
          key: 'countryName',
          label: 'Country Name',
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
          required: true,
          type: 'select',
          showSearch: true,
          options: this.state.allCountries,
          keyAccessor: x => x.name,
          valueAccessor: x => `${x.name}`,
          onChange: x => {
            this.events.chooseCountry(x)
            setFieldsValue({
              countryName: x
            })
          }
        },

        {
          key: 'stateName',
          label: 'State Name',
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
          required: true,
          type: 'select',
          showSearch: true,
          options: this.state.allStates,
          keyAccessor: x => x.name,
          valueAccessor: x => `${x.name}`,
          onChange: x => {
            this.events.chooseState(x)
            setFieldsValue({
              stateName: x
            })
          }
        },

        {
          key: 'cityName',
          label: 'City Name',
          // hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent' && getFieldValue('countryName') == 'India'),
          required: true,
          type: 'select',
          showSearch: true,
          options: this.state.allCities,
          keyAccessor: x => x.name,
          valueAccessor: x => `${x.name}`,
          onChange: x => {
            setFieldsValue({
              cityName: x
            })
          }
        },
        {
          key: 'cityName',
          label: 'City/Town',
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent' && getFieldValue('countryName') !== 'India'),
          onChange: (e) => {
            let { value } = e.target
            this.props.form.setFieldsValue({
              cityName: value
            })
            this.events.chooseCity(value)
          }
        },

        {
          key: 'pincode',
          label: 'Pincode'
        },

        {
          key: 'logo',
          customField: (
            <InputBox title={'Logo'} className={'logoCls rowFlex'}>
              <Input type={'file'} className={'form-control'}
                     key={this.state.uploadKey} name={'logo'} id={'logo'}
                     onChange={(e) => {
                       this.setState({
                         logo: e.target.files && e.target.files.length ? e.target.files[0] : null
                       })
                     }} />
              {oldLogo && oldLogo.url ?
                <Tooltip title={oldLogo.name}> <a className={'linkBtn'} target={'_blank'}
                                                  href={oldLogo.url}>
                  <Icon type={'eye'}></Icon>
                </a> </Tooltip> : ''}
            </InputBox>
          )
        },
        {
          label: 'Joining Date',
          key: 'joiningDate',
          type: 'date'
        },
        {
          label: 'X Travel Fund Limit',
          key: 'xTravelTransferFundLimit',
          type: 'number',
          hidden: !['tarun@unizportal.com', 'rohit@unizportal.com'].includes(getFieldValue('email'))
        },
        {
          key: 'createdByUser',
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
          customField: (
            <InputBox title={'Approved By'} className={'logoCls rowFlex'}>
              <Select
                showSearch={false}

                autoClearSearchValue={true}
                allowClear={true}
                value={this.state.createdByUserIdName}
                style={{ width: '100%' }}
                showArrow={true}
                onChange={(e) => {
                  this.events.assignUserFnx(e)
                }}
                optionLabelProp='label'
                placeholder='Assign Agent To'>
                {this.state.usersForAgent && this.state.usersForAgent.length && this.state.usersForAgent.map((item, index) => {
                  return (
                    <Option key={item._id} value={item._id}
                            label={`${item.name}(${item.email})`}>
                      <div>
                        {item.name} ({item.email})
                      </div>
                    </Option>
                  )
                })}
              </Select>
            </InputBox>
          )
        },

        /* {
             key: 'marketingManager',
             hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
             customField: (
                 <InputBox title={'Manager'} className={'logoCls rowFlex'}>
                     <Select
                         showSearch={false}
                         autoClearSearchValue={true}
                         allowClear={true}
                         value={this.state.selectedMarketingManager ? this.state.selectedMarketingManager : undefined}
                         style={{width: '100%'}}
                         showArrow={true}
                         onChange={(e) => {
                             console.log(e)
                             // this.events.assignManagerAgent(e)
                             this.setState({
                                 selectedMarketingManager: e
                             })
                         }}
                         mode={'multiple'}
                         optionLabelProp='label'
                         placeholder='Assign Agent to Marketing Manager'>
                         {this.state.marketingManagerForAgent && this.state.marketingManagerForAgent.length && this.state.marketingManagerForAgent.map((item, index) => {
                             return (
                                 <Option key={item._id} value={item._id}
                                         label={`${item.name}(${item.email})`}>
                                     <div>
                                         {item.name} ({item.email})
                                     </div>
                                 </Option>
                             )
                         })}
                     </Select>
                 </InputBox>
             )
         },

         {
             key: 'marketingUser',
             hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
             customField: (
                 <InputBox title={'Marketing User'} className={'logoCls rowFlex'}>
                     <Select
                         showSearch={false}
                         autoClearSearchValue={true}
                         allowClear={true}
                         value={this.state.selectedMarketingUser ? this.state.selectedMarketingUser : undefined}
                         style={{width: '100%'}}
                         showArrow={true}
                         mode={'multiple'}
                         onChange={(e) => {
                             this.setState({
                                 selectedMarketingUser: e
                             })
                         }}
                         optionLabelProp='label'
                         placeholder='Assign Agent to Marketing User'>
                         {this.state.marketingUsersForAgent && this.state.marketingUsersForAgent.length && this.state.marketingUsersForAgent.map((item, index) => {
                             return (
                                 <Option key={item._id} value={item._id}
                                         label={`${item.name}(${item.email})`}>
                                     <div>
                                         {item.name} ({item.email})
                                     </div>
                                 </Option>
                             )
                         })}
                     </Select>
                 </InputBox>
             )
         },*/
        /* {
             label: 'Account Name',
             key: 'accountName',
             hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
             required: userData && (userData.userType === 'admin' || userData.userType === 'userManager') ? false : true
         },
         {
             label: 'Account Number',
             key: 'accountNumber',
             hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
             required: userData && (userData.userType === 'admin' || userData.userType === 'userManager') ? false : true
         },
         {
             label: 'Bank Name',
             key: 'bankName',
             hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
             required: userData && (userData.userType === 'admin' || userData.userType === 'userManager') ? false : true
         },
         {
             label: 'Bank IFSC',
             key: 'IFSC',
             hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
             required: userData && (userData.userType === 'admin' || userData.userType === 'userManager') ? false : true
         },*/
        {
          label: 'PAN No.',
          key: 'panCardNumber',
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
          required: userData && (userData.userType === 'admin' || userData.userType === 'userManager') ? false : true
        },
        {
          key: 'GSTINAvailable',
          label: 'GSTIN Available',
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
          type: 'select',
          required: userData && (userData.userType === 'admin' || userData.userType === 'userManager') ? false : true,
          keyAccessor: x => x,
          valueAccessor: x => `${x}`,
          options: ['Yes', 'No'],
          onChange: x => {
            setFieldsValue({
              GSTINAvailable: x,
              GSTIN: null
            })
          }
        },
        {
          label: 'GSTIN Number.',
          key: 'GSTIN',
          hidden: !(getFieldValue('userType') && getFieldValue('GSTINAvailable') == 'Yes' && getFieldValue('userType') == 'agent'),
          required: userData && userData.userType === 'admin' ? false : true
        },
        {
          key: 'certificate',
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
          customField: (
            <InputBox title={'Agent Certificate'} className={'logoCls rowFlex'}>
              <Input type={'file'} className={'form-control'}
                     key={this.state.uploadKey} name={'certificate'} id={'certificate'}
                     onChange={(e) => {
                       this.setState({
                         certificate: e.target.files && e.target.files.length ? e.target.files[0] : null
                       })
                     }} />
              {certificate && certificate.url ?
                <Tooltip title={certificate.name}> <a className={'linkBtn'} target={'_blank'}
                                                      href={certificate.url}>
                  <Icon type={'eye'}></Icon>
                </a> </Tooltip> : ''}
            </InputBox>
          )
        },


        {
          key: 'alternateEmails',
          label: (
            <span>
              Alternate Emails <small style={{ color: 'black', fontWeight: 'bold' }}>( Note : Use comma for multiple emails )</small>
            </span>
          ),
          placeholder: 'Alternate Emails',
          span: 24,
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent')
        },
        {
          key: 'alternateMobileNo',
          label: (
            <span>
              Staff Member Mobile No <small style={{ color: 'black', fontWeight: 'bold' }}>( Note : Use comma for multiple mobiles no )</small>
            </span>
          ),
          placeholder: 'Staff Member Mobile No',
          span: 24,
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent')
        },
        {
          key: 'address',
          span: 24,
          customField: (
            <InputBox title={'Address'}>
              <TextArea rows={4} className={'form-control'} value={this.state.address} onChange={(e) => {
                this.setState({ address: e.target.value })
              }}>
              </TextArea>
            </InputBox>
          )
        },
        {
          label: 'Assign Agent Type',
          key: 'assignAgentType',
          hidden: !(getFieldValue('userType') && (getFieldValue('userType') == 'branchUser' && getFieldValue('department') == departmentObj.loaDepartment && getFieldValue('countryId') == countryIds.canada)),
          type: 'select',
          keyAccessor: x => x.value,
          valueAccessor: x => `${x.name}`,
          options: assignAgentTypeOptions,
          onChange: v => {
            this.setState({ agents: [] })
            setFieldsValue({
              assignAgentType: v
            })
          }
        },
        {
          key: 'agentId',
          hidden: !(getFieldValue('userType') && ((getFieldValue('userType') == 'branchManager' && getFieldValue('branchManagerType') == 'agentWise')
            || (getFieldValue('userType') == 'branchUser' && getFieldValue('assignAgentType') != 'all' && getFieldValue('department') == departmentObj.loaDepartment && getFieldValue('countryId') == countryIds.canada))),
          customField: (
            <InputBox title={'Agents'} className={'logoCls'}>
              <Select
                showSearch={true}
                onSearch={debounce(this.agentEvents.fetchAgent, 500)}
                filterOption={false}
                autoClearSearchValue={true}
                allowClear={true}
                value={agentName}
                style={{ width: '100%' }}
                showArrow={true}
                onChange={(e) => {
                  this.setState({
                    agentName: e
                  })
                }}
                onSelect={(e) => {
                  this.agentEvents.chooseAgent(e)
                }}
                optionLabelProp='label'
                placeholder='Search Agent'>
                {agentList && agentList.length && agentList.map((item, index) => {
                  return (
                    <Option key={item._id} value={item._id} label={item.name}>
                      <div>
                        {item.name} ({item.companyName})
                      </div>
                    </Option>
                  )
                })}
              </Select>
            </InputBox>
          )
        },
        {
          label: 'Assign Marketing Type',
          key: 'assignMarketingType',
          hidden: !(getFieldValue('userType') && this.showAssignMarketingField()),
          required: (getFieldValue('userType') && this.showAssignMarketingField()),
          type: 'select',
          keyAccessor: x => x.value,
          valueAccessor: x => `${x.name}`,
          options: assignMarketingTypeOptions,
          onChange: v => {
            this.setState({ agents: [] })
            setFieldsValue({
              assignMarketingType: v
            })
          }
        },
        {
          label: 'Assign Marketing Users',
          key: 'assignedMarketingUsers',
          type: 'select',
          span: 8,
          showSearch: true,
          mode: 'multiple',
          required: true,
          options: marketingUserList,
          hidden: !this.conditionalFxn.assignMarketingUserRequirement(),
          valueAccessor: x => `${x.name}`,
          keyAccessor: x => x._id,
          onChange: async (v) => {
            setFieldsValue({
              assignedMarketingUsers: v
            })
          }
        }


      ]
    }


    return (
      <Drawer
        title={`Update ${UserTypeHumanizeName[this.state.userType]} - ${this.state.name}`}
        visible={visible}
        placement='right'
        closable={true}
        onClose={onClose}
        width={'80%'}>
        <div className='form-box'>
          <div className='card unizportal'>
            <Form onSubmit={this.handleSubmit}>
              <Row gutter={16} className={'wrapBox'}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <React.Fragment key={key}>
                      {item.customField ? !item.hidden && <Col span={item.span ? item.span : 8}>
                        {item.customField}
                      </Col> : !item.hidden &&
                        <Col span={item.span ? item.span : 8} key={key}
                             style={item.extraBox1 ? { paddingBottom: 1 } : {}}>
                          <InputBox title={item.label1 ? item.label1 : ''}
                                    extra={item.extraBox1}>
                            <GetEachFormFields
                              item={item}
                              getFieldDecorator={getFieldDecorator}
                              formItemLayout={formItemLayout} />
                          </InputBox>
                        </Col>}
                    </React.Fragment>
                  )
                })}

                <Col span={24}>

                </Col>

                {agents && agents.length ? <Col span={24}>
                  <Card title={'Agents'} size={'small'}>
                    <div className={'guestEmailBox'}>
                      <ul>
                        {agents.map((item, index) => {
                          return (
                            <li className={'emailTag'} key={index}>
                              {item.name} ({item.companyName})
                              <Icon type={'close'}
                                    onClick={() => this.agentEvents.removeAgent(index)}
                                    className={'emailIcon'} />
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </Card>
                </Col> : null}
              </Row>


              <Form.Item>
                <Button type='primary' htmlType='submit' loading={this.props.loading} className={'btn'}>
                  UPDATE
                </Button>
              </Form.Item>

            </Form>
          </div>
        </div>

      </Drawer>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  pathname: router.location.pathname,
  search: router.location.search
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUser)
