import { apiUrl2 } from '../../../settings'

export const getAirLineListUrl = () => {
  return apiUrl2 + '/api/air-lines'
}
export const getAirportListUrl = () => {
  return apiUrl2 + '/api/airport'
}
export const updateAirportMainStatusUrl = () => {
  return apiUrl2 + '/api/update-airport-main-status'
}
export const getNearestAirportUrl = () => {
  return apiUrl2 + '/api/nearest-airport'
}
export const addNearestAirportUrl = () => {
  return apiUrl2 + '/api/add-nearest-airport'
}
export const deleteNearestAirportUrl = () => {
  return apiUrl2 + '/api/delete-nearest-airport'
}
