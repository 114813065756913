import React, { useState, useEffect } from 'react'
import { Button, Col, Collapse, Icon, Row, Tooltip, Drawer, Input, Form, Card } from 'antd'
import moment from 'moment'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  loadFcmtApplicationDocuments,
  loadStudentApplicationDocuments,
  loadStudentDocuments
} from '../actions/chooseStudent'
import UploadDocumentComponent from './uploadDocumentComponent'
import lodash from 'lodash'
import {
  countryIds,
  DocumentTypes,
  oxfordUniversity,
  fcmtUniversity,
  KasaHighSchool, CasaHCollege, AtlantisCollege, LarnacaCollege
} from '../../../components/_utils/appUtils'
import { directUniLetterRights, fcmtUniRights } from '../../WebComponent/allowComponentRightsWise'

let statusArr = ['Conditional Offer Received', 'Unconditional Offer Received', 'Offer Received']

const initialState = {
  documentDrawerVisible: false,
  title: '',
  description: '',
  document: {},
  documentKey: moment()
}
const DocumentBlock = (props) => {
  let kasaUniList = [KasaHighSchool, CasaHCollege, AtlantisCollege, LarnacaCollege]
  const dispatch = useDispatch()
  let [state, setState] = useState(initialState)
  let [documents, setDocuments] = useState([])
  let [newStatusList, setNewStatusList] = useState([])
  let [fcmtDocuments, setFcmtDocuments] = useState([])
  let [documentTypeArr, setDocumentTypeArr] = useState([])
  let { studentId, applicationId, userType, courseUniversityId, user } = props
  useEffect(() => {
    loadDocuments()
    loadApplicationDocuments()

    loadFcmtApplicationDocumentsFxn()

  }, [])


  const loadDocuments = async () => {
    let resp = await dispatch(loadStudentDocuments({ studentId, applicationId }))
    if (resp && resp.document && resp.document.length) {
      let titlesArr = []
      let grousp = lodash.groupBy(resp.document, (item) => {
        return item.documentType
      })
      lodash.each(grousp, (item, key) => {
        if (!DocumentTypes.includes(key)) {
          titlesArr.push(key)
        }
      })
      let documentTypeArr = [...DocumentTypes, ...titlesArr]
      if (courseUniversityId == oxfordUniversity) {
        documentTypeArr = lodash.reject(documentTypeArr, (item) => {
          return item == 'Other'
        })
      }
      setDocumentTypeArr(documentTypeArr)
      setDocuments(resp.document)
    }
  }

  const loadApplicationDocuments = async () => {
    let resp = await dispatch(loadStudentApplicationDocuments({ studentId, applicationId }))
    if (resp && resp.applications && resp.applications.appDocuments) {
      let { appDocuments, universityCountry } = resp.applications
      let findDocuments = lodash.filter(appDocuments, (item) => {
        return (item.status == 'Conditional Offer Received' || item.status == 'Unconditional Offer Received' || item.status == 'Offer Received')
      })
      if (universityCountry == countryIds.canada) {
        let feeReceipt = lodash.filter(appDocuments, (item) => {
          return (item.status == 'LOA/Fee Receipt Issued')
        })
        if (feeReceipt && feeReceipt.length) {
          findDocuments = [...findDocuments, ...feeReceipt]
        }
        statusArr.push('LOA/Fee Receipt Issued')
      }
      setNewStatusList(findDocuments)
    }
  }

  const loadFcmtApplicationDocumentsFxn = async () => {
    let resp = await dispatch(loadFcmtApplicationDocuments({ studentId, applicationId }))
    let { applications } = resp
    if (applications && applications.loaDocument) {
      let fcmtDocuments = []
      lodash.each(applications.loaDocument, (item, key) => {
        if (item) {
          fcmtDocuments.push({
            name: item.status,
            url: item.url
          })
        }
      })
      if (kasaUniList.includes(applications.courseUniversity)) {
        if (applications.offerReceiveLetter && applications.offerReceiveLetter.path) {
          fcmtDocuments.push({
            name: 'Offer Letter',
            url: applications.offerReceiveLetter.path
          })
        }
      } else {
        if (directUniLetterRights.offerLetter()) {
          if (applications.offerReceiveLetter && applications.offerReceiveLetter.path) {
            fcmtDocuments.push({
              name: 'Offer Letter',
              url: applications.offerReceiveLetter.path
            })
          }
        }
      }

      setFcmtDocuments(fcmtDocuments)
    }
  }


  const resp = useSelector(state => {
    let { loadDocumentRedux, loadApplicationDocumentsRedux } = state.chooseApplication
    if (loadDocumentRedux) {
      loadDocuments()
      dispatch({ type: 'END_LOAD_DOCUMENT' })
    }

  })

  const getDocumentObj = (documentsArr, name) => {
    let findObj = lodash.filter(documentsArr, (item) => {
      return item.documentType == name
    })
    findObj = lodash.sortBy(findObj, (item) => {
      return new Date(item.date)
    }).reverse()
    return findObj && findObj.length ? findObj : null
  }
  const getAppDocumentObj = (documentsArr, name) => {
    let findObj = lodash.filter(documentsArr, (item) => {
      return item.status == name
    })
    findObj = lodash.sortBy(findObj, (item) => {
      return new Date(item.date)
    }).reverse()
    return findObj && findObj.length ? findObj : null
  }


  const documentSubName = (name) => {
    let exp = name.split('.').pop()
    let nameVal = name.split('.')[0]
    if (nameVal.length > 10) {
      nameVal = `${nameVal.substring(0, 10)}[...].${exp}`
      return nameVal
    } else {
      return name
    }
  }

  const events = {
    showDrawer: (title) => {
      setState({ ...state, documentDrawerVisible: true, title })
    },
    hideDrawer: () => {
      setState({ ...state, documentDrawerVisible: false })
    }
  }

  let appDocumentComponent = (
    statusArr.map((item, key) => {
      let documentArr = getAppDocumentObj(newStatusList, item)
      return (
        documentArr && documentArr.length ? documentArr.map((item) => {
          return (<li key={key + 1}>
            <div className={item.oldDoc ? 'oldDoc' : ''}>
              {item.status}
            </div>

            <div className='action'>
              <a download={item.status} href={item.url}>
                <button className='btn'><img src='/dist/img/view.svg' alt='' /></button>
              </a>
            </div>
          </li>)
        }) : null
      )
    })
  )

  let fcmtDocumentComponent = (
    fcmtDocuments.map((item, key) => {
      return (
        <li key={key + 1}>
          <div className={item.oldDoc ? 'oldDoc' : ''}>
            {item.name}
          </div>

          <div className='action'>
            <a download={item.name} href={item.url}>
              <button className='btn'><img src='/dist/img/view.svg' alt='' />
              </button>
            </a>
          </div>
        </li>
      )
    })
  )

  let showAppDocsFxn = () => {
    if (courseUniversityId == fcmtUniversity) {
      if (fcmtUniRights.loaRights(user)) {
        return fcmtDocumentComponent
      } else {
        return null
      }
    } else if (kasaUniList.includes(courseUniversityId)) {
      return fcmtDocumentComponent
    } else if (courseUniversityId == oxfordUniversity) {
      return null
    } else {
      return appDocumentComponent
    }

    /*courseUniversityId == fcmtUniversity ? fcmtUniRights.loaRights(user) ? fcmtDocumentComponent : null :
        courseUniversityId == oxfordUniversity ? null : appDocumentComponent*/
  }

  return (
    <React.Fragment>
      <div className='card'>
        <div className='table-head d-flex align-items-center'>
          <h5>Documents</h5>
          <div className='sort-box-table mark-btn ml-auto'>
            {
              userType !== 'student' ?
                <button className='btn' onClick={() => {
                  events.showDrawer('Other')
                }}> Upload New Document
                </button> : ''
            }
          </div>
        </div>
        <div className='card-body table-responsive'>
          <ul>
            {documentTypeArr && documentTypeArr.length ? documentTypeArr.map((item, key) => {
              let documentArr = getDocumentObj(documents, item)
              return (
                documentArr && documentArr.length ? documentArr.map((item) => {
                  return (
                    <li key={key + 1}>
                      <div className={documentArr.oldDoc ? 'oldDoc' : ''}>
                        {item.documentType}
                      </div>

                      <div className='action'>
                        <a download={item.name} href={item.url}>
                          <button className='btn'><img src='/dist/img/view.svg' alt='' />
                          </button>
                        </a>
                      </div>
                    </li>
                  )
                }) : null
              )
            }) : null}

            {courseUniversityId == fcmtUniversity ?
              <>
                {fcmtUniRights.loaRights(user) ? appDocumentComponent : null}
              </> :
              <>
                {appDocumentComponent}
              </>
            }

            {showAppDocsFxn()}

            {/*             {courseUniversityId == fcmtUniversity ? fcmtUniRights.loaRights(user) ? fcmtDocumentComponent : null :
                            courseUniversityId == oxfordUniversity ? null : appDocumentComponent}*/}

          </ul>
        </div>
      </div>


      {state.documentDrawerVisible ?
        <UploadDocumentComponent visible={state.documentDrawerVisible}
                                 page={'document'}
                                 studentId={studentId} applicationId={applicationId}
                                 onClose={events.hideDrawer} /> : ''}

    </React.Fragment>
  )
}


const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedComponent = Form.create()(DocumentBlock)
export default connect(
  null,
  mapDispatchToProps
)(WrappedComponent)

