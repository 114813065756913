import { Icon, Tooltip } from 'antd'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useDispatch } from 'react-redux'
import {
  departmentObj, onshoreCanadaUniversityListForMarketing, FcmtCollegeOnshore, adminRightUser
} from '../../../components/_utils/appUtils'
import { getUrlPushWrapper } from '../../../routes/routes'
import CambieUniversity from '../../evaluateProfile/cambie/cambieUniversity'
import FlairUniversity from '../../evaluateProfile/fcmt/flairUniversity'
import { canadaUniversityRecordsCountFxn } from '../../reporting/actions'
import DirectUniversityComponent from '../drawers/directUniversityComponent'

const ButtonLink = props => {
  let {
    searchData,
    item,
    record,
    userData,
    applyForCourse,
    openReport,
    agent = {},
    uniId
  } = props
  const btnA = (
    <li className={'canadaDirectUniCount canadaDirectUniBox'} key={item.id}>
      <div className={'innerBox'}>
        <div className={'uniCountBox'} onClick={() => searchData(item)}>
          <LazyLoadImage src={item.image} />
          <h5 className={'record-count'}>{record.total ? record.total : 0}</h5>
        </div>
        <div className={'btnBox'}>
          <a
            onClick={() => {
              applyForCourse(item.id)
            }}>
            <Icon type={'plus-circle'} size={13} /> Add Student
          </a>

          {item.file ? (
            <Tooltip title={'Download Prospectus'}>
              <a href={item.file} download={item.file} target={'_blank'}>
                <img
                  src={'../assets/icons/downloadIcon.png'}
                  style={{ height: 20 }}
                />
              </a>
            </Tooltip>
          ) : null}
        </div>
      </div>
    </li>
  )
  let branchUserCheck = () => {
    let checkUniversityExists = _.find(userData.universities, item1 => {
      return item1.toString() == item.id
    })
    return userData.department == 'Visa Approved Department' ||
    userData.department == departmentObj.marketing
      ? btnA
      : checkUniversityExists
        ? btnA
        : null
  }
  return (
    <>
      {[...adminRightUser, 'canadaOnshoreAdmin'].includes(userData.userType) ||
      userData.userType == 'agent' ||
      userData.userType == 'subAgent'
        ? btnA
        : null}
      {userData.userType == 'branchManager'
        ? btnA
        : null}
      {userData.userType == 'branchUser' ? branchUserCheck() : null}
    </>
  )
}

const CanadaOnshoreUniRecordCount = props => {
  let { currentUserRights, directUniversityRecords, userData } = props
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  let [countRecords, setCountRecords] = useState({})
  let [universityListForMarketingList, setUniversityListForMarketingList] = useState(onshoreCanadaUniversityListForMarketing)
  let dispatch = useDispatch()

  let [fcmtApplicationDrawer, setFcmtApplicationDrawer] = useState({
    visible: false,
    universityId: ''
  })
  let [cambieApplicationDrawer, setCambieApplicationDrawer] = useState({
    visible: false,
    universityId: ''
  })

  let [directApplicationDrawer, setDirectApplicationDrawer] = useState({
    visible: false,
    universityId: ''
  })

  useEffect(() => {
  }, [])

  let loadDirectCount = async () => {
    let { data, user } = await dispatch(canadaUniversityRecordsCountFxn())
    setCountRecords(data)
  }

  let searchData = (item, type = '') => {
    if (item.id == FcmtCollegeOnshore) {
      dispatch(
        getUrlPushWrapper('application.fcmtOnshoreApplicationList', {
          appUniversityId: FcmtCollegeOnshore
        })
      )
    }
  }

  let openReport = (item, type = '') => {
    dispatch(
      getUrlPushWrapper('canadaDirectUniApp', {
        appUniversityId: item.id,
        type
      })
    )
  }

  const events = {
    showFcmtApplicationDrawer: universityId => {
      setFcmtApplicationDrawer({
        visible: true,
        universityId: universityId
      })
    },
    hideFcmtApplicationDrawer: () => {
      setFcmtApplicationDrawer({
        visible: false,
        universityId: ''
      })
      loadDirectCount()
    },

    showDirectApplicationDrawer: universityId => {
      setDirectApplicationDrawer({
        visible: true,
        universityId: universityId
      })
    },
    hideDirectApplicationDrawer: () => {
      setDirectApplicationDrawer({
        visible: false,
        universityId: ''
      })
      loadDirectCount()
    },
    applyForCourse: universityId => {
      if (universityId == FcmtCollegeOnshore) {
        events.showFcmtApplicationDrawer(universityId)
      }
    }
  }

  return (
    <>
      <div className='card-pane-new listing-section w-100 direct-uni-box'
           style={{ border: 'none' }}>
        <ul className={'count flex-wrap'}>
          {onshoreCanadaUniversityListForMarketing.map(item => {
            return (
              <ButtonLink
                key={item.id}
                uniId={item.id}
                {...props}
                item={item}
                userData={userData}
                record={directUniversityRecords[item.id] ? directUniversityRecords[item.id] : {}}
                searchData={searchData}
                openReport={openReport}
                applyForCourse={events.applyForCourse}
              />
            )
          })}


        </ul>
      </div>


      {fcmtApplicationDrawer && fcmtApplicationDrawer.visible ? (
        <FlairUniversity
          visible={fcmtApplicationDrawer.visible}
          selectedUniversity={fcmtApplicationDrawer.universityId}
          onClose={events.hideFcmtApplicationDrawer}
          {...props}
        />
      ) : null}

      {cambieApplicationDrawer && cambieApplicationDrawer.visible ? (
        <CambieUniversity
          visible={cambieApplicationDrawer.visible}
          selectedUniversity={cambieApplicationDrawer.universityId}
          onClose={events.hideCambieApplicationDrawer}
          {...props}
        />
      ) : null}

      {directApplicationDrawer &&
      directApplicationDrawer.visible &&
      directApplicationDrawer.universityId ? (
        <DirectUniversityComponent
          visible={directApplicationDrawer.visible}
          selectedUniversity={directApplicationDrawer.universityId}
          onClose={events.hideDirectApplicationDrawer}
          {...props}
        />
      ) : null}
    </>
  )
}

export default CanadaOnshoreUniRecordCount
