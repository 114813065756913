import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import {
  addStudentInsuranceUrl, getNibPriceUrl,
  getStudentInsuranceUrl,
  updateInsuranceStatusUrl,
  uploadInsuranceUrl
} from '../apis/apis'
import { notification } from 'antd'


export const addStudentInsurance = (valData) => async (dispatch) => {
  dispatch(showPageLoad())

  let { data } = await axios.post(addStudentInsuranceUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getInsuranceList = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...await getToken()
  }
  let { data } = await axios.get(getStudentInsuranceUrl(), config)
  dispatch(hidePageLoad())
  return data.data
}

export const updateInsuranceStatus = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateInsuranceStatusUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const uploadInsurancefile = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(uploadInsuranceUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


export const getNibPriceFxn = (valData) => async (dispatch) => {
  if (valData.showLoader) {
    dispatch(showPageLoad())
  }
  let { data } = await axios.post(getNibPriceUrl(), valData, getToken())
  dispatch(hidePageLoad())
  /*  if (!data.error) {
      notification.success({
        message: data.message || 'Success'
      })
    } else {
      notification.error({
        message: data.message || 'Error'
      })
    }*/
  return data
}


