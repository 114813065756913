import React, { useState, useEffect, useCallback, useRef } from 'react'
import _ from 'lodash'
import { Button, Col, Drawer, Form, Input, notification, Popconfirm, Row, Select, Spin } from 'antd'
import { useDispatch } from 'react-redux'
import { addNearestAirportFxn, getNearestAirportFxn, deleteNearestAirportFxn } from '../actions/backAction'
import InputBox from '../../../components/_utils/InputBox'
import AirportTypeheadComponent from './airportTypehead'
import { TableComp } from 'sz-react-utils-lite'

let initState = {
  airportId: '',
  details: ''
}

const NearestAirportList = (props) => {
  const tableRef = useRef()

  let { visible, onClose, airportId, airportName, airportCode } = props
  let [nearestAirports, setNearestAirports] = useState([])
  let [state, setState] = useState(initState)
  let dispatch = useDispatch()
  const events = {
    loadNearestAirport: async () => {
      let resp = await dispatch(getNearestAirportFxn({ airportId }))
      setNearestAirports(resp)
    },
    handleSubmit: async () => {
      if (!state.airportId) {
        notification.warn({ message: 'Please chose airport.' })
        return
      }
      let resp = await dispatch(addNearestAirportFxn({
        ...state,
        nearestAirportId: state.airportId,
        airportId
      }))
      if (resp && resp.success) {
        events._update(initState)
        events.loadNearestAirport()
      }
    },
    _update: (data) => {
      setState((prevState) => {
        return {
          ...prevState,
          ...data
        }
      })
    },
    deleteNearestEvent: async (data) => {
      let resp = await dispatch(deleteNearestAirportFxn({ airportId, nearestAirportId: data.airportId }))
      if (resp && resp.success) {
        events.loadNearestAirport()
      }
    }
  }
  useEffect(() => {
    events.loadNearestAirport()
  }, [])
  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 80,
      key: 'sno',
      render: (value, item, index) => index + 1
    },
    {
      title: 'Country Name',
      dataIndex: 'countryName',
      key: 'countryName',
      width: 220,
      render: (item, record) => {
        return (
          <>
            {item}<br />
            ({record.countryCode})
          </>
        )
      }
    },

    {
      title: 'City Name',
      dataIndex: 'cityName',
      key: 'cityName',
      width: 220,
      render: (item, record) => {
        return (
          <>
            {item}<br />
            ({record.cityCode})
          </>
        )
      }
    },

    {
      title: 'Airport',
      dataIndex: 'airportName',
      key: 'airportName',
      width: 220,
      render: (item, record) => {
        return (
          <>
            {item}<br />
            ({record.airportCode})
          </>
        )
      }
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 100,
      render: (item, record) => {
        return (
          <>
            <Popconfirm title={'Are you sure, you want to delete this record?'} onConfirm={() => {
              events.deleteNearestEvent(record)
            }}>
              <a className={'btn btn-default roundNew xs'}>Delete</a>
            </Popconfirm>
          </>
        )
      }
    }
  ]

  return (
    <Drawer
      visible={visible}
      title={`${airportName} (${airportCode}) - Nearest Airports`}
      onClose={onClose}
      width={'60%'}>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='form-box'>
            <div className='card unizportal'>
              <Row gutter={24}>
                <Col span={10}>
                  <InputBox title={'Choose Airport'}>
                    <AirportTypeheadComponent
                      key={state.airportId}
                      airportId={state.airportId}
                      mainAirportId={airportId}
                      customStyle={''}
                      dispatch={dispatch}
                      onSelect={item => {
                        events._update({ airportId: item })
                      }}
                    />
                  </InputBox>
                </Col>
                <Col span={10}>
                  <InputBox title={'Enter details'}>
                    <Input
                      className={'form-control'}
                      placeholder={'Enter details'}
                      value={state.details}
                      onChange={({ target }) => {
                        events._update({ details: target.value })
                      }} />
                  </InputBox>
                </Col>
                <Col span={4}>
                  <a className={'btn btn-success roundNew lg mt30'} onClick={events.handleSubmit}>
                    Submit
                  </a>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        <div className={'col-md-12'}>
          <div className='card'>
            <div className='table-head d-flex justify-content-between all-student-search'>
              <h5>Nearest Airports - <small>(Total : {nearestAirports.length || 0})</small></h5>
              <div className='sort-box-table mark-btn text-right'>
              </div>
            </div>
            <div className='card-body table-responsive'>
              <TableComp
                columns={columns}
                className={'table table-striped table-valign-middle'}
                dataSource={nearestAirports}
                pagination={false}
                ref={tableRef}
              />
            </div>
          </div>
        </div>

      </div>

    </Drawer>
  )
}
export default NearestAirportList
