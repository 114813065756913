import React, { useEffect, useState } from 'react'
import {
  Form,
  Steps,
  Row,
  Col,
  Input,
  Select,
  notification
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import {
  qualificationOptions,
  DocumentTypesForFcmt,
  filterOption,
  fcmtUniversity,
  AvalonUniversity,
  directUniversityList,
  CampBellUniversity,
  countryIds,
  KasaHighSchool,
  onShoreOptions, FcmtCollegeOnshore, ConditionOptions
} from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { listAllCountries } from '../../countries/actions/countries'
import {
  addStudent,
  checkBranchUserExists,
  getStudent,
  searchedStudent,
  updateStudent
} from '../../student/actions/student'
import _ from 'lodash'
import moment from 'moment'
import { InputBox } from '../../../components/_utils/appUtils'
import { CountryJson, LoadState, LoadCity } from '../../../components/_utils/countryUtil'

import debounce from 'lodash/debounce'
import { addCourseInStudent } from '../../applications/actions/chooseStudent'
import { listCourseCampus } from '../../campus/actions/campus'
import { listAllCourse, loadCourseIntakesFxn } from '../../course/actions/courseAction'
import { listAllUsers } from '../../users/actions/user'
import { AllowComponentRightsWise } from '../../WebComponent/allowComponentRightsWise'
import { FcmtUniversity } from '../../dashboard/views/adminDashboard'
import AgentTypeheadComponent from '../../../components/_utils/AgentTypeheadComponent'

const { Option } = Select

const { Step } = Steps

let initialState = {
  currentStep: 0,
  fileList: [],
  uploadKey: moment(),
  allCountries: CountryJson,
  allStates: [],
  allCities: [],
  countryName: '',
  stateName: '',
  cityName: '',
  studentList: [],
  studentId: '',
  dateOfBirth: '',
  addStuDrawer: false
}

const AddFcmtStudent = (props) => {
  const {
    form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields },
    onClose,
    pageType,
    // coursesList,
    selectedUniversity,
    currentUserRights
  } = props
  const dispatch = useDispatch()
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

  _.each(DocumentTypesForFcmt, (item) => {
    initialState[item.name] = ''
  })

  const [state, setState] = useState(initialState)
  const { allCountries, allStates, allCities, studentId } = state
  const [visibleChooseCourse, setVisibleChooseCourse] = useState(false)
  const [application, setApplication] = useState({})
  const [courseId, setCourseId] = useState('')
  const [intakesList, setIntakesList] = useState([])
  const [campusList, setCampusList] = useState([])
  const [intake, setIntake] = useState({})
  const [campusId, setCampusId] = useState('')
  const [intakeTemp, setIntakeTemp] = useState('')
  const [agentList, setAgentList] = useState([])
  const [agentId, setAgentId] = useState('')
  let [coursesList, setCoursesList] = useState([])
  let [universityId, setUniversityId] = useState(selectedUniversity)
  let [campBellFields, setCampBellFields] = useState(false)
  let [kasaFields, setKasaFields] = useState(false)


  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const setDefaultCountry = () => {
    let defaultCountry = 'India'
    props.form.setFieldsValue({
      countryName: defaultCountry
      // studentShore: 'OffShore'
    })
    events.chooseCountry(defaultCountry)
  }

  let formFields = {
    generateInputs: [
      {
        key: 'studentCurrentStatus',
        span: 8,
        label: 'Student Type',
        placeholder: 'Student Type',
        type: 'select',
        options: ['New', 'Old'],
        required: true,
        hidden: selectedUniversity !== FcmtCollegeOnshore,
        keyAccessor: x => x,
        valueAccessor: x => `${x}`,
        onChange: x => {
          props.form.setFieldsValue({
            studentCurrentStatus: x
          })
        }
      },
      { key: 'name', label: 'Student Name', required: true, placeholder: 'Enter Name', disabled: studentId },
      /*{
          key: 'studentShore',
          label: 'Student Shore',
          type: 'select',
          showSearch: true,
          hidden: !(selectedUniversity == fcmtUniversity),
          options: onShoreOptions,
          keyAccessor: x => x.value,
          valueAccessor: x => x.name,
          onChange: x => {
              props.form.setFieldsValue({
                  studentShore: x
              })
          }
      },*/
      {
        key: 'dateOfBirth',
        label: 'Date Of Birth',
        disabled: studentId,
        required: true,
        type: 'date',
        placeholder: 'Choose Date of Birth'
      },
      {
        key: 'mobile',
        label: 'Phone',
        required: true,
        type: 'number',
        placeholder: 'Enter Phone No',
        disabled: studentId
      }
      /*  {
          key: 'gender', label: 'Gender',
          type: 'select',
          disabled: studentId,
          options: ['Male', 'Female'],
          required: true, onChange: x => {
            props.form.setFieldsValue({
              gender: x
            })
          }
        }*/

    ],
    addressInput: [
      {
        key: 'address',
        label: 'Address',
        required: true,
        span: 8,
        placeholder: 'Enter Address',
        disabled: studentId
      },
      {
        key: 'countryName',
        label: 'Country',
        required: true,
        disabled: studentId,
        type: 'select',
        showSearch: true,
        options: allCountries,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            countryName: x
          })
          events.chooseCountry(x)
        }
      },
      {
        key: 'stateName',
        label: 'Province/State',
        required: true,
        disabled: studentId,
        type: 'select',
        showSearch: true,
        options: allStates,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            stateName: x
          })
          events.chooseState(x)
        }
      },
      {
        key: 'cityName',
        label: 'City/Town',
        required: true,
        disabled: studentId,
        onChange: x => {
          props.form.setFieldsValue({
            cityName: x.target.value
          })
          events.chooseCity(x.target.value)
        }
      },
      {
        key: 'postalCode',
        label: 'Postal/Zip Code',
        // type: 'number',
        placeholder: 'Postal/Zip Code',
        required: true,
        disabled: studentId
      },
      {
        key: 'email',
        label: 'Email',
        type: 'email',
        placeholder: 'Enter Email',
        disabled: studentId,
        required: true
      }

    ],
    educationInput: [
      {
        key: 'education.qualification',
        label: 'Qualification',
        type: 'select',
        required: true,
        disabled: studentId,
        options: qualificationOptions,
        onChange: x => {
          let obj = {
            qualification: x,
            passingYear: '',
            percentage: '',
            maths: '',
            english: ''
          }
          props.form.setFieldsValue({
            education: obj
          })
        },
        span: 8
      }
    ],
    educationInputField: [
      {
        key: 'education.passingYear',
        label: 'Passing Year',
        type: 'number',
        span: 6,
        required: true,
        disabled: studentId
      },
      {
        key: 'education.percentage',
        label: 'Percentage',
        type: 'number',
        span: 6,
        disabled: studentId,
        required: true
      },
      {
        key: 'education.maths',
        label: 'Math Score',
        type: 'number',
        span: 6,
        disabled: studentId,
        hidden: props.form.getFieldValue('education.qualification') !== 'XII',
        required: props.form.getFieldValue('education.qualification') == 'XII'
      },
      {
        key: 'education.english',
        label: 'English Score',
        type: 'number',
        span: 6,
        disabled: studentId,
        hidden: props.form.getFieldValue('education.qualification') !== 'XII',
        required: props.form.getFieldValue('education.qualification') == 'XII'
      }
    ],
    campBellFields: [
      {
        key: 'familyName',
        label: 'Family Name',
        span: 8,
        hidden: !campBellFields,
        required: campBellFields
      },
      {
        key: 'gender',
        label: 'Gender',
        type: 'select',
        options: ['Male', 'Female'],
        required: true, onChange: x => {
          props.form.setFieldsValue({
            gender: x
          })
        }
      },
      {
        key: 'passportNumber',
        label: 'Passport Number',
        span: 8,
        hidden: !campBellFields,
        required: campBellFields
      },
      {
        key: 'passportCountry',
        label: 'Passport Country',
        hidden: !campBellFields,
        required: campBellFields,
        type: 'select',
        showSearch: true,
        options: allCountries,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            passportCountry: x
          })
        }
      },
      {
        key: 'passportExpiry',
        label: 'Passport Expiry',
        span: 8,
        type: 'date',
        hidden: !campBellFields,
        required: campBellFields
      }
    ],
    KasaHighSchoolFields: [
      {
        key: 'passportNumber',
        label: 'Passport Number',
        span: 8,
        hidden: !kasaFields,
        required: kasaFields
      },
      {
        key: 'passportCountry',
        label: 'Passport Country',
        hidden: !kasaFields,
        required: kasaFields,
        type: 'select',
        showSearch: true,
        options: allCountries,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            passportCountry: x
          })
        }
      }
    ]


  }

  useEffect(() => {
    dispatch({ type: 'STUDENT_HIDE_LOADER' })
    dispatch(listAllCountries({ results: 1000000 }))
    if (selectedUniversity == CampBellUniversity) {
      setCampBellFields(true)
    }
    if (selectedUniversity == KasaHighSchool) {
      setKasaFields(true)
    }
    setDefaultCountry()
  }, [])

  const loadAllAgent = async (selectedCourse) => {
    let filters = {
      userType: 'agent',
      select: ['name', 'companyName'],
      block: false,
      results: 1000000
    }
    // if australia university then get only show australia university allow user list
    if (selectedCourse && selectedCourse.universityCountry && selectedCourse.universityCountry.toString() == countryIds.australia) {
      filters.showAustraliaUniversity = true
    }

    let { data } = await dispatch(listAllUsers(filters))
    setAgentList(data)
  }

  const events = {
    chooseDocument: (e) => {
      let { name, files } = e.target
      if (files && files.length) {
        setState({
          ...state,
          [name]: files[0]
        })
      }
    },
    getApplication: async () => {
      return new Promise(async (resolve, reject) => {
        let { selectedCourse } = props
        let valData = {}
        valData.courseUniversity = selectedCourse.courseUniversity
        valData.applicationFee = selectedCourse.applicationFee
        valData.universityCountry = selectedCourse.universityCountry
        valData.universityCity = selectedCourse.universityCity
        valData.universityState = selectedCourse.universityState
        valData.tuitionFee = selectedCourse.tuitionFee
        valData.courseId = selectedCourse.courseId
        valData.courseName = selectedCourse.courseName
        let { success, message } = await dispatch(checkBranchUserExists(valData))
        if (success) {
          resolve({ type: true, application: valData })
        } else {
          resolve({ type: false, message })
        }
      })
    },
    handleSubmit: (e) => {
      const { form, selectedCourse } = props
      let { userType } = user
      e.preventDefault()
      form.validateFieldsAndScroll(async (err, valData) => {
        if (!err) {
          let { countryName, stateName, cityName } = state
          if (!application || (application && !application.courseId)) {
            notification.warning({
              message: 'Choose course.'
            })
            return
          }
          if (!campusId) {
            notification.warning({
              message: 'Choose Campus.'
            })
            return
          }
          if (!intake || (intake && !intake.month)) {
            notification.warning({
              message: 'Choose Intake.'
            })
            return
          }
          if (userType == 'admin' || userType == 'branchManager' || userType == 'branchUser') {
            if (!agentId) {
              notification.warning({
                message: 'Choose Agent.'
              })
              return
            }
          }

          if (valData.familyName) {
            application.familyName = valData.familyName
          }

          application.campusId = campusId
          application.intake = intake

          let obj = {
            ...application, studentId, showNotification: true
          }
          if (agentId) {
            obj.agentId = agentId
          }


          if (studentId) {
            if (selectedUniversity == CampBellUniversity || selectedUniversity == KasaHighSchool) {
              let studentObj = {
                gender: valData.gender,
                passportNumber: valData.passportNumber,
                passportExpiry: valData.passportExpiry,
                passportCountry: valData.passportCountry
              }
              let data1 = await dispatch(updateStudent(studentObj, studentId))
              if (!data1.error) {
                let data = await dispatch(addCourseInStudent(obj))
                if (data && !data.error) {
                  onClose()
                }
              }

            } else {
              let data = await dispatch(addCourseInStudent(obj))
              if (data && !data.error) {
                onClose()
              }
            }
          } else {

            if (selectedUniversity == fcmtUniversity) {
              let findDoc = _.find(DocumentTypesForFcmt, (item) => {
                if (item.required && !state[item.name]) {
                  if (!(item.restrict && valData.countryName !== 'India')) {
                    return true
                  }
                }
              })
              if (findDoc) {
                notification.warning({
                  message: `Choose ${findDoc.name} document.`
                })
                return
              }
            }

            valData.application = [application]
            if (state.fileList && state.fileList.length) {
              valData.document = state.fileList
            }
            valData = { ...valData, countryName, stateName, cityName }
            if (agentId) {
              valData.agentId = agentId
            }
            let fd = new FormData()
            fd.append('obj', JSON.stringify(valData))
            _.each(DocumentTypesForFcmt, (item) => {
              if (state[item.name]) {
                fd.append([item.name], state[item.name])
              }
            })
            let data = await dispatch(addStudent(fd, true))
            if (data && !data.error) {
              form.resetFields()
              _.each(DocumentTypesForFcmt, (item) => {
                initialState[item.name] = ''
              })
              setState({
                ...initialState,
                uploadKey: moment()
              })
              setApplication({})
              onClose()
            }
          }
        } else {
          notification.warning({
            message: 'Fill All Required Fields'
          })
        }
      })
    },
    uploadDocument: (v) => {
      setState({
        ...state,
        fileList: v.fileList
      })
    },
    chooseCountry: (name) => {
      let countryName = _.find(allCountries, (item) => {
        return item.name == name
      })
      if (countryName) {
        setState({
          ...state,
          countryName: countryName.name ? countryName.name : '',
          allStates: countryName.id ? LoadState(countryName.id) : []
        })
      }
    },
    chooseState: (name) => {
      let stateName = _.find(allStates, (item) => {
        return item.name == name
      })
      if (stateName) {
        setState({
          ...state,
          stateName: stateName.name ? stateName.name : ''
          // allCities: stateName.id ? LoadCity(stateName.id) : []
        })
      }
    },
    chooseCity: (name) => {
      setState({
        ...state,
        cityName: name ? name : ''
      })
    },
    showAddApp: () => {
      setVisibleChooseCourse(true)
    },
    loadCampus: async (courseId) => {
      if (courseId) {
        let obj = {
          results: 1000000,
          courseId
        }
        let { data } = await dispatch(listCourseCampus(obj))
        setCampusList(data)
        if (data && data.length) {
          setCampusId(data[0]._id)
        }
      } else {
        setCampusId('')
        setCampusList([])
      }
    },
    loadIntakes: async (courseId) => {
      if (courseId) {
        let resp = await dispatch(loadCourseIntakesFxn({ courseId }))
        console.log('------------------', resp, '=======')
        let data = []
        _.each(resp, (item) => {
          if (item) {
            item.value = `${item.intake}-${item.year}`
            item.label = `${item.intake}, ${item.year}`
            data.push(item)
          }
        })
        setIntakesList(data)
        if (resp && resp.length) {
          setIntakeTemp(data[0].value)
          let intake = {
            month: resp[0].intake,
            year: resp[0].year
          }
          setIntake(intake)
        }
      } else {
        setIntakesList([])
        setIntake({})
        setIntakeTemp('')

      }
    },
    chooseIntake: (value) => {
      setIntakeTemp(value)
      let intakeVal = value.split('-')
      let intake = {
        month: intakeVal[0],
        year: intakeVal[1]
      }
      setIntake(intake)
    }
  }


  useEffect(() => {
    let header = document.getElementById('header')
    loadCourse(universityId)
  }, [])

  const loadCourse = async (id) => {
    let params = {
      courseUniversity: id,
      results: 1000
    }
    let { data } = await dispatch(listAllCourse(params))
    setCoursesList(data)
    // setCourseCount(courseCount)
  }

  const chooseCourseFxn = async (selectedCourseId) => {
    let selectedCourse = _.find(coursesList, (item) => {
      return item._id.toString() == selectedCourseId
    })
    if (selectedCourse) {
      let valData = {}
      valData.applicationFee = selectedCourse.courseUniversity.applicationFee
      valData.courseUniversity = selectedCourse.courseUniversity._id
      valData.universityCountry = selectedCourse.universityCountry._id
      valData.universityCity = selectedCourse.universityCity
      valData.universityState = selectedCourse.universityState
      valData.tuitionFee = selectedCourse.tuitionFee
      valData.courseId = selectedCourse._id
      valData._id = selectedCourse._id
      valData.courseName = selectedCourse.courseName

      let { success, message } = await dispatch(checkBranchUserExists(valData))
      if (success) {
        // loadAllAgent(valData)

        setApplication(valData)
        setCourseId(selectedCourse._id)
        events.loadCampus(selectedCourse._id)
        events.loadIntakes(selectedCourse._id)
      } else {
        setApplication({})
        notification.error({ message })
      }
    } else {
      setApplication({})
      setIntake({})
      setIntakeTemp('')
      setCampusId('')
      notification.error({ message: 'Course not found.' })
    }
  }

  const fetchStudent = async (value) => {
    let studentList = await searchedStudent({ name: value })
    setState({
      ...state,
      studentList
    })
  }

  const chooseStudentFxn = async (id) => {
    if (id) {
      let data = await dispatch(getStudent(id))
      if (data) {
        setTimeout(() => {
          let obj = {}
          _.each(data, (item, key) => {
            obj[key] = item
          })
          if (obj.dateOfBirth) {
            obj.dateOfBirth = moment(obj.dateOfBirth)
          }
          if (obj.passportExpiry) {
            obj.passportExpiry = moment(obj.passportExpiry)
          }
          if (obj.countryName) {
            events.chooseCountry(obj.countryName)
          }
          if (obj.stateName) {
            events.chooseState(obj.stateName)
          }

          setFieldsValue({ ...obj, email: obj.email ? obj.email : '' })
          setTimeout(() => {
            setFieldsValue({ education: obj.education })
          }, 500)
          setState({
            ...state,
            studentId: data._id,
            documents: obj.document,
            countryName: obj.countryName,
            stateName: obj.stateName,
            cityName: obj.cityName
          })
        }, 500)
      } else {
        resetForm()
      }
    } else {
      resetForm()
    }


  }

  const resetForm = () => {
    setTimeout(() => {
      let obj = {}
      setFieldsValue({ ...obj, email: '', name: '', dateOfBirth: '', mobile: '' })
      setTimeout(() => {
        setFieldsValue({ education: {} })
      }, 500)
      setState({
        ...state,
        studentId: '',
        documents: '',
        countryName: '',
        stateName: '',
        cityName: ''
      })
    }, 500)
  }

  return (
    <div>
      <div className='row'>
        <div className='col-lg-12'>
          <div>
            <Form className={'vertical-form'} autoComplete='off'>
              <div className='form-box'>
                <div className='heading-form stuHeader  d-flex align-items-center mt-2 mb-3'
                     id={'generalInformation'}>
                  <h5 className={'addStuInfo'}>
                    <Row gutter={16} className={'row1 rowWrap'}>
                      <Col span={10}>
                                                <span className='img'><img src='../dist/img/check.svg'
                                                                           alt='' /></span> Student information
                      </Col>
                      <Col span={4} offset={2} style={{ textAlign: 'right' }}>
                        {/*<label>Search Student</label>*/}
                      </Col>
                      <Col span={8}>
                        <Select
                          showSearch={true}
                          onSearch={debounce(fetchStudent, 500)}
                          filterOption={false}
                          autoClearSearchValue={true}
                          allowClear={true}
                          style={{ width: '100%' }}
                          showArrow={true}
                          onChange={chooseStudentFxn}
                          optionLabelProp='label'
                          placeholder='Search Student'>
                          {state.studentList && state.studentList.length && state.studentList.map((item, index) => {
                            return (
                              <Option key={item._id} value={item._id} label={item.name}>
                                <div>
                                  <div>
                                    {item.studentId} : {item.name}
                                  </div>
                                  <div>
                                    {item.email}
                                  </div>
                                </div>
                              </Option>
                            )
                          })}
                        </Select>
                      </Col>
                    </Row>

                  </h5>

                </div>

                <div className='card unizportal'>
                  <Row gutter={24} className={'rowWrap'}>

                    {formFields.generateInputs.map((item, key) => {
                      return (
                        (item.hidden == undefined || (item.hidden !== undefined && !item.hidden)) ?
                          <Col span={8} md={8} sm={12} xs={24} key={key} className={'mb10'}>
                            <GetEachFormFields
                              item={item}
                              getFieldDecorator={getFieldDecorator}
                              formItemLayout={formItemLayout} />
                          </Col> : null
                      )
                    })}
                    {formFields.addressInput.map((item, key) => {
                      return (
                        (item.hidden == undefined || (item.hidden !== undefined && !item.hidden)) ?
                          <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8}
                               sm={12} xs={24} key={key}
                               className={'mb10'}>
                            <GetEachFormFields
                              item={item}
                              getFieldDecorator={getFieldDecorator}
                              formItemLayout={formItemLayout} />
                          </Col> : null
                      )
                    })}
                    {formFields.campBellFields.map((item, key) => {
                      return (
                        (item.hidden == undefined || (item.hidden !== undefined && !item.hidden)) ?
                          <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8}
                               sm={12} xs={24} key={key}
                               className={'mb10'}>
                            <GetEachFormFields
                              item={item}
                              getFieldDecorator={getFieldDecorator}
                              formItemLayout={formItemLayout} />
                          </Col> : null
                      )
                    })}

                    {formFields.KasaHighSchoolFields.map((item, key) => {
                      return (
                        (item.hidden == undefined || (item.hidden !== undefined && !item.hidden)) ?
                          <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8}
                               sm={12} xs={24} key={key}
                               className={'mb10'}>
                            <GetEachFormFields
                              item={item}
                              getFieldDecorator={getFieldDecorator}
                              formItemLayout={formItemLayout} />
                          </Col> : null
                      )
                    })}

                    <Col span={8}>
                      <InputBox title={'Choose College'} labelCls={'labelDoc'}>
                        <Select
                          showSearch={true}
                          filterOption={filterOption}
                          value={universityId}
                          autoClearSearchValue={true}
                          // allowClear={true}
                          disabled={true}
                          style={{ width: '100%' }}
                          showArrow={true}
                          onChange={(e) => {
                            setUniversityId(e)
                            setCoursesList('')
                            setApplication({})
                            setCourseId('')
                            setIntake({})
                            setIntakeTemp('')
                            setCampusId('')
                            loadCourse(e)
                          }}
                          placeholder='Choose University'>
                          {directUniversityList && directUniversityList.length && directUniversityList.map((item, index) => {
                            return (
                              <Option key={item.id} value={item.id}>{item.name}</Option>
                            )
                          })}
                        </Select>
                      </InputBox>
                    </Col>
                    <Col span={8}>
                      <InputBox title={'Choose Course'} labelCls={'labelDoc'}>
                        <Select
                          showSearch={true}
                          filterOption={filterOption}
                          value={courseId}
                          autoClearSearchValue={true}
                          // allowClear={true}
                          style={{ width: '100%' }}
                          showArrow={true}
                          onChange={(e) => {
                            chooseCourseFxn(e)
                          }}
                          placeholder='Choose course'>
                          {coursesList && coursesList.length && coursesList.map((item, index) => {
                            return (
                              <Option key={item._id}
                                      value={item._id}>{item.courseName}</Option>
                            )
                          })}
                        </Select>
                      </InputBox>
                    </Col>
                    <Col span={8}>
                      <InputBox title={'Select Campus'} labelCls={'labelDoc'}>
                        <Select name='campusId' filterOption={filterOption}
                                className={'from-control'}
                                value={campusId}
                                id='campusId'
                                onChange={(e) => {
                                  setCampusId(e)
                                }}>
                          {campusList && campusList.length ? campusList.map((item, key) => {
                            return (
                              <Option key={key}
                                      value={item._id}>{item.campusName}</Option>
                            )
                          }) : null}
                        </Select>
                      </InputBox>
                    </Col>
                    <Col span={8}>
                      <InputBox title={'Intake'} labelCls={'labelDoc'}>
                        <Select name='intake' id='intake'
                                className={'from-control'}
                                value={intakeTemp}
                                onChange={(e) => {
                                  events.chooseIntake(e)
                                }}>
                          {intakesList && intakesList.length ? intakesList.map((item, key) => {
                            return (
                              <Option key={key} value={item.value}>{item.label}</Option>
                            )
                          }) : null}
                        </Select>
                      </InputBox>
                    </Col>

                    <AllowComponentRightsWise
                      rightUserType={['admin', 'branchManager', 'branchUser']}>
                      <Col span={8}>
                        <InputBox title={'Select Agent'} labelCls={'labelDoc'}>
                          <AgentTypeheadComponent
                            key={agentId}
                            agentId={agentId}
                            dispatch={dispatch}
                            customStyle=''
                            onSelect={item => setAgentId(item)}
                            blockStatus={false}
                          />
                        </InputBox>
                      </Col>
                    </AllowComponentRightsWise>

                    {!studentId && DocumentTypesForFcmt.map((item, key) => {
                      let titleName = `${item.name} Document`
                      if (selectedUniversity == fcmtUniversity && !studentId) {
                        if (!(item.restrict && getFieldValue('countryName') !== 'India')) {
                          titleName = `${item.required ? '*' : ''} ${item.name} Document`
                        }
                      }
                      if (selectedUniversity == FcmtCollegeOnshore && !studentId) {
                        if (!(item.onshoreRestrict && getFieldValue('countryName') !== 'India')) {
                          titleName = `${item.onshoreRestrict ? '*' : ''} ${item.name} Document`
                        }
                      }
                      return (
                        <Col span={8} md={8} sm={12} xs={24} key={key}>
                          <InputBox
                            title={titleName}
                            labelCls={'mt10'}>
                            <Input type={'file'} name={item.name} id={item.name}
                                   required={item.required}
                                   className={'form-control'}
                                   onChange={(e) => {
                                     events.chooseDocument(e)
                                   }} />
                          </InputBox>
                        </Col>
                      )
                    })}
                  </Row>
                  <div className='inner-form'>

                    <button className='btn' type='submit' onClick={events.handleSubmit}>save
                    </button>
                  </div>
                </div>

              </div>
            </Form>
          </div>
        </div>
      </div>

    </div>
  )
}


const WrappedAddFcmtStudent = Form.create()(AddFcmtStudent)
export default WrappedAddFcmtStudent




