import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, notification, Row } from 'antd'
import { connect, useDispatch } from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { addTeamTargetFxn, getUkMarketingUserFxn } from '../actions'

const TeamTarget = (props) => {
  const { form: { getFieldDecorator, getFieldsValue } } = props
  const dispatch = useDispatch()
  const [totalNoOfApplication, setTotalNoOfApplication] = useState(0)
  const [marketingUserUk, setMarketingUsers] = useState([])
  const months = [
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
    'january'
  ]

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const valData = await props.form.validateFields()
      if (totalNoOfApplication) {
        valData.totalApplicationtarget = totalNoOfApplication
      }
      const monthlyApplicationTarget = {}
      months.forEach(month => {
        if (valData[month]) {
          monthlyApplicationTarget[month] = valData[month]
          delete valData[month]
        }
      })
      valData.monthlyApplicationTarget = monthlyApplicationTarget

      const result = await dispatch(addTeamTargetFxn(valData))
      if (!result.error) {
        props.form.resetFields()
      }
    } catch (err) {
      notification.warning({
        message: 'Fill All Required Fields'
      })
    }
  }

  const formItemLayout = {
    labelCol: { xs: { span: 24 }, sm: { span: 8 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }
  }
  useEffect(() => {
    const getUkUsers = async () => {
      try {
        const result = await getUkMarketingUserFxn()
        if (result && Array.isArray(result.data)) {
          setMarketingUsers(result.data)
        } else {
          setMarketingUsers([])
        }
      } catch (err) {
        notification.warning({
          message: 'Failed to fetch users'
        })
      }
    }
    getUkUsers()
  }, [])
  const inputTypes = {
    fields: [
      {
        key: 'userId',
        label: 'User',
        type: 'select',
        showSearch: true,
        required: true,
        options: marketingUserUk,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            userId: x
          })
        }
      },
      {
        key: 'visaTarget',
        label: 'Visa Target',
        required: true,
        placeholder: 'Visa Target...',
        type: 'number'
      },
      {
        key: 'tuitionFees',
        label: 'Tuition Fees',
        required: true,
        placeholder: 'Tuition Fees....',
        type: 'number'
      }

    ]
  }

  useEffect(() => {
    const values = getFieldsValue()
    const total = months.reduce((sum, month) => {
      return sum + (parseInt(values[month]) || 0)
    }, 0)
    setTotalNoOfApplication(total)
  }, [getFieldsValue()])

  return (
    <div className='row'>
      <div className='col-lg-12'>
        <div className='form-box mt-4'>
          <div className='d-flex align-items-center mb-3 heading-form'>
            <h5>Team Target</h5>
          </div>
          <div className='card unizportal'>
            <Form onSubmit={handleSubmit}>
              <Row gutter={24}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <>
                      {item.customField ? !item.hidden &&
                        <Col className={'mt10'} span={item.span ? item.span : 8}>
                          {item.customField}
                        </Col> : !item.hidden &&
                        <Col className={'mt10'} span={item.span ? item.span : 8} key={key}>
                          <GetEachFormFields
                            item={item}
                            getFieldDecorator={getFieldDecorator}
                            formItemLayout={formItemLayout} />
                        </Col>}
                    </>
                  )
                })}
              </Row>
              <hr />
              <Row className={'ml15 mt20'}>
                <Col
                  style={{
                    border: '1px solid black',
                    padding: '4px 8px',
                    display: 'inline-block',
                    borderRadius: '4px'
                  }}
                  span={8}>
                  <b>Total number of applications</b> - {totalNoOfApplication}
                </Col>
              </Row>
              <Row className={'mt10'} gutter={24}>
                {months.map((month, index) => (
                  <Col className={'mt10'} span={8} key={index}>
                    <Form.Item label={month.toUpperCase()} {...formItemLayout}>
                      {getFieldDecorator(month, {
                        rules: [{ required: true, message: 'Enter number of application' }]
                      })(<Input
                          type='number'
                          placeholder={`${month} applications`}
                          className='form-control'
                          onChange={() => setTotalNoOfApplication(prev => prev)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                ))}
              </Row>
              <Button className={'btn btn-primary'} type='primary' htmlType='submit'>Save</Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ global }) => ({ currentUserRights: global.currentUserRights })
const TeamTargetWrappedComponent = Form.create()(TeamTarget)
export default connect(mapStateToProps)(TeamTargetWrappedComponent)
