import React, { useState, useEffect, useRef } from 'react'
import { CustomRowTable } from '../../components/_utils/RowTable'
import { Drawer, Card, Skeleton, Tooltip, Icon, Table } from 'antd'
import { useDispatch } from 'react-redux'
import { countryIds, DefaultTablePagination, displayDate } from '../../components/_utils/appUtils'
import { TableComp } from 'sz-react-utils-lite'
import { ColumnWidth } from '../WebComponent/columnWidth'
import moment from 'moment'

const getNotes = (notes) => {
  if (notes && notes.length) {
    return (
      <ul style={{ listStyle: 'disc' }}>
        {notes.map((item, index) => {
          return (
            <li key={index}>{item}</li>
          )
        })}
      </ul>
    )
  } else {
    return null
  }
}
const getIntakes = (intakes) => {
  if (intakes && intakes.length) {
    return (
      <>
        {intakes.map((item, index) => {
          return (
            <span key={index} style={{ color: 'black' }}>{item} {index < intakes.length - 1 ? <i>/ </i> : null}</span>
          )
        })}
      </>
    )
  } else {
    return null
  }
}
const getCampus = (campusList) => {
  if (campusList && campusList.length) {
    return (
      <>
        {campusList.map((item, index) => {
          return (
            <span key={index} style={{ color: 'black' }}>{item.campusName}{index < campusList.length - 1 ?
              <i>, </i> : null}</span>
          )
        })}
      </>
    )
  } else {
    return null
  }
}
const StudentInsuranceInfoDrawer = (props) => {
  let { user, visible, onClose } = props

  let [dependentList, setDependentList] = useState([])

  let tableRef = useRef()

  useEffect(() => {
    let { user } = props
    if (user && user.dependentList) {
      setDependentList(user.dependentList)
    }
  }, [])


  let columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Middle Name',
      key: 'middleName',
      dataIndex: 'middleName'
    },
    {
      title: 'Last Name',
      key: 'lastName',
      dataIndex: 'lastName'
    },
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title'
    },
    {
      title: 'Gender',
      key: 'gender',
      dataIndex: 'gender'
    },
    {
      title: 'Date Of Birth',
      key: 'dateOfBirth',
      dataIndex: 'dateOfBirth',
      render: (item) => {
        return (
          <>{displayDate(item)}</>
        )
      }
    }

  ]

  return (
    <Drawer visible={visible}
            title={'Student Insurance Details'} width={'70%'}
            onClose={onClose}>
      <Card>

        <div className={'infoUni tableBox striped'}>
          <CustomRowTable title={'Start Date'} value={(
            <div>
              {user.startDate ? displayDate(user.startDate) : null}
            </div>
          )} />
          <CustomRowTable title={'End Date'} value={(
            <div>
              {user.endDate ? displayDate(user.endDate) : null}
            </div>
          )} />
          <CustomRowTable title={'Title'} value={(
            <div>
              {user.title ? user.title : null}
            </div>
          )} />
          <CustomRowTable title={'Name'} value={(
            <div>
              {user.name ? user.name : null}
            </div>
          )} />
          <CustomRowTable title={'Middle Name'} value={(
            <div>
              {user.middleName ? user.middleName : null}
            </div>
          )} />
          <CustomRowTable title={'Last Name'} value={(
            <div>
              {user.lastName ? user.lastName : null}
            </div>
          )} />

          <CustomRowTable title={'Gender'} value={(
            <div>
              {user.gender ? user.gender : null}
            </div>
          )} />
          <CustomRowTable title={'Date Of Birth'} value={(
            <div>
              {user.dateOfBirth ? displayDate(user.dateOfBirth) : null}
            </div>
          )} />

          <CustomRowTable title={'Student Email'} value={(
            <div>
              {user.studentEmail ? user.studentEmail : null}
            </div>
          )} />
          <CustomRowTable title={'Mobile no'} value={(
            <div>
              {user.mobile ? user.mobile : null}
            </div>
          )} />
          <CustomRowTable title={'Counsellor Name'} value={(
            <div>
              {user.counsellorName ? user.counsellorName : null}
            </div>
          )} />
          <CustomRowTable title={'Counsellor Contact No'} value={(
            <div>
              {user.counsellorContactNo ? user.counsellorContactNo : null}
            </div>
          )} />


          <CustomRowTable title={'Product'} value={(
            <div>
              {user && user.product ? user.product : null}
            </div>
          )} />
          <CustomRowTable title={'Insurance Cover For'} value={(
            <div>
              {user && user.insuranceCoverFor ? user.insuranceCoverFor : null}
            </div>
          )} />


          <CustomRowTable title={'College Name'} value={(
            <div>
              {user && user.collegeName ? user.collegeName : null}
            </div>
          )} />
          <CustomRowTable title={'Passport'} value={(
            <div>
              {user && user.passport && user.passport.url ?
                <a href={user.passport.url} target={'_blank'} className={'btn btn-default roundNew xs'}>
                  Download Passport
                </a> : null}
            </div>
          )} />
          <CustomRowTable title={'Offer Letter'} value={(
            <div>
              {user && user.offerLetter && user.offerLetter.url ?
                <a href={user.offerLetter.url} target={'_blank'} className={'btn btn-default roundNew xs'}>
                  Download Offer Letter
                </a> : null}
            </div>
          )} />


        </div>
      </Card>
    </Drawer>
  )
}


export { StudentInsuranceInfoDrawer }
