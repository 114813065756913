import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import { notification } from 'antd'
import { addTeamTargetUrl, getUkMarketingUserUrl } from '../apis'

export const addTeamTargetFxn = (valData) => async dispatch => {
  dispatch(showPageLoad())
  try {
    const response = await axios.post(addTeamTargetUrl(), valData, getToken())
    if (!response.data.error) {
      dispatch(hidePageLoad())
      notification.success({
        message: response.data.message || 'Success'
      })

    } else {
      notification.error({
        message: response.data.message || 'Error'
      })
    }
    return response.data
  } catch (error) {
    console.error('Error adding task:', error)
    throw error
  }
}

export const getUkMarketingUserFxn = async () => {
  let config = {
    ...await getToken()
  }
  const response = await axios.get(getUkMarketingUserUrl(), config)
  return response.data
}