import React, { useEffect, useState } from 'react'
import { Button, Card, Drawer, notification, Select } from 'antd'
import { filterOption, InputBox } from '../../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import { updateGiftByUserFxn } from '../actions/user'

let { Option } = Select
const AssignUserToAgent = (props) => {
  let dispatch = useDispatch()
  let {
    visible,
    onClose,
    marketingUserList,
    reeudoManagerList,
    agentId,
    unizHomeUsers,
    xTravelMarketingUserList
  } = props
  let [giftByMarketingUserId, setGiftByMarketingUserId] = useState(props.giftByMarketingUserId)
  let [reeudoManagerId, setReeudoManagerId] = useState('')
  let [unizHomeUserId, setUnizHomeUserId] = useState('')
  let [xTravelWorldMarketingId, setXTravelWorldMarketingId] = useState('')
  useEffect(() => {
    if (props.reeudoManagerId && props.reeudoManagerId._id) {
      setReeudoManagerId(props.reeudoManagerId._id)
    }
    if (props.unizHomeUserId && props.unizHomeUserId._id) {
      setUnizHomeUserId(props.unizHomeUserId._id)
    }
    if (props.xTravelWorldMarketingId && props.xTravelWorldMarketingId._id) {
      setXTravelWorldMarketingId(props.xTravelWorldMarketingId._id)
    }
  }, [props])
  const handleSubmit = async () => {
    if (agentId) {
      if (giftByMarketingUserId == '' && reeudoManagerId == '') {
        notification.warning({ message: 'Please choose atleast one of them' })
        return
      }
      let valData = {}
      if (giftByMarketingUserId) {
        valData.giftByMarketingUserId = giftByMarketingUserId
      }
      if (reeudoManagerId) {
        valData.reeudoManagerId = reeudoManagerId
      }
      if (xTravelWorldMarketingId) {
        valData.xTravelWorldMarketingId = xTravelWorldMarketingId
      }
      valData.agentId = agentId
      let resp = await dispatch(updateGiftByUserFxn(valData))
      if (resp.success) {
        onClose()
      }


    } else {
      notification.warning({ message: 'Agent not found' })
    }
  }
  return (
    <Drawer
      title={'Assign Users To Agent'}
      visible={visible}
      placement='right'
      closable={true}
      width={'40%'}
      onClose={onClose}
    >
      <Card>
        <div className='sort-box-table custom-sort-box-new'>
          <InputBox title={'Choose Reeudo Managers'}>
            <Select
              name='Reeudo Managers'
              onChange={item => {
                setReeudoManagerId(item)
              }}
              filterOption={filterOption}
              placeholder='Reeudo Managers'
              allowClear={true}
              showSearch={true}
              value={reeudoManagerId || undefined}>
              {reeudoManagerList && reeudoManagerList.length
                ? reeudoManagerList.map((item, key) => {
                  return (
                    <Option value={item._id} key={item._id}>
                      {item.name}
                    </Option>
                  )
                })
                : null}
            </Select>
          </InputBox>
          <InputBox title={'Choose Gift By Marketing User'}>

            <Select
              name='Gift By Marketing User'
              onChange={item => {
                setGiftByMarketingUserId(item)
              }}
              filterOption={filterOption}
              placeholder='Gift By Marketing User'
              allowClear={true}
              showSearch={true}
              value={giftByMarketingUserId || undefined}>
              {marketingUserList && marketingUserList.length
                ? marketingUserList.map((item, key) => {
                  return (
                    <Option value={item._id} key={key}>
                      {item.name}
                    </Option>
                  )
                })
                : null}
            </Select>
          </InputBox>
          <InputBox title={'Choose Unizhome User'}>
            <Select
              name='Unizhome User'
              onChange={item => {
                setUnizHomeUserId(item)
              }}
              filterOption={filterOption}
              placeholder='Unizhome User'
              allowClear={true}
              showSearch={true}
              value={unizHomeUserId || undefined}>
              {unizHomeUsers && unizHomeUsers.length
                ? unizHomeUsers.map((item, key) => {
                  return (
                    <Option value={item._id} key={key}>
                      {item.name}
                    </Option>
                  )
                })
                : null}
            </Select>
          </InputBox>
          <InputBox title={'Choose X Travel  Managers'}>
            <Select
              name='X Travel World Managers'
              onChange={item => {
                setXTravelWorldMarketingId(item)
              }}
              filterOption={filterOption}
              placeholder='X Travel World Managers'
              allowClear={true}
              showSearch={true}
              value={xTravelWorldMarketingId || undefined}>
              {xTravelMarketingUserList && xTravelMarketingUserList.length
                ? xTravelMarketingUserList.map((item, key) => {
                  return (
                    <Option value={item._id} key={item._id}>
                      {item.name}
                    </Option>
                  )
                })
                : null}
            </Select>
          </InputBox>

        </div>

        <Button type='primary'
                htmlType='submit'
                className='btn lg'
                onClick={handleSubmit}>Submit</Button>

      </Card>
    </Drawer>
  )
}

export default AssignUserToAgent
