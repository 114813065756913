import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import {
  addXTravelFundTransferRequestUrl, getAllFundTransferRequestUrl,
  singleAgentXTravelMarginUrl,
  singleXTravelExchangeMarginUrl, updateFundTransferRequestUrl,
  updateXTravelExchangeMarginUrl,
  updateXTravelMarginUrl
} from '../api'
import { notification } from 'antd'

export const singleAgentXTravelMarginFxn = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(singleAgentXTravelMarginUrl(), config)
  dispatch(hidePageLoad())
  return data
}

export const updateAgentXTravelMarginFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.put(updateXTravelMarginUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const singleXTravelExchangeMarginFxn = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(singleXTravelExchangeMarginUrl(), config)
  dispatch(hidePageLoad())
  return data
}

export const updateXTravelExchangeMarginFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.put(updateXTravelExchangeMarginUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const addXTravelTransferRequestFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addXTravelFundTransferRequestUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getAllFundTransferRequestFxn = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getAllFundTransferRequestUrl(), config)
  dispatch(hidePageLoad())

  return data.data
}

export const FundTransferStatusUpdateFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateFundTransferRequestUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}