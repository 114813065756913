import { Spin } from 'antd'
import _ from 'lodash'
import React, { Component, Suspense, lazy, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link, Redirect, Route, Switch } from 'react-router-dom'
// import Exception from '../../components/Exception'
import '../../index.css'
import BasicLayout from '../../layouts/BasicLayout'
import { hidePageLoad } from '../../modules/actions'
import menuData from '../../routes/routes'
import { apiUrl, pageTitle } from '../../settings'
import AuthRouteList from './authRoute'
import FrontRoutesList from './frontRoute'
import UnizHomeRoutesList from './unizHomeRoute'
import {
  departmentObj,
  cambieEvents,
  hitanshuId,
  countryIds,
  accountantEmail,
  checkShowFcmtCollegeRight,
  bloomsburyShowToUser,
  ryanAgentId,
  adminRightUser,
  kasaBranchUserEmail,
  agentTeamTypes,
  getManagerTypeList, emailListObj
} from '../../components/_utils/appUtils'
import { getUserRights } from '../login/action'
import { getLatestUpdateCountFxn } from '../latestUpdate/actions'
import { taskRights } from '../../components/_utils/rightsUtils'
import { Socket, Socket2 } from '../../socket'
import ExtraRoutes from './extraRoutes'
import { push } from 'connected-react-router'
import { marketingRoutes } from '../../components/_utils/authFxn'

const UnizHomeLayout = lazy(() => import('../../unizHome/layout'))
const AuthLayout = lazy(() => import('../../layouts/AuthLayout'))
const FrontLayout = lazy(() => import('../../frontLayout'))
const Exception = lazy(() => import('../../components/Exception'))

const SocketComponent = React.lazy(() =>
  import('../../socket').then(module => ({
    default: module.SocketComponent
  }))
)

const loadingComponent = (
  <div className={'outerLoader'}>
    <Spin style={{ fontSize: 100 }} />
    Loading...
  </div>
)

const $ = window.$
const ChatDrawer = React.lazy(() =>
  import('../../containers/chatBox/views/chatDrawer')
)
const IdleTimer = React.lazy(() => import('../../IdleTimer'))
const Exp = () => (
  <Suspense fallback={loadingComponent}>
    <Exception
      type='404'
      desc={'You Seems lost !!'}
      linkElement={Link}
      redirect={'/dashboard'}
      backText={'Go To Homepage?'}
    />
  </Suspense>
)
const Exp403 = () => (
  <Suspense fallback={loadingComponent}>
    <Exception
      type='403'
      desc={'Sorry You Don\'t have access to this area !!'}
      linkElement={Link}
      redirect={'/dashboard'}
      backText={'Go To Homepage?'}
    />
  </Suspense>
)


const FrontRoute = props => {
  let { component: Component, path } = props
  return (
    <Route
      exact
      path={path}
      render={route => {
        return (
          <Suspense fallback={loadingComponent}>
            <FrontLayout>
              <Component />
            </FrontLayout>
          </Suspense>
        )
      }}
    />
  )
}
const AuthRoute = props => {
  let { component: Component, path } = props
  return (
    <Route
      exact
      path={path}
      render={route => {
        return (
          <Suspense fallback={loadingComponent}>
            <AuthLayout>
              <Component />
            </AuthLayout>
          </Suspense>
        )
      }}
    />
  )
}
const UnizHomeRedirectComponent = () => {
  useEffect(() => {
    window.location.href = 'https://www.unizhome.com'
  }, [])
  return null
}
const UnizHomeRoute = props => {
  let { component: Component, path, routeName } = props
  return (
    <Route
      exact
      path={path}
      render={route => {
        return (
          <Suspense fallback={loadingComponent}>
            {routeName == 'trainingRequest' ?
              <UnizHomeLayout>
                <Component />
              </UnizHomeLayout> :
              <UnizHomeRedirectComponent />}
          </Suspense>
        )
      }}
    />
  )
}

const JoinSocketComponent = (props) => {
  let { currentUser, dispatch } = props
  let joinUserEmit = () => {
    if (currentUser && currentUser._id) {
      dispatch({
        type: 'JOIN_SOCKET',
        value: true,
        userId: currentUser._id
      })
    }
  }

  let loadUpdatesFxn = () => {
    if (currentUser && currentUser._id) {
      Socket2.emit('latestUpdateCount', { userId: currentUser._id })
    }
    Socket2.on('latestUpdateCount', (data) => {
      if (data) {
        dispatch({ type: 'UNREAD_LATEST_UPDATE', unreadUpdates: data.unreadUpdates })
      }
    })
  }

  useEffect(() => {
    joinUserEmit()
    loadUpdatesFxn()
  }, [])

  const webVisitFxn = (user) => {
    let obj = {
      location: window.location.href,
      origin: window.location.origin,
      pathname: window.location.pathname,
      hash: window.location.hash,
      search: window.location.search,
      userId: user._id,
      name: user.name,
      email: user.email,
      userType: user.userType
    }
    Socket.emit('webVisit', { ...obj })
  }
  useEffect(() => {
    if (currentUser && currentUser._id) {
      webVisitFxn(currentUser)
    }
  }, [window.location.href])


  return null
}

class BasicLayoutWrapper extends Component {
  render() {
    const { menuData, component, path, user, currentUserRights } = this.props
    if (!user || (user && !user.userType)) {
      return window.location.pathname !== '/login' ? (
        <Redirect to='/login' />
      ) : (
        ''
      )
    }

    let menuItem = _(menuData)
      .thru(function(coll) {
        return _.union(coll, _.map(coll, 'children'))
      })
      .flatten()
      .find({ path: path })

    if (
      menuItem.authority !== undefined &&
      menuItem.authority.indexOf(user.userType) === -1
    ) {
      console.log('this user should not be here ', path)
      return <Exp403 />
    }

    if (!menuItem.title) {
      menuItem.title = 'UnizPortal'
    }
    // console.log(menuItem)

    return (
      <React.Fragment>
        <Helmet>
          <link href='../../dist/css/style.css' rel='stylesheet' as={'style'} />
          <link href='../../dist/css/customCss.css' rel='stylesheet' as={'style'} />
          <link
            href='../../plugins/fontawesome-free/css/all.min.css'
            rel='stylesheet' as={'style'}
          />
          <link
            href='../../plugins/overlayScrollbars/css/OverlayScrollbars.min.css'
            rel='stylesheet' as={'style'}
          />
          <link href='../../dist/css/adminlte.css' rel='stylesheet' as={'style'} />
          {/*<link href='https://fonts.gstatic.com' rel='preload' as={'style'}/>*/}
          <link
            href='https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700&display=swap'
            rel='preload' as={'style'}
          />
        </Helmet>
        <BasicLayout
          location={window.location}
          title={pageTitle}
          pageTitle={`${menuItem.title}`}
          menuData={menuData}>
          {!!component ? <this.props.component user={user} currentUserRights={currentUserRights} /> : <Exp />}
        </BasicLayout>
      </React.Fragment>
    )
  }
}

class App extends Component {
  constructor(props) {
    super(props)
    /*   this.state = {
           token: "",
           user: {}
       }*/
    this.state = {
      token: localStorage.getItem('token'),
      user:
        localStorage.getItem('user') != 'undefined'
          ? JSON.parse(localStorage.getItem('user'))
          : null
      // user: null
    }
    this.loadCurrentUserRight()
    this.setDefaultUserData()
  }

  setDefaultUserData = () => {
    let { currentUser, dispatch } = this.props
    let token = localStorage.getItem('token')
    let user = localStorage.getItem('user') != 'undefined'
      ? JSON.parse(localStorage.getItem('user'))
      : null
    this.setState({
      token: token,
      user: user
    })
    if ((!currentUser || (currentUser && !currentUser._id)) && user && user._id) {
      dispatch({
        type: 'SET_CURRENT_USER',
        user: user
      })
    }
  }


  componentDidMount() {
    this.props.dispatch(hidePageLoad())
    // this.setDefaultUserData()

    // this.loadLatestUpdateData()


    if (apiUrl.indexOf('localhost') < 0) {
      document.addEventListener('contextmenu', (e) => {
        e.preventDefault()
      })
    }

    document.addEventListener('keydown', (event) => {
      if (event.ctrlKey && (event.key === 'c' || event.key === 'a')) {
        event.preventDefault()
      } else if (event.ctrlKey && (event.shiftKey || event.altKey) && event.key === 'C') {
        event.preventDefault()
      } else if (event.ctrlKey && (event.shiftKey || event.altKey) && event.key === 'Z') {
        document.execCommand('copy')
      }
    })
    document.addEventListener('keyup', (event) => {
      // Disable text selection again when Ctrl or Shift is released
      if (!event.ctrlKey || !event.shiftKey || !event.altKey) {
        document.body.style.userSelect = 'none'
      }
    })

    document.addEventListener('mousedown', (event) => {
      let tagList = ['DIV', 'P', 'SPAN', 'A']
      let enableTagList = ['INPUT', 'TEXTAREA']
      /*  if (tagList.includes(event.target.tagName)) {
          event.preventDefault() // Disable text selection
          document.body.style.userSelect = 'none' // Ensure text selection remains disabled
        } else if ((event.ctrlKey && (event.shiftKey || event.altKey)) || enableTagList.includes(event.target.tagName)) {
          document.body.style.userSelect = 'text' // Enable text selection
        } else {
          event.preventDefault() // Disable text selection
          document.body.style.userSelect = 'none' // Ensure text selection remains disabled
        }*/

      if ((event.ctrlKey && (event.shiftKey || event.altKey))) {
        document.body.style.userSelect = 'text' // Enable text selection
      } else {
        document.body.style.userSelect = 'none' // Enable text selection
      }

    })
    document.addEventListener('dragstart', (event) => {
      event.preventDefault() // Prevent drag action
    })

    document.addEventListener('drop', (event) => {
      event.preventDefault() // Prevent drop action
    })


  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // this.joinUserEmit()
  }

  logoutFxn() {
    // not a main logout button
    let { dispatch } = this.props
    let user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null
    dispatch({
      type: 'LEAVE_SOCKET',
      leaveRoom: true,
      userId: user._id
    })
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('chatId')
    localStorage.removeItem('dontAskOtpForIds')

    document.body.className = ''
    setTimeout(() => {
      dispatch({ type: 'LOGOUT' })
    }, 500)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps && nextProps.loadCurrentUser) {
      this.loadCurrentUserRight()
      this.props.dispatch({ type: 'STOP_LOAD_CURRENT_USER' })
    }
  }

  loadCurrentUserRight = async () => {
    let user = this.props.currentUser
    if (user && user._id) {
      let { data } = await getUserRights({ userId: user._id })
      this.props.dispatch({
        type: 'SET_CURRENT_USER_RIGHT',
        rights: data
      })
    }
  }


  loadLatestUpdateData = async () => {
    let { dispatch, currentUser } = this.props
    if (currentUser && currentUser._id) {
      let { data } = await getLatestUpdateCountFxn()
      if (data && data.unreadUpdates) {
        dispatch({ type: 'UNREAD_LATEST_UPDATE', unreadUpdates: data.unreadUpdates })
      } else {
        dispatch({ type: 'UNREAD_LATEST_UPDATE', unreadUpdates: 0 })
      }
    }
  }


  render() {
    const { user } = this.state
    const { pageLoading, currentUser, currentUserRights } = this.props
    let userType = ''
    if (currentUser && currentUser.userType) {
      userType = currentUser.userType
    } else {
      userType = ''
    }
    let managerTypeList = getManagerTypeList()


    const checkRightInner = (data, item) => {
      if (data && data._id) {
        let {
          userType,
          allowAddingUser,
          showAgent,
          allowLeads,
          department,
          branchManagerType,
          countryRight,
          assignIntakesRight,
          showAllUniversities,
          showAllFcmtList,
          showAllCambieList,
          approveFCMTLoa,
          showOnShoreAustralia,
          approvedAgent,
          showApprovedCommission,
          approveOxfordDraft,
          showAllLesterList,
          showCommissionStructure,
          countryId,
          showCanadaLoa,
          showUnizHomeEnquiry,
          oxfordInterviewDepartment,
          approveCambieLoa,
          showAssignIntake,
          enableUploadTt,
          enableFcmtTtUpload,
          enableCornerStoneTtUpload,
          enableCambieTtUpload,
          enableCiraTtUpload,
          showIcef,
          showPendingVisa,
          showPendingGcKey,
          showOnShoreCambie,
          showOnShoreFcmt,
          dealCloseRight = false,
          referCodeForInstitute = '',
          referByUser = '',
          showContract = false,
          defaultCurrency,
          showAdvanceContract = false,
          showFcmtCollegeRight = false,
          xTravelFundTransferRight = false
        } = data

        let { allowApproveCambieAgent, unizhomeManager = false, marketingFor = 'unizportal' } = currentUserRights


        let condition = true
        if (
          userType == 'agent' &&
          item.key == 'allSubAgent' &&
          !allowAddingUser
        ) {
          condition = false
        }
        if (item.key == 'dailyLeads') {
          condition = false
        }
        if (item.key == 'assessmentDepartment') {
          if (department) {
            if (!department == departmentObj.assDepartment) {
              condition = false
            }
          } else {
            condition = false
          }
        }
        if (item.key == 'allAgents' || item.key == 'allSubAgents') {
          if (!showAgent) {
            condition = false
          }
        }
        if (item.key == 'user') {
          if (userType !== 'admin' && userType !== 'branchManager') {
            if (!showAgent) {
              condition = false
            }
          }
        }
        if (
          item.key == 'assessmentsWarnings' ||
          item.key == 'waitingToApplyWarnings'
        ) {
          // check warning department
          if (department) {
            if (department !== departmentObj.warningDepartment) {
              condition = false
            }
          } else {
            condition = false
          }
        }
        if (item.key == 'marketingAgent') {
          condition = false
          if (
            (branchManagerType && branchManagerType == 'agentWise') ||
            branchManagerType == 'marketingManager'
          ) {
            condition = true
          }
        }
        if (item.key == 'marketingUserAssignAgentList') {
          condition = false
          if (department == departmentObj.marketing || userType == 'marketingManager') {
            condition = true
          }
        }

        if (item.key == 'sendMailToAgent' || item.key == 'sendMailToAgent') {
          condition = false
          if (
            userType == 'branchManager' &&
            ((branchManagerType && branchManagerType !== 'universityWise') ||
              !branchManagerType)
          ) {
            condition = true
          } else if (
            userType == 'branchUser' &&
            department == departmentObj.marketing
          ) {
            condition = true
          } else if (userType == 'admin' || userType == 'hr') {
            condition = true
          }
        }
        if (
          item.key == 'searchCourse' ||
          item.key == 'searchCourse' ||
          item.key == 'addStudent'
        ) {
          if (
            userType == 'branchManager' &&
            branchManagerType &&
            branchManagerType == 'universityWise'
          ) {
            condition = false
          }
        }
        if (item.key == 'searchCourse') {
          if (department && department == departmentObj.warningDepartment) {
            condition = false
          } else {
            condition = true
          }
        }

        if (item.key == 'allApplicationsList') {
          if (userType == 'branchManager' && branchManagerType == 'marketingManager') {
            condition = false
          }
        }
        if (item.key == 'dailyExpenseListForMarketingUser' || item.key == 'addExpense') {
          condition = false
          if ((userType == 'branchUser' && department == departmentObj.marketing && defaultCurrency == 'INR') ||
            (userType == 'marketingManager' && defaultCurrency == 'INR') ||
            (userType == 'eventManager' && defaultCurrency == 'INR') ||
            (userType == 'branchManager' && !branchManagerType && branchManagerType !== null)
          ) {
            condition = true
          }
        }
        if (item.key == 'dailyExpenseListForMarketingUserCanada' || item.key == 'addExpenseCanada') {
          condition = false
          if ((userType == 'branchUser' && department == departmentObj.marketing && defaultCurrency == 'CAD')) {
            condition = true
          }
        }


        if (item.key == 'canadaApplicationList') {
          condition = false
          if (showCanadaLoa) {
            condition = true
          }
        }
        if (item.key == 'allCountries') {
          condition = false
          if (
            userType == 'admin' ||
            (userType == 'master' && countryRight == 'All')
          ) {
            condition = true
          }
        }
        if (item.key == 'allUniversities' || item.key == 'editUniversity') {
          condition = false
          if ([...adminRightUser, 'userManager', 'eligibilityMaster', 'master'].includes(userType) ||
            (userType == 'branchUser' && showAllUniversities) ||
            (userType == 'branchManager' && !branchManagerType)) {
            condition = true
          }
        }

        if (item.key == 'allFcmtApplicationsList') {
          condition = false
          if ((showAllFcmtList && showAllFcmtList == true) || userType == 'itTeam') {
            condition = true
          }
        }
        if (item.key == 'allLesterApplicationsList') {
          condition = false
          if (showAllLesterList && showAllLesterList == true) {
            condition = true
          }
        }

        if (item.key == 'allCambieApplicationsList') {
          condition = false
          if (showAllCambieList && showAllCambieList == true) {
            condition = true
          }
        }

        if (item.key == 'allUniversitiesForRyanAccounts') {
          condition = false
          if (userType == 'branchUser' && showAllUniversities) {
            condition = true
          }
        }
        if (item.key == 'agentCommission' || item.key == 'mainWithdrawalList') {
          condition = false
          if (userType == 'admin' || (currentUser && currentUser.email && currentUser.email == accountantEmail)) {
            condition = true
          }
        }
        if (item.key == 'approvedWithdrawals') {
          condition = false
          if (
            currentUser &&
            currentUser.email &&
            currentUser.email == accountantEmail
          ) {
            condition = true
          }
        }
        if ((item.key == 'allAgentCommissionList' || item.key == 'commissionWithdrawal') && department == departmentObj.accountDepartment) {
          condition = false
          if (showApprovedCommission) {
            condition = true
          }
        }

        if (item.key == 'allAgentForMarketingUsers') {
          condition = false
          if (branchManagerType) {
            if (branchManagerType == 'allAgentForMarketingUsers') {
              condition = true
            }
          } else if (
            department == departmentObj.marketing ||
            userType == 'marketingManager'
          ) {
            condition = true
          }
        }

        if (item.key == 'commission' ||
          item.key == 'universityCommission' ||
          item.key == 'receivedCommissionList' ||
          item.key == 'agentOldCommissionList' ||
          item.key == 'agentCommissionList') {
          condition = false
          if (userType == 'admin' ||
            (currentUser && currentUser.email &&
              currentUser.email == accountantEmail)
          ) {
            condition = true
          }
        }

        if (item.key == 'onshoreCommission' ||
          item.key == 'onShoreUniversityCommission' ||
          item.key == 'onShoreReceivedCommissionList' ||
          item.key == 'onShoreAgentCommissionList') {
          condition = false
          if (
            userType == 'admin' || userType == 'canadaOnshoreAdmin' || userType == 'onshoreAccountant' ||
            (currentUser && currentUser.email && currentUser.email == accountantEmail)
          ) {
            condition = true
          }
        }

        if (item.key == 'referredAgentCommission') {
          condition = false
          if (userType == 'branchManager' && !managerTypeList.includes(branchManagerType)) {
            condition = true
          }
        }


        if (item.key == 'commissionStructure') {
          condition = false
          if ((userType == 'agent' && user && !cambieEvents.checkAgent(user._id)) || userType == 'admin' || showCommissionStructure == true || (userType == 'branchUser' && department == departmentObj.marketing)
          ) {
            condition = true
            if (userType == 'branchUser') {
              item.dontShowOnMenu = false
            }
          }
        }
        if (item.key == 'australiaOnShoreList') {
          if (showOnShoreAustralia) {
            item.dontShowOnMenu = false
          }
        }

        if (item.key == 'assignIntake') {
          condition = false
          if (assignIntakesRight) {
            condition = true
          }
        }

        if (item.key == 'downloadReport') {
          // department == departmentObj.marketing && userType == 'branchUser'
          condition = false
          if (
            userType == 'admin' ||
            userType == 'branchManager' ||
            userType == 'branchUser' ||
            userType == 'marketingManager'
          ) {
            condition = true
          }
        }


        if (item.key == 'allStudent') {

          if (department && (department == departmentObj.interviewer || department == departmentObj.warningDepartment)) {
            condition = false
          } else {
            if (approveFCMTLoa || approveOxfordDraft || oxfordInterviewDepartment) {
              condition = false
            } else {
              condition = true
            }
          }
        }

        if (item.key == 'oxfordPendingApplicationsList' || item.key == 'oxfordPendingInterviewList') {
          if (approveOxfordDraft && approveOxfordDraft == true) {
            condition = true
          } else {
            condition = false
          }
        }
        if (item.key == 'oxfordInterviewApplications') {
          if (oxfordInterviewDepartment && oxfordInterviewDepartment == true) {
            condition = true
          } else {
            condition = false
          }
        }
        if (item.key == 'cambiePendingApplicationsList') {
          if (approveCambieLoa && approveCambieLoa == true) {
            condition = true
          } else {
            condition = false
          }
        }
        /*if (item.key == 'approvedAgents') {
                        condition = false
                        if (userType == 'admin' || (userType == 'branchUser' && department !== departmentObj.counselling)) {
                            condition = true
                        }
                    }*/

        if (item.key == 'approvedAgents') {
          condition = false
          if (userType == 'admin' || userType == 'userManager' || approvedAgent) {
            condition = true
          }
        }

        /*if (item.key == 'counsellingList') {
          condition = false
          if (
            userType == 'admin' ||
            (userType == 'branchUser' && department == departmentObj.counselling)
          ) {
            condition = true
          }
        }*/

        if (item.key == 'selfReport') {
          condition = false
          if (userType == 'branchUser' && department == departmentObj.marketing) {
            condition = true
          }
        }
        if (item.key == 'followupList') {
          condition = false
          if (userType == 'branchUser' && department == departmentObj.marketing) {
            condition = true
          }
        }
        if (item.key == 'counsellingFollowupList') {
          condition = false
          if ((userType == 'branchUser' && department == departmentObj.counselling) || userType == 'counsellingManager' || userType == 'eventManager') {
            condition = true
          }
        }

        if (item.key == 'dailyAgentReport') {
          condition = false
          if (
            (userType == 'branchManager' &&
              branchManagerType &&
              branchManagerType == 'agentWise') ||
            userType == 'admin'
          ) {
            condition = true
          }
        }

        if (item.key == 'searchCourse' || item.key == 'addStudent') {
          condition = true
          if ((userType == 'branchUser' && department == departmentObj.counselling) || oxfordInterviewDepartment || approveOxfordDraft || userType == 'courseMaster' || userType == 'master') {
            condition = false
          }
        }

        if (item.key == 'allStudent') {
          condition = true
          if ((userType == 'branchUser' && department == departmentObj.counselling) || oxfordInterviewDepartment || approveOxfordDraft || userType == 'courseMaster' || userType == 'master') {
            condition = false
          }
          if (userType == 'marketingManager' && (user && user._id !== hitanshuId)) { // show only hitanshu marketing manager
            condition = false
          }
        }

        if (item.key == 'unizHomeEnquiry') {
          condition = false
          if (showUnizHomeEnquiry) {
            condition = true
          }
        }


        if (item.key == 'expense' || item.key == 'dailyExpenseList' || item.key == 'expenseWithDrawList') {
          condition = false
          if (userType == 'admin' || (currentUser && currentUser.email && currentUser.email == accountantEmail)) {
            condition = true
          }
        }

        if (item.key == 'cambieAgentList') {
          condition = false
          if (userType == 'admin' || showOnShoreCambie || (currentUser && currentUser._id && cambieEvents.checkApprovedAgent(currentUser._id))
            || allowApproveCambieAgent
          ) {
            condition = true
          }

        }

        if (item.key == 'studentDiversity') {
          condition = false
          if (userType == 'admin' || (userType == 'marketingManager' && (user && user._id == hitanshuId))) {
            condition = true
          }
        }
        if (item.key == 'assignIntakeCampusWays') {
          condition = false
          if (userType == 'admin' || userType == 'userManager' || (showAssignIntake)) {
            condition = true
          }
        }

        if (item.key == 'fcmtPendingApplicationsList') {
          condition = false
          if (approveFCMTLoa && approveFCMTLoa == true) {
            condition = true
          }
        }
        if (item.key == 'fcmt' || item.key == 'fcmtFilePendingForSubmissions' || item.key == 'fcmtUploadTT' || item.key == 'fcmtPaidTT') {
          condition = false
          if (userType == 'admin' || (user && user.email == emailListObj.ashuEmail)) {
            condition = true
          }
        }

        if (item.key == 'cornerstone' || item.key == 'cornerstoneFilePendingForSubmissions' ||
          item.key == 'cornerstoneUploadTT' || item.key == 'cornerstonePaidTT') {
          condition = false
          if ((enableUploadTt && enableUploadTt == true) || userType == 'admin' || ((userType == 'marketingManager' || department == departmentObj.marketing) && enableCornerStoneTtUpload) ||
            (countryId && countryId._id == countryIds.canada && userType == 'branchManager' && !branchManagerType)) {
            condition = true
          } else {
            if (currentUser && currentUser.email == accountantEmail && item.key == 'cornerstone') {
              condition = true
            }
          }
        }

        if (item.key == 'cambie' || item.key == 'cambieFilePendingForSubmissions' || item.key == 'cambieUploadTT' || item.key == 'cambiePaidTT') {
          condition = false
          if ((enableUploadTt && enableUploadTt == true) || userType == 'admin' || ((userType == 'marketingManager' || department == departmentObj.marketing) && enableCambieTtUpload) ||
            (countryId && countryId._id == countryIds.canada && userType == 'branchManager' && !branchManagerType)) {
            condition = true
          }
        }
        if (item.key == 'cira' || item.key == 'ciraFilePendingForSubmissions' || item.key == 'ciraUploadTT' || item.key == 'ciraPaidTT') {
          condition = false
          if ((enableUploadTt && enableUploadTt == true) || userType == 'admin' || (enableCiraTtUpload) ||
            (countryId && countryId._id == countryIds.canada && userType == 'branchManager' && !branchManagerType)) {
            condition = true
          } else {
            if (currentUser && currentUser.email == accountantEmail && item.key == 'cira') {
              condition = true
            }
          }
        }
        if (item.key == 'cambieFilePendingForSubmissions' || item.key == 'ciraFilePendingForSubmissions') {
          condition = false
          if (userType == 'admin' || (userType == 'marketingManager' && enableFcmtTtUpload) || (enableFcmtTtUpload && department == departmentObj.marketing) ||
            (enableCiraTtUpload) ||
            (countryId && countryId._id == countryIds.canada && userType == 'branchManager' && !branchManagerType)) {
            condition = true
          }
        }
        if (item.key == 'oldMarketingUserDashboard') {
          condition = false
          if (userType == 'branchUser' && department == departmentObj.marketing) {
            condition = true
          }
        }
        if (item.key == 'pendingOnShoreApplications') {
          let onshoreCountryId = countryId && countryId._id ? countryId._id : ''
          condition = false
          if (((userType == 'branchUser' && department == departmentObj.assDepartment) || (userType == 'branchManager')) && onshoreCountryId == countryIds.canadaOnshore) {
            condition = true
          }
        }


        if (item.key == 'enroll' || item.key == 'addEnrolledCompany' || item.key == 'allEnrolledCompanies') {
          condition = false
          if (department == departmentObj.marketing || userType == 'admin') {
            condition = true
          }
        }


        if (item.key == 'marketingAgentsList') {
          condition = false
          if ((department == departmentObj.marketing && dealCloseRight) || (userType == 'marketingManager' && dealCloseRight) || userType == 'admin') {
            condition = true
          }
        }
        if (item.key == 'allRequestedList') {
          condition = false
          if ((userType == 'marketingManager' && dealCloseRight)
            || userType == 'admin' || userType == 'agent') {
            condition = true
          }
        }

        if (item.key == 'allPendingForGcKeyApp') {
          condition = false
          if (showPendingGcKey) {
            condition = true
          }
        }
        if (item.key == 'allPendingForVisaApp') {
          condition = false
          if (showPendingVisa) {
            condition = true
          }
        }
        if (item.key == 'onshoreCambieAppList') {
          condition = false
          if (showOnShoreCambie || ['cambieBranchUser', 'cambieManager', 'cambieReception', 'cambieDirector', 'admin'].includes(userType)) {
            condition = true
          }
        }

        if (item.key == 'fcmtReceivedCommission' || item.key == 'ciraReceivedCommission' || item.key == 'generateDirectUniCommission') {
          condition = false
          if (userType == 'admin' || (currentUser && currentUser.email && currentUser.email == accountantEmail)) {
            condition = true
          }
        }

        if (item.key == 'tasksList' || item.key == 'futureTasksList') {
          condition = taskRights.userRight()
        }

        if (item.key == 'onshoreFcmtAppList' || item.key == 'fcmtAgentList') {
          condition = false
          if (userType == 'admin' || (userType == 'branchManager' && showOnShoreFcmt)) {
            condition = true
          }
        }

        if (item.key == 'institutesList') {
          condition = false
          if ((referCodeForInstitute)) {
            condition = true
          }
        }

        if (item.key == 'applyEvent' || item.key == 'pastEvents') {
          condition = false
          if (referByUser == 'institute') {
            condition = true
          }
        }

        if (item.key == 'contractList') {
          condition = false
          if (showContract) {
            condition = true
          }
        }
        if (item.key == 'workshopsRoiList' || item.key == 'icefEventList' || item.key == 'addParticipant' || item.key == 'participantsList' || item.key == 'addIcefEvents') {
          condition = false
          if (showIcef) {
            condition = true
          }
        }
        if (item.key == 'counsellingStudentList') {
          condition = false
          if (department == departmentObj.counselling || userType == 'eventManager' || userType == 'institute' || referCodeForInstitute) {
            condition = true
          }
        }
        if (item.key == 'gic') {
          condition = false
          if (userType == 'admin' || userType == 'gicManager' || userType == 'assistantMarketingManager' || userType == 'agent' ||
            (userType == 'branchManager' && !branchManagerType && countryId && countryId._id == countryIds.canada)) {
            condition = true
          }
        }

        if (item.key == 'requestCallBackList') {
          condition = false
          if (userType == 'admin' || (currentUser && currentUser.email == 'gehana@unizportal.com')) {
            condition = true
          }
        }
        if (item.key == 'uploadPal') {
          condition = false
          if (userType == 'admin' || userType == 'cornerStoneManager') {
            condition = true
          }
        }
        if (item.key == 'uploadedPalList') {
          condition = false
          if (userType == 'admin' || (currentUser && currentUser.email == 'khushi@unizportal.com') || userType == 'cornerStoneManager') {
            condition = true
          }
        }

        if (item.key == 'instituteStudentList') {
          condition = false
          if (userType == 'institute' || referCodeForInstitute) {
            condition = true
          }
        }
        if (item.key == 'callbackRequestList') {
          condition = false
          if (userType == 'admin' || (userType == 'branchUser' && (department == departmentObj.marketing || (user && user.email == emailListObj.australiaCallbackUser)))) {
            condition = true
          }
        }

        if (item.key == 'travelPlan') {
          condition = false
          if ((userType == 'branchUser' && department == departmentObj.marketing) || userType == 'admin' || userType == 'assistantMarketingManager') {
            condition = true
          }
        }
        if (item.key == 'travelPlanList') {
          condition = false
          if ((userType == 'branchUser' && department == departmentObj.marketing) ||
            userType == 'admin' || userType == 'assistantMarketingManager' || userType == 'marketingManager'
          ) {
            condition = true
          }
        }
        if (item.key == 'fcmtApplicationsList') {
          condition = false
          if (user) {
            let resp = checkShowFcmtCollegeRight(user, currentUserRights)
            condition = resp
          }
        }

        if (item.key == 'cornerStoneVisaApprovedApps') {
          condition = false
          if (userType == 'admin' || (user && user.email == 'khushi@unizportal.com')) {
            condition = true
          }
        }

        if (item.key == 'advanceCommissionReqList') {
          condition = false
          if (userType == 'admin' || (user && user.email == 'shellwin@unizportal.com')) {
            condition = true
          }
        }
        if (item.key == 'countryWiseAllCourse') {
          condition = false
          if (user && user.email == 'akshay@unizportal.com') {
            condition = true
          }
        }

        /* if (item.key == 'advanceCommissionContract') {
           condition = false
           if (showAdvanceContract) {
             condition = true
           }
         }*/

        if (item.key == 'agentListByDirectUniversity') {
          condition = false
          if (department == departmentObj.marketing) {
            condition = true
          }
        }
        if (item.key == 'universityWiseReports') {
          condition = false
          if (user && user.email == 'shellwin@unizportal.com') {
            condition = true
          }
        }
        if (item.key == 'fcmtGenerateCommission' || item.key == 'ciraGenerateCommission') {
          condition = false
          if (user && user.email == accountantEmail) {
            condition = true
          }
        }
        if (item.key == 'eventList') {
          condition = false
          if (user && (userType == 'admin' || userType == 'userManager' || bloomsburyShowToUser.includes(user.email))) {
            condition = true
          }
        }
        if (item.key == 'bloomsburyInstituteApplications') {
          condition = false
          if (user && (userType == 'admin' || bloomsburyShowToUser.includes(user.email))) {
            condition = true
          }
        }
        if (item.key == 'allCountriesStatusList') {
          condition = false
          if (user && (userType == 'admin' || user.email == 'hitanshu@unizportal.com')) {
            condition = true
          }
        }

        if (['studentInsurance', 'insuranceList'].includes(item.key)) {
          condition = false
          if (user && ([...adminRightUser, 'oshcManager', 'agent'].includes(user.userType) ||
            (countryId && countryId._id == countryIds.australia && userType == 'branchManager' && !branchManagerType))) {
            condition = true
          }
        }

        if (currentUser && currentUser.userType && currentUser.userType == 'student') {
          let regStuKeys = ['referralComponent', 'yourReferrals', 'studentWithdrawal', 'transactionList', 'canadaOnShoreCourseListStudent', 'canadaLocalOffice']
          let unRegStuKeys = ['instituteEvents', 'registeredEvents', 'searchCourse', 'canadaOpenCloseCourse', 'ukOpenCloseCourse', 'usaOpenCloseCourse', 'bookCounselling', 'counsellingSessions']
          if (regStuKeys.includes(item.key)) {
            condition = currentUser.directRegistration
          }
          if (unRegStuKeys.includes(item.key)) {
            condition = !currentUser.directRegistration
          }
        }


        /*   if (item.key == 'exchangeComponent' || item.key == 'editTxnStudent') {
               condition = false
               if (user && (userType == 'admin' || (userType == 'agent'))) {
                   condition = true
               }
           }
*/


        if (item.key == 'universitySearchCourse') {
          condition = false
          if (user && ((countryId && countryId._id == countryIds.uk && userType == 'branchManager' && !branchManagerType) ||
            (countryId && countryId._id == countryIds.uk && userType == 'branchUser' && department == departmentObj.assDepartment) ||
            [...adminRightUser, 'userManager', ...agentTeamTypes, 'eligibilityMaster'].includes(userType))) {
            condition = true
          }
        }
        if (item.key == 'applicationRecord') {
          condition = false
          if (user && (adminRightUser.includes(userType) || ['varun@unizportal.com', 'rohit1@unizportal.com'].includes(user.email) || department == departmentObj.marketing)) {
            condition = true
          }
        }
        if (item.key == 'diwaliGiftDistribution') {
          condition = false
          if (user && ([...adminRightUser, 'branchUser', 'marketingManager'].includes(userType) || (department == departmentObj.marketing))) {
            condition = true
          }
        }
        if (item.key == 'unizhomeContract') {
          condition = false
          if (user && ([...adminRightUser, 'unizHomeHead'].includes(userType) || (unizhomeManager))) {
            condition = true
          }
        }
        if (item.key == 'agentEnquiryList' || item.key == 'singleEnquiryDetails') {
          condition = false
          if (user && ([...adminRightUser, 'assistantMarketingManager', 'onBoardManager', 'unizHomeHead'].includes(userType) || (unizhomeManager))) {
            condition = true
          }
        }
        if (item.key == 'txnList') {
          condition = false
          if (user && ([...adminRightUser, 'agent', 'reeudoManager', 'assistantMarketingManager'].includes(userType) || (userType == 'branchUser' && department == departmentObj.marketing))) {
            condition = true
          }
        }
        if (marketingRoutes(this.props.currentUserRights) && marketingRoutes(this.props.currentUserRights).length) {
          if (marketingRoutes(this.props.currentUserRights).includes(item.key)) {
            condition = true
          } else {
            condition = false
          }
        }
        if (item.key == 'allEmployees') {
          condition = false
          if (user && ([...adminRightUser, 'hr'].includes(userType) || (userType == 'branchUser' && department == departmentObj.visaApproved))) {
            condition = true
            if (userType == 'branchUser' && department == departmentObj.visaApproved) {
              item.newLink = true
            }
          }
        }

        if (item.key == 'addDepartmentExpense' || item.key == 'departmentExpenseList' || item.key == 'profitLossStatement') {
          condition = false
          if (user && ([...adminRightUser].includes(userType) || (userType == 'branchUser' && department == departmentObj.visaApproved))) {
            condition = true
          }
        }

        if (item.key == 'complaintList') {
          condition = false
          if ([...adminRightUser, 'branchUser', 'branchManager', 'onBoardManager'].includes(userType)) {
            condition = true
          }
        }
        if (item.key == 'agentCommissionBonusList') {
          condition = false
          if (adminRightUser.includes(userType) || (currentUser && currentUser.email && currentUser.email == accountantEmail)) {
            condition = true
          }
        }
        if (item.key == 'studentAccount') {
          condition = false
          if ([...adminRightUser, 'canadaOnshoreAdmin'].includes(userType) || (user && user.email && user.email == 'akshay@unizportal.com')) {
            condition = true
          }
        }

        if (item.key == 'xTravelFundRequestList') {
          condition = false
          if (userType == 'admin' || xTravelFundTransferRight || userType == 'assistantMarketingManager') {
            condition = true
          }
        }
        if (item.key == 'xTravelAddFundTransfer') {
          condition = false
          if (user && user.email == emailListObj.namanEmail) {
            condition = true
          }
        }

        return condition
      } else {
        // this.props.dispatch(push('/login'))
      }
    }
    const checkRightsFxn = item => {
      if (currentUserRights && currentUserRights._id) {
        return checkRightInner(currentUserRights, item)
      } else {
        if (user && user._id) {
          return checkRightInner(currentUserRights, user)
        }
      }
    }

    return (
      <div key={user}>
        {pageLoading && (
          <div className={'outerLoader'}>
            <Spin style={{ fontSize: 100 }} />
            Loading...
          </div>
        )}
        <Switch>
          {menuData.map((item, key) => {
            if (!item.children) {
              let routeBox = (
                <Route
                  exact
                  path={item.path}
                  key={item.path}
                  render={route => {
                    return (
                      <React.Fragment>
                        {item.authority && item.authority.length ? (
                          item.authority.includes(userType) ? (
                            <BasicLayoutWrapper
                              component={item.component}
                              path={item.path}
                              user={user}
                              currentUserRights={currentUserRights}
                              menuData={menuData}
                            />
                          ) : (
                            <Route component={Exp} />
                          )
                        ) : (
                          <BasicLayoutWrapper
                            component={item.component}
                            path={item.path}
                            user={user}
                            currentUserRights={currentUserRights}
                            menuData={menuData}
                          />
                        )}
                      </React.Fragment>
                    )
                  }}
                />
              )
              return item.restrict
                ? checkRightsFxn(item)
                  ? routeBox
                  : null
                : routeBox
            }
          })}
          {menuData.map((item, key) => {
            if (item.children) {
              return item.children.map((child, k) => {
                let routeBox = (
                  <Route
                    exact
                    path={child.path}
                    key={child.path}
                    render={route => {
                      return (
                        <React.Fragment>
                          {child.authority && child.authority.length ? (
                            child.authority.includes(userType) ? (
                              <BasicLayoutWrapper
                                component={child.component}
                                path={child.path}
                                user={user}
                                menuData={menuData}
                              />
                            ) : (
                              <Route component={Exp} />
                            )
                          ) : (
                            <BasicLayoutWrapper
                              component={child.component}
                              path={child.path}
                              user={user}
                              menuData={menuData}
                            />
                          )}
                        </React.Fragment>
                      )
                    }}
                  />
                )
                return child.restrict
                  ? checkRightsFxn(child)
                    ? routeBox
                    : null
                  : routeBox
              })
            }
          })}

          {AuthRouteList.map(item => {
            return (
              <AuthRoute
                path={item.path}
                component={item.component}
                key={item.key}
              />
            )
          })}


          {ExtraRoutes.map((item, key) => {
            return (
              currentUser && currentUser.userType ? (
                <UnizHomeRoute
                  path={item.path}
                  component={item.component}
                  routeName={item.key}
                  key={item.key}
                />
              ) : null
            )
          })}


          {/*     <Route exact path='*'
                           render={route => {
                               return <Redirect to='/'/>
                           }}
                    />*/}

        </Switch>


        {FrontRoutesList.map((item, key) => {
          return (
            <FrontRoute
              path={item.path}
              component={item.component}
              key={item.key}
            />
          )
        })}

        {UnizHomeRoutesList.map((item, key) => {
          return (
            <UnizHomeRoute
              path={item.path}
              component={item.component}
              routeName={item.key}
              key={item.key}
            />
          )
        })}

        <Route
          exact
          path='/home'
          render={route => {
            return <Redirect to='/' />
          }}
        />


        <Suspense fallback={<></>}>
          <SocketComponent />
        </Suspense>

        <Suspense fallback={<></>}>
          <IdleTimer />
        </Suspense>


        {currentUser && currentUser._id ? (
          <>
            <Suspense fallback={<></>}>
              <ChatDrawer />
            </Suspense>
          </>
        ) : null}

        <JoinSocketComponent {...this.props} />
      </div>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  pageLoading: global.pageLoading,
  currentUser: global.currentUser,
  loadCurrentUser: global.loadCurrentUser,
  currentUserRights: global.currentUserRights,
  soundStatus: global.soundStatus
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
