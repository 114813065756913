import axios from 'axios'
import _ from 'lodash'
import {apiUrl, apiUrl2, xTravelWorldUrl} from './settings'


export const API_URL = apiUrl

export const authAxios = axios.create({
    baseURL: apiUrl
})
export const authAxios2 = axios.create({
    baseURL: apiUrl2
})

export const xTravelAxios = axios.create({
    baseURL: xTravelWorldUrl
})

export let customAxios = axios.create({})

const CheckAgentBlocked = () => {
    return new Promise((next) => {
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
        if (user && user.userType) {
            authAxios
                .post('/api/checkAgentBlock', {}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    handleErr(err)
                    next({error: true, err})
                })
        }
    })
}
customAxios.interceptors.response.use(function (response) {
    // console.log(response, 'response')
    // console.log(response)
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    if (response.status == 200) {
        /*CheckAgentBlocked().then(() => {

        })*/
    }
    return response
}, function (error) {
    handleErr(error)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
})


export const getToken = () => {
    return ({
        'headers': {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
            'Access-Control-Allow-Credentials': true,
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'X-Requested-With': 'XMLHttpRequest',
            'crossdomain': true,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
            "withCredentials": true
        }
    })
}

export const getUserToken = async () => {
    return new Promise(async (next, error) => {
        try {
            // console.log("token is", await AsyncStorage.getItem('token'))
            next(localStorage.getItem('token'))
        } catch (e) {
            next(null)
        }
    })
}

export const getUserData = async () => {
    return new Promise((next, error) => {
        try {
            next(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null)
        } catch (e) {
            next(null)
        }
    })
}

export const handleErr = (err) => {
    try {
        if (err.response.status === 401) {
            let dontAskOtpForIds = _.clone(localStorage.getItem('dontAskOtpForIds'))
            localStorage.clear()
            // localStorage.setItem('dontAskOtpForIds', dontAskOtpForIds)
            window.location.reload()
            // console.log('this should be here ')
        }
    } catch (e) {
    }
}
export const getLoginUser = () => {
    return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
}


class Request {

    constructor() {

    }

    error = (err) => {
        try {
            if (err.response.status === 401) {
                localStorage.clear()
            }
        } catch (e) {
        }
    }

    login(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/loginadmin', data)
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getAllUser(data) {
        return new Promise((next) => {
            authAxios
                .get('/users', {params: {...data}}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    addUser(data) {
        return new Promise((next) => {
            authAxios
                .post('/users', {...data}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }


    addInstitution(data) {
        return new Promise((next) => {
            authAxios
                .post('/institution', {...data})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getInstitution({id}) {
        return new Promise((next) => {
            authAxios
                .get(`/institution/${id}`, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    editInstitution(data, {id}) {
        return new Promise((next) => {
            authAxios
                .put(`/institution/${id}`, {...data}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    deleteInstitution({id}) {
        return new Promise((next) => {
            authAxios
                .delete(`/institution/${id}`, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getAllInstitution(data) {
        return new Promise((next) => {
            authAxios
                .get('/institution', {params: {...data}})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    addMakes(data) {
        return new Promise((next) => {

            authAxios.post('/backOffice/make', {...data}, getToken())
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    getAllMakes(data) {
        return new Promise((next) => {
            authAxios.get('/backOffice/make', {params: {...data}}, getToken())
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    getAllModels(data) {
        return new Promise((next) => {
            authAxios.get('/backOffice/make/model/' + data.make, {params: {...data}}, getToken())
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    addFuel(data) {
        return new Promise((next) => {
            authAxios.post('/backOffice/fuelType', data, getToken())
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    getAllFuels(data) {
        return new Promise((next) => {
            authAxios.post('/backOffice/make/model/fuelTypes', data, getToken())
                .then((d) => {

                    next(d)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    editFuelType(data) {
        return new Promise((next) => {
            authAxios.put('/backOffice/fuelType/' + data._id, data, getToken())
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    getFuel({id, modelId}) {
        return new Promise((next) => {
            authAxios.get('/backOffice/fuel/' + id, {params: {modelId}}, getToken())
                .then((d) => {
                    next(d)
                }).catch((err) => {
                next({error: true, err})
            })

        })
    }

    getVariant(data) {
        return new Promise((next) => {
            authAxios.post('/backOffice/variant/data', data, getToken())
                .then((d) => {
                    next(d)
                }).catch((err) => {
                next({error: true, err})
            })

        })
    }

    deleteFuel(data) {
        return new Promise((next) => {
            let {val} = data

            authAxios.delete('/backOffice/fuelType/' + val._id, {data})
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    deleteVariant(data) {
        return new Promise((next) => {
            let {val} = data

            authAxios.delete('/backOffice/variant/' + val._id, {data})
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    getMake({id}) {
        return new Promise((next) => {
            authAxios.get('/backOffice/make/' + id, {}, getToken())
                .then((d) => {
                    next(d.data)

                }).catch((err) => {
                next({error: true, err})
            })


        })
    }


    editMake(data) {
        return new Promise((next) => {
            authAxios.put('/backOffice/make/' + data._id, {params: {...data}}, getToken())
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }


    deleteMake(data) {
        return new Promise((next) => {
            authAxios.delete('/backOffice/make/' + data._id, {params: {...data}}, getToken())
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    addModel(data) {
        return new Promise((next) => {

            authAxios.post('/backOffice/model', {...data}, getToken())
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    editModel(data) {
        return new Promise((next) => {
            authAxios.put('/backOffice/model/' + data.modelId, data, getToken())
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    deleteModel(data) {
        return new Promise((next) => {
            let {val} = data
            // axios.delete(url, { data: { foo: "bar" } });

            authAxios.delete('/backOffice/model/' + data.make, {data: val})
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    getModel({id}) {
        return new Promise((next) => {
            authAxios.get('/backOffice/model/' + id, getToken())
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    addVariant(data) {
        return new Promise((next) => {
            authAxios.post('/backOffice/variant', data, getToken())
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    getAllVariants(data) {
        return new Promise((next) => {
            authAxios.post('/backOffice/make/model/fuelType/variants', data, getToken())
                .then((d) => {
                    next(d)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    editVariant(data) {
        return new Promise((next) => {
            authAxios.put('/backOffice/variant/' + data._id, data, getToken())
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }


    addCar(data) {
        return new Promise((next) => {

            authAxios.post('/car', {...data}, getToken())
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    getAllCars(data) {
        return new Promise((next) => {
            authAxios.get('/backOffice/cars', {params: {...data}}, getToken())
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    getAllDealers(data) {
        return new Promise((next) => {
            authAxios.get('/backOffice/dealers', {params: {...data}}, getToken())
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    getAllRequirements(data) {
        return new Promise((next) => {
            authAxios.get('/backOffice/requirements', {params: {...data}}, getToken())
                .then((d) => {

                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    dealerFavDealer(data) {
        return new Promise((next) => {
            let d = {}
            d.id = data
            authAxios.post('/backOffice/dealers/favorite/list', d, getToken())
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }


    deleteDealer(data) {
        return new Promise((next) => {
            authAxios.delete('/backOffice/dealer/' + data._id, getToken())
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    deleteCar(data) {
        return new Promise((next) => {
            authAxios.delete('/backOffice/car/' + data._id, getToken())
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    carImage(data) {
        return new Promise((next) => {
            authAxios.post('/backOffice/car/getCarImage', data, getToken())
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    getAllVariantsYear(data) {
        return new Promise((next) => {
            authAxios.post('/backOffice/make/model/fuelType/variants/year', data, getToken())
                .then((d) => {
                    next(d)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    deleteVariantYear(data) {
        return new Promise((next) => {
            authAxios.post('/backOffice/variantYear/year', {...data})
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    addVariantYear(data) {
        return new Promise((next) => {
            authAxios.post('/backOffice/variantYear', data, getToken())
                .then((d) => {
                    next(d.data)
                }).catch((err) => {
                next({error: true, err})
            })


        })
    }

    uploadDocument(images) {
        let form = new FormData()
        _.each(images, (item) => {
            form.append('document', item)
        })
        return new Promise((next, error) => {
            authAxios
                .post('/multiFilesUploader', form)
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    error(err)
                })
        })
    }

    loginSendOtp(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/login/sendOtp', data)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {

                    this.error(err)
                })
        })
    }

    loginWithOtp(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/login/loginByOtp', data)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    this.error(err)
                })
        })
    }

    resendOtp(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/resendOtp', data)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    this.error(err)
                })
        })
    }


    updateUserPassword(data) {
        return new Promise(next => {
            authAxios
                .post(`/updateUserPassword`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {

                    this.error(err)
                })
        })
    }

    updateAgentPassword(data) {
        return new Promise(next => {
            authAxios
                .post(`/updateAgentPassword`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {

                    this.error(err)
                })
        })
    }

    getStudentOfferLetterFxn(data) {
        return new Promise(next => {
            authAxios
                .post(`/api/getStudentOfferLetter`, data)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {

                    this.error(err)
                })
        })
    }

    checkAgentLoginTime() {
        return new Promise((next) => {
            let user = (localStorage.getItem('user') != 'undefined') ? JSON.parse(localStorage.getItem('user')) : null
            // console.log(user, 'user')
            if (!user) {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                window.location.reload()
            }
        })
    }

}


export default new Request()
