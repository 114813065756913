import React, { useState } from 'react'
import { Avatar, Icon } from 'antd'
import userIcon from '../../../assets/relationshipManager/user.png'
import mobileIcon from '../../../assets/relationshipManager/mobile.png'
import emailIcon from '../../../assets/relationshipManager/email.png'
import phoneIcon from '../../../assets/relationshipManager/phone.png'
import RequestCallback from '../drawers/requestCallback'
import { countryIds } from '../../../components/_utils/appUtils'

let countryColors = {
  UK: '#ff0066',
  USA: '#ff0066',
  Australia: '#003399',
  'Australia Onshore': '#003399',
  Canada: '#ad2125',
  'Canada Onshore': '#ad2125',
  Germany: '#ad2125',
  'Cyprus': '#ad2125',
  'Europe': '#ad2125',
  'New Zealand': '#ad2125',
  'Singapore': '#ad2125',
  'France': '#ad2125',
  'Netherlands': '#ad2125',
  'Switzerland': '#ad2125'
}
const CountryRelationshipManager = (props) => {
  let { manager, currentUser } = props
  let [requestACallVisible, setRequestACallModal] = useState({
    visible: false,
    assignedTo: '',
    countryId: '',
    requestType: ''
  })
  const events = {
    showRequestACall: async (data) => {
      setRequestACallModal({
        visible: true,
        assignedTo: data.userId,
        countryId: countryIds.australia,
        requestType: 'supportingCallback'
      })
    },
    hideRequestACall: async () => {
      setRequestACallModal({
        visible: false,
        assignedTo: '',
        countryId: '',
        requestType: 'supportingCallback'
      })
    }
  }
  return (
    <>
      {manager && manager.userId && currentUser && currentUser.userType == 'agent' ? <div className={'w-100'}>
        <div className={'country-manager card-pane-new listing-section float-right'}>
          <div className={'manager-title'}>Your Relationship Manager</div>
          <div className={'manager-box'}>
            <Avatar
              size={55}
              className={'manager-avatar'}
              style={{
                borderColor: countryColors['Australia']
              }}
              src={'../dist/img/user.png'}
            />
            <div className={'manager-info'}>
              <div className={'aic manager-name'}>
                {manager.name}
              </div>
              <div className={'manager-mob-email'}>
                <div>
                  <div className={'aic'}>
                    <img src={emailIcon} /> {manager.email}
                  </div>
                  <div className={'aic'}>
                    <img src={mobileIcon} /> {manager.countryCode} {manager.mobile}
                  </div>
                </div>
                <a className={'request-callback-btn'} onClick={() => {
                  events.showRequestACall(manager)
                }}>
                  Request a Callback
                  <img src={phoneIcon} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> : null}
      {requestACallVisible.visible ?
        <RequestCallback
          {...requestACallVisible}
          onClose={events.hideRequestACall}
        /> : null}
    </>
  )
}
export default CountryRelationshipManager
