import React, { useEffect, useState } from 'react'
import {
  Button, Col, Form, notification, Row, Tooltip, Table, Popconfirm, Drawer, Tabs, Card, Icon
} from 'antd'
import { connect } from 'react-redux'
import { StatusTypes } from '../utils/propertyUtils'
import { useDispatch } from 'react-redux'
import {
  commonRoomAmenitiesFxn, deleteMediaFxn,
  deleteRoomMediaImageFxn, deleteRoomPolicyFxn, deleteUnizhomePolicyFxn,

  editUnizhomeRoomFxn,
  getAmenitiesFxn,
  getMediaFxn, getRoomUnitTypeFxn, updateRoomFeaturedImageFxn
} from '../action'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import AmenitiesModal from '../modal/addPropertyAmenities'
import EditPropertyImage from '../modal/editPropertyImage'
import EditRoomDescriptionModal from '../modal/editRoomDescriptionModal'
import AmenitiesComponent from './amenitiesComponent'
import moment from 'moment'
import renderHTML from 'react-render-html'
import AddPropertyImage from '../modal/addPropertyImage'

const { TabPane } = Tabs

const CustomCard = (props) => {
  let { title, children, extra } = props
  return (
    <>
      <div className='card p0'>
        <div className='table-head d-flex align-items-center all-student-search p0'>
          <h5 className={'mb10 ml-2'}>{title}</h5>
          <div className='search-box-table round'>
          </div>
          <div className='sort-box-table mark-btn'>
            <div className={'aic'}>
              {extra ? extra : null}
            </div>
          </div>
        </div>
        <div className='card-body table-responsive p0'>
          {children}
        </div>
      </div>
    </>
  )
}

const EditPropertyRoom = (props) => {
  let dispatch = useDispatch()
  const [roomUnitType, setRoomUnitType] = useState([])
  let { visible, onClose, form: { getFieldDecorator, setFieldsValue }, propertyId, room, onSubmit } = props

  const [amenities, setAmenities] = useState({})
  const [mediaData, setMediaData] = useState([])
  const [openAmenities, setOpenAmenities] = useState({
    visible: false,
    amenities: {}
  })
  const deleteRoomImage = async (record, roomId) => {

    let  resp  = await dispatch(deleteMediaFxn({ propertyId: propertyId, imageId: record._id, roomId: roomId && roomId }))
    if (resp && resp.success) {
      events.getMedia()
    }
  }
  const getRoomUnitType = async (record) => {

    let { data } = await dispatch(getRoomUnitTypeFxn())
    setRoomUnitType(data)

  }
  useEffect(() => {
    getRoomUnitType()
  }, [])
  const [imagesVisible, setImagesVisible] = useState({
    visible: false,
    imageData: {},
    propertyId: '',
    roomId: ''

  })
  let [imagesAddVisible, setImagesAddVisible] = useState({
    visible: false, imageData: {},
    propertyId: '',
    roomId: ''
  })
  const [editDescription, setEditDescription] = useState({
    visible: false,
    policy: {},
    propertyId: '',
    roomId: ''
  })
  const setDefaultValues = () => {
    setTimeout(() => {
      setFieldsValue({
        name: room.name,
        lease_duration: room.meta && room.meta.lease_duration || '',
        lease_duration_days: room.meta && room.meta.lease_duration_days || '',
        lease_duration_unit: room.meta && room.meta.lease_duration_unit || '',
        available_from_formatted: room.meta && room.meta.available_from_formatted ? moment(room.meta.available_from_formatted, 'DD MMM, YYYY') : null,
        roomStatus: room.roomStatus || '',
        unit_type: room.meta.unit_type || ''
      })
    }, 500)
  }
  let inputTypes = {
    fields: [
      {
        key: 'name',
        label: 'Room Name',
        placeholder: 'Edit Room',
        type: 'text',
        required: true
      },
      {
        key: 'lease_duration',
        label: 'Lease Duration',
        placeholder: 'Enter lease duration'
      },
      {
        key: 'lease_duration_days',
        label: 'Lease Duration (Days)',
        placeholder: 'Enter lease duration in days'
      },
      {
        key: 'lease_duration_unit',
        label: 'Lease Duration Unit',
        placeholder: 'Enter lease duration unit'
      },
      {
        key: 'available_from_formatted',
        label: 'Available From',
        placeholder: 'Available From',
        type: 'date',
        required: true
      },
      // {
      //     key: 'status',
      //     label: 'Status',
      //     placeholder: 'Edit status',
      //     type: 'text',
      //     required: true,
      // },
      {
        key: 'roomStatus',
        label: 'Status',
        placeholder: 'Edit Status',
        type: 'select',
        options: StatusTypes,
        keyAccessor: x => `${x.name}`,
        valueAccessor: x => `${x.value}`,
        onChange: x => {
          setFieldsValue({
            roomStatus: x
          })
        }
      },
      {
        key: 'unit_type',
        label: 'Unit Type',
        placeholder: 'Unit Type',
        type: 'select',
        options: roomUnitType,
        keyAccessor: x => `${x.name}`,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          setFieldsValue({
            unit_type: x
          })
        }
      }
    ]
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    const { form } = props
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        const updatedRoom = {
          _id: room._id,
          name: valData.name,
          roomStatus: valData.roomStatus,
          meta: {
            lease_duration: valData.lease_duration,
            lease_duration_days: valData.lease_duration_days,
            lease_duration_unit: valData.lease_duration_unit,
            unit_type: valData.unit_type,
            available_from_formatted: valData.available_from_formatted ? moment(valData.available_from_formatted).format('DD MMM, YYYY') : null
          }
        }

        const resp = await dispatch(editUnizhomeRoomFxn({
          propertyId: propertyId,
          roomId: room._id,
          room: updatedRoom
        }))
        if (resp && resp.success) {
          notification.success({ message: resp.message })
          onSubmit()
        }
      } else {
        notification.warning({
          message: 'Please fill in all required fields'
        })
      }
    })
  }

  const handleUpdateFeatures = async (amenities) => {
    const updatedFeatures = {
      ...amenities,
      _id: room._id
    }
    let resp = await dispatch(editUnizhomeRoomFxn({ propertyId: propertyId, room: [updatedFeatures] }))
    if (resp.success) {
      events.getMedia()
      notification.success({ message: resp.message })
    } else {
      notification.error({ message: resp.message })
    }
  }
  const updateRoomFeaturedImage = async (data, propertyId) => {
    let finalObj = {
      _id: data._id,
      roomId: data.roomId,
      propertyId: propertyId
    }

    try {
      let data = await dispatch(updateRoomFeaturedImageFxn(finalObj))
      if (data.success) {
        events.getMedia()
      }
    } catch (err) {
      console.log(err)
    }


  }
  const imagesColumns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (val, record, index) => {
        return index + 1
      }
    },
    {
      title: 'Image',
      dataIndex: 'path',
      key: 'path',
      render: (val, record) => {
        return (
          <p>
            <img src={record.path} height={50} width={'auto'} />
          </p>
        )
      }
    },

    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Featured',
      dataIndex: 'featured',
      key: 'featured',
      render: (val, record) => {
        return (
          <p>
            {
              record.featured ?
                <label className={'label label-success sm pointerCls'}>Featured</label> :
                <Popconfirm
                  title={`Are you sure, you want make this feature Image?`}
                  onConfirm={() => updateRoomFeaturedImage(record, propertyId)}
                  okText='Yes'
                  cancelText='No'>
                  <label className={'label label-info sm pointerCls'}>Non Featured</label>
                </Popconfirm>
            }
          </p>
        )
      }
    },
    {
      title: 'Action',
      key: '_id',
      dataIndex: '_id',
      width: 200,
      render: (val, record) => {
        let { featured } = record
        return (
          <React.Fragment>
            <Tooltip title='Edit Image'>
              <a className={'btn btn-default roundNew sm mr5'}
                 onClick={() => events.showImagesModal(record)}>
                Edit
              </a>
            </Tooltip>
            {featured ? null : <Tooltip title='Delete'>
              <Popconfirm
                title='Are you sure, you want to delete this record?'
                onConfirm={() => deleteRoomImage(record, room._id)}>
                <a className={'btn btn-danger roundNew sm'}>
                  Delete
                </a>
              </Popconfirm>
            </Tooltip>}
          </React.Fragment>
        )
      }
    }
  ]
  // const videosColumns = [
  //     {
  //         title: '#',
  //         dataIndex: 'index',
  //         key: 'index',
  //         render: (val, record, index) => {
  //             return index + 1
  //         }
  //     },
  //     {
  //         title: 'Video',
  //         dataIndex: 'base_path',
  //         key: 'base_path',
  //         render: (val, record) => {
  //             console.log(val, record, "zzzzzzzzzzzzzzz")
  //             return (
  //                 <a href={val}>
  //                     <video src={val} height='100px' width='120px'/>
  //                 </a>
  //             )
  //         }
  //     },
  //     {
  //         title: 'Caption',
  //         dataIndex: 'caption',
  //         key: 'caption',
  //     },
  //     {
  //         title: 'Type',
  //         dataIndex: 'type',
  //         key: 'type',
  //     },
  //     {
  //         title: 'Upload Date',
  //         dataIndex: 'upload_date',
  //         key: 'upload_date',
  //     },
  //     {
  //         title: 'Action',
  //         key: '_id',
  //         dataIndex: '_id',
  //         width: 150,
  //         render: (val, record) => {
  //             return (
  //               <React.Fragment>
  //                   <Tooltip title='Edit Video'>
  //                       <a
  //                         className={'linkAA'}
  //                         onClick={() => events.showImagesModal(record)}
  //                       >
  //                           <Icon type={'edit'} />
  //                       </a>
  //                   </Tooltip>
  //               </React.Fragment>
  //             )
  //         }
  //     }
  //
  // ]

  const policyColumns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (val, record, index) => {
        return index + 1
      }
    },
    {
      title: 'Display Name',
      dataIndex: 'display_name',
      key: 'display_name'

    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'

    },
    {
      title: 'Description',
      dataIndex: 'value',
      key: 'value',
      render: (text) => {
        return (
          <>
            {/*<span>{text && text.length > 180 ? `${text.slice(0, 180)}...` : text}</span>*/}
            {renderHTML(text)}
          </>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <>
          <Tooltip title='Edit Description'>
            <a className={'btn btn-default roundNew sm'}
               onClick={() => events.editDescriptionModal(record)}>
              Edit
            </a>
          </Tooltip>
          <Tooltip title='Delete Description'>
            <Popconfirm
              title={'Are you sure, you want to delete this Image?'}
              onConfirm={() => events.deletePolicy(record._id, room._id)}>
              <a className={'btn btn-danger roundNew sm ml5'}>
                Delete
              </a>
            </Popconfirm>
          </Tooltip>
                 </>
      )
    }
  ]
  useEffect(() => {
    setDefaultValues()
    events.getAmenities()
    events.getMedia()
  }, [room])

  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }
  const events = {
    hideAmenitiesModal: async () => {
      setOpenAmenities({
        visible: false,
        amenities: {}

      })

    },
    showAddImagesModal: async (imageData) => {

      setImagesAddVisible({
        visible: true,
        imageData: imageData,
        propertyId: propertyId,
        roomId: imageData._id
      })
    },
    hideAddImagesModal: async () => {
      setImagesAddVisible({
        visible: false,
        imageData: {},
        propertyId: '',
        roomId: ''
      })
      onSubmit()
    },
    showImagesModal: async (imageData) => {

      setImagesVisible({
        visible: true,
        imageData: imageData,
        propertyId: imageData.propertyId,
        roomId: imageData.roomId,
        ImageId: imageData._id
      })

    },
    hideImagesModal: async () => {
      setImagesVisible({
        visible: false,
        imageData: {},
        propertyId: '',
        ImageId: ''
      })
    },
    editDescriptionModal: async (policy) => {
      setEditDescription({
        visible: true,
        policy: policy,
        propertyId: propertyId,
        roomId: room._id
      })
    },
    hideDescriptionModal: async () => {
      setEditDescription({
        visible: false,
        policy: {},
        propertyId: '',
        roomId: ''
      })
      onSubmit()
    },
    deletePolicy: async (policyId, roomId) => {

      let resp = await dispatch(deleteRoomPolicyFxn({ propertyId: propertyId,policyId:policyId, roomId: roomId }))
      if (resp.success) {
        notification.success({ message: resp.message })
        onSubmit()
      } else {
        notification.error({ message: resp.message })
      }
    },
    getAmenities: async () => {
      let { data } = await dispatch(getAmenitiesFxn({ propertyId, roomId: room._id }))
      if (data && data.amenities) {
        setAmenities(data.amenities)
      }
    },
    getMedia: async () => {
      let data = await dispatch(getMediaFxn({ propertyId, roomId: room._id }))
      setMediaData(data.image.filter((item) => item.active == true));
      // setMediaData(data)
    }
  }
  return (
    <>
      <Drawer
        visible={visible}
        onClose={onClose}
        title={`Edit Room`}
        width={'80%'}
        footer={null}>
        <div className={'card unizportal'}>
          <Tabs>
            <TabPane tab='Basic Details' key='1'>
              <Form onSubmit={handleSubmit}>
                <Row gutter={24} className='wrapBox'>
                  {inputTypes.fields.map((item, key) => {
                    return (
                      <React.Fragment key={key}>
                        {item.customField ? (
                          <Col span={12}>
                            {item.customField}
                          </Col>
                        ) : ((item.hidden == undefined || (!item.hidden)) && (
                          <Col span={12}>
                            <GetEachFormFields
                              item={item}
                              getFieldDecorator={getFieldDecorator}
                              formItemLayout={formItemLayout}
                            />
                          </Col>
                        ))}
                      </React.Fragment>
                    )
                  })}
                </Row>
                <Row>
                  <Col span={4}>
                    <Form.Item>
                      <Button type='primary' htmlType='submit' className='btn mt40'>
                        Update
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </TabPane>
            <TabPane tab='Images' key='2'
              >
              <CustomCard title={'Images'} extra={(
                <a className={'btn btn-default roundNew sm'}
                   onClick={() => events.showAddImagesModal(room)}>+ Add Image</a>
              )}>
              <Table
                dataSource={mediaData}
                columns={imagesColumns}
                rowKey='_id'
                bordered
              />
              </CustomCard>
            </TabPane>
            <TabPane tab='Description' key='3'>
              <Table
                dataSource={room.description}
                columns={policyColumns}
                rowKey='_id'
                bordered
              />
            </TabPane>
            <TabPane tab='Amenities' key='4'>
              <Card>
                {/*   <Tooltip title='Edit Amenities'>
              <Icon
                    type='edit'
                    style={{ fontSize: '16px', cursor: 'pointer' }}
                    onClick={() => {
                      events.showAmenitiesModal(room.features)
                    }}
                  />
                </Tooltip>*/}
                {/*<AmenitiesComponent amenities={amenities} getAmenities={events.getAmenities} room={room} propertyId={propertyId} onClick={() => {*/}

                <AmenitiesComponent
                  propertyId={propertyId}
                  roomId={room._id}
                  refreshData={events.getAmenities}
                  amenities={amenities} />

                {/*    <Row gutter={24}>
                  {room.features && room.features.map((feature, index) => (
                    <Col span={12} key={feature._id}>
                      <div>
                        <strong className={'amenities-label'}>{feature.name}</strong>
                        <hr />
                        <div
                          onChange={checkedValues => {
                            console.log(`Updated values for ${feature.name}:`, checkedValues)
                          }}
                        >
                          <Row gutter={12}>
                            {feature.values.map((value) => (
                              <Col span={12} key={value._id}>
                                <p value={value._id} style={{ display: 'flex', alignItems: 'center' }}>
                                  <Icon type='check-circle' className='mr-3' style={{ color: 'green' }} />
                                  {value.name}
                                  <Icon
                                    type='close-circle'
                                    className='ml-3'
                                    style={{ color: 'red', cursor: 'pointer' }}

                                  />
                                </p>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      </div>
                      <br />
                    </Col>
                  ))}
                </Row>*/}
              </Card>
            </TabPane>
          </Tabs>
        </div>

        {openAmenities.visible && (
          <AmenitiesModal
            {...openAmenities}
            onClose={() => events.hideAmenitiesModal()}
            onSubmit={(updatedAmenities) => {
              setAmenities(updatedAmenities)
              handleUpdateFeatures(updatedAmenities)
            }}
          />
        )}
        {imagesAddVisible.visible && (
          <AddPropertyImage
            {...imagesAddVisible}
            propertyId={propertyId}
            refreshData={events.getMedia}
            onClose={() => events.hideAddImagesModal()}
          />
        )}
        {imagesVisible.visible && (
          <EditPropertyImage
            {...imagesVisible}
            refreshRecord={events.getMedia}
            onClose={() => events.hideImagesModal()}

          />
        )}
        {editDescription.visible && (
          <EditRoomDescriptionModal
            propertyId={propertyId}
            {...editDescription}
            onClose={() => events.hideDescriptionModal()}
            onSubmit={() => {
              events.hideDescriptionModal()
            }}
          />
        )}
      </Drawer>
    </>
  )
}

const EditPropertyRoomModal = Form.create()(EditPropertyRoom)

const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})

const mapDispatchToProps = dispatch => ({
  dispatch
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPropertyRoomModal)
