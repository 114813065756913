import React, { useState } from 'react'
import { Button, Card, Drawer, Form, Input } from 'antd'
import { InputBox } from '../../../components/_utils/appUtils'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { updateTuitionFeesFxn } from '../actions'

const UpdateTuitionFeesModal = (props) => {
  let { visible, onClose, record } = props
  let { studentId } = record
  let dispatch = useDispatch()
  let [state, setState] = useState({
    documentKey: moment(),
    sendToStudent: false
  })
  let [amount, setAmount] = useState(record.amount)

  const events = {
    chooseDocument: (e) => {
      let { files } = e.target
      if (files && files.length) {
        setState({
          ...state,
          document: files[0]
        })
      }
    },

    handleSubmit: async () => {
      let obj = {
        studentId: studentId,
        amount: amount
      }
      let data = await dispatch(updateTuitionFeesFxn(obj))
      if (data && !data.error) {
        onClose()
      }
    }
  }

  return (
    <Drawer width={500}
            title={'Update Tuition Fees Modal'}
            visible={visible}
            onClose={() => {
              onClose()
            }}>
      <Card bordered={false}>
        <Form>
          <InputBox title={'Amount'}>
            <Input type={'number'}
                   className={'form-control'}
                   value={amount}
                   onChange={(e) => {
                     setAmount(e.target.value)
                   }} />
          </InputBox>
          <Form.Item className={'mt20 alignRight'}>
            <Button type='primary' onClick={events.handleSubmit}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Drawer>
  )
}
export default UpdateTuitionFeesModal
