import React, { useState } from 'react'
import { Button, Card, DatePicker, Drawer, Form, Input, notification } from 'antd'
import { InputBox } from '../../../components/_utils/appUtils'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { addStudentInstallmentFxn } from '../actions'

const OneTimePayment = (props) => {
  let { visible, onClose, record } = props
  let { studentId } = record
  let dispatch = useDispatch()
  let [state, setState] = useState({
    documentKey: moment(),
    sendToStudent: false
  })
  let [amount, setAmount] = useState(record.amount)
  let [date, setDate] = useState()


  const events = {
    showDrawer: () => {
      setState({ ...state, noteDrawerVisible: true })
    },
    hideDrawer: () => {
      setState({ ...state, noteDrawerVisible: false })
    },
    setLocalState: (e) => {
      let { name, value } = e.target
      setState({
        ...state,
        [name]: value
      })
    },
    chooseDocument: (e) => {
      let { name, files } = e.target
      if (files && files.length) {
        setState({
          ...state,
          document: files[0]
        })
      }
    },

    handleSubmit: async () => {
      if (!amount) {
        notification.warning({
          message: 'Please Enter Amount'
        })
        return
      }
      if (!date) {
        notification.warning({
          message: 'Please Select Due Date'
        })
        return
      }

      let obj = {
        installments: [
          {
            amount: amount,
            createdAt: moment(),
            dueDate: date,
            installmentNo: 1,
            status: 'Pending'
          }
        ],
        studentId,
        paymentType: 'One Time',
        title: 'invoice'
      }
      let data = await dispatch(addStudentInstallmentFxn(obj))
      if (data && !data.error) {
        onClose()
      }
    }
  }

  return (
    <Drawer width={500}
            title={'One Time Payment'}
            visible={visible}
            onClose={() => {
              onClose()
            }}>
      <Card bordered={false}>
        <Form>
          <InputBox title={'Amount *'}>
            <Input type={'number'}
                   className={'form-control'}
                   value={amount}
                   onChange={(e) => {
                     setAmount(e.target.value)
                   }} />
          </InputBox>
          <InputBox title={'Due Date *'}>
            <DatePicker
              type='date'
              className='form-control'
              value={date}
              onChange={(e) =>
                setDate(e)
              }
            />
          </InputBox>
          <Form.Item className={'mt20 alignRight'}>
            <Button type='primary' onClick={events.handleSubmit}>
              SUBMIT
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Drawer>
  )
}
export default OneTimePayment
